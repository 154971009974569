<script setup>
</script>

<template>
  <div class="container mt-9">
    <ul class="nav justify-content-center pb-3">
      <li class="nav-item">
        <router-link to="/" class="nav-link px-2 text-body-secondary">
          Accueil
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/practice" class="nav-link px-2 text-body-secondary">
          Entrainement
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/posts" class="nav-link px-2 text-body-secondary">
          Journal
        </router-link>
      </li>
      <li class="nav-item">
        <a href="https://github.com/VincentHardouin/metro-travel" class="nav-link px-2 text-body-secondary">
          GitHub
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>
