---
slug: '2024-03-05'
title: '2024-03-05'
---

# 2024-03-05

Je rajoute la station de départ et d'arrivée sur la carte.
Avec un peu de refactoring pour pouvoir donner une couleur à la méthode `addStation`, j'arrive à un résultat qui me
plaît bien.

Aussi, je fixe la seed avec la date du jour en nombre : `20240305` pour que tous les joueurs aient les mêmes stations.
Cela m'a l'air concluant de ce côté-là.

En regardant la todo de la veille, je vois "- [ ] Enregistrer les stations déjà piochées ? Au moins celui de la vieille
pour ne pas tomber 2 jours de suite sur le même".
Vu ce que j'ai fait avec le random avec seed, je pense que c'est inutile.

Il manque en réalité, le moment où la personne a trouvé le chemin entre les deux stations.
C'est-à-dire, afficher le plus court chemin et le chemin qu'a fait le joueur.

Rapidement, j'ai demandé à ChatGPT de m'aider à obtenir le code pour zoomer avec la roulette, la feature est ajoutée
simplement, il restera des boutons à ajouter pour zoomer et dézoomer, ainsi qu'un bouton pour reset le zoom.

Allez, c'est le grand jour, j'ajoute le workflow de CD que je copie depuis un autre repo que j'ai fait.
