{
  "routes": [
    {
      "route_id": "IDFM:C01380",
      "route_short_name": "10",
      "route_long_name": "10",
      "route_type": "Subway",
      "route_color": "C9910D",
      "route_url": null,
      "id_ilico": "C01380",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-10.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.3003400592064946,
        "lat": 48.84717110903603
      }
    },
    {
      "route_id": "IDFM:C01371",
      "route_short_name": "1",
      "route_long_name": "1",
      "route_type": "Subway",
      "route_color": "FFBE00",
      "route_url": null,
      "id_ilico": "C01371",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-1.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.3299228902378135,
        "lat": 48.86524383472558
      }
    },
    {
      "route_id": "IDFM:C01386",
      "route_short_name": "3B",
      "route_long_name": "3B",
      "route_type": "Subway",
      "route_color": "6EC4E8",
      "route_url": null,
      "id_ilico": "C01386",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-3b.pdf",
      "long_name_first": "3",
      "geo_point_2d": {
        "lon": 2.4030809868334333,
        "lat": 48.870602267322354
      }
    },
    {
      "route_id": "IDFM:C01374",
      "route_short_name": "4",
      "route_long_name": "4",
      "route_type": "Subway",
      "route_color": "A0006E",
      "route_url": null,
      "id_ilico": "C01374",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-4.pdf",
      "long_name_first": "4",
      "geo_point_2d": {
        "lon": 2.3385449351024,
        "lat": 48.85308150963762
      }
    },
    {
      "route_id": "IDFM:C01376",
      "route_short_name": "6",
      "route_long_name": "6",
      "route_type": "Subway",
      "route_color": "6ECA97",
      "route_url": null,
      "id_ilico": "C01376",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-6.pdf",
      "long_name_first": "6",
      "geo_point_2d": {
        "lon": 2.337494120575029,
        "lat": 48.841931473955206
      }
    },
    {
      "route_id": "IDFM:C01378",
      "route_short_name": "8",
      "route_long_name": "8",
      "route_type": "Subway",
      "route_color": "D282BE",
      "route_url": null,
      "id_ilico": "C01378",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-8.pdf",
      "long_name_first": "8",
      "geo_point_2d": {
        "lon": 2.3675171195087836,
        "lat": 48.84145313727695
      }
    },
    {
      "route_id": "IDFM:C01379",
      "route_short_name": "9",
      "route_long_name": "9",
      "route_type": "Subway",
      "route_color": "B6BD00",
      "route_url": null,
      "id_ilico": "C01379",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-9.pdf",
      "long_name_first": "9",
      "geo_point_2d": {
        "lon": 2.343270148971878,
        "lat": 48.85946175326896
      }
    },
    {
      "route_id": "IDFM:C01382",
      "route_short_name": "12",
      "route_long_name": "12",
      "route_type": "Subway",
      "route_color": "007852",
      "route_url": null,
      "id_ilico": "C01382",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-12.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.3300037782725855,
        "lat": 48.86806075965758
      }
    },
    {
      "route_id": "IDFM:C01377",
      "route_short_name": "7",
      "route_long_name": "7",
      "route_type": "Subway",
      "route_color": "F49FB3",
      "route_url": null,
      "id_ilico": "C01377",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-7.1618237892.pdf",
      "long_name_first": "7",
      "geo_point_2d": {
        "lon": 2.3631758058482024,
        "lat": 48.85589825611279
      }
    },
    {
      "route_id": "IDFM:C01375",
      "route_short_name": "5",
      "route_long_name": "5",
      "route_type": "Subway",
      "route_color": "FF7E2E",
      "route_url": null,
      "id_ilico": "C01375",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-5.pdf",
      "long_name_first": "5",
      "geo_point_2d": {
        "lon": 2.387345751686032,
        "lat": 48.87872054142073
      }
    },
    {
      "route_id": "IDFM:C01372",
      "route_short_name": "2",
      "route_long_name": "2",
      "route_type": "Subway",
      "route_color": "0055C8",
      "route_url": null,
      "id_ilico": "C01372",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-2.pdf",
      "long_name_first": "2",
      "geo_point_2d": {
        "lon": 2.34297809072141,
        "lat": 48.87603939237403
      }
    },
    {
      "route_id": "IDFM:C01384",
      "route_short_name": "14",
      "route_long_name": "14",
      "route_type": "Subway",
      "route_color": "62259D",
      "route_url": null,
      "id_ilico": "C01384",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://ilico.iledefrance-mobilites.fr/uploads/plans/20240621T143138Z-20e94a14-b7b7-4826-a344-372c0ac054c1.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.350909327812072,
        "lat": 48.83702530011806
      }
    },
    {
      "route_id": "IDFM:C01387",
      "route_short_name": "7B",
      "route_long_name": "7B",
      "route_type": "Subway",
      "route_color": "6ECA97",
      "route_url": null,
      "id_ilico": "C01387",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-7b.pdf",
      "long_name_first": "7",
      "geo_point_2d": {
        "lon": 2.381741551634785,
        "lat": 48.88006689891539
      }
    },
    {
      "route_id": "IDFM:C01383",
      "route_short_name": "13",
      "route_long_name": "13",
      "route_type": "Subway",
      "route_color": "6EC4E8",
      "route_url": null,
      "id_ilico": "C01383",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-13.1607695529.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.3188914347613,
        "lat": 48.88290530909603
      }
    },
    {
      "route_id": "IDFM:C01373",
      "route_short_name": "3",
      "route_long_name": "3",
      "route_type": "Subway",
      "route_color": "6E6E00",
      "route_url": null,
      "id_ilico": "C01373",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://www.ratp.fr/sites/default/files/lines-assets/plan-de-ligne/metro/plan-de-ligne_metro_ligne-3.pdf",
      "long_name_first": "3",
      "geo_point_2d": {
        "lon": 2.3452011514034554,
        "lat": 48.87285149653619
      }
    },
    {
      "route_id": "IDFM:C01381",
      "route_short_name": "11",
      "route_long_name": "11",
      "route_type": "Subway",
      "route_color": "704B1C",
      "route_url": null,
      "id_ilico": "C01381",
      "operatorname": "RATP",
      "networkname": null,
      "url": "https://ilico.iledefrance-mobilites.fr/uploads/plans/20240704T125723Z-6531b8bf-8fa3-4600-a765-5e12e5ba03a8.pdf",
      "long_name_first": "1",
      "geo_point_2d": {
        "lon": 2.4076891847549002,
        "lat": 48.87529946855775
      }
    }
  ],
  "stops": [
    {
      "stop_id": "IDFM:21784",
      "stop_name": "Pointe du Lac",
      "stop_lat": 48.768752248609914,
      "stop_lon": 2.464290193819998,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:69884"
    },
    {
      "stop_id": "IDFM:21902",
      "stop_name": "République",
      "stop_lat": 48.86712186124499,
      "stop_lon": 2.364283168306328,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:21903",
      "stop_name": "République",
      "stop_lat": 48.867687468165165,
      "stop_lon": 2.3640990472225725,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:21904",
      "stop_name": "Michel-Ange - Molitor",
      "stop_lat": 48.84515597018703,
      "stop_lon": 2.2618218573220084,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73658"
    },
    {
      "stop_id": "IDFM:21905",
      "stop_name": "Oberkampf",
      "stop_lat": 48.864581198067484,
      "stop_lon": 2.3685262686038397,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71803"
    },
    {
      "stop_id": "IDFM:21906",
      "stop_name": "Havre-Caumartin",
      "stop_lat": 48.87346783937801,
      "stop_lon": 2.3274627984235092,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:482368"
    },
    {
      "stop_id": "IDFM:21907",
      "stop_name": "Michel-Ange - Auteuil",
      "stop_lat": 48.84797535417627,
      "stop_lon": 2.263989525333329,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71206"
    },
    {
      "stop_id": "IDFM:21908",
      "stop_name": "Rue de la Pompe",
      "stop_lat": 48.863951708879945,
      "stop_lon": 2.2778858979590564,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71292"
    },
    {
      "stop_id": "IDFM:21909",
      "stop_name": "Grands Boulevards",
      "stop_lat": 48.87141461757831,
      "stop_lon": 2.343167503558387,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71326"
    },
    {
      "stop_id": "IDFM:21910",
      "stop_name": "Saint-Ambroise",
      "stop_lat": 48.86141665790915,
      "stop_lon": 2.3738805382115475,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71781"
    },
    {
      "stop_id": "IDFM:21911",
      "stop_name": "Saint-Augustin",
      "stop_lat": 48.87454661293485,
      "stop_lon": 2.3210135978085997,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73690"
    },
    {
      "stop_id": "IDFM:21912",
      "stop_name": "Saint-Philippe du Roule",
      "stop_lat": 48.87215435003593,
      "stop_lon": 2.310001699600818,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71334"
    },
    {
      "stop_id": "IDFM:21913",
      "stop_name": "Porte de Montreuil",
      "stop_lat": 48.853563500943025,
      "stop_lon": 2.4107036110235414,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71710"
    },
    {
      "stop_id": "IDFM:21914",
      "stop_name": "Porte de Saint-Cloud",
      "stop_lat": 48.83795840561949,
      "stop_lon": 2.2570461929221506,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71084"
    },
    {
      "stop_id": "IDFM:21915",
      "stop_name": "Ranelagh",
      "stop_lat": 48.855306214887065,
      "stop_lon": 2.270032697561899,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71243"
    },
    {
      "stop_id": "IDFM:21916",
      "stop_name": "Voltaire",
      "stop_lat": 48.857735295553454,
      "stop_lon": 2.3802080115879227,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71750"
    },
    {
      "stop_id": "IDFM:21917",
      "stop_name": "Trocadéro",
      "stop_lat": 48.863088061116,
      "stop_lon": 2.28683987979629,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71285"
    },
    {
      "stop_id": "IDFM:21918",
      "stop_name": "République",
      "stop_lat": 48.86739447922248,
      "stop_lon": 2.3631211111325285,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:21919",
      "stop_name": "Nation",
      "stop_lat": 48.84888521841715,
      "stop_lon": 2.3959437210498136,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:21920",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.869349679171926,
      "stop_lon": 2.3537315826731833,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:21921",
      "stop_name": "Richelieu - Drouot",
      "stop_lat": 48.872135870317955,
      "stop_lon": 2.3385912215385414,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71330"
    },
    {
      "stop_id": "IDFM:21922",
      "stop_name": "Michel-Ange - Auteuil",
      "stop_lat": 48.84767825351756,
      "stop_lon": 2.2639392129884905,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71206"
    },
    {
      "stop_id": "IDFM:21923",
      "stop_name": "Michel-Ange - Molitor",
      "stop_lat": 48.84491122756327,
      "stop_lon": 2.261511916905205,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73658"
    },
    {
      "stop_id": "IDFM:21924",
      "stop_name": "Miromesnil",
      "stop_lat": 48.8737728492328,
      "stop_lon": 2.3146430401104467,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71346"
    },
    {
      "stop_id": "IDFM:21925",
      "stop_name": "Oberkampf",
      "stop_lat": 48.86477709852134,
      "stop_lon": 2.3681558453945515,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71803"
    },
    {
      "stop_id": "IDFM:21926",
      "stop_name": "Havre-Caumartin",
      "stop_lat": 48.87366675194512,
      "stop_lon": 2.327651103418782,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:482368"
    },
    {
      "stop_id": "IDFM:21927",
      "stop_name": "Iéna",
      "stop_lat": 48.864685839288676,
      "stop_lon": 2.293906396859107,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71294"
    },
    {
      "stop_id": "IDFM:21928",
      "stop_name": "Jasmin",
      "stop_lat": 48.852462147844555,
      "stop_lon": 2.268219083521012,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73657"
    },
    {
      "stop_id": "IDFM:21929",
      "stop_name": "La Muette",
      "stop_lat": 48.85809196689865,
      "stop_lon": 2.2740962446697224,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:474150"
    },
    {
      "stop_id": "IDFM:21930",
      "stop_name": "Exelmans",
      "stop_lat": 48.84258027627653,
      "stop_lon": 2.259800845144346,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71124"
    },
    {
      "stop_id": "IDFM:21931",
      "stop_name": "Franklin D. Roosevelt",
      "stop_lat": 48.86872488799857,
      "stop_lon": 2.309488192337113,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71318"
    },
    {
      "stop_id": "IDFM:21932",
      "stop_name": "Chaussée d'Antin - La Fayette",
      "stop_lat": 48.87288385004922,
      "stop_lon": 2.334000835220775,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73689"
    },
    {
      "stop_id": "IDFM:21933",
      "stop_name": "Bonne Nouvelle",
      "stop_lat": 48.8706433160169,
      "stop_lon": 2.3484940979375373,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71320"
    },
    {
      "stop_id": "IDFM:21934",
      "stop_name": "Charonne",
      "stop_lat": 48.85500743705073,
      "stop_lon": 2.3848878986690423,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73647"
    },
    {
      "stop_id": "IDFM:21935",
      "stop_name": "Alma - Marceau",
      "stop_lat": 48.8647282422178,
      "stop_lon": 2.301130527470699,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71290"
    },
    {
      "stop_id": "IDFM:21936",
      "stop_name": "Maraîchers",
      "stop_lat": 48.85273102047999,
      "stop_lon": 2.406038544798052,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71703"
    },
    {
      "stop_id": "IDFM:21937",
      "stop_name": "République",
      "stop_lat": 48.86794303120089,
      "stop_lon": 2.364804805140413,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:21938",
      "stop_name": "Pont de Sèvres",
      "stop_lat": 48.82968726438348,
      "stop_lon": 2.2305185727726955,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:70698"
    },
    {
      "stop_id": "IDFM:21939",
      "stop_name": "Billancourt",
      "stop_lat": 48.83183051284947,
      "stop_lon": 2.237965973200589,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71034"
    },
    {
      "stop_id": "IDFM:21940",
      "stop_name": "Marcel Sembat",
      "stop_lat": 48.83380810724753,
      "stop_lon": 2.24346821405889,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71054"
    },
    {
      "stop_id": "IDFM:21941",
      "stop_name": "Mairie de Montreuil",
      "stop_lat": 48.8622744369706,
      "stop_lon": 2.4418193772459085,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71785"
    },
    {
      "stop_id": "IDFM:21942",
      "stop_name": "Robespierre",
      "stop_lat": 48.855673808550314,
      "stop_lon": 2.4229730864963517,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71725"
    },
    {
      "stop_id": "IDFM:21943",
      "stop_name": "Croix de Chavaux",
      "stop_lat": 48.857992585158016,
      "stop_lon": 2.4358546126745826,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71756"
    },
    {
      "stop_id": "IDFM:21945",
      "stop_name": "Gambetta",
      "stop_lat": 48.865032564464,
      "stop_lon": 2.398537305704572,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71808"
    },
    {
      "stop_id": "IDFM:21946",
      "stop_name": "Saint-Fargeau",
      "stop_lat": 48.87184337222297,
      "stop_lon": 2.4044981831688688,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71860"
    },
    {
      "stop_id": "IDFM:21947",
      "stop_name": "Porte des Lilas",
      "stop_lat": 48.87656859902798,
      "stop_lon": 2.4070619733807948,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71889"
    },
    {
      "stop_id": "IDFM:21948",
      "stop_name": "Pelleport",
      "stop_lat": 48.86842046799948,
      "stop_lon": 2.40160636134298,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71828"
    },
    {
      "stop_id": "IDFM:21949",
      "stop_name": "Porte des Lilas",
      "stop_lat": 48.87723143067265,
      "stop_lon": 2.4065226763982275,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71889"
    },
    {
      "stop_id": "IDFM:21950",
      "stop_name": "Gambetta",
      "stop_lat": 48.86488271295642,
      "stop_lon": 2.397353102563538,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71808"
    },
    {
      "stop_id": "IDFM:21951",
      "stop_name": "Place des Fêtes",
      "stop_lat": 48.87672366197325,
      "stop_lon": 2.393139370360497,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71885"
    },
    {
      "stop_id": "IDFM:21952",
      "stop_name": "Jaurès",
      "stop_lat": 48.88302391342934,
      "stop_lon": 2.371438959985608,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:21954",
      "stop_name": "Olympiades",
      "stop_lat": 48.827123441429386,
      "stop_lon": 2.3669231215308075,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71557"
    },
    {
      "stop_id": "IDFM:21955",
      "stop_name": "Bibliothèque François Mitterrand",
      "stop_lat": 48.830006377536904,
      "stop_lon": 2.3764237443204546,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71572"
    },
    {
      "stop_id": "IDFM:21956",
      "stop_name": "Cour Saint-Emilion",
      "stop_lat": 48.83334999725014,
      "stop_lon": 2.386464909364189,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71576"
    },
    {
      "stop_id": "IDFM:21957",
      "stop_name": "Bercy",
      "stop_lat": 48.84014763512746,
      "stop_lon": 2.3791909087742873,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71607"
    },
    {
      "stop_id": "IDFM:21958",
      "stop_name": "Gare de Lyon",
      "stop_lat": 48.8442498880687,
      "stop_lon": 2.372519782814122,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73626"
    },
    {
      "stop_id": "IDFM:21959",
      "stop_name": "Châtelet",
      "stop_lat": 48.85951531585984,
      "stop_lon": 2.346275248188987,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:21960",
      "stop_name": "Pyramides",
      "stop_lat": 48.865755527376756,
      "stop_lon": 2.3346236060049232,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73788"
    },
    {
      "stop_id": "IDFM:21961",
      "stop_name": "Madeleine",
      "stop_lat": 48.87054467671624,
      "stop_lon": 2.3258100487932776,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:21962",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.87560743718584,
      "stop_lon": 2.324122029700433,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:21963",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.875025603040974,
      "stop_lon": 2.3246067760696794,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:21964",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.875689121953926,
      "stop_lon": 2.3274477500193202,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:21965",
      "stop_name": "Madeleine",
      "stop_lat": 48.8700546880179,
      "stop_lon": 2.325039317039756,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:21966",
      "stop_name": "Châtelet",
      "stop_lat": 48.85776111354502,
      "stop_lon": 2.3478374055687867,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:21967",
      "stop_name": "Gare d'Austerlitz",
      "stop_lat": 48.842355035073275,
      "stop_lon": 2.3645307640842486,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71135"
    },
    {
      "stop_id": "IDFM:21968",
      "stop_name": "Jussieu",
      "stop_lat": 48.846197891637004,
      "stop_lon": 2.3549316718232256,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71148"
    },
    {
      "stop_id": "IDFM:21969",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.84963080443241,
      "stop_lon": 2.2985257262366363,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:21970",
      "stop_name": "Duroc",
      "stop_lat": 48.84675637808778,
      "stop_lon": 2.3162247549746047,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71159"
    },
    {
      "stop_id": "IDFM:21971",
      "stop_name": "Église d'Auteuil",
      "stop_lat": 48.84714336145718,
      "stop_lon": 2.2691113890218917,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71166"
    },
    {
      "stop_id": "IDFM:21972",
      "stop_name": "Javel - André Citroën",
      "stop_lat": 48.84618110622323,
      "stop_lon": 2.278009496527359,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71150"
    },
    {
      "stop_id": "IDFM:21973",
      "stop_name": "Mabillon",
      "stop_lat": 48.85284373829949,
      "stop_lon": 2.335142819346476,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73639"
    },
    {
      "stop_id": "IDFM:21974",
      "stop_name": "Porte d'Auteuil",
      "stop_lat": 48.84812886119474,
      "stop_lon": 2.2582639186231943,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71169"
    },
    {
      "stop_id": "IDFM:21975",
      "stop_name": "Maubert - Mutualité",
      "stop_lat": 48.85010969451494,
      "stop_lon": 2.348219343927666,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71190"
    },
    {
      "stop_id": "IDFM:21976",
      "stop_name": "Sèvres - Babylone",
      "stop_lat": 48.85093150598804,
      "stop_lon": 2.326104689925573,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71203"
    },
    {
      "stop_id": "IDFM:21977",
      "stop_name": "Vaneau",
      "stop_lat": 48.84870050683084,
      "stop_lon": 2.321227717195941,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71170"
    },
    {
      "stop_id": "IDFM:21978",
      "stop_name": "Odéon",
      "stop_lat": 48.8519477060765,
      "stop_lon": 2.340619126473996,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73618"
    },
    {
      "stop_id": "IDFM:21979",
      "stop_name": "Ségur",
      "stop_lat": 48.847226390053955,
      "stop_lon": 2.3072520746428715,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71157"
    },
    {
      "stop_id": "IDFM:21980",
      "stop_name": "Sèvres - Babylone",
      "stop_lat": 48.85156530639137,
      "stop_lon": 2.326859663121737,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71203"
    },
    {
      "stop_id": "IDFM:21981",
      "stop_name": "Gare d'Austerlitz",
      "stop_lat": 48.84229779662816,
      "stop_lon": 2.3656078807625422,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71135"
    },
    {
      "stop_id": "IDFM:21982",
      "stop_name": "Mirabeau",
      "stop_lat": 48.84707750734599,
      "stop_lon": 2.2730641263728333,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71162"
    },
    {
      "stop_id": "IDFM:21983",
      "stop_name": "Cluny - La Sorbonne",
      "stop_lat": 48.851026876874485,
      "stop_lon": 2.3448963012120796,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73619"
    },
    {
      "stop_id": "IDFM:21984",
      "stop_name": "Cardinal Lemoine",
      "stop_lat": 48.84670003526311,
      "stop_lon": 2.3513280016731763,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71153"
    },
    {
      "stop_id": "IDFM:21985",
      "stop_name": "Chardon Lagache",
      "stop_lat": 48.84508829917885,
      "stop_lon": 2.26690547594029,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71141"
    },
    {
      "stop_id": "IDFM:21986",
      "stop_name": "Charles Michels",
      "stop_lat": 48.84636172348247,
      "stop_lon": 2.2856243417995747,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71156"
    },
    {
      "stop_id": "IDFM:21987",
      "stop_name": "Avenue Émile Zola",
      "stop_lat": 48.84695722990175,
      "stop_lon": 2.2950459810371475,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71158"
    },
    {
      "stop_id": "IDFM:21988",
      "stop_name": "Boulogne Jean Jaurès",
      "stop_lat": 48.842063301935575,
      "stop_lon": 2.239042669312605,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71147"
    },
    {
      "stop_id": "IDFM:21989",
      "stop_name": "Boulogne Pont de Saint-Cloud",
      "stop_lat": 48.84067248206251,
      "stop_lon": 2.2283125898228056,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:70721"
    },
    {
      "stop_id": "IDFM:21990",
      "stop_name": "Hôtel de Ville",
      "stop_lat": 48.85757442237991,
      "stop_lon": 2.35154698561547,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:415852"
    },
    {
      "stop_id": "IDFM:21991",
      "stop_name": "Pyrénées",
      "stop_lat": 48.87381265491752,
      "stop_lon": 2.384883957748741,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71864"
    },
    {
      "stop_id": "IDFM:21992",
      "stop_name": "Rambuteau",
      "stop_lat": 48.86119015695036,
      "stop_lon": 2.3532739485947713,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71273"
    },
    {
      "stop_id": "IDFM:21993",
      "stop_name": "Télégraphe",
      "stop_lat": 48.87551038418075,
      "stop_lon": 2.3986480899605933,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71870"
    },
    {
      "stop_id": "IDFM:21994",
      "stop_name": "Arts et Métiers",
      "stop_lat": 48.86553459885931,
      "stop_lon": 2.3567233472754983,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71293"
    },
    {
      "stop_id": "IDFM:21995",
      "stop_name": "Jourdain",
      "stop_lat": 48.875247348641416,
      "stop_lon": 2.389325378910096,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71868"
    },
    {
      "stop_id": "IDFM:21996",
      "stop_name": "Goncourt",
      "stop_lat": 48.8700070178386,
      "stop_lon": 2.3707644347484003,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71840"
    },
    {
      "stop_id": "IDFM:21997",
      "stop_name": "Arts et Métiers",
      "stop_lat": 48.86529961264491,
      "stop_lon": 2.3565081436435467,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71293"
    },
    {
      "stop_id": "IDFM:21998",
      "stop_name": "Belleville",
      "stop_lat": 48.871908975367674,
      "stop_lon": 2.376753738418521,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71853"
    },
    {
      "stop_id": "IDFM:21999",
      "stop_name": "Mairie des Lilas",
      "stop_lat": 48.87974365006289,
      "stop_lon": 2.4162978208011863,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71909"
    },
    {
      "stop_id": "IDFM:22000",
      "stop_name": "Saint-Marcel",
      "stop_lat": 48.83853898898134,
      "stop_lon": 2.3606397867113924,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71092"
    },
    {
      "stop_id": "IDFM:22001",
      "stop_name": "Stalingrad",
      "stop_lat": 48.8841445128949,
      "stop_lon": 2.367348121277192,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:22002",
      "stop_name": "Richard-Lenoir",
      "stop_lat": 48.859876937868705,
      "stop_lon": 2.371813630476951,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:73648"
    },
    {
      "stop_id": "IDFM:22003",
      "stop_name": "Porte de Pantin",
      "stop_lat": 48.888535337600175,
      "stop_lon": 2.3920607143419303,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71989"
    },
    {
      "stop_id": "IDFM:22004",
      "stop_name": "Quai de la Rapée",
      "stop_lat": 48.84649085420337,
      "stop_lon": 2.365992890937552,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71637"
    },
    {
      "stop_id": "IDFM:22005",
      "stop_name": "Ourcq",
      "stop_lat": 48.88714990283661,
      "stop_lon": 2.3866765356019912,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71972"
    },
    {
      "stop_id": "IDFM:22006",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.83096622708413,
      "stop_lon": 2.35559694336975,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:22007",
      "stop_name": "Gare du Nord",
      "stop_lat": 48.87970181695894,
      "stop_lon": 2.3572164888604563,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71410"
    },
    {
      "stop_id": "IDFM:22008",
      "stop_name": "Bréguet-Sabin",
      "stop_lat": 48.85624405468632,
      "stop_lon": 2.3701946685897517,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:73621"
    },
    {
      "stop_id": "IDFM:22009",
      "stop_name": "Campo-Formio",
      "stop_lat": 48.835595431233266,
      "stop_lon": 2.358468834639247,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71063"
    },
    {
      "stop_id": "IDFM:22010",
      "stop_name": "Bastille",
      "stop_lat": 48.85328747830434,
      "stop_lon": 2.368922075646989,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:22011",
      "stop_name": "Jacques Bonsergent",
      "stop_lat": 48.870666468034706,
      "stop_lon": 2.3611315892483544,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71322"
    },
    {
      "stop_id": "IDFM:22012",
      "stop_name": "Laumière",
      "stop_lat": 48.88513384808583,
      "stop_lon": 2.3793909893523923,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71957"
    },
    {
      "stop_id": "IDFM:22013",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.87616299446643,
      "stop_lon": 2.35806459441865,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:22014",
      "stop_name": "Bobigny-Pantin - Raymond Queneau",
      "stop_lat": 48.89560207433252,
      "stop_lon": 2.426308679232036,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72039"
    },
    {
      "stop_id": "IDFM:22015",
      "stop_name": "Bobigny Pablo Picasso",
      "stop_lat": 48.9063655341063,
      "stop_lon": 2.4490271414582585,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72486"
    },
    {
      "stop_id": "IDFM:22016",
      "stop_name": "Église de Pantin",
      "stop_lat": 48.893163974098265,
      "stop_lon": 2.413119553706935,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72024"
    },
    {
      "stop_id": "IDFM:22017",
      "stop_name": "Hoche",
      "stop_lat": 48.89128939386479,
      "stop_lon": 2.4032119887626737,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72004"
    },
    {
      "stop_id": "IDFM:22018",
      "stop_name": "Réaumur - Sébastopol",
      "stop_lat": 48.86638175675157,
      "stop_lon": 2.3520507871028355,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73636"
    },
    {
      "stop_id": "IDFM:22019",
      "stop_name": "Parmentier",
      "stop_lat": 48.86524326053047,
      "stop_lon": 2.3747205969196896,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71801"
    },
    {
      "stop_id": "IDFM:22020",
      "stop_name": "Père Lachaise",
      "stop_lat": 48.86296417435419,
      "stop_lon": 2.386157046298338,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71787"
    },
    {
      "stop_id": "IDFM:22021",
      "stop_name": "Pereire",
      "stop_lat": 48.88484432273985,
      "stop_lon": 2.2976831860125837,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71453"
    },
    {
      "stop_id": "IDFM:22022",
      "stop_name": "Porte de Bagnolet",
      "stop_lat": 48.86432836987192,
      "stop_lon": 2.4088234090802456,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71799"
    },
    {
      "stop_id": "IDFM:22023",
      "stop_name": "Porte de Champerret",
      "stop_lat": 48.885736266685264,
      "stop_lon": 2.291848147336647,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71450"
    },
    {
      "stop_id": "IDFM:22024",
      "stop_name": "Quatre Septembre",
      "stop_lat": 48.86959592111917,
      "stop_lon": 2.3362786423248583,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71313"
    },
    {
      "stop_id": "IDFM:22025",
      "stop_name": "Rue Saint-Maur",
      "stop_lat": 48.86413108020325,
      "stop_lon": 2.380486332251813,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71795"
    },
    {
      "stop_id": "IDFM:22026",
      "stop_name": "Temple",
      "stop_lat": 48.86675662943444,
      "stop_lon": 2.361561234526356,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71296"
    },
    {
      "stop_id": "IDFM:22027",
      "stop_name": "Villiers",
      "stop_lat": 48.88107318307563,
      "stop_lon": 2.315815092646378,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71403"
    },
    {
      "stop_id": "IDFM:22028",
      "stop_name": "Wagram",
      "stop_lat": 48.88376067556563,
      "stop_lon": 2.3046251407269573,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71423"
    },
    {
      "stop_id": "IDFM:22029",
      "stop_name": "Sentier",
      "stop_lat": 48.86734660429376,
      "stop_lon": 2.3475538159377884,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73634"
    },
    {
      "stop_id": "IDFM:22030",
      "stop_name": "Opéra",
      "stop_lat": 48.87143742899726,
      "stop_lon": 2.331047286711241,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:22031",
      "stop_name": "Malesherbes",
      "stop_lat": 48.882763294053696,
      "stop_lon": 2.3094384103380805,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71416"
    },
    {
      "stop_id": "IDFM:22032",
      "stop_name": "Europe",
      "stop_lat": 48.878780759649736,
      "stop_lon": 2.3222946416498362,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73617"
    },
    {
      "stop_id": "IDFM:22033",
      "stop_name": "Bourse",
      "stop_lat": 48.868792843933164,
      "stop_lon": 2.3406785469758815,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73635"
    },
    {
      "stop_id": "IDFM:22034",
      "stop_name": "Louise Michel",
      "stop_lat": 48.88853900119613,
      "stop_lon": 2.2882778490679767,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73667"
    },
    {
      "stop_id": "IDFM:22035",
      "stop_name": "Pont de Levallois - Bécon",
      "stop_lat": 48.89718710383118,
      "stop_lon": 2.2801801985017236,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:72057"
    },
    {
      "stop_id": "IDFM:22036",
      "stop_name": "Anatole France",
      "stop_lat": 48.892206617614,
      "stop_lon": 2.2849816963403193,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71519"
    },
    {
      "stop_id": "IDFM:22037",
      "stop_name": "Gallieni",
      "stop_lat": 48.86512770051251,
      "stop_lon": 2.4159299613832097,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71817"
    },
    {
      "stop_id": "IDFM:22038",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84382361125447,
      "stop_lon": 2.323989185205003,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:22039",
      "stop_name": "Pasteur",
      "stop_lat": 48.842958015471986,
      "stop_lon": 2.312554745666783,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71125"
    },
    {
      "stop_id": "IDFM:22040",
      "stop_name": "Pigalle",
      "stop_lat": 48.882011788411084,
      "stop_lon": 2.3371840091053384,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71409"
    },
    {
      "stop_id": "IDFM:22041",
      "stop_name": "Porte de la Chapelle",
      "stop_lat": 48.89740242267042,
      "stop_lon": 2.359248542310361,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72064"
    },
    {
      "stop_id": "IDFM:22042",
      "stop_name": "Porte de Versailles",
      "stop_lat": 48.83230151439446,
      "stop_lon": 2.2881329450932197,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71045"
    },
    {
      "stop_id": "IDFM:22043",
      "stop_name": "Rue du Bac",
      "stop_lat": 48.85587553200213,
      "stop_lon": 2.3256727584985164,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71227"
    },
    {
      "stop_id": "IDFM:22044",
      "stop_name": "Saint-Georges",
      "stop_lat": 48.87841659490173,
      "stop_lon": 2.3375706403796697,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71366"
    },
    {
      "stop_id": "IDFM:22045",
      "stop_name": "Solférino",
      "stop_lat": 48.858550089641746,
      "stop_lon": 2.3231029882985132,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71270"
    },
    {
      "stop_id": "IDFM:22046",
      "stop_name": "Trinité - d'Estienne d'Orves",
      "stop_lat": 48.87625096700832,
      "stop_lon": 2.333030634158166,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71355"
    },
    {
      "stop_id": "IDFM:22047",
      "stop_name": "Vaugirard",
      "stop_lat": 48.839437918076904,
      "stop_lon": 2.3010745156545456,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71091"
    },
    {
      "stop_id": "IDFM:22048",
      "stop_name": "Volontaires",
      "stop_lat": 48.84141172841498,
      "stop_lon": 2.3079833805768515,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71113"
    },
    {
      "stop_id": "IDFM:22049",
      "stop_name": "Rennes",
      "stop_lat": 48.84833282904063,
      "stop_lon": 2.3277871847873874,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73640"
    },
    {
      "stop_id": "IDFM:22050",
      "stop_name": "Notre-Dame-de-Lorette",
      "stop_lat": 48.8760350312806,
      "stop_lon": 2.3378736192226386,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71350"
    },
    {
      "stop_id": "IDFM:22051",
      "stop_name": "Notre-Dame des Champs",
      "stop_lat": 48.84508150807547,
      "stop_lon": 2.328564944227944,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71138"
    },
    {
      "stop_id": "IDFM:22052",
      "stop_name": "Marcadet - Poissonniers",
      "stop_lat": 48.89145292865467,
      "stop_lon": 2.3499794329782024,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71511"
    },
    {
      "stop_id": "IDFM:22053",
      "stop_name": "Marx Dormoy",
      "stop_lat": 48.89060729278663,
      "stop_lon": 2.359945883178033,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71510"
    },
    {
      "stop_id": "IDFM:22054",
      "stop_name": "Jules Joffrin",
      "stop_lat": 48.892492268201934,
      "stop_lon": 2.3443200293804827,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71521"
    },
    {
      "stop_id": "IDFM:22055",
      "stop_name": "Lamarck - Caulaincourt",
      "stop_lat": 48.889699132276796,
      "stop_lon": 2.338463589982122,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71492"
    },
    {
      "stop_id": "IDFM:22056",
      "stop_name": "Falguière",
      "stop_lat": 48.844317683534335,
      "stop_lon": 2.317551235617027,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73641"
    },
    {
      "stop_id": "IDFM:22057",
      "stop_name": "Concorde",
      "stop_lat": 48.86615003482594,
      "stop_lon": 2.323181449571859,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:22058",
      "stop_name": "Convention",
      "stop_lat": 48.837125900520995,
      "stop_lon": 2.2963642614342694,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71075"
    },
    {
      "stop_id": "IDFM:22059",
      "stop_name": "Abbesses",
      "stop_lat": 48.884383574623314,
      "stop_lon": 2.338367478180517,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71432"
    },
    {
      "stop_id": "IDFM:22060",
      "stop_name": "Assemblée Nationale",
      "stop_lat": 48.861071939526084,
      "stop_lon": 2.320575836647556,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73642"
    },
    {
      "stop_id": "IDFM:22061",
      "stop_name": "Corentin Celton",
      "stop_lat": 48.826823295159116,
      "stop_lon": 2.2789586605256367,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:70990"
    },
    {
      "stop_id": "IDFM:22062",
      "stop_name": "Mairie d'Issy",
      "stop_lat": 48.82416442588802,
      "stop_lon": 2.273560674093182,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:70671"
    },
    {
      "stop_id": "IDFM:22072",
      "stop_name": "Nation",
      "stop_lat": 48.84845279707999,
      "stop_lon": 2.3957988351125254,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:22073",
      "stop_name": "Nation",
      "stop_lat": 48.84789550545761,
      "stop_lon": 2.3958598043279085,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:22074",
      "stop_name": "Saint-Paul (Le Marais)",
      "stop_lat": 48.85518742160445,
      "stop_lon": 2.3608852562751967,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71222"
    },
    {
      "stop_id": "IDFM:22075",
      "stop_name": "Tuileries",
      "stop_lat": 48.864550163577015,
      "stop_lon": 2.3297453212172794,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71284"
    },
    {
      "stop_id": "IDFM:22076",
      "stop_name": "Reuilly - Diderot",
      "stop_lat": 48.84723403765801,
      "stop_lon": 2.386056169673362,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71654"
    },
    {
      "stop_id": "IDFM:22077",
      "stop_name": "Porte de Vincennes",
      "stop_lat": 48.84700773108863,
      "stop_lon": 2.4108049967015006,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71663"
    },
    {
      "stop_id": "IDFM:22078",
      "stop_name": "Porte Maillot",
      "stop_lat": 48.87816265364455,
      "stop_lon": 2.2825838473615496,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71379"
    },
    {
      "stop_id": "IDFM:22079",
      "stop_name": "Palais Royal - Musée du Louvre",
      "stop_lat": 48.86222226557415,
      "stop_lon": 2.3364543677542517,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71297"
    },
    {
      "stop_id": "IDFM:22080",
      "stop_name": "Nation",
      "stop_lat": 48.848084289973976,
      "stop_lon": 2.3958439887237373,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:22081",
      "stop_name": "Louvre - Rivoli",
      "stop_lat": 48.86078090878698,
      "stop_lon": 2.3409026134809903,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:73630"
    },
    {
      "stop_id": "IDFM:22082",
      "stop_name": "Franklin D. Roosevelt",
      "stop_lat": 48.8691648256349,
      "stop_lon": 2.309359666519628,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71318"
    },
    {
      "stop_id": "IDFM:22083",
      "stop_name": "Gare de Lyon",
      "stop_lat": 48.8456832067358,
      "stop_lon": 2.3731565937892047,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:73626"
    },
    {
      "stop_id": "IDFM:22084",
      "stop_name": "George V",
      "stop_lat": 48.87203776458909,
      "stop_lon": 2.300759784978918,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71328"
    },
    {
      "stop_id": "IDFM:22085",
      "stop_name": "Concorde",
      "stop_lat": 48.866472156532026,
      "stop_lon": 2.3228909937191604,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:22086",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87392926445533,
      "stop_lon": 2.2951172138230334,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:22087",
      "stop_name": "Châtelet",
      "stop_lat": 48.85922471342816,
      "stop_lon": 2.3457609541847755,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:22088",
      "stop_name": "Argentine",
      "stop_lat": 48.87566737659971,
      "stop_lon": 2.289435418542214,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71348"
    },
    {
      "stop_id": "IDFM:22089",
      "stop_name": "Bastille",
      "stop_lat": 48.85205429349772,
      "stop_lon": 2.368718961034089,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:22090",
      "stop_name": "Champs-Élysées - Clemenceau",
      "stop_lat": 48.867583959552555,
      "stop_lon": 2.314397292025236,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71305"
    },
    {
      "stop_id": "IDFM:22091",
      "stop_name": "Hôtel de Ville",
      "stop_lat": 48.85735240518587,
      "stop_lon": 2.3520676701391,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:415852"
    },
    {
      "stop_id": "IDFM:22092",
      "stop_name": "Châtelet",
      "stop_lat": 48.859661211559086,
      "stop_lon": 2.3466414308440138,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:22093",
      "stop_name": "Champs-Élysées - Clemenceau",
      "stop_lat": 48.86750083759083,
      "stop_lon": 2.31401668387612,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71305"
    },
    {
      "stop_id": "IDFM:22094",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87499457617108,
      "stop_lon": 2.2958117752357627,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:22095",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87383861478572,
      "stop_lon": 2.294995735441831,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:22096",
      "stop_name": "Concorde",
      "stop_lat": 48.86652125193945,
      "stop_lon": 2.3220315426557194,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:22097",
      "stop_name": "Bastille",
      "stop_lat": 48.853425461693256,
      "stop_lon": 2.3695064282002,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:22098",
      "stop_name": "Reuilly - Diderot",
      "stop_lat": 48.84721292669797,
      "stop_lon": 2.3872010704093922,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71654"
    },
    {
      "stop_id": "IDFM:22099",
      "stop_name": "Pont de Neuilly",
      "stop_lat": 48.885489388974705,
      "stop_lon": 2.2584954293691255,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71442"
    },
    {
      "stop_id": "IDFM:22100",
      "stop_name": "Les Sablons",
      "stop_lat": 48.88129083793358,
      "stop_lon": 2.271904762259129,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:425779"
    },
    {
      "stop_id": "IDFM:22101",
      "stop_name": "La Défense (Grande Arche)",
      "stop_lat": 48.89181786646099,
      "stop_lon": 2.2379881830988277,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71517"
    },
    {
      "stop_id": "IDFM:22102",
      "stop_name": "Esplanade de la Défense",
      "stop_lat": 48.88833173800946,
      "stop_lon": 2.2499040727323307,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71485"
    },
    {
      "stop_id": "IDFM:22103",
      "stop_name": "Saint-Mandé",
      "stop_lat": 48.846238208060065,
      "stop_lon": 2.4189623545327077,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71647"
    },
    {
      "stop_id": "IDFM:22104",
      "stop_name": "Château de Vincennes",
      "stop_lat": 48.84431751431355,
      "stop_lon": 2.440540095406114,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71634"
    },
    {
      "stop_id": "IDFM:22105",
      "stop_name": "Bérault",
      "stop_lat": 48.84540614839331,
      "stop_lon": 2.4292049740990573,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71650"
    },
    {
      "stop_id": "IDFM:22106",
      "stop_name": "Villiers",
      "stop_lat": 48.88130644704797,
      "stop_lon": 2.3157165772440385,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71403"
    },
    {
      "stop_id": "IDFM:22107",
      "stop_name": "Stalingrad",
      "stop_lat": 48.884243984567746,
      "stop_lon": 2.365751439124989,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:22108",
      "stop_name": "Ternes",
      "stop_lat": 48.8782007528283,
      "stop_lon": 2.2981136521983068,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71367"
    },
    {
      "stop_id": "IDFM:22109",
      "stop_name": "Victor Hugo",
      "stop_lat": 48.8698545716049,
      "stop_lon": 2.2859020006220643,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71315"
    },
    {
      "stop_id": "IDFM:22110",
      "stop_name": "Rome",
      "stop_lat": 48.882256139435015,
      "stop_lon": 2.3214039779193896,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71408"
    },
    {
      "stop_id": "IDFM:22111",
      "stop_name": "Porte Dauphine",
      "stop_lat": 48.871396795248316,
      "stop_lon": 2.2776327175452717,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:474152"
    },
    {
      "stop_id": "IDFM:22112",
      "stop_name": "Père Lachaise",
      "stop_lat": 48.86248637862667,
      "stop_lon": 2.3876893260656895,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71787"
    },
    {
      "stop_id": "IDFM:22113",
      "stop_name": "Philippe Auguste",
      "stop_lat": 48.858175121260864,
      "stop_lon": 2.3905477055525735,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71751"
    },
    {
      "stop_id": "IDFM:22114",
      "stop_name": "Pigalle",
      "stop_lat": 48.882269542226226,
      "stop_lon": 2.338257976509587,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71409"
    },
    {
      "stop_id": "IDFM:22115",
      "stop_name": "Place de Clichy",
      "stop_lat": 48.88366908827266,
      "stop_lon": 2.3279583280102196,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71435"
    },
    {
      "stop_id": "IDFM:22116",
      "stop_name": "Ménilmontant",
      "stop_lat": 48.866440166781686,
      "stop_lon": 2.38353966877949,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71815"
    },
    {
      "stop_id": "IDFM:22117",
      "stop_name": "Monceau",
      "stop_lat": 48.880568770126835,
      "stop_lon": 2.309412967374703,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:73671"
    },
    {
      "stop_id": "IDFM:22118",
      "stop_name": "Jaurès",
      "stop_lat": 48.88159683079925,
      "stop_lon": 2.370269867509251,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:22119",
      "stop_name": "La Chapelle",
      "stop_lat": 48.884386410852784,
      "stop_lon": 2.3604041692740987,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71434"
    },
    {
      "stop_id": "IDFM:22120",
      "stop_name": "Colonel Fabien",
      "stop_lat": 48.877695345805535,
      "stop_lon": 2.3705761028196575,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71893"
    },
    {
      "stop_id": "IDFM:22121",
      "stop_name": "Courcelles",
      "stop_lat": 48.87926525411311,
      "stop_lon": 2.3032943624252122,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71376"
    },
    {
      "stop_id": "IDFM:22122",
      "stop_name": "Alexandre Dumas",
      "stop_lat": 48.856199097341126,
      "stop_lon": 2.3945914186721278,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71728"
    },
    {
      "stop_id": "IDFM:22123",
      "stop_name": "Anvers",
      "stop_lat": 48.882868568843925,
      "stop_lon": 2.3441548403302916,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71419"
    },
    {
      "stop_id": "IDFM:22124",
      "stop_name": "Avron",
      "stop_lat": 48.85164854359716,
      "stop_lon": 2.3981875566593973,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71697"
    },
    {
      "stop_id": "IDFM:22125",
      "stop_name": "Blanche",
      "stop_lat": 48.88376635447715,
      "stop_lon": 2.3324843754343627,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71420"
    },
    {
      "stop_id": "IDFM:22126",
      "stop_name": "Couronnes",
      "stop_lat": 48.86932051914457,
      "stop_lon": 2.3805336747351005,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71834"
    },
    {
      "stop_id": "IDFM:22127",
      "stop_name": "Barbès - Rochechouart",
      "stop_lat": 48.88377608955688,
      "stop_lon": 2.3506070793550977,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71426"
    },
    {
      "stop_id": "IDFM:22128",
      "stop_name": "Belleville",
      "stop_lat": 48.872314077361665,
      "stop_lon": 2.3768306915222848,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71853"
    },
    {
      "stop_id": "IDFM:22129",
      "stop_name": "Place de Clichy",
      "stop_lat": 48.883737890227636,
      "stop_lon": 2.327398345647013,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71435"
    },
    {
      "stop_id": "IDFM:22130",
      "stop_name": "Barbès - Rochechouart",
      "stop_lat": 48.883402060486226,
      "stop_lon": 2.349629927853002,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71426"
    },
    {
      "stop_id": "IDFM:22131",
      "stop_name": "Raspail",
      "stop_lat": 48.83915635952557,
      "stop_lon": 2.330466993719873,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71088"
    },
    {
      "stop_id": "IDFM:22132",
      "stop_name": "Marcadet - Poissonniers",
      "stop_lat": 48.891280439844486,
      "stop_lon": 2.3496815417224775,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71511"
    },
    {
      "stop_id": "IDFM:22133",
      "stop_name": "Simplon",
      "stop_lat": 48.8938152613519,
      "stop_lon": 2.347794968187123,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71556"
    },
    {
      "stop_id": "IDFM:22134",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86962358263758,
      "stop_lon": 2.354491616563834,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:22135",
      "stop_name": "Vavin",
      "stop_lat": 48.842242093452064,
      "stop_lon": 2.32902391746117,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71117"
    },
    {
      "stop_id": "IDFM:22136",
      "stop_name": "Saint-Germain-des-Prés",
      "stop_lat": 48.85357456382241,
      "stop_lon": 2.333947810798194,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71216"
    },
    {
      "stop_id": "IDFM:22137",
      "stop_name": "Saint-Michel",
      "stop_lat": 48.85358493521485,
      "stop_lon": 2.343964657062377,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73620"
    },
    {
      "stop_id": "IDFM:22138",
      "stop_name": "Saint-Placide",
      "stop_lat": 48.847041473609366,
      "stop_lon": 2.3268499626718526,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71184"
    },
    {
      "stop_id": "IDFM:22139",
      "stop_name": "Saint-Sulpice",
      "stop_lat": 48.85120862756671,
      "stop_lon": 2.330611953081543,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71202"
    },
    {
      "stop_id": "IDFM:22140",
      "stop_name": "Porte d'Orléans",
      "stop_lat": 48.823416351140224,
      "stop_lon": 2.3254932653821045,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70657"
    },
    {
      "stop_id": "IDFM:22141",
      "stop_name": "Porte de Clignancourt",
      "stop_lat": 48.897557781418705,
      "stop_lon": 2.3447472731828847,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:478883"
    },
    {
      "stop_id": "IDFM:22142",
      "stop_name": "Réaumur - Sébastopol",
      "stop_lat": 48.86604305354321,
      "stop_lon": 2.352613903045621,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73636"
    },
    {
      "stop_id": "IDFM:22143",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84385251452581,
      "stop_lon": 2.3243294607791096,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:22144",
      "stop_name": "Mouton-Duvernet",
      "stop_lat": 48.831382009093616,
      "stop_lon": 2.32979034782274,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73653"
    },
    {
      "stop_id": "IDFM:22145",
      "stop_name": "Odéon",
      "stop_lat": 48.85210334038093,
      "stop_lon": 2.339486020317896,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73618"
    },
    {
      "stop_id": "IDFM:22146",
      "stop_name": "Les Halles",
      "stop_lat": 48.86252665962869,
      "stop_lon": 2.34600573656331,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73794"
    },
    {
      "stop_id": "IDFM:22147",
      "stop_name": "Etienne Marcel",
      "stop_lat": 48.86370301202431,
      "stop_lon": 2.3489761968791085,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:479068"
    },
    {
      "stop_id": "IDFM:22148",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.87627821831841,
      "stop_lon": 2.3577495818204395,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:22149",
      "stop_name": "Gare du Nord",
      "stop_lat": 48.879510553130615,
      "stop_lon": 2.356768884572096,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71410"
    },
    {
      "stop_id": "IDFM:22150",
      "stop_name": "Cité",
      "stop_lat": 48.85493384910671,
      "stop_lon": 2.3472322349319112,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71223"
    },
    {
      "stop_id": "IDFM:22151",
      "stop_name": "Denfert-Rochereau",
      "stop_lat": 48.83392232305997,
      "stop_lon": 2.3321281604376116,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71056"
    },
    {
      "stop_id": "IDFM:22152",
      "stop_name": "Château Rouge",
      "stop_lat": 48.887241930648955,
      "stop_lon": 2.3495819680256593,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71469"
    },
    {
      "stop_id": "IDFM:22153",
      "stop_name": "Château d'Eau",
      "stop_lat": 48.87244691759551,
      "stop_lon": 2.3560517414015862,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71333"
    },
    {
      "stop_id": "IDFM:22154",
      "stop_name": "Alésia",
      "stop_lat": 48.82820106421924,
      "stop_lon": 2.3270932349484528,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71030"
    },
    {
      "stop_id": "IDFM:22155",
      "stop_name": "Raspail",
      "stop_lat": 48.83908487281128,
      "stop_lon": 2.330549660589195,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71088"
    },
    {
      "stop_id": "IDFM:22156",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84205447194666,
      "stop_lon": 2.321273396249205,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:22157",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84231364506487,
      "stop_lon": 2.320983883036087,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:22158",
      "stop_name": "Denfert-Rochereau",
      "stop_lat": 48.83431320632965,
      "stop_lon": 2.3328724454976864,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71056"
    },
    {
      "stop_id": "IDFM:22159",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86938572399119,
      "stop_lon": 2.353744768129407,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:22160",
      "stop_name": "Saint-Jacques",
      "stop_lat": 48.832916006219165,
      "stop_lon": 2.337154370925007,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71041"
    },
    {
      "stop_id": "IDFM:22161",
      "stop_name": "Quai de la Gare",
      "stop_lat": 48.83700262953923,
      "stop_lon": 2.372825013717543,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71597"
    },
    {
      "stop_id": "IDFM:22162",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.848697697821954,
      "stop_lon": 2.298906224573169,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:22163",
      "stop_name": "Sèvres-Lecourbe",
      "stop_lat": 48.84558437021755,
      "stop_lon": 2.3094677673863235,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71144"
    },
    {
      "stop_id": "IDFM:22164",
      "stop_name": "Trocadéro",
      "stop_lat": 48.86346147371448,
      "stop_lon": 2.287625377853519,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71285"
    },
    {
      "stop_id": "IDFM:22165",
      "stop_name": "Passy",
      "stop_lat": 48.85751508962033,
      "stop_lon": 2.285839418881462,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71237"
    },
    {
      "stop_id": "IDFM:22166",
      "stop_name": "Pasteur",
      "stop_lat": 48.842482718285865,
      "stop_lon": 2.312792651441268,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71125"
    },
    {
      "stop_id": "IDFM:22167",
      "stop_name": "Picpus",
      "stop_lat": 48.84501767720269,
      "stop_lon": 2.401248269453829,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71639"
    },
    {
      "stop_id": "IDFM:22168",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.830914982874944,
      "stop_lon": 2.356101612721528,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:22169",
      "stop_name": "Nationale",
      "stop_lat": 48.83318160406702,
      "stop_lon": 2.362857958087621,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71043"
    },
    {
      "stop_id": "IDFM:22170",
      "stop_name": "Kléber",
      "stop_lat": 48.87148941293503,
      "stop_lon": 2.293146137248626,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73656"
    },
    {
      "stop_id": "IDFM:22171",
      "stop_name": "Dupleix",
      "stop_lat": 48.8504116258067,
      "stop_lon": 2.293663726731039,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71197"
    },
    {
      "stop_id": "IDFM:22172",
      "stop_name": "Edgar Quinet",
      "stop_lat": 48.84098044632817,
      "stop_lon": 2.3253749636089105,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71108"
    },
    {
      "stop_id": "IDFM:22173",
      "stop_name": "Glacière",
      "stop_lat": 48.831170235245835,
      "stop_lon": 2.3434974927179884,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71026"
    },
    {
      "stop_id": "IDFM:22174",
      "stop_name": "Chevaleret",
      "stop_lat": 48.83496308210916,
      "stop_lon": 2.368081282972743,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73651"
    },
    {
      "stop_id": "IDFM:22175",
      "stop_name": "Corvisart",
      "stop_lat": 48.82986001331238,
      "stop_lon": 2.350611225926166,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71013"
    },
    {
      "stop_id": "IDFM:22176",
      "stop_name": "Cambronne",
      "stop_lat": 48.84754311219352,
      "stop_lon": 2.3029417283376117,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71167"
    },
    {
      "stop_id": "IDFM:22177",
      "stop_name": "Bel-Air",
      "stop_lat": 48.84142733223358,
      "stop_lon": 2.400867131995259,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73650"
    },
    {
      "stop_id": "IDFM:22178",
      "stop_name": "Bercy",
      "stop_lat": 48.840176028121896,
      "stop_lon": 2.379463070185257,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71607"
    },
    {
      "stop_id": "IDFM:22179",
      "stop_name": "Bir-Hakeim",
      "stop_lat": 48.85388427726308,
      "stop_lon": 2.2892963570634604,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:473829"
    },
    {
      "stop_id": "IDFM:22180",
      "stop_name": "Boissière",
      "stop_lat": 48.8668483434173,
      "stop_lon": 2.2900328376074373,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71301"
    },
    {
      "stop_id": "IDFM:22181",
      "stop_name": "Daumesnil",
      "stop_lat": 48.839434008053175,
      "stop_lon": 2.396148628489371,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71614"
    },
    {
      "stop_id": "IDFM:22182",
      "stop_name": "Dugommier",
      "stop_lat": 48.838951094045356,
      "stop_lon": 2.3896007705991944,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71606"
    },
    {
      "stop_id": "IDFM:22183",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.84950618174656,
      "stop_lon": 2.298745444388579,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:22184",
      "stop_name": "Daumesnil",
      "stop_lat": 48.83967393140864,
      "stop_lon": 2.395573606140371,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71614"
    },
    {
      "stop_id": "IDFM:22185",
      "stop_name": "Invalides",
      "stop_lat": 48.86053445554123,
      "stop_lon": 2.3146399951225347,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71274"
    },
    {
      "stop_id": "IDFM:22186",
      "stop_name": "Balard",
      "stop_lat": 48.836650792286115,
      "stop_lon": 2.2785093588069993,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71073"
    },
    {
      "stop_id": "IDFM:22187",
      "stop_name": "Lourmel",
      "stop_lat": 48.83866086365992,
      "stop_lon": 2.2822419598550767,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71086"
    },
    {
      "stop_id": "IDFM:22188",
      "stop_name": "Boucicaut",
      "stop_lat": 48.841024160993214,
      "stop_lon": 2.2879184311245595,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:412992"
    },
    {
      "stop_id": "IDFM:22189",
      "stop_name": "Félix Faure",
      "stop_lat": 48.84268433479664,
      "stop_lon": 2.2918472203679703,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71137"
    },
    {
      "stop_id": "IDFM:22190",
      "stop_name": "Commerce",
      "stop_lat": 48.84463784771031,
      "stop_lon": 2.293687475167137,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71132"
    },
    {
      "stop_id": "IDFM:22191",
      "stop_name": "École Militaire",
      "stop_lat": 48.85491966058707,
      "stop_lon": 2.3063456838200764,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71224"
    },
    {
      "stop_id": "IDFM:22192",
      "stop_name": "La Tour-Maubourg",
      "stop_lat": 48.85766922956403,
      "stop_lon": 2.3105887898739876,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71242"
    },
    {
      "stop_id": "IDFM:22193",
      "stop_name": "Invalides",
      "stop_lat": 48.86109201138112,
      "stop_lon": 2.3146326604445164,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71274"
    },
    {
      "stop_id": "IDFM:22194",
      "stop_name": "Madeleine",
      "stop_lat": 48.86956561611772,
      "stop_lon": 2.3260272117729994,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:22195",
      "stop_name": "Opéra",
      "stop_lat": 48.87059812248669,
      "stop_lon": 2.332135072925294,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:22196",
      "stop_name": "Richelieu - Drouot",
      "stop_lat": 48.87173920910264,
      "stop_lon": 2.3384190255427955,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71330"
    },
    {
      "stop_id": "IDFM:22197",
      "stop_name": "Grands Boulevards",
      "stop_lat": 48.87146677844407,
      "stop_lon": 2.342839648636545,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71326"
    },
    {
      "stop_id": "IDFM:22198",
      "stop_name": "Bonne Nouvelle",
      "stop_lat": 48.8706347683979,
      "stop_lon": 2.3485760027859746,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71320"
    },
    {
      "stop_id": "IDFM:22199",
      "stop_name": "République",
      "stop_lat": 48.86748513277203,
      "stop_lon": 2.3632563242479026,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:22200",
      "stop_name": "Filles du Calvaire",
      "stop_lat": 48.86308830188695,
      "stop_lon": 2.366826859795412,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71277"
    },
    {
      "stop_id": "IDFM:22201",
      "stop_name": "Saint-Sébastien - Froissart",
      "stop_lat": 48.86098670797991,
      "stop_lon": 2.367370357789606,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71777"
    },
    {
      "stop_id": "IDFM:22202",
      "stop_name": "Chemin Vert",
      "stop_lat": 48.85710602289208,
      "stop_lon": 2.3682216127650335,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:73622"
    },
    {
      "stop_id": "IDFM:22203",
      "stop_name": "Ledru-Rollin",
      "stop_lat": 48.85133776679838,
      "stop_lon": 2.3761412233185926,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71693"
    },
    {
      "stop_id": "IDFM:22204",
      "stop_name": "Faidherbe - Chaligny",
      "stop_lat": 48.850200705509266,
      "stop_lon": 2.384072471688875,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:74001"
    },
    {
      "stop_id": "IDFM:22205",
      "stop_name": "Montgallet",
      "stop_lat": 48.8440641552825,
      "stop_lon": 2.39052195642492,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71631"
    },
    {
      "stop_id": "IDFM:22206",
      "stop_name": "Michel Bizot",
      "stop_lat": 48.837000553950986,
      "stop_lon": 2.40228042706469,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71595"
    },
    {
      "stop_id": "IDFM:22207",
      "stop_name": "Porte Dorée",
      "stop_lat": 48.8350662204656,
      "stop_lon": 2.405749381400099,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71591"
    },
    {
      "stop_id": "IDFM:22208",
      "stop_name": "Porte de Charenton",
      "stop_lat": 48.83344922287947,
      "stop_lon": 2.402511872850073,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71579"
    },
    {
      "stop_id": "IDFM:22209",
      "stop_name": "Liberté",
      "stop_lat": 48.826148180607525,
      "stop_lon": 2.4064912314699263,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71558"
    },
    {
      "stop_id": "IDFM:22210",
      "stop_name": "Charenton - Écoles",
      "stop_lat": 48.82125590204953,
      "stop_lon": 2.413847237068526,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70648"
    },
    {
      "stop_id": "IDFM:22211",
      "stop_name": "Créteil - L'Échat",
      "stop_lat": 48.79661083011156,
      "stop_lon": 2.4493695956133714,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70269"
    },
    {
      "stop_id": "IDFM:22212",
      "stop_name": "Créteil - Université",
      "stop_lat": 48.789915686006935,
      "stop_lon": 2.450529196348293,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70172"
    },
    {
      "stop_id": "IDFM:22213",
      "stop_name": "Créteil - Préfecture",
      "stop_lat": 48.77975652677895,
      "stop_lon": 2.4593051692912615,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70021"
    },
    {
      "stop_id": "IDFM:22214",
      "stop_name": "Ecole Vétérinaire de Maisons-Alfort",
      "stop_lat": 48.814758684006726,
      "stop_lon": 2.422322751702586,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70522"
    },
    {
      "stop_id": "IDFM:22215",
      "stop_name": "Maisons-Alfort - Stade",
      "stop_lat": 48.80891847848263,
      "stop_lon": 2.434819533389365,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70426"
    },
    {
      "stop_id": "IDFM:22216",
      "stop_name": "Maisons-Alfort - Les Juilliottes",
      "stop_lat": 48.80310585036659,
      "stop_lon": 2.4457883063759605,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70358"
    },
    {
      "stop_id": "IDFM:22217",
      "stop_name": "Saint-François-Xavier",
      "stop_lat": 48.8512787565696,
      "stop_lon": 2.314407403528732,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71204"
    },
    {
      "stop_id": "IDFM:22218",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.87538131600797,
      "stop_lon": 2.325488390672613,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:22219",
      "stop_name": "Porte de Clichy",
      "stop_lat": 48.89464071673921,
      "stop_lon": 2.3136590957118313,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71545"
    },
    {
      "stop_id": "IDFM:22220",
      "stop_name": "Porte de Saint-Ouen",
      "stop_lat": 48.89749783732745,
      "stop_lon": 2.3290483407446505,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72078"
    },
    {
      "stop_id": "IDFM:22221",
      "stop_name": "Porte de Vanves",
      "stop_lat": 48.82766229777493,
      "stop_lon": 2.305223578739507,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71006"
    },
    {
      "stop_id": "IDFM:22222",
      "stop_name": "Pernety",
      "stop_lat": 48.834078679774976,
      "stop_lon": 2.318393706614701,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:412687"
    },
    {
      "stop_id": "IDFM:22223",
      "stop_name": "Plaisance",
      "stop_lat": 48.83174121370108,
      "stop_lon": 2.313833364032832,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71039"
    },
    {
      "stop_id": "IDFM:22224",
      "stop_name": "Miromesnil",
      "stop_lat": 48.87344791579522,
      "stop_lon": 2.3160106931210245,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71346"
    },
    {
      "stop_id": "IDFM:22225",
      "stop_name": "Liège",
      "stop_lat": 48.87953493737576,
      "stop_lon": 2.3268526712397555,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71382"
    },
    {
      "stop_id": "IDFM:22226",
      "stop_name": "Varenne",
      "stop_lat": 48.85662498274656,
      "stop_lon": 2.315113930509657,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71232"
    },
    {
      "stop_id": "IDFM:22227",
      "stop_name": "La Fourche",
      "stop_lat": 48.887424510518066,
      "stop_lon": 2.3256869607667356,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71474"
    },
    {
      "stop_id": "IDFM:22228",
      "stop_name": "Guy Môquet",
      "stop_lat": 48.8930014419616,
      "stop_lon": 2.327483258626126,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71528"
    },
    {
      "stop_id": "IDFM:22229",
      "stop_name": "Duroc",
      "stop_lat": 48.847009893611975,
      "stop_lon": 2.3165212194565217,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71159"
    },
    {
      "stop_id": "IDFM:22230",
      "stop_name": "Gaîté",
      "stop_lat": 48.83854469308166,
      "stop_lon": 2.322463569405974,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73675"
    },
    {
      "stop_id": "IDFM:22231",
      "stop_name": "Brochant",
      "stop_lat": 48.89062201554889,
      "stop_lon": 2.3201902468999456,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73661"
    },
    {
      "stop_id": "IDFM:22232",
      "stop_name": "Les Agnettes",
      "stop_lat": 48.92308777796361,
      "stop_lon": 2.286263202479002,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72240"
    },
    {
      "stop_id": "IDFM:22233",
      "stop_name": "Asnières - Gennevilliers Les Courtilles",
      "stop_lat": 48.93060364140038,
      "stop_lon": 2.284372100658843,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72286"
    },
    {
      "stop_id": "IDFM:22234",
      "stop_name": "Gabriel Péri",
      "stop_lat": 48.91639290208823,
      "stop_lon": 2.2946921563609406,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72203"
    },
    {
      "stop_id": "IDFM:22235",
      "stop_name": "Malakoff - Plateau de Vanves",
      "stop_lat": 48.82220756804045,
      "stop_lon": 2.298295381702497,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73766"
    },
    {
      "stop_id": "IDFM:22236",
      "stop_name": "Malakoff - Rue Etienne Dolet",
      "stop_lat": 48.815265523780766,
      "stop_lon": 2.296972463702674,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:70562"
    },
    {
      "stop_id": "IDFM:22237",
      "stop_name": "Châtillon-Montrouge",
      "stop_lat": 48.81075140670139,
      "stop_lon": 2.301717705004608,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:461505"
    },
    {
      "stop_id": "IDFM:22238",
      "stop_name": "Mairie de Clichy",
      "stop_lat": 48.90349897462669,
      "stop_lon": 2.3058484063578857,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72118"
    },
    {
      "stop_id": "IDFM:22239",
      "stop_name": "Carrefour Pleyel",
      "stop_lat": 48.91980854982986,
      "stop_lon": 2.343443653352174,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72217"
    },
    {
      "stop_id": "IDFM:22240",
      "stop_name": "Basilique de Saint-Denis",
      "stop_lat": 48.93678306532419,
      "stop_lon": 2.3592958408126816,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72326"
    },
    {
      "stop_id": "IDFM:22241",
      "stop_name": "Saint-Denis - Porte de Paris",
      "stop_lat": 48.929922750445755,
      "stop_lon": 2.3561729251015997,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72285"
    },
    {
      "stop_id": "IDFM:22242",
      "stop_name": "Saint-Denis - Université",
      "stop_lat": 48.945911458883856,
      "stop_lon": 2.3644942905103172,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72358"
    },
    {
      "stop_id": "IDFM:22243",
      "stop_name": "Mairie de Saint-Ouen",
      "stop_lat": 48.91202122382531,
      "stop_lon": 2.3337865427286206,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72168"
    },
    {
      "stop_id": "IDFM:22244",
      "stop_name": "Garibaldi",
      "stop_lat": 48.906372391923206,
      "stop_lon": 2.331962553647662,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72128"
    },
    {
      "stop_id": "IDFM:22364",
      "stop_name": "Châtelet",
      "stop_lat": 48.856953460785334,
      "stop_lon": 2.3481609912345776,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:22365",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.83154024475565,
      "stop_lon": 2.355303769754901,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:22366",
      "stop_name": "Place Monge",
      "stop_lat": 48.842666385642886,
      "stop_lon": 2.3521548685787708,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71133"
    },
    {
      "stop_id": "IDFM:22367",
      "stop_name": "Château Landon",
      "stop_lat": 48.87844145573239,
      "stop_lon": 2.3620179355106075,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:474149"
    },
    {
      "stop_id": "IDFM:22368",
      "stop_name": "Stalingrad",
      "stop_lat": 48.88431508830977,
      "stop_lon": 2.368996070723285,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:22369",
      "stop_name": "Sully - Morland",
      "stop_lat": 48.85120791254347,
      "stop_lon": 2.3617517381263218,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71201"
    },
    {
      "stop_id": "IDFM:22370",
      "stop_name": "Tolbiac",
      "stop_lat": 48.82613686175372,
      "stop_lon": 2.357318439213192,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71001"
    },
    {
      "stop_id": "IDFM:22371",
      "stop_name": "Riquet",
      "stop_lat": 48.88810829453359,
      "stop_lon": 2.3737641527012556,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71977"
    },
    {
      "stop_id": "IDFM:22372",
      "stop_name": "Porte d'Ivry",
      "stop_lat": 48.821489035562834,
      "stop_lon": 2.3695112543194927,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70636"
    },
    {
      "stop_id": "IDFM:22373",
      "stop_name": "Porte de Choisy",
      "stop_lat": 48.820132089232395,
      "stop_lon": 2.3646245490007405,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70604"
    },
    {
      "stop_id": "IDFM:22374",
      "stop_name": "Porte de la Villette",
      "stop_lat": 48.89780269235572,
      "stop_lon": 2.385869033143345,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72430"
    },
    {
      "stop_id": "IDFM:22375",
      "stop_name": "Pyramides",
      "stop_lat": 48.865880289914124,
      "stop_lon": 2.3344175359665553,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73788"
    },
    {
      "stop_id": "IDFM:22376",
      "stop_name": "Palais Royal - Musée du Louvre",
      "stop_lat": 48.862683997769665,
      "stop_lon": 2.337007354765684,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71297"
    },
    {
      "stop_id": "IDFM:22377",
      "stop_name": "Poissonnière",
      "stop_lat": 48.87716484925024,
      "stop_lon": 2.3487397513900734,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71363"
    },
    {
      "stop_id": "IDFM:22378",
      "stop_name": "Pont Marie (Cité des Arts)",
      "stop_lat": 48.85341474936241,
      "stop_lon": 2.357349968447059,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71217"
    },
    {
      "stop_id": "IDFM:22379",
      "stop_name": "Pont Neuf",
      "stop_lat": 48.858456031111984,
      "stop_lon": 2.3417089232034765,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71253"
    },
    {
      "stop_id": "IDFM:22380",
      "stop_name": "Porte d'Italie",
      "stop_lat": 48.81903428608761,
      "stop_lon": 2.359462523882543,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70596"
    },
    {
      "stop_id": "IDFM:22381",
      "stop_name": "Opéra",
      "stop_lat": 48.87030303390994,
      "stop_lon": 2.3324387808144875,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:22382",
      "stop_name": "Le Peletier",
      "stop_lat": 48.874959406040965,
      "stop_lon": 2.3401505597063736,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:478885"
    },
    {
      "stop_id": "IDFM:22383",
      "stop_name": "Les Gobelins",
      "stop_lat": 48.835841622344475,
      "stop_lon": 2.3524168018982756,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71076"
    },
    {
      "stop_id": "IDFM:22384",
      "stop_name": "Louis Blanc",
      "stop_lat": 48.88124182075271,
      "stop_lon": 2.3643562491890786,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71407"
    },
    {
      "stop_id": "IDFM:22385",
      "stop_name": "Maison Blanche",
      "stop_lat": 48.82216816831483,
      "stop_lon": 2.3585387043161337,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70645"
    },
    {
      "stop_id": "IDFM:22386",
      "stop_name": "Jussieu",
      "stop_lat": 48.84596333903808,
      "stop_lon": 2.354798309820857,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71148"
    },
    {
      "stop_id": "IDFM:22387",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.876361054333444,
      "stop_lon": 2.3581030566976637,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:22388",
      "stop_name": "Chaussée d'Antin - La Fayette",
      "stop_lat": 48.87313420577469,
      "stop_lon": 2.3337385944087012,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73689"
    },
    {
      "stop_id": "IDFM:22389",
      "stop_name": "Corentin Cariou",
      "stop_lat": 48.89467266005628,
      "stop_lon": 2.382291557164633,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72031"
    },
    {
      "stop_id": "IDFM:22390",
      "stop_name": "Cadet",
      "stop_lat": 48.875963285075564,
      "stop_lon": 2.344446309702274,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71351"
    },
    {
      "stop_id": "IDFM:22391",
      "stop_name": "Censier - Daubenton",
      "stop_lat": 48.84022624851393,
      "stop_lon": 2.351626572605499,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71100"
    },
    {
      "stop_id": "IDFM:22392",
      "stop_name": "Crimée",
      "stop_lat": 48.8908857589288,
      "stop_lon": 2.37693573698238,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72013"
    },
    {
      "stop_id": "IDFM:22393",
      "stop_name": "Fort d'Aubervilliers",
      "stop_lat": 48.91475665506255,
      "stop_lon": 2.404075377271855,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73695"
    },
    {
      "stop_id": "IDFM:22394",
      "stop_name": "Aubervilliers-Pantin Quatre Chemins",
      "stop_lat": 48.90379699038963,
      "stop_lon": 2.3921143824978492,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72460"
    },
    {
      "stop_id": "IDFM:22395",
      "stop_name": "La Courneuve - 8 Mai 1945",
      "stop_lat": 48.92024579662936,
      "stop_lon": 2.4102214036405085,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73696"
    },
    {
      "stop_id": "IDFM:22396",
      "stop_name": "Mairie d'Ivry",
      "stop_lat": 48.811210093393306,
      "stop_lon": 2.383498053635828,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70488"
    },
    {
      "stop_id": "IDFM:22397",
      "stop_name": "Pierre et Marie Curie",
      "stop_lat": 48.815836500997094,
      "stop_lon": 2.377246886036494,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70537"
    },
    {
      "stop_id": "IDFM:22398",
      "stop_name": "Le Kremlin-Bicêtre",
      "stop_lat": 48.81025257278989,
      "stop_lon": 2.362130413802561,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70452"
    },
    {
      "stop_id": "IDFM:22399",
      "stop_name": "Villejuif Léo Lagrange",
      "stop_lat": 48.80435318028833,
      "stop_lon": 2.3639317476477353,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70375"
    },
    {
      "stop_id": "IDFM:22400",
      "stop_name": "Villejuif - Louis Aragon",
      "stop_lat": 48.78753717099888,
      "stop_lon": 2.36783901176962,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70143"
    },
    {
      "stop_id": "IDFM:22401",
      "stop_name": "Villejuif Paul Vaillant-Couturier",
      "stop_lat": 48.79631793416014,
      "stop_lon": 2.3683316015776423,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70248"
    },
    {
      "stop_id": "IDFM:22442",
      "stop_name": "Rue des Boulets",
      "stop_lat": 48.85221407333194,
      "stop_lon": 2.3891047050322425,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71694"
    },
    {
      "stop_id": "IDFM:24679",
      "stop_name": "Front Populaire",
      "stop_lat": 48.906763048164294,
      "stop_lon": 2.365777177193171,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:74041"
    },
    {
      "stop_id": "IDFM:24680",
      "stop_name": "Jaurès",
      "stop_lat": 48.882344230208226,
      "stop_lon": 2.370451734004561,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:24681",
      "stop_name": "Louis Blanc",
      "stop_lat": 48.88120621182599,
      "stop_lon": 2.364424862493678,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71407"
    },
    {
      "stop_id": "IDFM:24682",
      "stop_name": "Place des Fêtes",
      "stop_lat": 48.87693859004261,
      "stop_lon": 2.392959616507311,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71885"
    },
    {
      "stop_id": "IDFM:24683",
      "stop_name": "Buttes Chaumont",
      "stop_lat": 48.87849908839857,
      "stop_lon": 2.381569842088008,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71900"
    },
    {
      "stop_id": "IDFM:24684",
      "stop_name": "Pré-Saint-Gervais",
      "stop_lat": 48.8801595806643,
      "stop_lon": 2.398580770693528,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71911"
    },
    {
      "stop_id": "IDFM:24685",
      "stop_name": "Bolivar",
      "stop_lat": 48.88078966297506,
      "stop_lon": 2.374124871187542,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71920"
    },
    {
      "stop_id": "IDFM:24686",
      "stop_name": "Botzaris",
      "stop_lat": 48.87943610160117,
      "stop_lon": 2.388908111185046,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71906"
    },
    {
      "stop_id": "IDFM:24687",
      "stop_name": "Danube",
      "stop_lat": 48.88194921492064,
      "stop_lon": 2.393228471227492,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71930"
    },
    {
      "stop_id": "IDFM:25084",
      "stop_name": "Buzenval",
      "stop_lat": 48.85176243983227,
      "stop_lon": 2.401170769368413,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:479928"
    },
    {
      "stop_id": "IDFM:26602",
      "stop_name": "Mairie de Montrouge",
      "stop_lat": 48.818471900073675,
      "stop_lon": 2.319523835754741,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70586"
    },
    {
      "stop_id": "IDFM:462914",
      "stop_name": "Marcel Sembat",
      "stop_lat": 48.83359298218974,
      "stop_lon": 2.243583048618053,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71054"
    },
    {
      "stop_id": "IDFM:462915",
      "stop_name": "Saint-Philippe du Roule",
      "stop_lat": 48.87215513107743,
      "stop_lon": 2.3101372056878247,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71334"
    },
    {
      "stop_id": "IDFM:462942",
      "stop_name": "Nation",
      "stop_lat": 48.848979537674516,
      "stop_lon": 2.395921369214201,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:462943",
      "stop_name": "Nation",
      "stop_lat": 48.84816963759798,
      "stop_lon": 2.395825965451431,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:462944",
      "stop_name": "Cluny - La Sorbonne",
      "stop_lat": 48.850793731720536,
      "stop_lon": 2.3450218824744566,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73619"
    },
    {
      "stop_id": "IDFM:462945",
      "stop_name": "Rambuteau",
      "stop_lat": 48.86119015695036,
      "stop_lon": 2.3532739485947713,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71273"
    },
    {
      "stop_id": "IDFM:462946",
      "stop_name": "République",
      "stop_lat": 48.86720306822776,
      "stop_lon": 2.3643332975454276,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:462947",
      "stop_name": "Pasteur",
      "stop_lat": 48.84282366881032,
      "stop_lon": 2.3126518981045816,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71125"
    },
    {
      "stop_id": "IDFM:462948",
      "stop_name": "Pasteur",
      "stop_lat": 48.842528387543226,
      "stop_lon": 2.3129146804739396,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71125"
    },
    {
      "stop_id": "IDFM:462949",
      "stop_name": "Convention",
      "stop_lat": 48.83713505380496,
      "stop_lon": 2.2963913864809173,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71075"
    },
    {
      "stop_id": "IDFM:462950",
      "stop_name": "Rue du Bac",
      "stop_lat": 48.85588467848543,
      "stop_lon": 2.3256998984524113,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71227"
    },
    {
      "stop_id": "IDFM:462951",
      "stop_name": "Porte de Saint-Ouen",
      "stop_lat": 48.89750667705023,
      "stop_lon": 2.3290209467099534,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72078"
    },
    {
      "stop_id": "IDFM:462952",
      "stop_name": "Guy Môquet",
      "stop_lat": 48.8930014419616,
      "stop_lon": 2.327483258626126,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71528"
    },
    {
      "stop_id": "IDFM:462953",
      "stop_name": "Gabriel Péri",
      "stop_lat": 48.916347418146714,
      "stop_lon": 2.29460408001733,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72203"
    },
    {
      "stop_id": "IDFM:462954",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84228592360469,
      "stop_lon": 2.3208534383022275,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:462955",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84213060781436,
      "stop_lon": 2.3212187199903807,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:462956",
      "stop_name": "Exelmans",
      "stop_lat": 48.842624465259945,
      "stop_lon": 2.25967486021195,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71124"
    },
    {
      "stop_id": "IDFM:462957",
      "stop_name": "Miromesnil",
      "stop_lat": 48.87369210197914,
      "stop_lon": 2.3146768238242688,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71346"
    },
    {
      "stop_id": "IDFM:462958",
      "stop_name": "Javel - André Citroën",
      "stop_lat": 48.846086877931405,
      "stop_lon": 2.2780435059854063,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71150"
    },
    {
      "stop_id": "IDFM:462959",
      "stop_name": "Vaneau",
      "stop_lat": 48.84889457005888,
      "stop_lon": 2.3213512423547358,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71170"
    },
    {
      "stop_id": "IDFM:462960",
      "stop_name": "République",
      "stop_lat": 48.86788497134881,
      "stop_lon": 2.3648796725347783,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:462961",
      "stop_name": "République",
      "stop_lat": 48.867732931981166,
      "stop_lon": 2.3641925544688656,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:462962",
      "stop_name": "République",
      "stop_lat": 48.86742161002706,
      "stop_lon": 2.363149406900382,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:462963",
      "stop_name": "République",
      "stop_lat": 48.86751235781114,
      "stop_lon": 2.3633023407804536,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71311"
    },
    {
      "stop_id": "IDFM:462964",
      "stop_name": "Volontaires",
      "stop_lat": 48.84152824313428,
      "stop_lon": 2.3079137063600563,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71113"
    },
    {
      "stop_id": "IDFM:462965",
      "stop_name": "Notre-Dame des Champs",
      "stop_lat": 48.845077745637816,
      "stop_lon": 2.3286958054987075,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71138"
    },
    {
      "stop_id": "IDFM:462966",
      "stop_name": "Sèvres - Babylone",
      "stop_lat": 48.851574452640946,
      "stop_lon": 2.326886801039095,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71203"
    },
    {
      "stop_id": "IDFM:462967",
      "stop_name": "Sèvres - Babylone",
      "stop_lat": 48.85112511792463,
      "stop_lon": 2.3261490660967246,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71203"
    },
    {
      "stop_id": "IDFM:462968",
      "stop_name": "Rennes",
      "stop_lat": 48.84819024311401,
      "stop_lon": 2.32802068556793,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73640"
    },
    {
      "stop_id": "IDFM:462969",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.875594817796994,
      "stop_lon": 2.327470237303278,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:462970",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.875462135328796,
      "stop_lon": 2.3254668925871607,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:462971",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.87496650103443,
      "stop_lon": 2.3244924701707954,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:462972",
      "stop_name": "Saint-Lazare",
      "stop_lat": 48.875660854671985,
      "stop_lon": 2.3240258961246782,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71370"
    },
    {
      "stop_id": "IDFM:462973",
      "stop_name": "Marcadet - Poissonniers",
      "stop_lat": 48.89154760285429,
      "stop_lon": 2.3500246218070155,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71511"
    },
    {
      "stop_id": "IDFM:462974",
      "stop_name": "Marcadet - Poissonniers",
      "stop_lat": 48.89127969913649,
      "stop_lon": 2.3495451670385634,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71511"
    },
    {
      "stop_id": "IDFM:462975",
      "stop_name": "La Fourche",
      "stop_lat": 48.88743365673691,
      "stop_lon": 2.3257141171661355,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71474"
    },
    {
      "stop_id": "IDFM:462976",
      "stop_name": "Carrefour Pleyel",
      "stop_lat": 48.919732608049756,
      "stop_lon": 2.3435346741834655,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72217"
    },
    {
      "stop_id": "IDFM:462977",
      "stop_name": "Garibaldi",
      "stop_lat": 48.90638108007163,
      "stop_lon": 2.331907872638921,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72128"
    },
    {
      "stop_id": "IDFM:462978",
      "stop_name": "Brochant",
      "stop_lat": 48.89065187119005,
      "stop_lon": 2.3199048172093666,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73661"
    },
    {
      "stop_id": "IDFM:462979",
      "stop_name": "Château d'Eau",
      "stop_lat": 48.87247308911766,
      "stop_lon": 2.3559014497805357,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71333"
    },
    {
      "stop_id": "IDFM:462980",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84374228942691,
      "stop_lon": 2.32392211008296,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:462981",
      "stop_name": "Jaurès",
      "stop_lat": 48.881596221131936,
      "stop_lon": 2.3701539703369967,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:462982",
      "stop_name": "La Chapelle",
      "stop_lat": 48.88447648340964,
      "stop_lon": 2.360430336162137,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71434"
    },
    {
      "stop_id": "IDFM:462983",
      "stop_name": "Couronnes",
      "stop_lat": 48.86919334513237,
      "stop_lon": 2.3802889829271936,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71834"
    },
    {
      "stop_id": "IDFM:462984",
      "stop_name": "Colonel Fabien",
      "stop_lat": 48.87764980852146,
      "stop_lon": 2.370467574793864,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71893"
    },
    {
      "stop_id": "IDFM:462985",
      "stop_name": "Pont de Levallois - Bécon",
      "stop_lat": 48.89722831603318,
      "stop_lon": 2.28030375318625,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:72057"
    },
    {
      "stop_id": "IDFM:462986",
      "stop_name": "Sentier",
      "stop_lat": 48.86728343166051,
      "stop_lon": 2.3475137108219895,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73634"
    },
    {
      "stop_id": "IDFM:462987",
      "stop_name": "Villiers",
      "stop_lat": 48.881153980760416,
      "stop_lon": 2.3157901685080926,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71403"
    },
    {
      "stop_id": "IDFM:462988",
      "stop_name": "Villiers",
      "stop_lat": 48.88121227337839,
      "stop_lon": 2.3157614492393397,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71403"
    },
    {
      "stop_id": "IDFM:462989",
      "stop_name": "Quatre Septembre",
      "stop_lat": 48.86965909738424,
      "stop_lon": 2.3363187355954875,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71313"
    },
    {
      "stop_id": "IDFM:462990",
      "stop_name": "Pelleport",
      "stop_lat": 48.86846488701483,
      "stop_lon": 2.4014967922879253,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71828"
    },
    {
      "stop_id": "IDFM:462991",
      "stop_name": "Gambetta",
      "stop_lat": 48.86497267606974,
      "stop_lon": 2.3973588775666475,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71808"
    },
    {
      "stop_id": "IDFM:462992",
      "stop_name": "Saint-Sulpice",
      "stop_lat": 48.85113858957915,
      "stop_lon": 2.330953550650454,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71202"
    },
    {
      "stop_id": "IDFM:462993",
      "stop_name": "Saint-Germain-des-Prés",
      "stop_lat": 48.85367371343323,
      "stop_lon": 2.3339874291342255,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71216"
    },
    {
      "stop_id": "IDFM:462994",
      "stop_name": "Mabillon",
      "stop_lat": 48.85296034336302,
      "stop_lon": 2.3350868179946915,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73639"
    },
    {
      "stop_id": "IDFM:462995",
      "stop_name": "Odéon",
      "stop_lat": 48.852175453081486,
      "stop_lon": 2.3395157696170745,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73618"
    },
    {
      "stop_id": "IDFM:462996",
      "stop_name": "Montparnasse Bienvenue",
      "stop_lat": 48.84391578859517,
      "stop_lon": 2.3243858698057784,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71139"
    },
    {
      "stop_id": "IDFM:462997",
      "stop_name": "Gambetta",
      "stop_lat": 48.865032564464,
      "stop_lon": 2.398537305704572,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71808"
    },
    {
      "stop_id": "IDFM:462998",
      "stop_name": "Odéon",
      "stop_lat": 48.85202455451491,
      "stop_lon": 2.340692291066523,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:73618"
    },
    {
      "stop_id": "IDFM:462999",
      "stop_name": "Laumière",
      "stop_lat": 48.88520088689033,
      "stop_lon": 2.3793117790180993,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71957"
    },
    {
      "stop_id": "IDFM:463000",
      "stop_name": "Bastille",
      "stop_lat": 48.85348468769834,
      "stop_lon": 2.369652217650188,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:463001",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.876271214541624,
      "stop_lon": 2.358120525273069,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:463002",
      "stop_name": "Bobigny-Pantin - Raymond Queneau",
      "stop_lat": 48.89563789793626,
      "stop_lon": 2.426277595760804,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72039"
    },
    {
      "stop_id": "IDFM:463003",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.830991153013954,
      "stop_lon": 2.3560502682264337,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:463004",
      "stop_name": "Nationale",
      "stop_lat": 48.83323527967617,
      "stop_lon": 2.3628041715935075,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71043"
    },
    {
      "stop_id": "IDFM:463005",
      "stop_name": "Bir-Hakeim",
      "stop_lat": 48.85392536837598,
      "stop_lon": 2.2894007376345997,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:473829"
    },
    {
      "stop_id": "IDFM:463006",
      "stop_name": "Château Landon",
      "stop_lat": 48.87845059378512,
      "stop_lon": 2.3620450936034145,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:474149"
    },
    {
      "stop_id": "IDFM:463007",
      "stop_name": "Porte de la Villette",
      "stop_lat": 48.897865181710756,
      "stop_lon": 2.3857788171360426,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72430"
    },
    {
      "stop_id": "IDFM:463008",
      "stop_name": "Stalingrad",
      "stop_lat": 48.88437303056126,
      "stop_lon": 2.3688985490576733,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:463009",
      "stop_name": "Stalingrad",
      "stop_lat": 48.88432054886986,
      "stop_lon": 2.3657743705581695,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:463010",
      "stop_name": "Champs-Élysées - Clemenceau",
      "stop_lat": 48.86765629219383,
      "stop_lon": 2.314464501322781,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71305"
    },
    {
      "stop_id": "IDFM:463011",
      "stop_name": "Champs-Élysées - Clemenceau",
      "stop_lat": 48.86749552078754,
      "stop_lon": 2.3138736155952313,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71305"
    },
    {
      "stop_id": "IDFM:463012",
      "stop_name": "Porte de Vincennes",
      "stop_lat": 48.84700773108863,
      "stop_lon": 2.4108049967015006,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71663"
    },
    {
      "stop_id": "IDFM:463013",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87392926445533,
      "stop_lon": 2.2951172138230334,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:463015",
      "stop_name": "Maisons-Alfort - Les Juilliottes",
      "stop_lat": 48.80321905319987,
      "stop_lon": 2.4459564771573397,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70358"
    },
    {
      "stop_id": "IDFM:463016",
      "stop_name": "Vavin",
      "stop_lat": 48.84205232329236,
      "stop_lon": 2.3288628540691043,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71117"
    },
    {
      "stop_id": "IDFM:463017",
      "stop_name": "Hoche",
      "stop_lat": 48.891495749437304,
      "stop_lon": 2.403114154894396,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72004"
    },
    {
      "stop_id": "IDFM:463018",
      "stop_name": "Bastille",
      "stop_lat": 48.85330031648217,
      "stop_lon": 2.3687984466220384,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:463019",
      "stop_name": "Bastille",
      "stop_lat": 48.85196422011205,
      "stop_lon": 2.368692796099789,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:463564"
    },
    {
      "stop_id": "IDFM:463020",
      "stop_name": "Campo-Formio",
      "stop_lat": 48.83554293374935,
      "stop_lon": 2.3587419566993457,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71063"
    },
    {
      "stop_id": "IDFM:463021",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.876081616617256,
      "stop_lon": 2.357982972943156,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:463022",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.876283511884786,
      "stop_lon": 2.3578981321043053,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71359"
    },
    {
      "stop_id": "IDFM:463023",
      "stop_name": "Gare d'Austerlitz",
      "stop_lat": 48.842395852858516,
      "stop_lon": 2.3654431802574227,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71135"
    },
    {
      "stop_id": "IDFM:463024",
      "stop_name": "Gare d'Austerlitz",
      "stop_lat": 48.84228236697996,
      "stop_lon": 2.364395394600644,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71135"
    },
    {
      "stop_id": "IDFM:463025",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.830965713292656,
      "stop_lon": 2.355501591481485,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:463026",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.83154938471729,
      "stop_lon": 2.355330902236442,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71033"
    },
    {
      "stop_id": "IDFM:463027",
      "stop_name": "Ourcq",
      "stop_lat": 48.88691595319499,
      "stop_lon": 2.386652016759884,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71972"
    },
    {
      "stop_id": "IDFM:463028",
      "stop_name": "Nation",
      "stop_lat": 48.84789064769285,
      "stop_lon": 2.3957883190257903,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:463029",
      "stop_name": "Porte d'Ivry",
      "stop_lat": 48.821352988336876,
      "stop_lon": 2.369294978223312,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70636"
    },
    {
      "stop_id": "IDFM:463030",
      "stop_name": "Porte de Choisy",
      "stop_lat": 48.82005592914816,
      "stop_lon": 2.3646785940494577,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70604"
    },
    {
      "stop_id": "IDFM:463031",
      "stop_name": "Aubervilliers-Pantin Quatre Chemins",
      "stop_lat": 48.90375706036556,
      "stop_lon": 2.3922205712292195,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72460"
    },
    {
      "stop_id": "IDFM:463032",
      "stop_name": "Stalingrad",
      "stop_lat": 48.8841445128949,
      "stop_lon": 2.367348121277192,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71961"
    },
    {
      "stop_id": "IDFM:463033",
      "stop_name": "Louis Blanc",
      "stop_lat": 48.880895011629946,
      "stop_lon": 2.3650968074522214,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71407"
    },
    {
      "stop_id": "IDFM:463034",
      "stop_name": "Louis Blanc",
      "stop_lat": 48.88118341152446,
      "stop_lon": 2.364365143550256,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71407"
    },
    {
      "stop_id": "IDFM:463035",
      "stop_name": "Billancourt",
      "stop_lat": 48.83191997405451,
      "stop_lon": 2.237891101038026,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71034"
    },
    {
      "stop_id": "IDFM:463036",
      "stop_name": "Richelieu - Drouot",
      "stop_lat": 48.87223943327036,
      "stop_lon": 2.3386163553228103,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71330"
    },
    {
      "stop_id": "IDFM:463037",
      "stop_name": "Richelieu - Drouot",
      "stop_lat": 48.87164051013642,
      "stop_lon": 2.3384603609547776,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71330"
    },
    {
      "stop_id": "IDFM:463038",
      "stop_name": "Jasmin",
      "stop_lat": 48.85243341872557,
      "stop_lon": 2.267933291647067,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73657"
    },
    {
      "stop_id": "IDFM:463039",
      "stop_name": "Buzenval",
      "stop_lat": 48.85176243983227,
      "stop_lon": 2.401170769368413,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:479928"
    },
    {
      "stop_id": "IDFM:463040",
      "stop_name": "Saint-Mandé",
      "stop_lat": 48.84622934735893,
      "stop_lon": 2.4189897063438073,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71647"
    },
    {
      "stop_id": "IDFM:463041",
      "stop_name": "Tuileries",
      "stop_lat": 48.864477839314645,
      "stop_lon": 2.3296780947116416,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71284"
    },
    {
      "stop_id": "IDFM:463042",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87371127132366,
      "stop_lon": 2.2947520493673434,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:463043",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87494579313413,
      "stop_lon": 2.295927507409278,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71347"
    },
    {
      "stop_id": "IDFM:463044",
      "stop_name": "Pont de Neuilly",
      "stop_lat": 48.8854985504452,
      "stop_lon": 2.2585225729481575,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71442"
    },
    {
      "stop_id": "IDFM:463045",
      "stop_name": "Olympiades",
      "stop_lat": 48.826944155043044,
      "stop_lon": 2.367034270941924,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71557"
    },
    {
      "stop_id": "IDFM:463046",
      "stop_name": "Gare de Lyon",
      "stop_lat": 48.84467563174822,
      "stop_lon": 2.373100581933962,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73626"
    },
    {
      "stop_id": "IDFM:463047",
      "stop_name": "Ecole Vétérinaire de Maisons-Alfort",
      "stop_lat": 48.81483550210013,
      "stop_lon": 2.422399525054415,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70522"
    },
    {
      "stop_id": "IDFM:463048",
      "stop_name": "Filles du Calvaire",
      "stop_lat": 48.8630421114174,
      "stop_lon": 2.3665956945709348,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71277"
    },
    {
      "stop_id": "IDFM:463049",
      "stop_name": "Grands Boulevards",
      "stop_lat": 48.871558801223806,
      "stop_lon": 2.3432202183423976,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71326"
    },
    {
      "stop_id": "IDFM:463050",
      "stop_name": "Porte Dorée",
      "stop_lat": 48.83501737409391,
      "stop_lon": 2.4058739129875644,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71591"
    },
    {
      "stop_id": "IDFM:463051",
      "stop_name": "Balard",
      "stop_lat": 48.83666789483108,
      "stop_lon": 2.2783626618092008,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71073"
    },
    {
      "stop_id": "IDFM:463052",
      "stop_name": "Alma - Marceau",
      "stop_lat": 48.86464714790582,
      "stop_lon": 2.3011043523926706,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71290"
    },
    {
      "stop_id": "IDFM:463053",
      "stop_name": "Robespierre",
      "stop_lat": 48.855754613895044,
      "stop_lon": 2.4229449336324724,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71725"
    },
    {
      "stop_id": "IDFM:463054",
      "stop_name": "Oberkampf",
      "stop_lat": 48.86487188759515,
      "stop_lon": 2.3682236708807456,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71803"
    },
    {
      "stop_id": "IDFM:463055",
      "stop_name": "Rue de la Pompe",
      "stop_lat": 48.86403732937509,
      "stop_lon": 2.277916063289398,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71292"
    },
    {
      "stop_id": "IDFM:463056",
      "stop_name": "Créteil - Préfecture",
      "stop_lat": 48.77975652677895,
      "stop_lon": 2.4593051692912615,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70021"
    },
    {
      "stop_id": "IDFM:463057",
      "stop_name": "Pointe du Lac",
      "stop_lat": 48.76876136434425,
      "stop_lon": 2.464317312722042,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:69884"
    },
    {
      "stop_id": "IDFM:463058",
      "stop_name": "Grands Boulevards",
      "stop_lat": 48.871601969451355,
      "stop_lon": 2.3428924760133967,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71326"
    },
    {
      "stop_id": "IDFM:463059",
      "stop_name": "Oberkampf",
      "stop_lat": 48.86464456516527,
      "stop_lon": 2.3686045625807752,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71803"
    },
    {
      "stop_id": "IDFM:463060",
      "stop_name": "Châtelet",
      "stop_lat": 48.85686770157052,
      "stop_lon": 2.348102094822081,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:463061",
      "stop_name": "Hôtel de Ville",
      "stop_lat": 48.85760073633446,
      "stop_lon": 2.3514239919428728,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:415852"
    },
    {
      "stop_id": "IDFM:463062",
      "stop_name": "Notre-Dame-de-Lorette",
      "stop_lat": 48.87604417483004,
      "stop_lon": 2.3379007718233322,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71350"
    },
    {
      "stop_id": "IDFM:463063",
      "stop_name": "Madeleine",
      "stop_lat": 48.8696462363775,
      "stop_lon": 2.3259702743206723,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:463064",
      "stop_name": "Madeleine",
      "stop_lat": 48.870630484228144,
      "stop_lon": 2.3258757399808627,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:463065",
      "stop_name": "Jules Joffrin",
      "stop_lat": 48.892492268201934,
      "stop_lon": 2.3443200293804827,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71521"
    },
    {
      "stop_id": "IDFM:463066",
      "stop_name": "Liège",
      "stop_lat": 48.87971082693934,
      "stop_lon": 2.3269450274387555,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71382"
    },
    {
      "stop_id": "IDFM:463067",
      "stop_name": "Nation",
      "stop_lat": 48.848453436979646,
      "stop_lon": 2.395925559927623,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71673"
    },
    {
      "stop_id": "IDFM:463068",
      "stop_name": "Malesherbes",
      "stop_lat": 48.88284002257779,
      "stop_lon": 2.309487846960011,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71416"
    },
    {
      "stop_id": "IDFM:463069",
      "stop_name": "Europe",
      "stop_lat": 48.87875316404477,
      "stop_lon": 2.32218591952051,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73617"
    },
    {
      "stop_id": "IDFM:463070",
      "stop_name": "Wagram",
      "stop_lat": 48.8838463907272,
      "stop_lon": 2.304673087584434,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71423"
    },
    {
      "stop_id": "IDFM:463071",
      "stop_name": "Saint-Fargeau",
      "stop_lat": 48.871887658077014,
      "stop_lon": 2.404361344448241,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71860"
    },
    {
      "stop_id": "IDFM:463072",
      "stop_name": "Réaumur - Sébastopol",
      "stop_lat": 48.866077771215046,
      "stop_lon": 2.352381731140252,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73636"
    },
    {
      "stop_id": "IDFM:463073",
      "stop_name": "Porte de Clignancourt",
      "stop_lat": 48.897525631278015,
      "stop_lon": 2.344624099102791,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:478883"
    },
    {
      "stop_id": "IDFM:463074",
      "stop_name": "Jaurès",
      "stop_lat": 48.882326316477965,
      "stop_lon": 2.370465586581431,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:463075",
      "stop_name": "Dugommier",
      "stop_lat": 48.83903652832887,
      "stop_lon": 2.3895997700798097,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71606"
    },
    {
      "stop_id": "IDFM:463076",
      "stop_name": "Charles Michels",
      "stop_lat": 48.84660453239739,
      "stop_lon": 2.2856210133667174,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71156"
    },
    {
      "stop_id": "IDFM:463077",
      "stop_name": "Jussieu",
      "stop_lat": 48.8462878950311,
      "stop_lon": 2.354944184375758,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71148"
    },
    {
      "stop_id": "IDFM:463078",
      "stop_name": "Jussieu",
      "stop_lat": 48.84596333903808,
      "stop_lon": 2.354798309820857,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71148"
    },
    {
      "stop_id": "IDFM:463079",
      "stop_name": "Châtelet",
      "stop_lat": 48.8576887395772,
      "stop_lon": 2.347759259772123,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:463080",
      "stop_name": "Hôtel de Ville",
      "stop_lat": 48.85735240518587,
      "stop_lon": 2.3520676701391,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:415852"
    },
    {
      "stop_id": "IDFM:463081",
      "stop_name": "Madeleine",
      "stop_lat": 48.87003294090397,
      "stop_lon": 2.325169929034193,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71324"
    },
    {
      "stop_id": "IDFM:463082",
      "stop_name": "Front Populaire",
      "stop_lat": 48.90666420278779,
      "stop_lon": 2.3657920239894126,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:74041"
    },
    {
      "stop_id": "IDFM:463083",
      "stop_name": "Duroc",
      "stop_lat": 48.84699198555429,
      "stop_lon": 2.316535081176305,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71159"
    },
    {
      "stop_id": "IDFM:463084",
      "stop_name": "Duroc",
      "stop_lat": 48.84668027285166,
      "stop_lon": 2.3162843486517035,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71159"
    },
    {
      "stop_id": "IDFM:463085",
      "stop_name": "Plaisance",
      "stop_lat": 48.83175036306747,
      "stop_lon": 2.313860489344306,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71039"
    },
    {
      "stop_id": "IDFM:463086",
      "stop_name": "Mairie de Clichy",
      "stop_lat": 48.90344880444455,
      "stop_lon": 2.305726300569584,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72118"
    },
    {
      "stop_id": "IDFM:463087",
      "stop_name": "Châtillon-Montrouge",
      "stop_lat": 48.81072820779425,
      "stop_lon": 2.301595459041787,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:461505"
    },
    {
      "stop_id": "IDFM:463088",
      "stop_name": "Pigalle",
      "stop_lat": 48.88219737464818,
      "stop_lon": 2.3382179854559224,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71409"
    },
    {
      "stop_id": "IDFM:463089",
      "stop_name": "Pigalle",
      "stop_lat": 48.88202093206733,
      "stop_lon": 2.337211164701128,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71409"
    },
    {
      "stop_id": "IDFM:463090",
      "stop_name": "Bourse",
      "stop_lat": 48.868738736969725,
      "stop_lon": 2.3406519668083314,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73635"
    },
    {
      "stop_id": "IDFM:463091",
      "stop_name": "Réaumur - Sébastopol",
      "stop_lat": 48.86628268363084,
      "stop_lon": 2.3520239377964742,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73636"
    },
    {
      "stop_id": "IDFM:463092",
      "stop_name": "Porte d'Orléans",
      "stop_lat": 48.823394602620716,
      "stop_lon": 2.325623760353114,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70657"
    },
    {
      "stop_id": "IDFM:463093",
      "stop_name": "Barbès - Rochechouart",
      "stop_lat": 48.88342517173516,
      "stop_lon": 2.3497455480753806,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71426"
    },
    {
      "stop_id": "IDFM:463094",
      "stop_name": "Barbès - Rochechouart",
      "stop_lat": 48.883852349861876,
      "stop_lon": 2.350573400888514,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71426"
    },
    {
      "stop_id": "IDFM:463095",
      "stop_name": "Jaurès",
      "stop_lat": 48.88311355416086,
      "stop_lon": 2.371383333112514,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71940"
    },
    {
      "stop_id": "IDFM:463096",
      "stop_name": "Richard-Lenoir",
      "stop_lat": 48.85985966744055,
      "stop_lon": 2.3719501400872653,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:73648"
    },
    {
      "stop_id": "IDFM:463097",
      "stop_name": "Saint-Marcel",
      "stop_lat": 48.83847698406796,
      "stop_lon": 2.3608176665940044,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71092"
    },
    {
      "stop_id": "IDFM:463098",
      "stop_name": "Picpus",
      "stop_lat": 48.84510324750185,
      "stop_lon": 2.4012745388223746,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71639"
    },
    {
      "stop_id": "IDFM:463099",
      "stop_name": "Dupleix",
      "stop_lat": 48.85038448621434,
      "stop_lon": 2.2936368390558783,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71197"
    },
    {
      "stop_id": "IDFM:463100",
      "stop_name": "Edgar Quinet",
      "stop_lat": 48.84090350169825,
      "stop_lon": 2.3252865779432965,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71108"
    },
    {
      "stop_id": "IDFM:463101",
      "stop_name": "Passy",
      "stop_lat": 48.8575315296204,
      "stop_lon": 2.2855802300954733,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71237"
    },
    {
      "stop_id": "IDFM:463102",
      "stop_name": "Kléber",
      "stop_lat": 48.871301612039105,
      "stop_lon": 2.2933259179892325,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73656"
    },
    {
      "stop_id": "IDFM:463103",
      "stop_name": "Denfert-Rochereau",
      "stop_lat": 48.83424986732888,
      "stop_lon": 2.332803776289825,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71056"
    },
    {
      "stop_id": "IDFM:463104",
      "stop_name": "Denfert-Rochereau",
      "stop_lat": 48.83394869369227,
      "stop_lon": 2.332018835216387,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71056"
    },
    {
      "stop_id": "IDFM:463105",
      "stop_name": "Corvisart",
      "stop_lat": 48.82986001331238,
      "stop_lon": 2.350611225926166,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71013"
    },
    {
      "stop_id": "IDFM:463106",
      "stop_name": "Place Monge",
      "stop_lat": 48.842683564383904,
      "stop_lon": 2.3520055901754713,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71133"
    },
    {
      "stop_id": "IDFM:463107",
      "stop_name": "Le Kremlin-Bicêtre",
      "stop_lat": 48.81028030813587,
      "stop_lon": 2.3622716933808072,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70452"
    },
    {
      "stop_id": "IDFM:463108",
      "stop_name": "Arts et Métiers",
      "stop_lat": 48.865380699169286,
      "stop_lon": 2.356535223129095,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71293"
    },
    {
      "stop_id": "IDFM:463109",
      "stop_name": "Asnières - Gennevilliers Les Courtilles",
      "stop_lat": 48.930544259451466,
      "stop_lon": 2.284216775386713,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72286"
    },
    {
      "stop_id": "IDFM:463110",
      "stop_name": "Belleville",
      "stop_lat": 48.87198955568015,
      "stop_lon": 2.376684607718542,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71853"
    },
    {
      "stop_id": "IDFM:463111",
      "stop_name": "Belleville",
      "stop_lat": 48.87225013398613,
      "stop_lon": 2.376640589315146,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71853"
    },
    {
      "stop_id": "IDFM:463112",
      "stop_name": "Bibliothèque François Mitterrand",
      "stop_lat": 48.829925765928905,
      "stop_lon": 2.376487371168301,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71572"
    },
    {
      "stop_id": "IDFM:463113",
      "stop_name": "Blanche",
      "stop_lat": 48.88376635447715,
      "stop_lon": 2.3324843754343627,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71420"
    },
    {
      "stop_id": "IDFM:463114",
      "stop_name": "Boissière",
      "stop_lat": 48.8668483434173,
      "stop_lon": 2.2900328376074373,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71301"
    },
    {
      "stop_id": "IDFM:463115",
      "stop_name": "Bonne Nouvelle",
      "stop_lat": 48.87048144580479,
      "stop_lon": 2.348496122542364,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71320"
    },
    {
      "stop_id": "IDFM:463116",
      "stop_name": "Abbesses",
      "stop_lat": 48.884392717991425,
      "stop_lon": 2.338394635220906,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71432"
    },
    {
      "stop_id": "IDFM:463117",
      "stop_name": "Alexandre Dumas",
      "stop_lat": 48.85624467843737,
      "stop_lon": 2.3947127337554788,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71728"
    },
    {
      "stop_id": "IDFM:463118",
      "stop_name": "Alésia",
      "stop_lat": 48.828152157703514,
      "stop_lon": 2.3271926250651647,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71030"
    },
    {
      "stop_id": "IDFM:463119",
      "stop_name": "Anatole France",
      "stop_lat": 48.89223258539651,
      "stop_lon": 2.2848122193525104,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71519"
    },
    {
      "stop_id": "IDFM:463120",
      "stop_name": "Anvers",
      "stop_lat": 48.882868568843925,
      "stop_lon": 2.3441548403302916,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71419"
    },
    {
      "stop_id": "IDFM:463121",
      "stop_name": "Argentine",
      "stop_lat": 48.87566737659971,
      "stop_lon": 2.289435418542214,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71348"
    },
    {
      "stop_id": "IDFM:463122",
      "stop_name": "Arts et Métiers",
      "stop_lat": 48.86561531899988,
      "stop_lon": 2.3566822734187167,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71293"
    },
    {
      "stop_id": "IDFM:463123",
      "stop_name": "Assemblée Nationale",
      "stop_lat": 48.861122050080816,
      "stop_lon": 2.3206896796944263,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73642"
    },
    {
      "stop_id": "IDFM:463124",
      "stop_name": "Avenue Émile Zola",
      "stop_lat": 48.84703801370517,
      "stop_lon": 2.2950189971435506,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71158"
    },
    {
      "stop_id": "IDFM:463125",
      "stop_name": "Avron",
      "stop_lat": 48.85161645805963,
      "stop_lon": 2.3980666384995177,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71697"
    },
    {
      "stop_id": "IDFM:463126",
      "stop_name": "Basilique de Saint-Denis",
      "stop_lat": 48.9367747982655,
      "stop_lon": 2.359431626687363,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72326"
    },
    {
      "stop_id": "IDFM:463127",
      "stop_name": "Bel-Air",
      "stop_lat": 48.84142733223358,
      "stop_lon": 2.400867131995259,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73650"
    },
    {
      "stop_id": "IDFM:463128",
      "stop_name": "Bercy",
      "stop_lat": 48.84018509185968,
      "stop_lon": 2.3794765872566757,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71607"
    },
    {
      "stop_id": "IDFM:463129",
      "stop_name": "Bercy",
      "stop_lat": 48.84010199803391,
      "stop_lon": 2.3790620152277233,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71607"
    },
    {
      "stop_id": "IDFM:463130",
      "stop_name": "Bérault",
      "stop_lat": 48.845491696267075,
      "stop_lon": 2.4292278831660283,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71650"
    },
    {
      "stop_id": "IDFM:463131",
      "stop_name": "Bobigny Pablo Picasso",
      "stop_lat": 48.906366795030564,
      "stop_lon": 2.4493013366465726,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72486"
    },
    {
      "stop_id": "IDFM:463132",
      "stop_name": "Bolivar",
      "stop_lat": 48.880789805531926,
      "stop_lon": 2.374152140698752,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71920"
    },
    {
      "stop_id": "IDFM:463133",
      "stop_name": "Bonne Nouvelle",
      "stop_lat": 48.87049007203075,
      "stop_lon": 2.348428667867017,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71320"
    },
    {
      "stop_id": "IDFM:463134",
      "stop_name": "Botzaris",
      "stop_lat": 48.879534991268365,
      "stop_lon": 2.388900951315739,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71906"
    },
    {
      "stop_id": "IDFM:463135",
      "stop_name": "Boucicaut",
      "stop_lat": 48.841122353680156,
      "stop_lon": 2.2877944613211754,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:412992"
    },
    {
      "stop_id": "IDFM:463136",
      "stop_name": "Boulogne Pont de Saint-Cloud",
      "stop_lat": 48.84067248206251,
      "stop_lon": 2.2283125898228056,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:70721"
    },
    {
      "stop_id": "IDFM:463137",
      "stop_name": "Boulogne Jean Jaurès",
      "stop_lat": 48.841991575324506,
      "stop_lon": 2.239077780728926,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71147"
    },
    {
      "stop_id": "IDFM:463138",
      "stop_name": "Bréguet-Sabin",
      "stop_lat": 48.8562178110506,
      "stop_lon": 2.3703346849773035,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:73621"
    },
    {
      "stop_id": "IDFM:463139",
      "stop_name": "Cadet",
      "stop_lat": 48.87604838013132,
      "stop_lon": 2.3443838837796562,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71351"
    },
    {
      "stop_id": "IDFM:463140",
      "stop_name": "Cambronne",
      "stop_lat": 48.84749339701438,
      "stop_lon": 2.302898786915822,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71167"
    },
    {
      "stop_id": "IDFM:463141",
      "stop_name": "Cardinal Lemoine",
      "stop_lat": 48.84670003526311,
      "stop_lon": 2.3513280016731763,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71153"
    },
    {
      "stop_id": "IDFM:463142",
      "stop_name": "Charenton - Écoles",
      "stop_lat": 48.82148073316996,
      "stop_lon": 2.413844709558796,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70648"
    },
    {
      "stop_id": "IDFM:463143",
      "stop_name": "Charonne",
      "stop_lat": 48.854939443448494,
      "stop_lon": 2.384782396448888,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73647"
    },
    {
      "stop_id": "IDFM:463144",
      "stop_name": "Chaussée d'Antin - La Fayette",
      "stop_lat": 48.87280723715393,
      "stop_lon": 2.333970457594147,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73689"
    },
    {
      "stop_id": "IDFM:463145",
      "stop_name": "Chaussée d'Antin - La Fayette",
      "stop_lat": 48.873201272012516,
      "stop_lon": 2.333669568112413,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73689"
    },
    {
      "stop_id": "IDFM:463146",
      "stop_name": "Chemin Vert",
      "stop_lat": 48.85708736782028,
      "stop_lon": 2.368095085385991,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:73622"
    },
    {
      "stop_id": "IDFM:463147",
      "stop_name": "Chevaleret",
      "stop_lat": 48.83494516788824,
      "stop_lon": 2.3680951237726693,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:73651"
    },
    {
      "stop_id": "IDFM:463148",
      "stop_name": "Château Rouge",
      "stop_lat": 48.88707887746752,
      "stop_lon": 2.3493658080048405,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71469"
    },
    {
      "stop_id": "IDFM:463149",
      "stop_name": "Château de Vincennes",
      "stop_lat": 48.84431751431355,
      "stop_lon": 2.440540095406114,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71634"
    },
    {
      "stop_id": "IDFM:463150",
      "stop_name": "Concorde",
      "stop_lat": 48.866557992949716,
      "stop_lon": 2.3229614457982586,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:463151",
      "stop_name": "Concorde",
      "stop_lat": 48.86656106315263,
      "stop_lon": 2.321915152849789,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:463152",
      "stop_name": "Corentin Cariou",
      "stop_lat": 48.89467266005628,
      "stop_lon": 2.382291557164633,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72031"
    },
    {
      "stop_id": "IDFM:463153",
      "stop_name": "Corentin Celton",
      "stop_lat": 48.826823295159116,
      "stop_lon": 2.2789586605256367,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:70990"
    },
    {
      "stop_id": "IDFM:463154",
      "stop_name": "Courcelles",
      "stop_lat": 48.87926525411311,
      "stop_lon": 2.3032943624252122,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71376"
    },
    {
      "stop_id": "IDFM:463155",
      "stop_name": "Crimée",
      "stop_lat": 48.890814345621656,
      "stop_lon": 2.3770380600019925,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:72013"
    },
    {
      "stop_id": "IDFM:463156",
      "stop_name": "Buttes Chaumont",
      "stop_lat": 48.878422971131464,
      "stop_lon": 2.381632921459486,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71900"
    },
    {
      "stop_id": "IDFM:463157",
      "stop_name": "Censier - Daubenton",
      "stop_lat": 48.84026600820119,
      "stop_lon": 2.351495277596339,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71100"
    },
    {
      "stop_id": "IDFM:463158",
      "stop_name": "Châtelet",
      "stop_lat": 48.85971581985686,
      "stop_lon": 2.3467600097151675,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:463159",
      "stop_name": "Châtelet",
      "stop_lat": 48.859556532726764,
      "stop_lon": 2.346411849769497,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:463160",
      "stop_name": "Châtelet",
      "stop_lat": 48.85930591756934,
      "stop_lon": 2.3458090025909044,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71264"
    },
    {
      "stop_id": "IDFM:463161",
      "stop_name": "Cité",
      "stop_lat": 48.85492106147581,
      "stop_lon": 2.347361051762225,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71223"
    },
    {
      "stop_id": "IDFM:463162",
      "stop_name": "Commerce",
      "stop_lat": 48.84458485524012,
      "stop_lon": 2.293851708440619,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71132"
    },
    {
      "stop_id": "IDFM:463163",
      "stop_name": "Félix Faure",
      "stop_lat": 48.84274672574357,
      "stop_lon": 2.291751809719901,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71137"
    },
    {
      "stop_id": "IDFM:463164",
      "stop_name": "Concorde",
      "stop_lat": 48.8661808158217,
      "stop_lon": 2.323058362902906,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71298"
    },
    {
      "stop_id": "IDFM:463165",
      "stop_name": "Cour Saint-Emilion",
      "stop_lat": 48.833319303843425,
      "stop_lon": 2.386617850214058,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71576"
    },
    {
      "stop_id": "IDFM:463166",
      "stop_name": "Créteil - L'Échat",
      "stop_lat": 48.79661108096057,
      "stop_lon": 2.449424047013373,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70269"
    },
    {
      "stop_id": "IDFM:463167",
      "stop_name": "Créteil - Université",
      "stop_lat": 48.78994285432577,
      "stop_lon": 2.4505697455342568,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70172"
    },
    {
      "stop_id": "IDFM:463168",
      "stop_name": "Daumesnil",
      "stop_lat": 48.83967393140864,
      "stop_lon": 2.395573606140371,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71614"
    },
    {
      "stop_id": "IDFM:463169",
      "stop_name": "Daumesnil",
      "stop_lat": 48.839537421644565,
      "stop_lon": 2.3961460679154514,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71614"
    },
    {
      "stop_id": "IDFM:463170",
      "stop_name": "Esplanade de la Défense",
      "stop_lat": 48.88834989394862,
      "stop_lon": 2.249931086743073,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71485"
    },
    {
      "stop_id": "IDFM:463171",
      "stop_name": "Etienne Marcel",
      "stop_lat": 48.86370301202431,
      "stop_lon": 2.3489761968791085,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:479068"
    },
    {
      "stop_id": "IDFM:463172",
      "stop_name": "Faidherbe - Chaligny",
      "stop_lat": 48.85011054413369,
      "stop_lon": 2.384028566383108,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:74001"
    },
    {
      "stop_id": "IDFM:463173",
      "stop_name": "Falguière",
      "stop_lat": 48.84444335195695,
      "stop_lon": 2.3175087114640447,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:73641"
    },
    {
      "stop_id": "IDFM:463174",
      "stop_name": "Gallieni",
      "stop_lat": 48.86539746068654,
      "stop_lon": 2.415921484287642,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71817"
    },
    {
      "stop_id": "IDFM:463176",
      "stop_name": "Croix de Chavaux",
      "stop_lat": 48.857992585158016,
      "stop_lon": 2.4358546126745826,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71756"
    },
    {
      "stop_id": "IDFM:463177",
      "stop_name": "École Militaire",
      "stop_lat": 48.854861755122315,
      "stop_lon": 2.3064410394306907,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71224"
    },
    {
      "stop_id": "IDFM:463178",
      "stop_name": "Église de Pantin",
      "stop_lat": 48.89324469416795,
      "stop_lon": 2.413074999597678,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:72024"
    },
    {
      "stop_id": "IDFM:463179",
      "stop_name": "Fort d'Aubervilliers",
      "stop_lat": 48.91470775289347,
      "stop_lon": 2.404187821795194,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73695"
    },
    {
      "stop_id": "IDFM:463180",
      "stop_name": "Franklin D. Roosevelt",
      "stop_lat": 48.86866242617973,
      "stop_lon": 2.3095735420193506,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71318"
    },
    {
      "stop_id": "IDFM:463181",
      "stop_name": "Franklin D. Roosevelt",
      "stop_lat": 48.86925069418826,
      "stop_lon": 2.309434325751582,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71318"
    },
    {
      "stop_id": "IDFM:463182",
      "stop_name": "Gare du Nord",
      "stop_lat": 48.87968001223662,
      "stop_lon": 2.357342884924845,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71410"
    },
    {
      "stop_id": "IDFM:463183",
      "stop_name": "Gare du Nord",
      "stop_lat": 48.87959170741013,
      "stop_lon": 2.356808788205409,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71410"
    },
    {
      "stop_id": "IDFM:463184",
      "stop_name": "Gaîté",
      "stop_lat": 48.83852608502253,
      "stop_lon": 2.3223539967788067,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73675"
    },
    {
      "stop_id": "IDFM:463185",
      "stop_name": "George V",
      "stop_lat": 48.87203776458909,
      "stop_lon": 2.300759784978918,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71328"
    },
    {
      "stop_id": "IDFM:463186",
      "stop_name": "Glacière",
      "stop_lat": 48.831115947616446,
      "stop_lon": 2.343438236678704,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71026"
    },
    {
      "stop_id": "IDFM:463187",
      "stop_name": "Goncourt",
      "stop_lat": 48.869936723164365,
      "stop_lon": 2.3710788377398995,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71840"
    },
    {
      "stop_id": "IDFM:463188",
      "stop_name": "Havre-Caumartin",
      "stop_lat": 48.8737568325288,
      "stop_lon": 2.327677208279404,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:482368"
    },
    {
      "stop_id": "IDFM:463189",
      "stop_name": "Havre-Caumartin",
      "stop_lat": 48.8735493178711,
      "stop_lon": 2.32755854621969,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:482368"
    },
    {
      "stop_id": "IDFM:463190",
      "stop_name": "Iéna",
      "stop_lat": 48.864780022257456,
      "stop_lon": 2.2938642256822708,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71294"
    },
    {
      "stop_id": "IDFM:463191",
      "stop_name": "Jacques Bonsergent",
      "stop_lat": 48.870620926265296,
      "stop_lon": 2.361023902048923,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71322"
    },
    {
      "stop_id": "IDFM:463192",
      "stop_name": "Jourdain",
      "stop_lat": 48.875247348641416,
      "stop_lon": 2.389325378910096,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71868"
    },
    {
      "stop_id": "IDFM:463193",
      "stop_name": "La Défense (Grande Arche)",
      "stop_lat": 48.89199558886802,
      "stop_lon": 2.2383579200010373,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71517"
    },
    {
      "stop_id": "IDFM:463194",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.848765448122315,
      "stop_lon": 2.298957096977008,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:463195",
      "stop_name": "Ledru-Rollin",
      "stop_lat": 48.85119274222268,
      "stop_lon": 2.375924913735691,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71693"
    },
    {
      "stop_id": "IDFM:463197",
      "stop_name": "Gare de Lyon",
      "stop_lat": 48.84575948671397,
      "stop_lon": 2.373125016776689,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:73626"
    },
    {
      "stop_id": "IDFM:463198",
      "stop_name": "Invalides",
      "stop_lat": 48.86084865840772,
      "stop_lon": 2.3145404484362686,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71274"
    },
    {
      "stop_id": "IDFM:463199",
      "stop_name": "Invalides",
      "stop_lat": 48.86059945384315,
      "stop_lon": 2.314996938416133,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71274"
    },
    {
      "stop_id": "IDFM:463200",
      "stop_name": "La Courneuve - 8 Mai 1945",
      "stop_lat": 48.92037064946693,
      "stop_lon": 2.410008476572451,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73696"
    },
    {
      "stop_id": "IDFM:463201",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.84967105681514,
      "stop_lon": 2.298488389923641,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:463202",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.849532805857095,
      "stop_lon": 2.298684579816939,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71199"
    },
    {
      "stop_id": "IDFM:463203",
      "stop_name": "La Muette",
      "stop_lat": 48.85809196689865,
      "stop_lon": 2.2740962446697224,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:474150"
    },
    {
      "stop_id": "IDFM:463204",
      "stop_name": "La Tour-Maubourg",
      "stop_lat": 48.85772702353455,
      "stop_lon": 2.3104735359369846,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71242"
    },
    {
      "stop_id": "IDFM:463205",
      "stop_name": "Lamarck - Caulaincourt",
      "stop_lat": 48.88968180996681,
      "stop_lon": 2.3385838243831936,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71492"
    },
    {
      "stop_id": "IDFM:463206",
      "stop_name": "Le Peletier",
      "stop_lat": 48.874851793670715,
      "stop_lon": 2.340206459456337,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:478885"
    },
    {
      "stop_id": "IDFM:463207",
      "stop_name": "Les Agnettes",
      "stop_lat": 48.923055662740055,
      "stop_lon": 2.28615583314788,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72240"
    },
    {
      "stop_id": "IDFM:463208",
      "stop_name": "Les Halles",
      "stop_lat": 48.8625048403394,
      "stop_lon": 2.3461273251736805,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73794"
    },
    {
      "stop_id": "IDFM:463209",
      "stop_name": "Liberté",
      "stop_lat": 48.82608032193611,
      "stop_lon": 2.406409459132366,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71558"
    },
    {
      "stop_id": "IDFM:463210",
      "stop_name": "Louise Michel",
      "stop_lat": 48.88849338167193,
      "stop_lon": 2.2881680068067354,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:73667"
    },
    {
      "stop_id": "IDFM:463211",
      "stop_name": "Lourmel",
      "stop_lat": 48.838785539019476,
      "stop_lon": 2.2820358715985742,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71086"
    },
    {
      "stop_id": "IDFM:463212",
      "stop_name": "Mairie de Saint-Ouen",
      "stop_lat": 48.911958864732505,
      "stop_lon": 2.333893080685357,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72168"
    },
    {
      "stop_id": "IDFM:463213",
      "stop_name": "Malakoff - Plateau de Vanves",
      "stop_lat": 48.82214529059278,
      "stop_lon": 2.2984111759006174,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:73766"
    },
    {
      "stop_id": "IDFM:463214",
      "stop_name": "Malakoff - Rue Etienne Dolet",
      "stop_lat": 48.81533836595972,
      "stop_lon": 2.2971240100835835,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:70562"
    },
    {
      "stop_id": "IDFM:463215",
      "stop_name": "Maraîchers",
      "stop_lat": 48.85265013095636,
      "stop_lon": 2.4060490065790447,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71703"
    },
    {
      "stop_id": "IDFM:463216",
      "stop_name": "Les Gobelins",
      "stop_lat": 48.83589170669027,
      "stop_lon": 2.3525311653622043,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71076"
    },
    {
      "stop_id": "IDFM:463217",
      "stop_name": "Les Sablons",
      "stop_lat": 48.88129083793358,
      "stop_lon": 2.271904762259129,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:425779"
    },
    {
      "stop_id": "IDFM:463218",
      "stop_name": "Louvre - Rivoli",
      "stop_lat": 48.86090733426327,
      "stop_lon": 2.3409964272720227,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:73630"
    },
    {
      "stop_id": "IDFM:463219",
      "stop_name": "Mairie d'Issy",
      "stop_lat": 48.82434428766646,
      "stop_lon": 2.273558168023932,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:70671"
    },
    {
      "stop_id": "IDFM:463220",
      "stop_name": "Mairie d'Ivry",
      "stop_lat": 48.811210093393306,
      "stop_lon": 2.383498053635828,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70488"
    },
    {
      "stop_id": "IDFM:463221",
      "stop_name": "Mairie de Montreuil",
      "stop_lat": 48.862313130940244,
      "stop_lon": 2.4414386664522225,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71785"
    },
    {
      "stop_id": "IDFM:463222",
      "stop_name": "Mairie de Montrouge",
      "stop_lat": 48.818454456190004,
      "stop_lon": 2.3196193991237846,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70586"
    },
    {
      "stop_id": "IDFM:463223",
      "stop_name": "Mairie des Lilas",
      "stop_lat": 48.879658467973904,
      "stop_lon": 2.4163499077398614,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71909"
    },
    {
      "stop_id": "IDFM:463224",
      "stop_name": "Maison Blanche",
      "stop_lat": 48.82214950607496,
      "stop_lon": 2.358412946155939,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70645"
    },
    {
      "stop_id": "IDFM:463225",
      "stop_name": "Maisons-Alfort - Stade",
      "stop_lat": 48.80897743416717,
      "stop_lon": 2.434924424365985,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:70426"
    },
    {
      "stop_id": "IDFM:463226",
      "stop_name": "Michel Bizot",
      "stop_lat": 48.83707743103578,
      "stop_lon": 2.402366739988004,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71595"
    },
    {
      "stop_id": "IDFM:463227",
      "stop_name": "Palais Royal - Musée du Louvre",
      "stop_lat": 48.862366755622624,
      "stop_lon": 2.336561574182243,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71297"
    },
    {
      "stop_id": "IDFM:463228",
      "stop_name": "Pereire",
      "stop_lat": 48.884929864637186,
      "stop_lon": 2.297701123838181,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71453"
    },
    {
      "stop_id": "IDFM:463229",
      "stop_name": "Pernety",
      "stop_lat": 48.83416372097089,
      "stop_lon": 2.318323659736018,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:412687"
    },
    {
      "stop_id": "IDFM:463230",
      "stop_name": "Philippe Auguste",
      "stop_lat": 48.858089428135756,
      "stop_lon": 2.390497596745289,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71751"
    },
    {
      "stop_id": "IDFM:463231",
      "stop_name": "Pont Marie (Cité des Arts)",
      "stop_lat": 48.85353209666315,
      "stop_lon": 2.357430292176306,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71217"
    },
    {
      "stop_id": "IDFM:463232",
      "stop_name": "Pont Neuf",
      "stop_lat": 48.85854633926832,
      "stop_lon": 2.341776749148542,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71253"
    },
    {
      "stop_id": "IDFM:463233",
      "stop_name": "Pont de Sèvres",
      "stop_lat": 48.82966919074605,
      "stop_lon": 2.2305052170885546,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:70698"
    },
    {
      "stop_id": "IDFM:463234",
      "stop_name": "Porte d'Auteuil",
      "stop_lat": 48.847904123629235,
      "stop_lon": 2.258280744595537,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71169"
    },
    {
      "stop_id": "IDFM:463235",
      "stop_name": "Porte d'Italie",
      "stop_lat": 48.81910659655869,
      "stop_lon": 2.3595297331071694,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70596"
    },
    {
      "stop_id": "IDFM:463236",
      "stop_name": "Marx Dormoy",
      "stop_lat": 48.89057957856722,
      "stop_lon": 2.359808478084024,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71510"
    },
    {
      "stop_id": "IDFM:463237",
      "stop_name": "Maubert - Mutualité",
      "stop_lat": 48.85019546606951,
      "stop_lon": 2.3482804131694897,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71190"
    },
    {
      "stop_id": "IDFM:463238",
      "stop_name": "Michel-Ange - Auteuil",
      "stop_lat": 48.84770893216831,
      "stop_lon": 2.2638093220226634,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71206"
    },
    {
      "stop_id": "IDFM:463239",
      "stop_name": "Michel-Ange - Molitor",
      "stop_lat": 48.844880488259605,
      "stop_lon": 2.261631583355701,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73658"
    },
    {
      "stop_id": "IDFM:463240",
      "stop_name": "Monceau",
      "stop_lat": 48.880568770126835,
      "stop_lon": 2.309412967374703,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:73671"
    },
    {
      "stop_id": "IDFM:463241",
      "stop_name": "Montgallet",
      "stop_lat": 48.84400507570895,
      "stop_lon": 2.3904000136353645,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71631"
    },
    {
      "stop_id": "IDFM:463242",
      "stop_name": "Mouton-Duvernet",
      "stop_lat": 48.83133758557153,
      "stop_lon": 2.329887640408421,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73653"
    },
    {
      "stop_id": "IDFM:463243",
      "stop_name": "Ménilmontant",
      "stop_lat": 48.866390140145434,
      "stop_lon": 2.3834303882303045,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71815"
    },
    {
      "stop_id": "IDFM:463244",
      "stop_name": "Opéra",
      "stop_lat": 48.87066534465599,
      "stop_lon": 2.3320941299398554,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:463245",
      "stop_name": "Opéra",
      "stop_lat": 48.870326260852515,
      "stop_lon": 2.332572085877118,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:463246",
      "stop_name": "Opéra",
      "stop_lat": 48.87150983278418,
      "stop_lon": 2.331128974301561,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71337"
    },
    {
      "stop_id": "IDFM:463249",
      "stop_name": "Palais Royal - Musée du Louvre",
      "stop_lat": 48.86276538184415,
      "stop_lon": 2.3370872868854393,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71297"
    },
    {
      "stop_id": "IDFM:463250",
      "stop_name": "Parmentier",
      "stop_lat": 48.865162111168765,
      "stop_lon": 2.3746806763746613,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71801"
    },
    {
      "stop_id": "IDFM:463251",
      "stop_name": "Pierre et Marie Curie",
      "stop_lat": 48.81587757774557,
      "stop_lon": 2.377362970985953,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70537"
    },
    {
      "stop_id": "IDFM:463252",
      "stop_name": "Place de Clichy",
      "stop_lat": 48.88374519677981,
      "stop_lon": 2.3278987090661456,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71435"
    },
    {
      "stop_id": "IDFM:463253",
      "stop_name": "Place de Clichy",
      "stop_lat": 48.883723673452664,
      "stop_lon": 2.327268983025867,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71435"
    },
    {
      "stop_id": "IDFM:463254",
      "stop_name": "Place des Fêtes",
      "stop_lat": 48.87672366197325,
      "stop_lon": 2.393139370360497,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71885"
    },
    {
      "stop_id": "IDFM:463255",
      "stop_name": "Poissonnière",
      "stop_lat": 48.877079707510546,
      "stop_lon": 2.3487939912846394,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71363"
    },
    {
      "stop_id": "IDFM:463256",
      "stop_name": "Porte Dauphine",
      "stop_lat": 48.8717351159749,
      "stop_lon": 2.2778093495879346,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:474152"
    },
    {
      "stop_id": "IDFM:463257",
      "stop_name": "Porte Maillot",
      "stop_lat": 48.877805650437985,
      "stop_lon": 2.2822896139411903,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71379"
    },
    {
      "stop_id": "IDFM:463258",
      "stop_name": "Porte de Champerret",
      "stop_lat": 48.885652393133846,
      "stop_lon": 2.2921124821588448,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71450"
    },
    {
      "stop_id": "IDFM:463259",
      "stop_name": "Porte de Saint-Cloud",
      "stop_lat": 48.83799728287381,
      "stop_lon": 2.2567894964207142,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71084"
    },
    {
      "stop_id": "IDFM:463260",
      "stop_name": "Porte de Vanves",
      "stop_lat": 48.827613464004294,
      "stop_lon": 2.3053323866574735,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71006"
    },
    {
      "stop_id": "IDFM:463261",
      "stop_name": "Porte de Versailles",
      "stop_lat": 48.83251953251351,
      "stop_lon": 2.2877417091423347,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71045"
    },
    {
      "stop_id": "IDFM:463262",
      "stop_name": "Porte des Lilas",
      "stop_lat": 48.87663999703494,
      "stop_lon": 2.4069512666209,
      "route_id": "IDFM:C01386",
      "parent_station": "IDFM:71889"
    },
    {
      "stop_id": "IDFM:463263",
      "stop_name": "Porte des Lilas",
      "stop_lat": 48.87723143067265,
      "stop_lon": 2.4065226763982275,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71889"
    },
    {
      "stop_id": "IDFM:463264",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86950305730265,
      "stop_lon": 2.3538223820093616,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:463265",
      "stop_name": "Sully - Morland",
      "stop_lat": 48.851271408393764,
      "stop_lon": 2.3618531696972482,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71201"
    },
    {
      "stop_id": "IDFM:463266",
      "stop_name": "Ternes",
      "stop_lat": 48.878227730862385,
      "stop_lon": 2.2981132886172433,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71367"
    },
    {
      "stop_id": "IDFM:463267",
      "stop_name": "Tolbiac",
      "stop_lat": 48.82615323734341,
      "stop_lon": 2.357018567738162,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71001"
    },
    {
      "stop_id": "IDFM:463268",
      "stop_name": "Trocadéro",
      "stop_lat": 48.86348766501957,
      "stop_lon": 2.2874927969664416,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71285"
    },
    {
      "stop_id": "IDFM:463269",
      "stop_name": "Villejuif - Louis Aragon",
      "stop_lat": 48.787572501132765,
      "stop_lon": 2.3677168990538426,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70143"
    },
    {
      "stop_id": "IDFM:463276",
      "stop_name": "Porte de Bagnolet",
      "stop_lat": 48.86453937293447,
      "stop_lon": 2.4087542211895427,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71799"
    },
    {
      "stop_id": "IDFM:463277",
      "stop_name": "Porte de Charenton",
      "stop_lat": 48.8336104146937,
      "stop_lon": 2.402372975541869,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71579"
    },
    {
      "stop_id": "IDFM:463278",
      "stop_name": "Porte de Clichy",
      "stop_lat": 48.89443128801746,
      "stop_lon": 2.31320835075881,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71545"
    },
    {
      "stop_id": "IDFM:463279",
      "stop_name": "Porte de Montreuil",
      "stop_lat": 48.85348263120656,
      "stop_lon": 2.410718154027495,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71710"
    },
    {
      "stop_id": "IDFM:463280",
      "stop_name": "Porte de Pantin",
      "stop_lat": 48.88845921134684,
      "stop_lon": 2.3921229723281483,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71989"
    },
    {
      "stop_id": "IDFM:463281",
      "stop_name": "Porte de la Chapelle",
      "stop_lat": 48.89741064164834,
      "stop_lon": 2.359103857300198,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72064"
    },
    {
      "stop_id": "IDFM:463282",
      "stop_name": "Pré-Saint-Gervais",
      "stop_lat": 48.8801595806643,
      "stop_lon": 2.398580770693528,
      "route_id": "IDFM:C01387",
      "parent_station": "IDFM:71911"
    },
    {
      "stop_id": "IDFM:463283",
      "stop_name": "Pyramides",
      "stop_lat": 48.86583012818995,
      "stop_lon": 2.33429208315197,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:73788"
    },
    {
      "stop_id": "IDFM:463284",
      "stop_name": "Pyramides",
      "stop_lat": 48.86580563193237,
      "stop_lon": 2.3347388358754153,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73788"
    },
    {
      "stop_id": "IDFM:463285",
      "stop_name": "Père Lachaise",
      "stop_lat": 48.86244984493746,
      "stop_lon": 2.3875798912544455,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71787"
    },
    {
      "stop_id": "IDFM:463286",
      "stop_name": "Père Lachaise",
      "stop_lat": 48.86304099836643,
      "stop_lon": 2.3862311113287342,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71787"
    },
    {
      "stop_id": "IDFM:463287",
      "stop_name": "Pyrénées",
      "stop_lat": 48.87381879018058,
      "stop_lon": 2.3852029155695313,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71864"
    },
    {
      "stop_id": "IDFM:463288",
      "stop_name": "Quai de la Gare",
      "stop_lat": 48.8370742706096,
      "stop_lon": 2.372766248948172,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71597"
    },
    {
      "stop_id": "IDFM:463289",
      "stop_name": "Quai de la Rapée",
      "stop_lat": 48.846427325479766,
      "stop_lon": 2.365884650750405,
      "route_id": "IDFM:C01375",
      "parent_station": "IDFM:71637"
    },
    {
      "stop_id": "IDFM:463290",
      "stop_name": "Ranelagh",
      "stop_lat": 48.85550355839564,
      "stop_lon": 2.269948157934635,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71243"
    },
    {
      "stop_id": "IDFM:463291",
      "stop_name": "Raspail",
      "stop_lat": 48.83910415024143,
      "stop_lon": 2.3307802165662403,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71088"
    },
    {
      "stop_id": "IDFM:463292",
      "stop_name": "Raspail",
      "stop_lat": 48.8390661288004,
      "stop_lon": 2.3304144712354793,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71088"
    },
    {
      "stop_id": "IDFM:463293",
      "stop_name": "Reuilly - Diderot",
      "stop_lat": 48.847094767969466,
      "stop_lon": 2.3869585404965377,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71654"
    },
    {
      "stop_id": "IDFM:463294",
      "stop_name": "Reuilly - Diderot",
      "stop_lat": 48.84734982784612,
      "stop_lon": 2.3858367760086865,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71654"
    },
    {
      "stop_id": "IDFM:463295",
      "stop_name": "Riquet",
      "stop_lat": 48.888157262065796,
      "stop_lon": 2.373669464743196,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:71977"
    },
    {
      "stop_id": "IDFM:463296",
      "stop_name": "Rome",
      "stop_lat": 48.88234581879614,
      "stop_lon": 2.3213591738229726,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71408"
    },
    {
      "stop_id": "IDFM:463297",
      "stop_name": "Rue Saint-Maur",
      "stop_lat": 48.86405000324374,
      "stop_lon": 2.3804600339610773,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71795"
    },
    {
      "stop_id": "IDFM:463298",
      "stop_name": "Rue des Boulets",
      "stop_lat": 48.85236862327955,
      "stop_lon": 2.389429968854563,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71694"
    },
    {
      "stop_id": "IDFM:463299",
      "stop_name": "Saint-Ambroise",
      "stop_lat": 48.861480218467655,
      "stop_lon": 2.3739964519221886,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71781"
    },
    {
      "stop_id": "IDFM:463300",
      "stop_name": "Saint-Augustin",
      "stop_lat": 48.874632152132925,
      "stop_lon": 2.321031570403537,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:73690"
    },
    {
      "stop_id": "IDFM:463301",
      "stop_name": "Saint-Denis - Porte de Paris",
      "stop_lat": 48.929922750445755,
      "stop_lon": 2.3561729251015997,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72285"
    },
    {
      "stop_id": "IDFM:463302",
      "stop_name": "Saint-Denis - Université",
      "stop_lat": 48.9458307173959,
      "stop_lon": 2.364530774042862,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:72358"
    },
    {
      "stop_id": "IDFM:463303",
      "stop_name": "Saint-François-Xavier",
      "stop_lat": 48.85129155093847,
      "stop_lon": 2.3142859461805556,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71204"
    },
    {
      "stop_id": "IDFM:463304",
      "stop_name": "Saint-Georges",
      "stop_lat": 48.87836199275949,
      "stop_lon": 2.3374546190400913,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71366"
    },
    {
      "stop_id": "IDFM:463305",
      "stop_name": "Saint-Jacques",
      "stop_lat": 48.832857226540206,
      "stop_lon": 2.3370965389054956,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71041"
    },
    {
      "stop_id": "IDFM:463306",
      "stop_name": "Saint-Michel",
      "stop_lat": 48.85359407756809,
      "stop_lon": 2.343991799033288,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73620"
    },
    {
      "stop_id": "IDFM:463307",
      "stop_name": "Saint-Paul (Le Marais)",
      "stop_lat": 48.85527781668134,
      "stop_lon": 2.3609713728017505,
      "route_id": "IDFM:C01371",
      "parent_station": "IDFM:71222"
    },
    {
      "stop_id": "IDFM:463308",
      "stop_name": "Saint-Placide",
      "stop_lat": 48.84700665158937,
      "stop_lon": 2.327054815066312,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71184"
    },
    {
      "stop_id": "IDFM:463309",
      "stop_name": "Saint-Sébastien - Froissart",
      "stop_lat": 48.86094943927989,
      "stop_lon": 2.3671254626151184,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:71777"
    },
    {
      "stop_id": "IDFM:463310",
      "stop_name": "Simplon",
      "stop_lat": 48.8938152613519,
      "stop_lon": 2.347794968187123,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:71556"
    },
    {
      "stop_id": "IDFM:463311",
      "stop_name": "Solférino",
      "stop_lat": 48.85853194972854,
      "stop_lon": 2.3230759642515415,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71270"
    },
    {
      "stop_id": "IDFM:463312",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86946694920397,
      "stop_lon": 2.353797473153033,
      "route_id": "IDFM:C01378",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:463313",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86966773787259,
      "stop_lon": 2.3543411093711257,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:73633"
    },
    {
      "stop_id": "IDFM:463314",
      "stop_name": "Sèvres-Lecourbe",
      "stop_lat": 48.84564768265073,
      "stop_lon": 2.309529610430393,
      "route_id": "IDFM:C01376",
      "parent_station": "IDFM:71144"
    },
    {
      "stop_id": "IDFM:463315",
      "stop_name": "Ségur",
      "stop_lat": 48.847167269995026,
      "stop_lon": 2.307137849403385,
      "route_id": "IDFM:C01380",
      "parent_station": "IDFM:71157"
    },
    {
      "stop_id": "IDFM:463316",
      "stop_name": "Temple",
      "stop_lat": 48.86668483219092,
      "stop_lon": 2.3615893783321895,
      "route_id": "IDFM:C01373",
      "parent_station": "IDFM:71296"
    },
    {
      "stop_id": "IDFM:463317",
      "stop_name": "Trinité - d'Estienne d'Orves",
      "stop_lat": 48.87633650730735,
      "stop_lon": 2.333049172418236,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71355"
    },
    {
      "stop_id": "IDFM:463318",
      "stop_name": "Trocadéro",
      "stop_lat": 48.86308797989344,
      "stop_lon": 2.2868262498013006,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71285"
    },
    {
      "stop_id": "IDFM:463319",
      "stop_name": "Télégraphe",
      "stop_lat": 48.875519513947125,
      "stop_lon": 2.3986752529487725,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71870"
    },
    {
      "stop_id": "IDFM:463320",
      "stop_name": "Varenne",
      "stop_lat": 48.85662420264694,
      "stop_lon": 2.3149776470363417,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71232"
    },
    {
      "stop_id": "IDFM:463321",
      "stop_name": "Vaugirard",
      "stop_lat": 48.83953628376544,
      "stop_lon": 2.300977822126098,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:71091"
    },
    {
      "stop_id": "IDFM:463322",
      "stop_name": "Victor Hugo",
      "stop_lat": 48.869926082410636,
      "stop_lon": 2.2858287659129797,
      "route_id": "IDFM:C01372",
      "parent_station": "IDFM:71315"
    },
    {
      "stop_id": "IDFM:463323",
      "stop_name": "Villejuif Léo Lagrange",
      "stop_lat": 48.80436739901952,
      "stop_lon": 2.364068547658874,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70375"
    },
    {
      "stop_id": "IDFM:463324",
      "stop_name": "Villejuif Paul Vaillant-Couturier",
      "stop_lat": 48.79630822122641,
      "stop_lon": 2.368195583812103,
      "route_id": "IDFM:C01377",
      "parent_station": "IDFM:70248"
    },
    {
      "stop_id": "IDFM:463325",
      "stop_name": "Voltaire",
      "stop_lat": 48.85766243438601,
      "stop_lon": 2.3800316941488044,
      "route_id": "IDFM:C01379",
      "parent_station": "IDFM:71750"
    },
    {
      "stop_id": "IDFM:474397",
      "stop_name": "Miromesnil",
      "stop_lat": 48.87344757302508,
      "stop_lon": 2.3159507090192264,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71346"
    },
    {
      "stop_id": "IDFM:478395",
      "stop_name": "La Fourche",
      "stop_lat": 48.88736901850862,
      "stop_lon": 2.325414931297021,
      "route_id": "IDFM:C01383",
      "parent_station": "IDFM:71474"
    },
    {
      "stop_id": "IDFM:480286",
      "stop_name": "Pont Cardinet",
      "stop_lat": 48.888102389888395,
      "stop_lon": 2.3151908648850013,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71475"
    },
    {
      "stop_id": "IDFM:480288",
      "stop_name": "Saint-Ouen",
      "stop_lat": 48.90521399238465,
      "stop_lon": 2.3226598821131716,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:72126"
    },
    {
      "stop_id": "IDFM:480290",
      "stop_name": "Mairie de Saint-Ouen",
      "stop_lat": 48.91170451534923,
      "stop_lon": 2.333435858789529,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:72168"
    },
    {
      "stop_id": "IDFM:480330",
      "stop_name": "Mairie de Saint-Ouen",
      "stop_lat": 48.91164217552501,
      "stop_lon": 2.3335458072132695,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:72168"
    },
    {
      "stop_id": "IDFM:480340",
      "stop_name": "Pont Cardinet",
      "stop_lat": 48.88810340364297,
      "stop_lon": 2.3153681405051527,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71475"
    },
    {
      "stop_id": "IDFM:480341",
      "stop_name": "Saint-Ouen",
      "stop_lat": 48.90516956991565,
      "stop_lon": 2.3227559546697414,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:72126"
    },
    {
      "stop_id": "IDFM:480531",
      "stop_name": "Porte de Clichy",
      "stop_lat": 48.894333484274206,
      "stop_lon": 2.313404000186253,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71545"
    },
    {
      "stop_id": "IDFM:480541",
      "stop_name": "Porte de Clichy",
      "stop_lat": 48.89428867785142,
      "stop_lon": 2.313431869544054,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:71545"
    },
    {
      "stop_id": "IDFM:483277",
      "stop_name": "Barbara",
      "stop_lat": 48.80979025430712,
      "stop_lon": 2.3174992240284045,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70441"
    },
    {
      "stop_id": "IDFM:483313",
      "stop_name": "Barbara",
      "stop_lat": 48.8097987811254,
      "stop_lon": 2.3174174096489284,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:70441"
    },
    {
      "stop_id": "IDFM:483314",
      "stop_name": "Bagneux - Lucie Aubrac",
      "stop_lat": 48.80354822259104,
      "stop_lon": 2.317458362049886,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:483315"
    },
    {
      "stop_id": "IDFM:483423",
      "stop_name": "Bagneux - Lucie Aubrac",
      "stop_lat": 48.80353046920307,
      "stop_lon": 2.317499440854107,
      "route_id": "IDFM:C01374",
      "parent_station": "IDFM:483315"
    },
    {
      "stop_id": "IDFM:483898",
      "stop_name": "Mairie d'Aubervilliers",
      "stop_lat": 48.91373058592168,
      "stop_lon": 2.3808510232809863,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72524"
    },
    {
      "stop_id": "IDFM:483900",
      "stop_name": "Aimé Césaire",
      "stop_lat": 48.90843758066954,
      "stop_lon": 2.37813084516706,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72491"
    },
    {
      "stop_id": "IDFM:483903",
      "stop_name": "Aimé Césaire",
      "stop_lat": 48.90838312983791,
      "stop_lon": 2.378035995881576,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72491"
    },
    {
      "stop_id": "IDFM:483904",
      "stop_name": "Mairie d'Aubervilliers",
      "stop_lat": 48.913739225808136,
      "stop_lon": 2.3807826993163266,
      "route_id": "IDFM:C01382",
      "parent_station": "IDFM:72524"
    },
    {
      "stop_id": "IDFM:490762",
      "stop_name": "Serge Gainsbourg",
      "stop_lat": 48.88125303440166,
      "stop_lon": 2.4271621685895237,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490779"
    },
    {
      "stop_id": "IDFM:490764",
      "stop_name": "Romainville-Carnot",
      "stop_lat": 48.88369120755144,
      "stop_lon": 2.440689824547642,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71947"
    },
    {
      "stop_id": "IDFM:490766",
      "stop_name": "Montreuil - Hôpital",
      "stop_lat": 48.87833284108111,
      "stop_lon": 2.454682210916966,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71897"
    },
    {
      "stop_id": "IDFM:490768",
      "stop_name": "La Dhuys",
      "stop_lat": 48.87808331706366,
      "stop_lon": 2.4651563455784005,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490782"
    },
    {
      "stop_id": "IDFM:490770",
      "stop_name": "Coteaux Beauclair",
      "stop_lat": 48.88212212689498,
      "stop_lon": 2.4673647804495924,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490783"
    },
    {
      "stop_id": "IDFM:490772",
      "stop_name": "Rosny-Bois-Perrier",
      "stop_lat": 48.88307064342213,
      "stop_lon": 2.4806093523524932,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:426280"
    },
    {
      "stop_id": "IDFM:490774",
      "stop_name": "Saint-Denis - Pleyel",
      "stop_lat": 48.91891285848584,
      "stop_lon": 2.3457474221109633,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:490784"
    },
    {
      "stop_id": "IDFM:490787",
      "stop_name": "Serge Gainsbourg",
      "stop_lat": 48.881208070541476,
      "stop_lon": 2.427162663175348,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490779"
    },
    {
      "stop_id": "IDFM:490788",
      "stop_name": "Romainville-Carnot",
      "stop_lat": 48.883574237927014,
      "stop_lon": 2.4406774445901713,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71947"
    },
    {
      "stop_id": "IDFM:490789",
      "stop_name": "Montreuil - Hôpital",
      "stop_lat": 48.878269829247664,
      "stop_lon": 2.4546692358364623,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:71897"
    },
    {
      "stop_id": "IDFM:490791",
      "stop_name": "La Dhuys",
      "stop_lat": 48.8780111916913,
      "stop_lon": 2.465116181953647,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490782"
    },
    {
      "stop_id": "IDFM:490792",
      "stop_name": "Coteaux Beauclair",
      "stop_lat": 48.88208645899326,
      "stop_lon": 2.4674333244222826,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:490783"
    },
    {
      "stop_id": "IDFM:490793",
      "stop_name": "Rosny-Bois-Perrier",
      "stop_lat": 48.88302567942574,
      "stop_lon": 2.480609800808591,
      "route_id": "IDFM:C01381",
      "parent_station": "IDFM:426280"
    },
    {
      "stop_id": "IDFM:490794",
      "stop_name": "Saint-Denis - Pleyel",
      "stop_lat": 48.91873375567987,
      "stop_lon": 2.345886129823062,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:490784"
    },
    {
      "stop_id": "IDFM:490833",
      "stop_name": "Thiais - Orly​ (Pont de Rungis​)",
      "stop_lat": 48.746835369386844,
      "stop_lon": 2.3723847511214924,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:69677"
    },
    {
      "stop_id": "IDFM:490835",
      "stop_name": "Chevilly-Larue​ (Marché International​)",
      "stop_lat": 48.758492915434516,
      "stop_lon": 2.3674425092258047,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73753"
    },
    {
      "stop_id": "IDFM:490837",
      "stop_name": "L'Haÿ-les-Roses",
      "stop_lat": 48.77532646395088,
      "stop_lon": 2.354991210566311,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:69974"
    },
    {
      "stop_id": "IDFM:490839",
      "stop_name": "Hôpital Bicêtre",
      "stop_lat": 48.809971090725966,
      "stop_lon": 2.349946533239157,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:70445"
    },
    {
      "stop_id": "IDFM:490841",
      "stop_name": "Maison Blanche",
      "stop_lat": 48.82120786862075,
      "stop_lon": 2.3589182598590392,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:70645"
    },
    {
      "stop_id": "IDFM:490848",
      "stop_name": "Maison Blanche",
      "stop_lat": 48.82119024728473,
      "stop_lon": 2.358986576752596,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:70645"
    },
    {
      "stop_id": "IDFM:490849",
      "stop_name": "Hôpital Bicêtre",
      "stop_lat": 48.80994448106093,
      "stop_lon": 2.350014950529505,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:70445"
    },
    {
      "stop_id": "IDFM:490850",
      "stop_name": "L'Haÿ-les-Roses",
      "stop_lat": 48.775326684341785,
      "stop_lon": 2.355032032091648,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:69974"
    },
    {
      "stop_id": "IDFM:490851",
      "stop_name": "Chevilly-Larue​ (Marché International​)",
      "stop_lat": 48.75848356146962,
      "stop_lon": 2.367374604185919,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:73753"
    },
    {
      "stop_id": "IDFM:490852",
      "stop_name": "Thiais - Orly​ (Pont de Rungis​)",
      "stop_lat": 48.74684479207573,
      "stop_lon": 2.372466241689093,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:69677"
    },
    {
      "stop_id": "IDFM:490908",
      "stop_name": "Aéroport d'Orly",
      "stop_lat": 48.727919520945235,
      "stop_lon": 2.362102529020536,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:63284"
    },
    {
      "stop_id": "IDFM:490917",
      "stop_name": "Aéroport d'Orly",
      "stop_lat": 48.72791074498099,
      "stop_lon": 2.3621434233506027,
      "route_id": "IDFM:C01384",
      "parent_station": "IDFM:63284"
    }
  ],
  "adjacentStops": [
    {
      "from_stop_id": "IDFM:21784",
      "to_stop_id": "IDFM:463056",
      "duration": 120,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ],
        [
          2.464290193819998,
          48.768752248609914
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:69884",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:21784",
      "to_stop_id": "IDFM:463057",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:69884",
      "to_stop_unique_id": "IDFM:69884"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:21903",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:21918",
      "duration": 137,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:22026",
      "duration": 258,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:462946",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:462961",
      "duration": 153,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:462963",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:463250",
      "duration": 60,
      "path": [
        [
          2.364283168306328,
          48.86712186124499
        ],
        [
          2.3746806763746613,
          48.865162111168765
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:21902",
      "to_stop_id": "IDFM:463316",
      "duration": 60,
      "path": [
        [
          2.3615893783321895,
          48.86668483219092
        ],
        [
          2.364283168306328,
          48.86712186124499
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:21902",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:21905",
      "duration": 60,
      "path": [
        [
          2.3640990472225725,
          48.867687468165165
        ],
        [
          2.3685262686038397,
          48.864581198067484
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:21918",
      "duration": 134,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:22026",
      "duration": 270,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:462946",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:462961",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:462963",
      "duration": 124,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:463191",
      "duration": 60,
      "path": [
        [
          2.361023902048923,
          48.870620926265296
        ],
        [
          2.3640990472225725,
          48.867687468165165
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:21903",
      "to_stop_id": "IDFM:463316",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21904",
      "to_stop_id": "IDFM:21923",
      "duration": 51,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21904",
      "to_stop_id": "IDFM:21985",
      "duration": 60,
      "path": [
        [
          2.2618218573220084,
          48.84515597018703
        ],
        [
          2.26690547594029,
          48.84508829917885
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71141"
    },
    {
      "from_stop_id": "IDFM:21904",
      "to_stop_id": "IDFM:463137",
      "duration": 120,
      "path": [
        [
          2.239077780728926,
          48.841991575324506
        ],
        [
          2.2618218573220084,
          48.84515597018703
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:21904",
      "to_stop_id": "IDFM:463234",
      "duration": 60,
      "path": [
        [
          2.258280744595537,
          48.847904123629235
        ],
        [
          2.2618218573220084,
          48.84515597018703
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71169"
    },
    {
      "from_stop_id": "IDFM:21904",
      "to_stop_id": "IDFM:463239",
      "duration": 63,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:21903",
      "duration": 60,
      "path": [
        [
          2.3640990472225725,
          48.867687468165165
        ],
        [
          2.3685262686038397,
          48.864581198067484
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:21925",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:22002",
      "duration": 60,
      "path": [
        [
          2.3685262686038397,
          48.864581198067484
        ],
        [
          2.371813630476951,
          48.859876937868705
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:22200",
      "duration": 264,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:463048",
      "duration": 282,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:463054",
      "duration": 103,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21905",
      "to_stop_id": "IDFM:463059",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:21926",
      "duration": 77,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:21963",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:21964",
      "duration": 314,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:22030",
      "duration": 60,
      "path": [
        [
          2.3274627984235092,
          48.87346783937801
        ],
        [
          2.331047286711241,
          48.87143742899726
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:22218",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:462969",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:462970",
      "duration": 338,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:462971",
      "duration": 60,
      "path": [
        [
          2.3244924701707954,
          48.87496650103443
        ],
        [
          2.3274627984235092,
          48.87346783937801
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:463188",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21906",
      "to_stop_id": "IDFM:463189",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21907",
      "to_stop_id": "IDFM:21922",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21907",
      "to_stop_id": "IDFM:21971",
      "duration": 60,
      "path": [
        [
          2.2691113890218917,
          48.84714336145718
        ],
        [
          2.263989525333329,
          48.84797535417627
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71166"
    },
    {
      "from_stop_id": "IDFM:21907",
      "to_stop_id": "IDFM:21974",
      "duration": 60,
      "path": [
        [
          2.263989525333329,
          48.84797535417627
        ],
        [
          2.2582639186231943,
          48.84812886119474
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71169"
    },
    {
      "from_stop_id": "IDFM:21907",
      "to_stop_id": "IDFM:463238",
      "duration": 71,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21908",
      "to_stop_id": "IDFM:21917",
      "duration": 60,
      "path": [
        [
          2.2778858979590564,
          48.863951708879945
        ],
        [
          2.28683987979629,
          48.863088061116
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:21908",
      "to_stop_id": "IDFM:463055",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:21908",
      "to_stop_id": "IDFM:463203",
      "duration": 60,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ],
        [
          2.2778858979590564,
          48.863951708879945
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:21909",
      "to_stop_id": "IDFM:21921",
      "duration": 60,
      "path": [
        [
          2.3385912215385414,
          48.872135870317955
        ],
        [
          2.343167503558387,
          48.87141461757831
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:21909",
      "to_stop_id": "IDFM:22197",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:21909",
      "to_stop_id": "IDFM:463049",
      "duration": 21,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:21909",
      "to_stop_id": "IDFM:463058",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:21909",
      "to_stop_id": "IDFM:463133",
      "duration": 60,
      "path": [
        [
          2.343167503558387,
          48.87141461757831
        ],
        [
          2.348428667867017,
          48.87049007203075
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:21910",
      "to_stop_id": "IDFM:21925",
      "duration": 60,
      "path": [
        [
          2.3681558453945515,
          48.86477709852134
        ],
        [
          2.3738805382115475,
          48.86141665790915
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21910",
      "to_stop_id": "IDFM:22002",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:21910",
      "to_stop_id": "IDFM:463096",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:21910",
      "to_stop_id": "IDFM:463299",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:21910",
      "to_stop_id": "IDFM:463325",
      "duration": 60,
      "path": [
        [
          2.3738805382115475,
          48.86141665790915
        ],
        [
          2.3800316941488044,
          48.85766243438601
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:21926",
      "duration": 60,
      "path": [
        [
          2.3210135978085997,
          48.87454661293485
        ],
        [
          2.327651103418782,
          48.87366675194512
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:21962",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:21963",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:21964",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:22218",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:462957",
      "duration": 60,
      "path": [
        [
          2.3146768238242688,
          48.87369210197914
        ],
        [
          2.3210135978085997,
          48.87454661293485
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:462969",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:462970",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:462971",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:462972",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21911",
      "to_stop_id": "IDFM:463300",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21912",
      "to_stop_id": "IDFM:21924",
      "duration": 60,
      "path": [
        [
          2.3146430401104467,
          48.8737728492328
        ],
        [
          2.310001699600818,
          48.87215435003593
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:21912",
      "to_stop_id": "IDFM:21931",
      "duration": 60,
      "path": [
        [
          2.310001699600818,
          48.87215435003593
        ],
        [
          2.309488192337113,
          48.86872488799857
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:21912",
      "to_stop_id": "IDFM:462915",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:21913",
      "to_stop_id": "IDFM:21936",
      "duration": 60,
      "path": [
        [
          2.4107036110235414,
          48.853563500943025
        ],
        [
          2.406038544798052,
          48.85273102047999
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:21913",
      "to_stop_id": "IDFM:463053",
      "duration": 60,
      "path": [
        [
          2.4229449336324724,
          48.855754613895044
        ],
        [
          2.4107036110235414,
          48.853563500943025
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:21913",
      "to_stop_id": "IDFM:463221",
      "duration": 360,
      "path": [
        [
          2.4414386664522225,
          48.862313130940244
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4229449336324724,
          48.855754613895044
        ],
        [
          2.4107036110235414,
          48.853563500943025
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71785"
    },
    {
      "from_stop_id": "IDFM:21913",
      "to_stop_id": "IDFM:463279",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:21914",
      "to_stop_id": "IDFM:21930",
      "duration": 60,
      "path": [
        [
          2.2570461929221506,
          48.83795840561949
        ],
        [
          2.259800845144346,
          48.84258027627653
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:21914",
      "to_stop_id": "IDFM:462914",
      "duration": 120,
      "path": [
        [
          2.243583048618053,
          48.83359298218974
        ],
        [
          2.2570461929221506,
          48.83795840561949
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:21914",
      "to_stop_id": "IDFM:463259",
      "duration": 24,
      "path": null,
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:21915",
      "to_stop_id": "IDFM:21928",
      "duration": 60,
      "path": [
        [
          2.268219083521012,
          48.852462147844555
        ],
        [
          2.270032697561899,
          48.855306214887065
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:21915",
      "to_stop_id": "IDFM:463203",
      "duration": 60,
      "path": [
        [
          2.270032697561899,
          48.855306214887065
        ],
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:21915",
      "to_stop_id": "IDFM:463290",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:21916",
      "to_stop_id": "IDFM:21934",
      "duration": 60,
      "path": [
        [
          2.3848878986690423,
          48.85500743705073
        ],
        [
          2.3802080115879227,
          48.857735295553454
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:21916",
      "to_stop_id": "IDFM:463299",
      "duration": 60,
      "path": [
        [
          2.3802080115879227,
          48.857735295553454
        ],
        [
          2.3739964519221886,
          48.861480218467655
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:21916",
      "to_stop_id": "IDFM:463325",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:21917",
      "to_stop_id": "IDFM:21908",
      "duration": 60,
      "path": [
        [
          2.2778858979590564,
          48.863951708879945
        ],
        [
          2.28683987979629,
          48.863088061116
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:21917",
      "to_stop_id": "IDFM:21927",
      "duration": 60,
      "path": [
        [
          2.28683987979629,
          48.863088061116
        ],
        [
          2.293906396859107,
          48.864685839288676
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:21917",
      "to_stop_id": "IDFM:22164",
      "duration": 122,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:21917",
      "to_stop_id": "IDFM:463268",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:21917",
      "to_stop_id": "IDFM:463318",
      "duration": 1,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:21902",
      "duration": 130,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:21903",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:21920",
      "duration": 60,
      "path": [
        [
          2.3537315826731833,
          48.869349679171926
        ],
        [
          2.3631211111325285,
          48.86739447922248
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:21925",
      "duration": 60,
      "path": [
        [
          2.3631211111325285,
          48.86739447922248
        ],
        [
          2.3681558453945515,
          48.86477709852134
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:22026",
      "duration": 171,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:462946",
      "duration": 113,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:462961",
      "duration": 130,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:462963",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21918",
      "to_stop_id": "IDFM:463316",
      "duration": 174,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:22072",
      "duration": 176,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:22073",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:22080",
      "duration": 192,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:22442",
      "duration": 60,
      "path": [
        [
          2.3891047050322425,
          48.85221407333194
        ],
        [
          2.3959437210498136,
          48.84888521841715
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:462942",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:462943",
      "duration": 176,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:463028",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:463039",
      "duration": 60,
      "path": [
        [
          2.3959437210498136,
          48.84888521841715
        ],
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:21919",
      "to_stop_id": "IDFM:463067",
      "duration": 176,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:21918",
      "duration": 60,
      "path": [
        [
          2.3537315826731833,
          48.869349679171926
        ],
        [
          2.3631211111325285,
          48.86739447922248
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:22134",
      "duration": 136,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:22159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:463133",
      "duration": 60,
      "path": [
        [
          2.348428667867017,
          48.87049007203075
        ],
        [
          2.3537315826731833,
          48.869349679171926
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:463264",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:463312",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21920",
      "to_stop_id": "IDFM:463313",
      "duration": 144,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21921",
      "to_stop_id": "IDFM:21909",
      "duration": 60,
      "path": [
        [
          2.3385912215385414,
          48.872135870317955
        ],
        [
          2.343167503558387,
          48.87141461757831
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:21921",
      "to_stop_id": "IDFM:22196",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:21921",
      "to_stop_id": "IDFM:463036",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:21921",
      "to_stop_id": "IDFM:463037",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:21921",
      "to_stop_id": "IDFM:463144",
      "duration": 60,
      "path": [
        [
          2.333970457594147,
          48.87280723715393
        ],
        [
          2.3385912215385414,
          48.872135870317955
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:21922",
      "to_stop_id": "IDFM:21907",
      "duration": 42,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21922",
      "to_stop_id": "IDFM:21928",
      "duration": 60,
      "path": [
        [
          2.2639392129884905,
          48.84767825351756
        ],
        [
          2.268219083521012,
          48.852462147844555
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:21922",
      "to_stop_id": "IDFM:463238",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21922",
      "to_stop_id": "IDFM:463239",
      "duration": 60,
      "path": [
        [
          2.261631583355701,
          48.844880488259605
        ],
        [
          2.2639392129884905,
          48.84767825351756
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21923",
      "to_stop_id": "IDFM:21904",
      "duration": 61,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21923",
      "to_stop_id": "IDFM:21930",
      "duration": 366,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:21923",
      "to_stop_id": "IDFM:462956",
      "duration": 0,
      "path": [
        [
          2.261511916905205,
          48.84491122756327
        ],
        [
          2.25967486021195,
          48.842624465259945
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:21923",
      "to_stop_id": "IDFM:463238",
      "duration": 60,
      "path": [
        [
          2.2638093220226634,
          48.84770893216831
        ],
        [
          2.261511916905205,
          48.84491122756327
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21923",
      "to_stop_id": "IDFM:463239",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21924",
      "to_stop_id": "IDFM:21912",
      "duration": 60,
      "path": [
        [
          2.3146430401104467,
          48.8737728492328
        ],
        [
          2.310001699600818,
          48.87215435003593
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:21924",
      "to_stop_id": "IDFM:22224",
      "duration": 62,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:21924",
      "to_stop_id": "IDFM:462957",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:21924",
      "to_stop_id": "IDFM:463300",
      "duration": 60,
      "path": [
        [
          2.321031570403537,
          48.874632152132925
        ],
        [
          2.3146430401104467,
          48.8737728492328
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21924",
      "to_stop_id": "IDFM:474397",
      "duration": 56,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:21905",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:21910",
      "duration": 60,
      "path": [
        [
          2.3681558453945515,
          48.86477709852134
        ],
        [
          2.3738805382115475,
          48.86141665790915
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:21918",
      "duration": 60,
      "path": [
        [
          2.3631211111325285,
          48.86739447922248
        ],
        [
          2.3681558453945515,
          48.86477709852134
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:22200",
      "duration": 269,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:463048",
      "duration": 285,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:463054",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21925",
      "to_stop_id": "IDFM:463059",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:21906",
      "duration": 80,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:21911",
      "duration": 60,
      "path": [
        [
          2.3210135978085997,
          48.87454661293485
        ],
        [
          2.327651103418782,
          48.87366675194512
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:21963",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:21964",
      "duration": 287,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:22218",
      "duration": 315,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:462969",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:462970",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:462971",
      "duration": 347,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:463144",
      "duration": 60,
      "path": [
        [
          2.327651103418782,
          48.87366675194512
        ],
        [
          2.333970457594147,
          48.87280723715393
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:463188",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21926",
      "to_stop_id": "IDFM:463189",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21927",
      "to_stop_id": "IDFM:21917",
      "duration": 60,
      "path": [
        [
          2.28683987979629,
          48.863088061116
        ],
        [
          2.293906396859107,
          48.864685839288676
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:21927",
      "to_stop_id": "IDFM:463052",
      "duration": 60,
      "path": [
        [
          2.293906396859107,
          48.864685839288676
        ],
        [
          2.3011043523926706,
          48.86464714790582
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:21927",
      "to_stop_id": "IDFM:463190",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:21928",
      "to_stop_id": "IDFM:21915",
      "duration": 60,
      "path": [
        [
          2.268219083521012,
          48.852462147844555
        ],
        [
          2.270032697561899,
          48.855306214887065
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:21928",
      "to_stop_id": "IDFM:21922",
      "duration": 60,
      "path": [
        [
          2.2639392129884905,
          48.84767825351756
        ],
        [
          2.268219083521012,
          48.852462147844555
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21928",
      "to_stop_id": "IDFM:463038",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:21929",
      "to_stop_id": "IDFM:463055",
      "duration": 60,
      "path": [
        [
          2.277916063289398,
          48.86403732937509
        ],
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:21929",
      "to_stop_id": "IDFM:463203",
      "duration": 0,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:21929",
      "to_stop_id": "IDFM:463290",
      "duration": 60,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ],
        [
          2.269948157934635,
          48.85550355839564
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:21930",
      "to_stop_id": "IDFM:21914",
      "duration": 60,
      "path": [
        [
          2.2570461929221506,
          48.83795840561949
        ],
        [
          2.259800845144346,
          48.84258027627653
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:21930",
      "to_stop_id": "IDFM:21923",
      "duration": 366,
      "path": null,
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21930",
      "to_stop_id": "IDFM:462956",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:21930",
      "to_stop_id": "IDFM:463239",
      "duration": 0,
      "path": [
        [
          2.259800845144346,
          48.84258027627653
        ],
        [
          2.261631583355701,
          48.844880488259605
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21931",
      "to_stop_id": "IDFM:21912",
      "duration": 60,
      "path": [
        [
          2.310001699600818,
          48.87215435003593
        ],
        [
          2.309488192337113,
          48.86872488799857
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:21931",
      "to_stop_id": "IDFM:21935",
      "duration": 60,
      "path": [
        [
          2.309488192337113,
          48.86872488799857
        ],
        [
          2.301130527470699,
          48.8647282422178
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:21931",
      "to_stop_id": "IDFM:22082",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:21931",
      "to_stop_id": "IDFM:463180",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:21931",
      "to_stop_id": "IDFM:463181",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:22030",
      "duration": 343,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:22195",
      "duration": 367,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:22388",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463036",
      "duration": 60,
      "path": [
        [
          2.3386163553228103,
          48.87223943327036
        ],
        [
          2.334000835220775,
          48.87288385004922
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463144",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463145",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463188",
      "duration": 60,
      "path": [
        [
          2.334000835220775,
          48.87288385004922
        ],
        [
          2.327677208279404,
          48.8737568325288
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463244",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:21932",
      "to_stop_id": "IDFM:463246",
      "duration": 330,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:21933",
      "to_stop_id": "IDFM:22198",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:21933",
      "to_stop_id": "IDFM:463049",
      "duration": 60,
      "path": [
        [
          2.3484940979375373,
          48.8706433160169
        ],
        [
          2.3432202183423976,
          48.871558801223806
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:21933",
      "to_stop_id": "IDFM:463115",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:21933",
      "to_stop_id": "IDFM:463133",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:21933",
      "to_stop_id": "IDFM:463264",
      "duration": 60,
      "path": [
        [
          2.3538223820093616,
          48.86950305730265
        ],
        [
          2.3484940979375373,
          48.8706433160169
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:21934",
      "to_stop_id": "IDFM:21916",
      "duration": 60,
      "path": [
        [
          2.3848878986690423,
          48.85500743705073
        ],
        [
          2.3802080115879227,
          48.857735295553454
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:21934",
      "to_stop_id": "IDFM:463143",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:21934",
      "to_stop_id": "IDFM:463298",
      "duration": 60,
      "path": [
        [
          2.389429968854563,
          48.85236862327955
        ],
        [
          2.3848878986690423,
          48.85500743705073
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:21935",
      "to_stop_id": "IDFM:21931",
      "duration": 60,
      "path": [
        [
          2.309488192337113,
          48.86872488799857
        ],
        [
          2.301130527470699,
          48.8647282422178
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:21935",
      "to_stop_id": "IDFM:463052",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:21935",
      "to_stop_id": "IDFM:463190",
      "duration": 60,
      "path": [
        [
          2.301130527470699,
          48.8647282422178
        ],
        [
          2.2938642256822708,
          48.864780022257456
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:21936",
      "to_stop_id": "IDFM:21913",
      "duration": 60,
      "path": [
        [
          2.4107036110235414,
          48.853563500943025
        ],
        [
          2.406038544798052,
          48.85273102047999
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:21936",
      "to_stop_id": "IDFM:25084",
      "duration": 60,
      "path": [
        [
          2.406038544798052,
          48.85273102047999
        ],
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:21936",
      "to_stop_id": "IDFM:463215",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:21902",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:21903",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:21918",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:21996",
      "duration": 60,
      "path": [
        [
          2.3707644347484003,
          48.8700070178386
        ],
        [
          2.364804805140413,
          48.86794303120089
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:22026",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:462946",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:462960",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:462961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:462963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:463108",
      "duration": 60,
      "path": [
        [
          2.364804805140413,
          48.86794303120089
        ],
        [
          2.356535223129095,
          48.865380699169286
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21937",
      "to_stop_id": "IDFM:463316",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21938",
      "to_stop_id": "IDFM:21939",
      "duration": 60,
      "path": [
        [
          2.2305185727726955,
          48.82968726438348
        ],
        [
          2.237965973200589,
          48.83183051284947
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:70698",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:21938",
      "to_stop_id": "IDFM:463233",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:70698",
      "to_stop_unique_id": "IDFM:70698"
    },
    {
      "from_stop_id": "IDFM:21939",
      "to_stop_id": "IDFM:21938",
      "duration": 60,
      "path": [
        [
          2.2305185727726955,
          48.82968726438348
        ],
        [
          2.237965973200589,
          48.83183051284947
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:70698"
    },
    {
      "from_stop_id": "IDFM:21939",
      "to_stop_id": "IDFM:462914",
      "duration": 60,
      "path": [
        [
          2.237965973200589,
          48.83183051284947
        ],
        [
          2.243583048618053,
          48.83359298218974
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:21939",
      "to_stop_id": "IDFM:463035",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:21940",
      "to_stop_id": "IDFM:462914",
      "duration": 32,
      "path": null,
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:21940",
      "to_stop_id": "IDFM:463035",
      "duration": 60,
      "path": [
        [
          2.24346821405889,
          48.83380810724753
        ],
        [
          2.237891101038026,
          48.83191997405451
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:21940",
      "to_stop_id": "IDFM:463259",
      "duration": 120,
      "path": [
        [
          2.2567894964207142,
          48.83799728287381
        ],
        [
          2.24346821405889,
          48.83380810724753
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:21941",
      "to_stop_id": "IDFM:21943",
      "duration": 120,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4418193772459085,
          48.8622744369706
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71785",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:21941",
      "to_stop_id": "IDFM:463221",
      "duration": 35,
      "path": null,
      "from_stop_unique_id": "IDFM:71785",
      "to_stop_unique_id": "IDFM:71785"
    },
    {
      "from_stop_id": "IDFM:21942",
      "to_stop_id": "IDFM:21943",
      "duration": 60,
      "path": [
        [
          2.4229730864963517,
          48.855673808550314
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:21942",
      "to_stop_id": "IDFM:463053",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:21942",
      "to_stop_id": "IDFM:463279",
      "duration": 60,
      "path": [
        [
          2.410718154027495,
          48.85348263120656
        ],
        [
          2.4229730864963517,
          48.855673808550314
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:21943",
      "to_stop_id": "IDFM:21941",
      "duration": 120,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4418193772459085,
          48.8622744369706
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71785"
    },
    {
      "from_stop_id": "IDFM:21943",
      "to_stop_id": "IDFM:21942",
      "duration": 60,
      "path": [
        [
          2.4229730864963517,
          48.855673808550314
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:21943",
      "to_stop_id": "IDFM:463176",
      "duration": 0,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:21945",
      "to_stop_id": "IDFM:21948",
      "duration": 60,
      "path": [
        [
          2.398537305704572,
          48.865032564464
        ],
        [
          2.40160636134298,
          48.86842046799948
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:21945",
      "to_stop_id": "IDFM:21950",
      "duration": 112,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21945",
      "to_stop_id": "IDFM:462991",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21945",
      "to_stop_id": "IDFM:462997",
      "duration": 0,
      "path": [
        [
          2.398537305704572,
          48.865032564464
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21946",
      "to_stop_id": "IDFM:21947",
      "duration": 60,
      "path": [
        [
          2.4044981831688688,
          48.87184337222297
        ],
        [
          2.4070619733807948,
          48.87656859902798
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21946",
      "to_stop_id": "IDFM:21948",
      "duration": 60,
      "path": [
        [
          2.40160636134298,
          48.86842046799948
        ],
        [
          2.4044981831688688,
          48.87184337222297
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:21946",
      "to_stop_id": "IDFM:463071",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:21947",
      "to_stop_id": "IDFM:21946",
      "duration": 60,
      "path": [
        [
          2.4044981831688688,
          48.87184337222297
        ],
        [
          2.4070619733807948,
          48.87656859902798
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:21947",
      "to_stop_id": "IDFM:21949",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21947",
      "to_stop_id": "IDFM:463262",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21947",
      "to_stop_id": "IDFM:463263",
      "duration": 139,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21948",
      "to_stop_id": "IDFM:21945",
      "duration": 60,
      "path": [
        [
          2.398537305704572,
          48.865032564464
        ],
        [
          2.40160636134298,
          48.86842046799948
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21948",
      "to_stop_id": "IDFM:21946",
      "duration": 60,
      "path": [
        [
          2.40160636134298,
          48.86842046799948
        ],
        [
          2.4044981831688688,
          48.87184337222297
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:21948",
      "to_stop_id": "IDFM:462990",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:21949",
      "to_stop_id": "IDFM:21947",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21949",
      "to_stop_id": "IDFM:463223",
      "duration": 60,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ],
        [
          2.4163499077398614,
          48.879658467973904
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:21949",
      "to_stop_id": "IDFM:463262",
      "duration": 131,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21949",
      "to_stop_id": "IDFM:463263",
      "duration": 0,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21949",
      "to_stop_id": "IDFM:463319",
      "duration": 60,
      "path": [
        [
          2.3986752529487725,
          48.875519513947125
        ],
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:21950",
      "to_stop_id": "IDFM:21945",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21950",
      "to_stop_id": "IDFM:22020",
      "duration": 60,
      "path": [
        [
          2.386157046298338,
          48.86296417435419
        ],
        [
          2.397353102563538,
          48.86488271295642
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:21950",
      "to_stop_id": "IDFM:22022",
      "duration": 60,
      "path": [
        [
          2.397353102563538,
          48.86488271295642
        ],
        [
          2.4088234090802456,
          48.86432836987192
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:21950",
      "to_stop_id": "IDFM:462991",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21950",
      "to_stop_id": "IDFM:462997",
      "duration": 112,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:21951",
      "to_stop_id": "IDFM:21993",
      "duration": 60,
      "path": [
        [
          2.3986480899605933,
          48.87551038418075
        ],
        [
          2.393139370360497,
          48.87672366197325
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:21951",
      "to_stop_id": "IDFM:21995",
      "duration": 0,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:21951",
      "to_stop_id": "IDFM:24682",
      "duration": 77,
      "path": null,
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:21951",
      "to_stop_id": "IDFM:463254",
      "duration": 0,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:22001",
      "duration": 60,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ],
        [
          2.371438959985608,
          48.88302391342934
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:22012",
      "duration": 60,
      "path": [
        [
          2.371438959985608,
          48.88302391342934
        ],
        [
          2.3793909893523923,
          48.88513384808583
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:22118",
      "duration": 242,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:22368",
      "duration": 291,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:24680",
      "duration": 161,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:462981",
      "duration": 242,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:463008",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:463074",
      "duration": 168,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:21952",
      "to_stop_id": "IDFM:463095",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:21954",
      "to_stop_id": "IDFM:21955",
      "duration": 60,
      "path": [
        [
          2.3764237443204546,
          48.830006377536904
        ],
        [
          2.3669231215308075,
          48.827123441429386
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:21954",
      "to_stop_id": "IDFM:463045",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:21954",
      "to_stop_id": "IDFM:490841",
      "duration": 60,
      "path": [
        [
          2.3669231215308075,
          48.827123441429386
        ],
        [
          2.3589182598590392,
          48.82120786862075
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:21955",
      "to_stop_id": "IDFM:21954",
      "duration": 60,
      "path": [
        [
          2.3764237443204546,
          48.830006377536904
        ],
        [
          2.3669231215308075,
          48.827123441429386
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:21955",
      "to_stop_id": "IDFM:21956",
      "duration": 60,
      "path": [
        [
          2.386464909364189,
          48.83334999725014
        ],
        [
          2.3764237443204546,
          48.830006377536904
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:21955",
      "to_stop_id": "IDFM:463112",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:21956",
      "to_stop_id": "IDFM:21955",
      "duration": 60,
      "path": [
        [
          2.386464909364189,
          48.83334999725014
        ],
        [
          2.3764237443204546,
          48.830006377536904
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:21956",
      "to_stop_id": "IDFM:463129",
      "duration": 60,
      "path": [
        [
          2.3790620152277233,
          48.84010199803391
        ],
        [
          2.386464909364189,
          48.83334999725014
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:21956",
      "to_stop_id": "IDFM:463165",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:21957",
      "to_stop_id": "IDFM:21958",
      "duration": 60,
      "path": [
        [
          2.3791909087742873,
          48.84014763512746
        ],
        [
          2.372519782814122,
          48.8442498880687
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:21957",
      "to_stop_id": "IDFM:22178",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:21957",
      "to_stop_id": "IDFM:463128",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:21957",
      "to_stop_id": "IDFM:463129",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:21957",
      "to_stop_id": "IDFM:463165",
      "duration": 60,
      "path": [
        [
          2.386617850214058,
          48.833319303843425
        ],
        [
          2.3791909087742873,
          48.84014763512746
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:21958",
      "to_stop_id": "IDFM:21957",
      "duration": 60,
      "path": [
        [
          2.3791909087742873,
          48.84014763512746
        ],
        [
          2.372519782814122,
          48.8442498880687
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:21958",
      "to_stop_id": "IDFM:22083",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:21958",
      "to_stop_id": "IDFM:463046",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:21958",
      "to_stop_id": "IDFM:463159",
      "duration": 180,
      "path": [
        [
          2.372519782814122,
          48.8442498880687
        ],
        [
          2.346411849769497,
          48.859556532726764
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21958",
      "to_stop_id": "IDFM:463197",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:21960",
      "duration": 60,
      "path": [
        [
          2.3346236060049232,
          48.865755527376756
        ],
        [
          2.346275248188987,
          48.85951531585984
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:21966",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:22087",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:22092",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:22364",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463046",
      "duration": 180,
      "path": [
        [
          2.346275248188987,
          48.85951531585984
        ],
        [
          2.373100581933962,
          48.84467563174822
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463060",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463079",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463158",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463159",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21959",
      "to_stop_id": "IDFM:463160",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21960",
      "to_stop_id": "IDFM:21959",
      "duration": 60,
      "path": [
        [
          2.3346236060049232,
          48.865755527376756
        ],
        [
          2.346275248188987,
          48.85951531585984
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21960",
      "to_stop_id": "IDFM:21961",
      "duration": 60,
      "path": [
        [
          2.3258100487932776,
          48.87054467671624
        ],
        [
          2.3346236060049232,
          48.865755527376756
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21960",
      "to_stop_id": "IDFM:22375",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:21960",
      "to_stop_id": "IDFM:463283",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:21960",
      "to_stop_id": "IDFM:463284",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:21960",
      "duration": 60,
      "path": [
        [
          2.3258100487932776,
          48.87054467671624
        ],
        [
          2.3346236060049232,
          48.865755527376756
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:21965",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:22194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:462972",
      "duration": 60,
      "path": [
        [
          2.3240258961246782,
          48.875660854671985
        ],
        [
          2.3258100487932776,
          48.87054467671624
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:463063",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:463064",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21961",
      "to_stop_id": "IDFM:463081",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:21911",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:21963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:21964",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:22218",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:462969",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:462970",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:462971",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:462972",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:463064",
      "duration": 60,
      "path": [
        [
          2.3258757399808627,
          48.870630484228144
        ],
        [
          2.324122029700433,
          48.87560743718584
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:463300",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21962",
      "to_stop_id": "IDFM:480340",
      "duration": 120,
      "path": [
        [
          2.324122029700433,
          48.87560743718584
        ],
        [
          2.3153681405051527,
          48.88810340364297
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:21906",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:21926",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:21964",
      "duration": 275,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:22032",
      "duration": 60,
      "path": [
        [
          2.3246067760696794,
          48.875025603040974
        ],
        [
          2.3222946416498362,
          48.878780759649736
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:22218",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:462969",
      "duration": 280,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:462970",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:462971",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:463188",
      "duration": 337,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:463189",
      "duration": 60,
      "path": [
        [
          2.32755854621969,
          48.8735493178711
        ],
        [
          2.3246067760696794,
          48.875025603040974
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21963",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21906",
      "duration": 314,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21926",
      "duration": 287,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21963",
      "duration": 220,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:21965",
      "duration": 60,
      "path": [
        [
          2.3274477500193202,
          48.875689121953926
        ],
        [
          2.325039317039756,
          48.8700546880179
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:22218",
      "duration": 131,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:462969",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:462970",
      "duration": 139,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:462971",
      "duration": 253,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:463188",
      "duration": 274,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:463189",
      "duration": 303,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:21964",
      "to_stop_id": "IDFM:463317",
      "duration": 60,
      "path": [
        [
          2.333049172418236,
          48.87633650730735
        ],
        [
          2.3274477500193202,
          48.875689121953926
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:21961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:21964",
      "duration": 60,
      "path": [
        [
          2.3274477500193202,
          48.875689121953926
        ],
        [
          2.325039317039756,
          48.8700546880179
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:22194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:463063",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:463064",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:463081",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:21965",
      "to_stop_id": "IDFM:463164",
      "duration": 60,
      "path": [
        [
          2.325039317039756,
          48.8700546880179
        ],
        [
          2.323058362902906,
          48.8661808158217
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:21959",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:21990",
      "duration": 346,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:22087",
      "duration": 267,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:22092",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:22364",
      "duration": 250,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463060",
      "duration": 250,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463061",
      "duration": 60,
      "path": [
        [
          2.3514239919428728,
          48.85760073633446
        ],
        [
          2.3478374055687867,
          48.85776111354502
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463079",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463158",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463159",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21966",
      "to_stop_id": "IDFM:463160",
      "duration": 250,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21967",
      "to_stop_id": "IDFM:21981",
      "duration": 100,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21967",
      "to_stop_id": "IDFM:463023",
      "duration": 85,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21967",
      "to_stop_id": "IDFM:463024",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21967",
      "to_stop_id": "IDFM:463077",
      "duration": 120,
      "path": [
        [
          2.3645307640842486,
          48.842355035073275
        ],
        [
          2.354944184375758,
          48.8462878950311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:21984",
      "duration": 60,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3549316718232256,
          48.846197891637004
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:22386",
      "duration": 75,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:463024",
      "duration": 60,
      "path": [
        [
          2.3549316718232256,
          48.846197891637004
        ],
        [
          2.364395394600644,
          48.84228236697996
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:463077",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:463078",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21968",
      "to_stop_id": "IDFM:463141",
      "duration": 343,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3549316718232256,
          48.846197891637004
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:21979",
      "duration": 60,
      "path": [
        [
          2.3072520746428715,
          48.847226390053955
        ],
        [
          2.2985257262366363,
          48.84963080443241
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:22162",
      "duration": 157,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:22183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:463124",
      "duration": 60,
      "path": [
        [
          2.2985257262366363,
          48.84963080443241
        ],
        [
          2.2950189971435506,
          48.84703801370517
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:463194",
      "duration": 157,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:463201",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21969",
      "to_stop_id": "IDFM:463202",
      "duration": 20,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:21979",
      "duration": 60,
      "path": [
        [
          2.3162247549746047,
          48.84675637808778
        ],
        [
          2.3072520746428715,
          48.847226390053955
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:22056",
      "duration": 366,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:22229",
      "duration": 152,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:462959",
      "duration": 60,
      "path": [
        [
          2.3213512423547358,
          48.84889457005888
        ],
        [
          2.3162247549746047,
          48.84675637808778
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:463083",
      "duration": 144,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:463084",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:21970",
      "to_stop_id": "IDFM:463173",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:21971",
      "to_stop_id": "IDFM:21907",
      "duration": 60,
      "path": [
        [
          2.2691113890218917,
          48.84714336145718
        ],
        [
          2.263989525333329,
          48.84797535417627
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71166",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21971",
      "to_stop_id": "IDFM:21972",
      "duration": 60,
      "path": [
        [
          2.278009496527359,
          48.84618110622323
        ],
        [
          2.2691113890218917,
          48.84714336145718
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71166",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:21971",
      "to_stop_id": "IDFM:21982",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71166",
      "to_stop_unique_id": "IDFM:71162"
    },
    {
      "from_stop_id": "IDFM:21971",
      "to_stop_id": "IDFM:21985",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:71166",
      "to_stop_unique_id": "IDFM:71141"
    },
    {
      "from_stop_id": "IDFM:21972",
      "to_stop_id": "IDFM:21971",
      "duration": 60,
      "path": [
        [
          2.278009496527359,
          48.84618110622323
        ],
        [
          2.2691113890218917,
          48.84714336145718
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71166"
    },
    {
      "from_stop_id": "IDFM:21972",
      "to_stop_id": "IDFM:462958",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:21972",
      "to_stop_id": "IDFM:463076",
      "duration": 60,
      "path": [
        [
          2.2856210133667174,
          48.84660453239739
        ],
        [
          2.278009496527359,
          48.84618110622323
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:21973",
      "to_stop_id": "IDFM:21976",
      "duration": 60,
      "path": [
        [
          2.326104689925573,
          48.85093150598804
        ],
        [
          2.335142819346476,
          48.85284373829949
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21973",
      "to_stop_id": "IDFM:21978",
      "duration": 60,
      "path": [
        [
          2.335142819346476,
          48.85284373829949
        ],
        [
          2.340619126473996,
          48.8519477060765
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:21973",
      "to_stop_id": "IDFM:22136",
      "duration": 152,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:21973",
      "to_stop_id": "IDFM:462993",
      "duration": 159,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:21973",
      "to_stop_id": "IDFM:462994",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:21974",
      "to_stop_id": "IDFM:21907",
      "duration": 60,
      "path": [
        [
          2.263989525333329,
          48.84797535417627
        ],
        [
          2.2582639186231943,
          48.84812886119474
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71169",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:21974",
      "to_stop_id": "IDFM:21988",
      "duration": 120,
      "path": [
        [
          2.2582639186231943,
          48.84812886119474
        ],
        [
          2.239042669312605,
          48.842063301935575
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71169",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:21974",
      "to_stop_id": "IDFM:463234",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71169",
      "to_stop_unique_id": "IDFM:71169"
    },
    {
      "from_stop_id": "IDFM:21975",
      "to_stop_id": "IDFM:21983",
      "duration": 335,
      "path": null,
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:21975",
      "to_stop_id": "IDFM:21984",
      "duration": 60,
      "path": [
        [
          2.348219343927666,
          48.85010969451494
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:21975",
      "to_stop_id": "IDFM:462944",
      "duration": 60,
      "path": [
        [
          2.3450218824744566,
          48.850793731720536
        ],
        [
          2.348219343927666,
          48.85010969451494
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:21975",
      "to_stop_id": "IDFM:463237",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:21976",
      "to_stop_id": "IDFM:21973",
      "duration": 60,
      "path": [
        [
          2.326104689925573,
          48.85093150598804
        ],
        [
          2.335142819346476,
          48.85284373829949
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:21976",
      "to_stop_id": "IDFM:21977",
      "duration": 60,
      "path": [
        [
          2.321227717195941,
          48.84870050683084
        ],
        [
          2.326104689925573,
          48.85093150598804
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:21976",
      "to_stop_id": "IDFM:21980",
      "duration": 70,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21976",
      "to_stop_id": "IDFM:462966",
      "duration": 65,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21976",
      "to_stop_id": "IDFM:462967",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21977",
      "to_stop_id": "IDFM:21976",
      "duration": 60,
      "path": [
        [
          2.321227717195941,
          48.84870050683084
        ],
        [
          2.326104689925573,
          48.85093150598804
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21977",
      "to_stop_id": "IDFM:462959",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:21977",
      "to_stop_id": "IDFM:463084",
      "duration": 60,
      "path": [
        [
          2.3162843486517035,
          48.84668027285166
        ],
        [
          2.321227717195941,
          48.84870050683084
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:21978",
      "to_stop_id": "IDFM:21973",
      "duration": 60,
      "path": [
        [
          2.335142819346476,
          48.85284373829949
        ],
        [
          2.340619126473996,
          48.8519477060765
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:21978",
      "to_stop_id": "IDFM:22145",
      "duration": 80,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:21978",
      "to_stop_id": "IDFM:462944",
      "duration": 0,
      "path": [
        [
          2.340619126473996,
          48.8519477060765
        ],
        [
          2.3450218824744566,
          48.850793731720536
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:21978",
      "to_stop_id": "IDFM:462995",
      "duration": 91,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:21978",
      "to_stop_id": "IDFM:462998",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:21979",
      "to_stop_id": "IDFM:21969",
      "duration": 60,
      "path": [
        [
          2.3072520746428715,
          48.847226390053955
        ],
        [
          2.2985257262366363,
          48.84963080443241
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21979",
      "to_stop_id": "IDFM:21970",
      "duration": 60,
      "path": [
        [
          2.3162247549746047,
          48.84675637808778
        ],
        [
          2.3072520746428715,
          48.847226390053955
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:21979",
      "to_stop_id": "IDFM:22163",
      "duration": 311,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:21979",
      "to_stop_id": "IDFM:463314",
      "duration": 308,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:21979",
      "to_stop_id": "IDFM:463315",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:21976",
      "duration": 67,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:22049",
      "duration": 60,
      "path": [
        [
          2.326859663121737,
          48.85156530639137
        ],
        [
          2.3277871847873874,
          48.84833282904063
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:22139",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:462950",
      "duration": 60,
      "path": [
        [
          2.3256998984524113,
          48.85588467848543
        ],
        [
          2.326859663121737,
          48.85156530639137
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:462966",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21980",
      "to_stop_id": "IDFM:462967",
      "duration": 57,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:21981",
      "to_stop_id": "IDFM:21967",
      "duration": 145,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21981",
      "to_stop_id": "IDFM:22004",
      "duration": 60,
      "path": [
        [
          2.3656078807625422,
          48.84229779662816
        ],
        [
          2.365992890937552,
          48.84649085420337
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:21981",
      "to_stop_id": "IDFM:463023",
      "duration": 20,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21981",
      "to_stop_id": "IDFM:463024",
      "duration": 113,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:21981",
      "to_stop_id": "IDFM:463097",
      "duration": 60,
      "path": [
        [
          2.3608176665940044,
          48.83847698406796
        ],
        [
          2.3656078807625422,
          48.84229779662816
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:21982",
      "to_stop_id": "IDFM:21971",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71162",
      "to_stop_unique_id": "IDFM:71166"
    },
    {
      "from_stop_id": "IDFM:21982",
      "to_stop_id": "IDFM:21985",
      "duration": 60,
      "path": [
        [
          2.26690547594029,
          48.84508829917885
        ],
        [
          2.2730641263728333,
          48.84707750734599
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71162",
      "to_stop_unique_id": "IDFM:71141"
    },
    {
      "from_stop_id": "IDFM:21982",
      "to_stop_id": "IDFM:462958",
      "duration": 60,
      "path": [
        [
          2.2730641263728333,
          48.84707750734599
        ],
        [
          2.2780435059854063,
          48.846086877931405
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71162",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:21975",
      "duration": 335,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:22137",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:462944",
      "duration": 35,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:462998",
      "duration": 60,
      "path": [
        [
          2.3448963012120796,
          48.851026876874485
        ],
        [
          2.340692291066523,
          48.85202455451491
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:463237",
      "duration": 60,
      "path": [
        [
          2.3482804131694897,
          48.85019546606951
        ],
        [
          2.3448963012120796,
          48.851026876874485
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:21983",
      "to_stop_id": "IDFM:463306",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:21968",
      "duration": 60,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3549316718232256,
          48.846197891637004
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:21975",
      "duration": 60,
      "path": [
        [
          2.348219343927666,
          48.85010969451494
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:22386",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:463077",
      "duration": 342,
      "path": [
        [
          2.354944184375758,
          48.8462878950311
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:463078",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:21984",
      "to_stop_id": "IDFM:463141",
      "duration": 0,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:21985",
      "to_stop_id": "IDFM:21904",
      "duration": 60,
      "path": [
        [
          2.2618218573220084,
          48.84515597018703
        ],
        [
          2.26690547594029,
          48.84508829917885
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71141",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:21985",
      "to_stop_id": "IDFM:21971",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:71141",
      "to_stop_unique_id": "IDFM:71166"
    },
    {
      "from_stop_id": "IDFM:21985",
      "to_stop_id": "IDFM:21982",
      "duration": 60,
      "path": [
        [
          2.26690547594029,
          48.84508829917885
        ],
        [
          2.2730641263728333,
          48.84707750734599
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71141",
      "to_stop_unique_id": "IDFM:71162"
    },
    {
      "from_stop_id": "IDFM:21986",
      "to_stop_id": "IDFM:21987",
      "duration": 60,
      "path": [
        [
          2.2856243417995747,
          48.84636172348247
        ],
        [
          2.2950459810371475,
          48.84695722990175
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:21986",
      "to_stop_id": "IDFM:462958",
      "duration": 60,
      "path": [
        [
          2.2780435059854063,
          48.846086877931405
        ],
        [
          2.2856243417995747,
          48.84636172348247
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:21986",
      "to_stop_id": "IDFM:463076",
      "duration": 34,
      "path": null,
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:21987",
      "to_stop_id": "IDFM:21986",
      "duration": 60,
      "path": [
        [
          2.2856243417995747,
          48.84636172348247
        ],
        [
          2.2950459810371475,
          48.84695722990175
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:21987",
      "to_stop_id": "IDFM:22190",
      "duration": 352,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:21987",
      "to_stop_id": "IDFM:463124",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:21987",
      "to_stop_id": "IDFM:463162",
      "duration": 354,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:21987",
      "to_stop_id": "IDFM:463202",
      "duration": 60,
      "path": [
        [
          2.2950459810371475,
          48.84695722990175
        ],
        [
          2.298684579816939,
          48.849532805857095
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:21988",
      "to_stop_id": "IDFM:21974",
      "duration": 120,
      "path": [
        [
          2.2582639186231943,
          48.84812886119474
        ],
        [
          2.239042669312605,
          48.842063301935575
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:71169"
    },
    {
      "from_stop_id": "IDFM:21988",
      "to_stop_id": "IDFM:21989",
      "duration": 120,
      "path": [
        [
          2.239042669312605,
          48.842063301935575
        ],
        [
          2.2283125898228056,
          48.84067248206251
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:70721"
    },
    {
      "from_stop_id": "IDFM:21988",
      "to_stop_id": "IDFM:463137",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:21989",
      "to_stop_id": "IDFM:21988",
      "duration": 120,
      "path": [
        [
          2.239042669312605,
          48.842063301935575
        ],
        [
          2.2283125898228056,
          48.84067248206251
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:70721",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:21989",
      "to_stop_id": "IDFM:463136",
      "duration": 0,
      "path": [
        [
          2.2283125898228056,
          48.84067248206251
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:70721",
      "to_stop_unique_id": "IDFM:70721"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:21966",
      "duration": 346,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:22091",
      "duration": 149,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:22364",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:462945",
      "duration": 60,
      "path": [
        [
          2.35154698561547,
          48.85757442237991
        ],
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:463060",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:463061",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:463079",
      "duration": 60,
      "path": [
        [
          2.347759259772123,
          48.8576887395772
        ],
        [
          2.35154698561547,
          48.85757442237991
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:21990",
      "to_stop_id": "IDFM:463080",
      "duration": 168,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21991",
      "to_stop_id": "IDFM:21998",
      "duration": 60,
      "path": [
        [
          2.376753738418521,
          48.871908975367674
        ],
        [
          2.384883957748741,
          48.87381265491752
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:21991",
      "to_stop_id": "IDFM:463192",
      "duration": 60,
      "path": [
        [
          2.384883957748741,
          48.87381265491752
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:21991",
      "to_stop_id": "IDFM:463287",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:21992",
      "to_stop_id": "IDFM:462945",
      "duration": 0,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:21992",
      "to_stop_id": "IDFM:463061",
      "duration": 60,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ],
        [
          2.3514239919428728,
          48.85760073633446
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:21992",
      "to_stop_id": "IDFM:463108",
      "duration": 60,
      "path": [
        [
          2.356535223129095,
          48.865380699169286
        ],
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21993",
      "to_stop_id": "IDFM:21951",
      "duration": 60,
      "path": [
        [
          2.3986480899605933,
          48.87551038418075
        ],
        [
          2.393139370360497,
          48.87672366197325
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:21993",
      "to_stop_id": "IDFM:463263",
      "duration": 60,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ],
        [
          2.3986480899605933,
          48.87551038418075
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21993",
      "to_stop_id": "IDFM:463319",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:21994",
      "to_stop_id": "IDFM:21997",
      "duration": 137,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21994",
      "to_stop_id": "IDFM:463091",
      "duration": 60,
      "path": [
        [
          2.3520239377964742,
          48.86628268363084
        ],
        [
          2.3567233472754983,
          48.86553459885931
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:21994",
      "to_stop_id": "IDFM:463108",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21994",
      "to_stop_id": "IDFM:463122",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21994",
      "to_stop_id": "IDFM:463316",
      "duration": 60,
      "path": [
        [
          2.3567233472754983,
          48.86553459885931
        ],
        [
          2.3615893783321895,
          48.86668483219092
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:21995",
      "to_stop_id": "IDFM:21951",
      "duration": 0,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:21995",
      "to_stop_id": "IDFM:463192",
      "duration": 0,
      "path": [
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:21995",
      "to_stop_id": "IDFM:463287",
      "duration": 0,
      "path": [
        [
          2.389325378910096,
          48.875247348641416
        ],
        [
          2.3852029155695313,
          48.87381879018058
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:21996",
      "to_stop_id": "IDFM:21937",
      "duration": 60,
      "path": [
        [
          2.3707644347484003,
          48.8700070178386
        ],
        [
          2.364804805140413,
          48.86794303120089
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21996",
      "to_stop_id": "IDFM:463110",
      "duration": 60,
      "path": [
        [
          2.376684607718542,
          48.87198955568015
        ],
        [
          2.3707644347484003,
          48.8700070178386
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:21996",
      "to_stop_id": "IDFM:463187",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:21994",
      "duration": 144,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:22142",
      "duration": 377,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:462945",
      "duration": 60,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ],
        [
          2.3565081436435467,
          48.86529961264491
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:462960",
      "duration": 60,
      "path": [
        [
          2.3565081436435467,
          48.86529961264491
        ],
        [
          2.3648796725347783,
          48.86788497134881
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:463108",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21997",
      "to_stop_id": "IDFM:463122",
      "duration": 123,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:21998",
      "to_stop_id": "IDFM:21991",
      "duration": 60,
      "path": [
        [
          2.376753738418521,
          48.871908975367674
        ],
        [
          2.384883957748741,
          48.87381265491752
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:21998",
      "to_stop_id": "IDFM:22128",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:21998",
      "to_stop_id": "IDFM:463110",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:21998",
      "to_stop_id": "IDFM:463111",
      "duration": 145,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:21998",
      "to_stop_id": "IDFM:463187",
      "duration": 60,
      "path": [
        [
          2.3710788377398995,
          48.869936723164365
        ],
        [
          2.376753738418521,
          48.871908975367674
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:21999",
      "to_stop_id": "IDFM:463223",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:21999",
      "to_stop_id": "IDFM:463263",
      "duration": 60,
      "path": [
        [
          2.4162978208011863,
          48.87974365006289
        ],
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:21999",
      "to_stop_id": "IDFM:490762",
      "duration": 60,
      "path": [
        [
          2.4271621685895237,
          48.88125303440166
        ],
        [
          2.4162978208011863,
          48.87974365006289
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:22000",
      "to_stop_id": "IDFM:22009",
      "duration": 60,
      "path": [
        [
          2.3606397867113924,
          48.83853898898134
        ],
        [
          2.358468834639247,
          48.835595431233266
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:22000",
      "to_stop_id": "IDFM:463023",
      "duration": 60,
      "path": [
        [
          2.3654431802574227,
          48.842395852858516
        ],
        [
          2.3606397867113924,
          48.83853898898134
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:22000",
      "to_stop_id": "IDFM:463097",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:21952",
      "duration": 60,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ],
        [
          2.371438959985608,
          48.88302391342934
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:22107",
      "duration": 127,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:22368",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:463008",
      "duration": 184,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:463009",
      "duration": 116,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:463032",
      "duration": 0,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22001",
      "to_stop_id": "IDFM:463182",
      "duration": 120,
      "path": [
        [
          2.357342884924845,
          48.87968001223662
        ],
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22002",
      "to_stop_id": "IDFM:21905",
      "duration": 60,
      "path": [
        [
          2.3685262686038397,
          48.864581198067484
        ],
        [
          2.371813630476951,
          48.859876937868705
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:22002",
      "to_stop_id": "IDFM:21910",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:22002",
      "to_stop_id": "IDFM:22008",
      "duration": 60,
      "path": [
        [
          2.371813630476951,
          48.859876937868705
        ],
        [
          2.3701946685897517,
          48.85624405468632
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:22002",
      "to_stop_id": "IDFM:463096",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:22002",
      "to_stop_id": "IDFM:463299",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:22003",
      "to_stop_id": "IDFM:22005",
      "duration": 60,
      "path": [
        [
          2.3920607143419303,
          48.888535337600175
        ],
        [
          2.3866765356019912,
          48.88714990283661
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:22003",
      "to_stop_id": "IDFM:463017",
      "duration": 60,
      "path": [
        [
          2.403114154894396,
          48.891495749437304
        ],
        [
          2.3920607143419303,
          48.888535337600175
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:22003",
      "to_stop_id": "IDFM:463280",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:22004",
      "to_stop_id": "IDFM:21981",
      "duration": 60,
      "path": [
        [
          2.3656078807625422,
          48.84229779662816
        ],
        [
          2.365992890937552,
          48.84649085420337
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:22004",
      "to_stop_id": "IDFM:22010",
      "duration": 60,
      "path": [
        [
          2.365992890937552,
          48.84649085420337
        ],
        [
          2.368922075646989,
          48.85328747830434
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22004",
      "to_stop_id": "IDFM:463289",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:22005",
      "to_stop_id": "IDFM:22003",
      "duration": 60,
      "path": [
        [
          2.3920607143419303,
          48.888535337600175
        ],
        [
          2.3866765356019912,
          48.88714990283661
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:22005",
      "to_stop_id": "IDFM:462999",
      "duration": 60,
      "path": [
        [
          2.3866765356019912,
          48.88714990283661
        ],
        [
          2.3793117790180993,
          48.88520088689033
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:22005",
      "to_stop_id": "IDFM:463027",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:22168",
      "duration": 47,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:22365",
      "duration": 85,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:463003",
      "duration": 42,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:463020",
      "duration": 60,
      "path": [
        [
          2.35559694336975,
          48.83096622708413
        ],
        [
          2.3587419566993457,
          48.83554293374935
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:463025",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22006",
      "to_stop_id": "IDFM:463026",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:22119",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:22149",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:462982",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:463021",
      "duration": 120,
      "path": [
        [
          2.3572164888604563,
          48.87970181695894
        ],
        [
          2.357982972943156,
          48.876081616617256
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:463032",
      "duration": 120,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ],
        [
          2.3572164888604563,
          48.87970181695894
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:463182",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22007",
      "to_stop_id": "IDFM:463183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22008",
      "to_stop_id": "IDFM:22002",
      "duration": 60,
      "path": [
        [
          2.371813630476951,
          48.859876937868705
        ],
        [
          2.3701946685897517,
          48.85624405468632
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:22008",
      "to_stop_id": "IDFM:22202",
      "duration": 220,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:22008",
      "to_stop_id": "IDFM:463018",
      "duration": 60,
      "path": [
        [
          2.3701946685897517,
          48.85624405468632
        ],
        [
          2.3687984466220384,
          48.85330031648217
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22008",
      "to_stop_id": "IDFM:463138",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:22008",
      "to_stop_id": "IDFM:463146",
      "duration": 229,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:22009",
      "to_stop_id": "IDFM:22000",
      "duration": 60,
      "path": [
        [
          2.3606397867113924,
          48.83853898898134
        ],
        [
          2.358468834639247,
          48.835595431233266
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:22009",
      "to_stop_id": "IDFM:463020",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:22009",
      "to_stop_id": "IDFM:463025",
      "duration": 120,
      "path": [
        [
          2.358468834639247,
          48.835595431233266
        ],
        [
          2.355501591481485,
          48.830965713292656
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:22004",
      "duration": 60,
      "path": [
        [
          2.365992890937552,
          48.84649085420337
        ],
        [
          2.368922075646989,
          48.85328747830434
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:22089",
      "duration": 68,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:22097",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:463000",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:463018",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:463019",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22010",
      "to_stop_id": "IDFM:463138",
      "duration": 60,
      "path": [
        [
          2.368922075646989,
          48.85328747830434
        ],
        [
          2.3703346849773035,
          48.8562178110506
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:22011",
      "to_stop_id": "IDFM:22013",
      "duration": 60,
      "path": [
        [
          2.3611315892483544,
          48.870666468034706
        ],
        [
          2.35806459441865,
          48.87616299446643
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22011",
      "to_stop_id": "IDFM:462961",
      "duration": 60,
      "path": [
        [
          2.3641925544688656,
          48.867732931981166
        ],
        [
          2.3611315892483544,
          48.870666468034706
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22011",
      "to_stop_id": "IDFM:463191",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:22012",
      "to_stop_id": "IDFM:21952",
      "duration": 60,
      "path": [
        [
          2.371438959985608,
          48.88302391342934
        ],
        [
          2.3793909893523923,
          48.88513384808583
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22012",
      "to_stop_id": "IDFM:462999",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:22012",
      "to_stop_id": "IDFM:463027",
      "duration": 60,
      "path": [
        [
          2.3793909893523923,
          48.88513384808583
        ],
        [
          2.386652016759884,
          48.88691595319499
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:22011",
      "duration": 60,
      "path": [
        [
          2.3611315892483544,
          48.870666468034706
        ],
        [
          2.35806459441865,
          48.87616299446643
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:22148",
      "duration": 117,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:22387",
      "duration": 55,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:463001",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:463021",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:463022",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22013",
      "to_stop_id": "IDFM:463182",
      "duration": 60,
      "path": [
        [
          2.35806459441865,
          48.87616299446643
        ],
        [
          2.357342884924845,
          48.87968001223662
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22014",
      "to_stop_id": "IDFM:22016",
      "duration": 60,
      "path": [
        [
          2.413119553706935,
          48.893163974098265
        ],
        [
          2.426308679232036,
          48.89560207433252
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:22014",
      "to_stop_id": "IDFM:463002",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:22014",
      "to_stop_id": "IDFM:463131",
      "duration": 240,
      "path": [
        [
          2.426308679232036,
          48.89560207433252
        ],
        [
          2.4493013366465726,
          48.906366795030564
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72486"
    },
    {
      "from_stop_id": "IDFM:22015",
      "to_stop_id": "IDFM:463002",
      "duration": 180,
      "path": [
        [
          2.4490271414582585,
          48.9063655341063
        ],
        [
          2.426277595760804,
          48.89563789793626
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72486",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:22015",
      "to_stop_id": "IDFM:463131",
      "duration": 25,
      "path": null,
      "from_stop_unique_id": "IDFM:72486",
      "to_stop_unique_id": "IDFM:72486"
    },
    {
      "from_stop_id": "IDFM:22016",
      "to_stop_id": "IDFM:22014",
      "duration": 60,
      "path": [
        [
          2.413119553706935,
          48.893163974098265
        ],
        [
          2.426308679232036,
          48.89560207433252
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:22016",
      "to_stop_id": "IDFM:22017",
      "duration": 60,
      "path": [
        [
          2.4032119887626737,
          48.89128939386479
        ],
        [
          2.413119553706935,
          48.893163974098265
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:22016",
      "to_stop_id": "IDFM:463178",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:22017",
      "to_stop_id": "IDFM:22016",
      "duration": 60,
      "path": [
        [
          2.4032119887626737,
          48.89128939386479
        ],
        [
          2.413119553706935,
          48.893163974098265
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:22017",
      "to_stop_id": "IDFM:463017",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:22017",
      "to_stop_id": "IDFM:463280",
      "duration": 60,
      "path": [
        [
          2.3921229723281483,
          48.88845921134684
        ],
        [
          2.4032119887626737,
          48.89128939386479
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:22018",
      "to_stop_id": "IDFM:22029",
      "duration": 60,
      "path": [
        [
          2.3520507871028355,
          48.86638175675157
        ],
        [
          2.3475538159377884,
          48.86734660429376
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:22018",
      "to_stop_id": "IDFM:22142",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22018",
      "to_stop_id": "IDFM:463072",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22018",
      "to_stop_id": "IDFM:463091",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22018",
      "to_stop_id": "IDFM:463122",
      "duration": 60,
      "path": [
        [
          2.3566822734187167,
          48.86561531899988
        ],
        [
          2.3520507871028355,
          48.86638175675157
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:22019",
      "to_stop_id": "IDFM:22025",
      "duration": 60,
      "path": [
        [
          2.380486332251813,
          48.86413108020325
        ],
        [
          2.3747205969196896,
          48.86524326053047
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:22019",
      "to_stop_id": "IDFM:462946",
      "duration": 60,
      "path": [
        [
          2.3747205969196896,
          48.86524326053047
        ],
        [
          2.3643332975454276,
          48.86720306822776
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22019",
      "to_stop_id": "IDFM:463250",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:22020",
      "to_stop_id": "IDFM:21950",
      "duration": 60,
      "path": [
        [
          2.386157046298338,
          48.86296417435419
        ],
        [
          2.397353102563538,
          48.86488271295642
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:22020",
      "to_stop_id": "IDFM:22112",
      "duration": 93,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22020",
      "to_stop_id": "IDFM:463285",
      "duration": 87,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22020",
      "to_stop_id": "IDFM:463286",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22020",
      "to_stop_id": "IDFM:463297",
      "duration": 60,
      "path": [
        [
          2.3804600339610773,
          48.86405000324374
        ],
        [
          2.386157046298338,
          48.86296417435419
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:22021",
      "to_stop_id": "IDFM:22028",
      "duration": 60,
      "path": [
        [
          2.2976831860125837,
          48.88484432273985
        ],
        [
          2.3046251407269573,
          48.88376067556563
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:22021",
      "to_stop_id": "IDFM:463228",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:22021",
      "to_stop_id": "IDFM:463258",
      "duration": 60,
      "path": [
        [
          2.2921124821588448,
          48.885652393133846
        ],
        [
          2.2976831860125837,
          48.88484432273985
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:22022",
      "to_stop_id": "IDFM:21950",
      "duration": 60,
      "path": [
        [
          2.397353102563538,
          48.86488271295642
        ],
        [
          2.4088234090802456,
          48.86432836987192
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:22022",
      "to_stop_id": "IDFM:22037",
      "duration": 60,
      "path": [
        [
          2.4088234090802456,
          48.86432836987192
        ],
        [
          2.4159299613832097,
          48.86512770051251
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71817"
    },
    {
      "from_stop_id": "IDFM:22022",
      "to_stop_id": "IDFM:463276",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:22023",
      "to_stop_id": "IDFM:22034",
      "duration": 60,
      "path": [
        [
          2.291848147336647,
          48.885736266685264
        ],
        [
          2.2882778490679767,
          48.88853900119613
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:22023",
      "to_stop_id": "IDFM:463228",
      "duration": 60,
      "path": [
        [
          2.297701123838181,
          48.884929864637186
        ],
        [
          2.291848147336647,
          48.885736266685264
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:22023",
      "to_stop_id": "IDFM:463258",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:22030",
      "duration": 60,
      "path": [
        [
          2.331047286711241,
          48.87143742899726
        ],
        [
          2.3362786423248583,
          48.86959592111917
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:22196",
      "duration": 363,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:22381",
      "duration": 371,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:462989",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:463037",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:463090",
      "duration": 60,
      "path": [
        [
          2.3362786423248583,
          48.86959592111917
        ],
        [
          2.3406519668083314,
          48.868738736969725
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:22024",
      "to_stop_id": "IDFM:463245",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22025",
      "to_stop_id": "IDFM:22019",
      "duration": 60,
      "path": [
        [
          2.380486332251813,
          48.86413108020325
        ],
        [
          2.3747205969196896,
          48.86524326053047
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:22025",
      "to_stop_id": "IDFM:463286",
      "duration": 60,
      "path": [
        [
          2.3862311113287342,
          48.86304099836643
        ],
        [
          2.380486332251813,
          48.86413108020325
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22025",
      "to_stop_id": "IDFM:463297",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:21902",
      "duration": 258,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:21903",
      "duration": 270,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:21918",
      "duration": 171,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:21937",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:22199",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:462946",
      "duration": 60,
      "path": [
        [
          2.3643332975454276,
          48.86720306822776
        ],
        [
          2.361561234526356,
          48.86675662943444
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:462960",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:462961",
      "duration": 281,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:462962",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:462963",
      "duration": 194,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:463122",
      "duration": 60,
      "path": [
        [
          2.361561234526356,
          48.86675662943444
        ],
        [
          2.3566822734187167,
          48.86561531899988
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:22026",
      "to_stop_id": "IDFM:463316",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:22027",
      "to_stop_id": "IDFM:22031",
      "duration": 60,
      "path": [
        [
          2.3094384103380805,
          48.882763294053696
        ],
        [
          2.315815092646378,
          48.88107318307563
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:22027",
      "to_stop_id": "IDFM:22106",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22027",
      "to_stop_id": "IDFM:462987",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22027",
      "to_stop_id": "IDFM:462988",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22027",
      "to_stop_id": "IDFM:463069",
      "duration": 60,
      "path": [
        [
          2.315815092646378,
          48.88107318307563
        ],
        [
          2.32218591952051,
          48.87875316404477
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:22028",
      "to_stop_id": "IDFM:22021",
      "duration": 60,
      "path": [
        [
          2.2976831860125837,
          48.88484432273985
        ],
        [
          2.3046251407269573,
          48.88376067556563
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:22028",
      "to_stop_id": "IDFM:22031",
      "duration": 60,
      "path": [
        [
          2.3046251407269573,
          48.88376067556563
        ],
        [
          2.3094384103380805,
          48.882763294053696
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:22028",
      "to_stop_id": "IDFM:463070",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:22029",
      "to_stop_id": "IDFM:22018",
      "duration": 60,
      "path": [
        [
          2.3520507871028355,
          48.86638175675157
        ],
        [
          2.3475538159377884,
          48.86734660429376
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22029",
      "to_stop_id": "IDFM:22033",
      "duration": 60,
      "path": [
        [
          2.3475538159377884,
          48.86734660429376
        ],
        [
          2.3406785469758815,
          48.868792843933164
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:22029",
      "to_stop_id": "IDFM:462986",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:21906",
      "duration": 60,
      "path": [
        [
          2.3274627984235092,
          48.87346783937801
        ],
        [
          2.331047286711241,
          48.87143742899726
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:21932",
      "duration": 343,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:22024",
      "duration": 60,
      "path": [
        [
          2.331047286711241,
          48.87143742899726
        ],
        [
          2.3362786423248583,
          48.86959592111917
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:22195",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:22381",
      "duration": 150,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:22388",
      "duration": 347,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:463144",
      "duration": 334,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:463145",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:463244",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:463245",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22030",
      "to_stop_id": "IDFM:463246",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22031",
      "to_stop_id": "IDFM:22027",
      "duration": 60,
      "path": [
        [
          2.3094384103380805,
          48.882763294053696
        ],
        [
          2.315815092646378,
          48.88107318307563
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22031",
      "to_stop_id": "IDFM:22028",
      "duration": 60,
      "path": [
        [
          2.3046251407269573,
          48.88376067556563
        ],
        [
          2.3094384103380805,
          48.882763294053696
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:22031",
      "to_stop_id": "IDFM:22117",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:22031",
      "to_stop_id": "IDFM:463068",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:22031",
      "to_stop_id": "IDFM:463240",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:22032",
      "to_stop_id": "IDFM:21963",
      "duration": 60,
      "path": [
        [
          2.3246067760696794,
          48.875025603040974
        ],
        [
          2.3222946416498362,
          48.878780759649736
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22032",
      "to_stop_id": "IDFM:462987",
      "duration": 60,
      "path": [
        [
          2.3222946416498362,
          48.878780759649736
        ],
        [
          2.3157901685080926,
          48.881153980760416
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22032",
      "to_stop_id": "IDFM:463069",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:22033",
      "to_stop_id": "IDFM:22029",
      "duration": 60,
      "path": [
        [
          2.3475538159377884,
          48.86734660429376
        ],
        [
          2.3406785469758815,
          48.868792843933164
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:22033",
      "to_stop_id": "IDFM:462989",
      "duration": 60,
      "path": [
        [
          2.3406785469758815,
          48.868792843933164
        ],
        [
          2.3363187355954875,
          48.86965909738424
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22033",
      "to_stop_id": "IDFM:463090",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:22034",
      "to_stop_id": "IDFM:22023",
      "duration": 60,
      "path": [
        [
          2.291848147336647,
          48.885736266685264
        ],
        [
          2.2882778490679767,
          48.88853900119613
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:22034",
      "to_stop_id": "IDFM:22036",
      "duration": 60,
      "path": [
        [
          2.2882778490679767,
          48.88853900119613
        ],
        [
          2.2849816963403193,
          48.892206617614
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:22034",
      "to_stop_id": "IDFM:463210",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:22035",
      "to_stop_id": "IDFM:462985",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72057",
      "to_stop_unique_id": "IDFM:72057"
    },
    {
      "from_stop_id": "IDFM:22035",
      "to_stop_id": "IDFM:463119",
      "duration": 60,
      "path": [
        [
          2.2801801985017236,
          48.89718710383118
        ],
        [
          2.2848122193525104,
          48.89223258539651
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:72057",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:22036",
      "to_stop_id": "IDFM:22034",
      "duration": 60,
      "path": [
        [
          2.2882778490679767,
          48.88853900119613
        ],
        [
          2.2849816963403193,
          48.892206617614
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:22036",
      "to_stop_id": "IDFM:462985",
      "duration": 60,
      "path": [
        [
          2.2849816963403193,
          48.892206617614
        ],
        [
          2.28030375318625,
          48.89722831603318
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:72057"
    },
    {
      "from_stop_id": "IDFM:22036",
      "to_stop_id": "IDFM:463119",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:22037",
      "to_stop_id": "IDFM:22022",
      "duration": 60,
      "path": [
        [
          2.4088234090802456,
          48.86432836987192
        ],
        [
          2.4159299613832097,
          48.86512770051251
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71817",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:22037",
      "to_stop_id": "IDFM:463174",
      "duration": 38,
      "path": null,
      "from_stop_unique_id": "IDFM:71817",
      "to_stop_unique_id": "IDFM:71817"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:22051",
      "duration": 60,
      "path": [
        [
          2.328564944227944,
          48.84508150807547
        ],
        [
          2.323989185205003,
          48.84382361125447
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:22143",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:22156",
      "duration": 307,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:22157",
      "duration": 235,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:462954",
      "duration": 264,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:462955",
      "duration": 293,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:462980",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:462996",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22038",
      "to_stop_id": "IDFM:463173",
      "duration": 60,
      "path": [
        [
          2.323989185205003,
          48.84382361125447
        ],
        [
          2.3175087114640447,
          48.84444335195695
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:22039",
      "to_stop_id": "IDFM:22166",
      "duration": 115,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22039",
      "to_stop_id": "IDFM:462947",
      "duration": 21,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22039",
      "to_stop_id": "IDFM:462948",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22039",
      "to_stop_id": "IDFM:462964",
      "duration": 60,
      "path": [
        [
          2.312554745666783,
          48.842958015471986
        ],
        [
          2.3079137063600563,
          48.84152824313428
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:22039",
      "to_stop_id": "IDFM:463173",
      "duration": 60,
      "path": [
        [
          2.3175087114640447,
          48.84444335195695
        ],
        [
          2.312554745666783,
          48.842958015471986
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:22044",
      "duration": 60,
      "path": [
        [
          2.3375706403796697,
          48.87841659490173
        ],
        [
          2.3371840091053384,
          48.882011788411084
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:22059",
      "duration": 60,
      "path": [
        [
          2.3371840091053384,
          48.882011788411084
        ],
        [
          2.338367478180517,
          48.884383574623314
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:22114",
      "duration": 122,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:463088",
      "duration": 116,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:463089",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22040",
      "to_stop_id": "IDFM:463116",
      "duration": 355,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22041",
      "to_stop_id": "IDFM:22053",
      "duration": 60,
      "path": [
        [
          2.359945883178033,
          48.89060729278663
        ],
        [
          2.359248542310361,
          48.89740242267042
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:22041",
      "to_stop_id": "IDFM:463082",
      "duration": 120,
      "path": [
        [
          2.359248542310361,
          48.89740242267042
        ],
        [
          2.3657920239894126,
          48.90666420278779
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:22041",
      "to_stop_id": "IDFM:463281",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:22042",
      "to_stop_id": "IDFM:22058",
      "duration": 60,
      "path": [
        [
          2.2881329450932197,
          48.83230151439446
        ],
        [
          2.2963642614342694,
          48.837125900520995
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:22042",
      "to_stop_id": "IDFM:22061",
      "duration": 60,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ],
        [
          2.2881329450932197,
          48.83230151439446
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:22042",
      "to_stop_id": "IDFM:463261",
      "duration": 47,
      "path": null,
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:22043",
      "to_stop_id": "IDFM:22045",
      "duration": 60,
      "path": [
        [
          2.3256727584985164,
          48.85587553200213
        ],
        [
          2.3231029882985132,
          48.858550089641746
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:22043",
      "to_stop_id": "IDFM:462950",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:22043",
      "to_stop_id": "IDFM:462966",
      "duration": 60,
      "path": [
        [
          2.326886801039095,
          48.851574452640946
        ],
        [
          2.3256727584985164,
          48.85587553200213
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:22044",
      "to_stop_id": "IDFM:22040",
      "duration": 60,
      "path": [
        [
          2.3375706403796697,
          48.87841659490173
        ],
        [
          2.3371840091053384,
          48.882011788411084
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22044",
      "to_stop_id": "IDFM:22050",
      "duration": 338,
      "path": null,
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22044",
      "to_stop_id": "IDFM:463062",
      "duration": 60,
      "path": [
        [
          2.3379007718233322,
          48.87604417483004
        ],
        [
          2.3375706403796697,
          48.87841659490173
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22044",
      "to_stop_id": "IDFM:463304",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:22045",
      "to_stop_id": "IDFM:22043",
      "duration": 60,
      "path": [
        [
          2.3256727584985164,
          48.85587553200213
        ],
        [
          2.3231029882985132,
          48.858550089641746
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:22045",
      "to_stop_id": "IDFM:463123",
      "duration": 60,
      "path": [
        [
          2.3231029882985132,
          48.858550089641746
        ],
        [
          2.3206896796944263,
          48.861122050080816
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:22045",
      "to_stop_id": "IDFM:463311",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:22046",
      "to_stop_id": "IDFM:462969",
      "duration": 60,
      "path": [
        [
          2.327470237303278,
          48.875594817796994
        ],
        [
          2.333030634158166,
          48.87625096700832
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22046",
      "to_stop_id": "IDFM:463062",
      "duration": 60,
      "path": [
        [
          2.333030634158166,
          48.87625096700832
        ],
        [
          2.3379007718233322,
          48.87604417483004
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22046",
      "to_stop_id": "IDFM:463317",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:22047",
      "to_stop_id": "IDFM:22048",
      "duration": 60,
      "path": [
        [
          2.3010745156545456,
          48.839437918076904
        ],
        [
          2.3079833805768515,
          48.84141172841498
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:22047",
      "to_stop_id": "IDFM:22058",
      "duration": 60,
      "path": [
        [
          2.2963642614342694,
          48.837125900520995
        ],
        [
          2.3010745156545456,
          48.839437918076904
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:22047",
      "to_stop_id": "IDFM:463321",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:22048",
      "to_stop_id": "IDFM:22047",
      "duration": 60,
      "path": [
        [
          2.3010745156545456,
          48.839437918076904
        ],
        [
          2.3079833805768515,
          48.84141172841498
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:22048",
      "to_stop_id": "IDFM:462947",
      "duration": 60,
      "path": [
        [
          2.3079833805768515,
          48.84141172841498
        ],
        [
          2.3126518981045816,
          48.84282366881032
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22048",
      "to_stop_id": "IDFM:462964",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:22049",
      "to_stop_id": "IDFM:21980",
      "duration": 60,
      "path": [
        [
          2.326859663121737,
          48.85156530639137
        ],
        [
          2.3277871847873874,
          48.84833282904063
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:22049",
      "to_stop_id": "IDFM:22051",
      "duration": 60,
      "path": [
        [
          2.3277871847873874,
          48.84833282904063
        ],
        [
          2.328564944227944,
          48.84508150807547
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:22049",
      "to_stop_id": "IDFM:22138",
      "duration": 202,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22049",
      "to_stop_id": "IDFM:462968",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:22049",
      "to_stop_id": "IDFM:463308",
      "duration": 199,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:22044",
      "duration": 338,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:22382",
      "duration": 261,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:463062",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:463206",
      "duration": 274,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:463304",
      "duration": 60,
      "path": [
        [
          2.3374546190400913,
          48.87836199275949
        ],
        [
          2.3378736192226386,
          48.8760350312806
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:22050",
      "to_stop_id": "IDFM:463317",
      "duration": 60,
      "path": [
        [
          2.3378736192226386,
          48.8760350312806
        ],
        [
          2.333049172418236,
          48.87633650730735
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:22051",
      "to_stop_id": "IDFM:22038",
      "duration": 60,
      "path": [
        [
          2.328564944227944,
          48.84508150807547
        ],
        [
          2.323989185205003,
          48.84382361125447
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22051",
      "to_stop_id": "IDFM:22049",
      "duration": 60,
      "path": [
        [
          2.3277871847873874,
          48.84833282904063
        ],
        [
          2.328564944227944,
          48.84508150807547
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:22051",
      "to_stop_id": "IDFM:22138",
      "duration": 320,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22051",
      "to_stop_id": "IDFM:462965",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:22051",
      "to_stop_id": "IDFM:463308",
      "duration": 306,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22052",
      "to_stop_id": "IDFM:22053",
      "duration": 60,
      "path": [
        [
          2.3499794329782024,
          48.89145292865467
        ],
        [
          2.359945883178033,
          48.89060729278663
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:22052",
      "to_stop_id": "IDFM:22132",
      "duration": 172,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22052",
      "to_stop_id": "IDFM:462973",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22052",
      "to_stop_id": "IDFM:462974",
      "duration": 181,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22052",
      "to_stop_id": "IDFM:463065",
      "duration": 60,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ],
        [
          2.3499794329782024,
          48.89145292865467
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:22053",
      "to_stop_id": "IDFM:22041",
      "duration": 60,
      "path": [
        [
          2.359945883178033,
          48.89060729278663
        ],
        [
          2.359248542310361,
          48.89740242267042
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:22053",
      "to_stop_id": "IDFM:22052",
      "duration": 60,
      "path": [
        [
          2.3499794329782024,
          48.89145292865467
        ],
        [
          2.359945883178033,
          48.89060729278663
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22053",
      "to_stop_id": "IDFM:463236",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:22054",
      "to_stop_id": "IDFM:22055",
      "duration": 60,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ],
        [
          2.338463589982122,
          48.889699132276796
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:22054",
      "to_stop_id": "IDFM:22133",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:22054",
      "to_stop_id": "IDFM:462973",
      "duration": 60,
      "path": [
        [
          2.3500246218070155,
          48.89154760285429
        ],
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22054",
      "to_stop_id": "IDFM:463065",
      "duration": 0,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:22054",
      "to_stop_id": "IDFM:463310",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:22055",
      "to_stop_id": "IDFM:22054",
      "duration": 60,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ],
        [
          2.338463589982122,
          48.889699132276796
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:22055",
      "to_stop_id": "IDFM:463116",
      "duration": 60,
      "path": [
        [
          2.338463589982122,
          48.889699132276796
        ],
        [
          2.338394635220906,
          48.884392717991425
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22055",
      "to_stop_id": "IDFM:463205",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:22056",
      "to_stop_id": "IDFM:21970",
      "duration": 366,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22056",
      "to_stop_id": "IDFM:462947",
      "duration": 60,
      "path": [
        [
          2.3126518981045816,
          48.84282366881032
        ],
        [
          2.317551235617027,
          48.844317683534335
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22056",
      "to_stop_id": "IDFM:462980",
      "duration": 60,
      "path": [
        [
          2.317551235617027,
          48.844317683534335
        ],
        [
          2.32392211008296,
          48.84374228942691
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22056",
      "to_stop_id": "IDFM:463084",
      "duration": 354,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22056",
      "to_stop_id": "IDFM:463173",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:22085",
      "duration": 109,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:22096",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:463081",
      "duration": 60,
      "path": [
        [
          2.323181449571859,
          48.86615003482594
        ],
        [
          2.325169929034193,
          48.87003294090397
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:463123",
      "duration": 60,
      "path": [
        [
          2.3206896796944263,
          48.861122050080816
        ],
        [
          2.323181449571859,
          48.86615003482594
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:463150",
      "duration": 149,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:463151",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22057",
      "to_stop_id": "IDFM:463164",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22058",
      "to_stop_id": "IDFM:22042",
      "duration": 60,
      "path": [
        [
          2.2881329450932197,
          48.83230151439446
        ],
        [
          2.2963642614342694,
          48.837125900520995
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:22058",
      "to_stop_id": "IDFM:22047",
      "duration": 60,
      "path": [
        [
          2.2963642614342694,
          48.837125900520995
        ],
        [
          2.3010745156545456,
          48.839437918076904
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:22058",
      "to_stop_id": "IDFM:462949",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:22040",
      "duration": 60,
      "path": [
        [
          2.3371840091053384,
          48.882011788411084
        ],
        [
          2.338367478180517,
          48.884383574623314
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:22114",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:463088",
      "duration": 309,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:463089",
      "duration": 351,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:463116",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22059",
      "to_stop_id": "IDFM:463205",
      "duration": 60,
      "path": [
        [
          2.338367478180517,
          48.884383574623314
        ],
        [
          2.3385838243831936,
          48.88968180996681
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:22060",
      "to_stop_id": "IDFM:463123",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:22060",
      "to_stop_id": "IDFM:463164",
      "duration": 60,
      "path": [
        [
          2.323058362902906,
          48.8661808158217
        ],
        [
          2.320575836647556,
          48.861071939526084
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22060",
      "to_stop_id": "IDFM:463311",
      "duration": 60,
      "path": [
        [
          2.320575836647556,
          48.861071939526084
        ],
        [
          2.3230759642515415,
          48.85853194972854
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:22061",
      "to_stop_id": "IDFM:22042",
      "duration": 60,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ],
        [
          2.2881329450932197,
          48.83230151439446
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:22061",
      "to_stop_id": "IDFM:22062",
      "duration": 60,
      "path": [
        [
          2.273560674093182,
          48.82416442588802
        ],
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:70671"
    },
    {
      "from_stop_id": "IDFM:22061",
      "to_stop_id": "IDFM:463153",
      "duration": 0,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:22062",
      "to_stop_id": "IDFM:22061",
      "duration": 60,
      "path": [
        [
          2.273560674093182,
          48.82416442588802
        ],
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70671",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:22062",
      "to_stop_id": "IDFM:463219",
      "duration": 25,
      "path": null,
      "from_stop_unique_id": "IDFM:70671",
      "to_stop_unique_id": "IDFM:70671"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:21919",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:22073",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:22080",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:22124",
      "duration": 60,
      "path": [
        [
          2.3957988351125254,
          48.84845279707999
        ],
        [
          2.3981875566593973,
          48.85164854359716
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:462942",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:462943",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:463028",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22072",
      "to_stop_id": "IDFM:463067",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:21919",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:22072",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:22080",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:22167",
      "duration": 60,
      "path": [
        [
          2.3958598043279085,
          48.84789550545761
        ],
        [
          2.401248269453829,
          48.84501767720269
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:462942",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:462943",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:463028",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22073",
      "to_stop_id": "IDFM:463067",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22074",
      "to_stop_id": "IDFM:463019",
      "duration": 120,
      "path": [
        [
          2.3608852562751967,
          48.85518742160445
        ],
        [
          2.368692796099789,
          48.85196422011205
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22074",
      "to_stop_id": "IDFM:463080",
      "duration": 60,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ],
        [
          2.3608852562751967,
          48.85518742160445
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22074",
      "to_stop_id": "IDFM:463307",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:22075",
      "to_stop_id": "IDFM:463041",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:22075",
      "to_stop_id": "IDFM:463150",
      "duration": 60,
      "path": [
        [
          2.3297453212172794,
          48.864550163577015
        ],
        [
          2.3229614457982586,
          48.866557992949716
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22075",
      "to_stop_id": "IDFM:463227",
      "duration": 60,
      "path": [
        [
          2.336561574182243,
          48.862366755622624
        ],
        [
          2.3297453212172794,
          48.864550163577015
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22076",
      "to_stop_id": "IDFM:22080",
      "duration": 60,
      "path": [
        [
          2.386056169673362,
          48.84723403765801
        ],
        [
          2.3958439887237373,
          48.848084289973976
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22076",
      "to_stop_id": "IDFM:22083",
      "duration": 60,
      "path": [
        [
          2.3731565937892047,
          48.8456832067358
        ],
        [
          2.386056169673362,
          48.84723403765801
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:22076",
      "to_stop_id": "IDFM:22098",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22076",
      "to_stop_id": "IDFM:463293",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22076",
      "to_stop_id": "IDFM:463294",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22077",
      "to_stop_id": "IDFM:22080",
      "duration": 60,
      "path": [
        [
          2.3958439887237373,
          48.848084289973976
        ],
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22077",
      "to_stop_id": "IDFM:22103",
      "duration": 60,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ],
        [
          2.4189623545327077,
          48.846238208060065
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:22077",
      "to_stop_id": "IDFM:463012",
      "duration": 0,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:22078",
      "to_stop_id": "IDFM:22088",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2825838473615496,
          48.87816265364455
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:22078",
      "to_stop_id": "IDFM:463217",
      "duration": 60,
      "path": [
        [
          2.2825838473615496,
          48.87816265364455
        ],
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:22078",
      "to_stop_id": "IDFM:463257",
      "duration": 57,
      "path": null,
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:22079",
      "to_stop_id": "IDFM:22081",
      "duration": 60,
      "path": [
        [
          2.3364543677542517,
          48.86222226557415
        ],
        [
          2.3409026134809903,
          48.86078090878698
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:22079",
      "to_stop_id": "IDFM:22376",
      "duration": 71,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22079",
      "to_stop_id": "IDFM:463041",
      "duration": 60,
      "path": [
        [
          2.3296780947116416,
          48.864477839314645
        ],
        [
          2.3364543677542517,
          48.86222226557415
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:22079",
      "to_stop_id": "IDFM:463227",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22079",
      "to_stop_id": "IDFM:463249",
      "duration": 99,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:21919",
      "duration": 166,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:22072",
      "duration": 102,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:22073",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:22076",
      "duration": 60,
      "path": [
        [
          2.386056169673362,
          48.84723403765801
        ],
        [
          2.3958439887237373,
          48.848084289973976
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:22077",
      "duration": 60,
      "path": [
        [
          2.3958439887237373,
          48.848084289973976
        ],
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:462942",
      "duration": 151,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:462943",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:463028",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22080",
      "to_stop_id": "IDFM:463067",
      "duration": 102,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22081",
      "to_stop_id": "IDFM:22079",
      "duration": 60,
      "path": [
        [
          2.3364543677542517,
          48.86222226557415
        ],
        [
          2.3409026134809903,
          48.86078090878698
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22081",
      "to_stop_id": "IDFM:22087",
      "duration": 60,
      "path": [
        [
          2.3409026134809903,
          48.86078090878698
        ],
        [
          2.3457609541847755,
          48.85922471342816
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22081",
      "to_stop_id": "IDFM:22379",
      "duration": 337,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:22081",
      "to_stop_id": "IDFM:463218",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:22081",
      "to_stop_id": "IDFM:463232",
      "duration": 326,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:22082",
      "to_stop_id": "IDFM:21931",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:22082",
      "to_stop_id": "IDFM:22084",
      "duration": 60,
      "path": [
        [
          2.300759784978918,
          48.87203776458909
        ],
        [
          2.309359666519628,
          48.8691648256349
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:22082",
      "to_stop_id": "IDFM:22090",
      "duration": 60,
      "path": [
        [
          2.309359666519628,
          48.8691648256349
        ],
        [
          2.314397292025236,
          48.867583959552555
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22082",
      "to_stop_id": "IDFM:463180",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:22082",
      "to_stop_id": "IDFM:463181",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:22083",
      "to_stop_id": "IDFM:21958",
      "duration": 240,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:22083",
      "to_stop_id": "IDFM:22076",
      "duration": 60,
      "path": [
        [
          2.3731565937892047,
          48.8456832067358
        ],
        [
          2.386056169673362,
          48.84723403765801
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22083",
      "to_stop_id": "IDFM:463019",
      "duration": 120,
      "path": [
        [
          2.368692796099789,
          48.85196422011205
        ],
        [
          2.3731565937892047,
          48.8456832067358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22083",
      "to_stop_id": "IDFM:463046",
      "duration": 240,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:22083",
      "to_stop_id": "IDFM:463197",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:22084",
      "to_stop_id": "IDFM:22082",
      "duration": 60,
      "path": [
        [
          2.300759784978918,
          48.87203776458909
        ],
        [
          2.309359666519628,
          48.8691648256349
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:22084",
      "to_stop_id": "IDFM:22086",
      "duration": 60,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22084",
      "to_stop_id": "IDFM:463185",
      "duration": 0,
      "path": [
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:22057",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:22090",
      "duration": 60,
      "path": [
        [
          2.314397292025236,
          48.867583959552555
        ],
        [
          2.3228909937191604,
          48.866472156532026
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:22096",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:463041",
      "duration": 60,
      "path": [
        [
          2.3228909937191604,
          48.866472156532026
        ],
        [
          2.3296780947116416,
          48.864477839314645
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:463150",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:463151",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22085",
      "to_stop_id": "IDFM:463164",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:22084",
      "duration": 60,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:22094",
      "duration": 64,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:22095",
      "duration": 20,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:463013",
      "duration": 0,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:463042",
      "duration": 45,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:463043",
      "duration": 52,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22086",
      "to_stop_id": "IDFM:463121",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:21959",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:21966",
      "duration": 250,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:22081",
      "duration": 60,
      "path": [
        [
          2.3409026134809903,
          48.86078090878698
        ],
        [
          2.3457609541847755,
          48.85922471342816
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:22092",
      "duration": 113,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:22364",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463060",
      "duration": 298,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463079",
      "duration": 210,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463080",
      "duration": 60,
      "path": [
        [
          2.3457609541847755,
          48.85922471342816
        ],
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463158",
      "duration": 130,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463159",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22087",
      "to_stop_id": "IDFM:463160",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22088",
      "to_stop_id": "IDFM:22078",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2825838473615496,
          48.87816265364455
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:22088",
      "to_stop_id": "IDFM:463013",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22088",
      "to_stop_id": "IDFM:463121",
      "duration": 0,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:22010",
      "duration": 73,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:22097",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:463000",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:463018",
      "duration": 75,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:463019",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:463197",
      "duration": 120,
      "path": [
        [
          2.373125016776689,
          48.84575948671397
        ],
        [
          2.368718961034089,
          48.85205429349772
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:22089",
      "to_stop_id": "IDFM:463307",
      "duration": 60,
      "path": [
        [
          2.368718961034089,
          48.85205429349772
        ],
        [
          2.3609713728017505,
          48.85527781668134
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:22090",
      "to_stop_id": "IDFM:22082",
      "duration": 60,
      "path": [
        [
          2.309359666519628,
          48.8691648256349
        ],
        [
          2.314397292025236,
          48.867583959552555
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:22090",
      "to_stop_id": "IDFM:22085",
      "duration": 60,
      "path": [
        [
          2.314397292025236,
          48.867583959552555
        ],
        [
          2.3228909937191604,
          48.866472156532026
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22090",
      "to_stop_id": "IDFM:22093",
      "duration": 59,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22090",
      "to_stop_id": "IDFM:463010",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22090",
      "to_stop_id": "IDFM:463011",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:21990",
      "duration": 144,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:22364",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:463060",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:463061",
      "duration": 194,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:463080",
      "duration": 0,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:463160",
      "duration": 60,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ],
        [
          2.3458090025909044,
          48.85930591756934
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22091",
      "to_stop_id": "IDFM:463307",
      "duration": 60,
      "path": [
        [
          2.3609713728017505,
          48.85527781668134
        ],
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:21959",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:21966",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:22087",
      "duration": 109,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:22146",
      "duration": 60,
      "path": [
        [
          2.34600573656331,
          48.86252665962869
        ],
        [
          2.3466414308440138,
          48.859661211559086
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:22150",
      "duration": 60,
      "path": [
        [
          2.3466414308440138,
          48.859661211559086
        ],
        [
          2.3472322349319112,
          48.85493384910671
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:22364",
      "duration": 255,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:463060",
      "duration": 264,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:463079",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:463158",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:463159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22092",
      "to_stop_id": "IDFM:463160",
      "duration": 93,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22093",
      "to_stop_id": "IDFM:22090",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22093",
      "to_stop_id": "IDFM:22224",
      "duration": 60,
      "path": [
        [
          2.31401668387612,
          48.86750083759083
        ],
        [
          2.3160106931210245,
          48.87344791579522
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:22093",
      "to_stop_id": "IDFM:463010",
      "duration": 104,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22093",
      "to_stop_id": "IDFM:463011",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22093",
      "to_stop_id": "IDFM:463198",
      "duration": 60,
      "path": [
        [
          2.3145404484362686,
          48.86084865840772
        ],
        [
          2.31401668387612,
          48.86750083759083
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:22086",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:22095",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:463013",
      "duration": 62,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:463042",
      "duration": 206,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:463043",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:463266",
      "duration": 60,
      "path": [
        [
          2.2981132886172433,
          48.878227730862385
        ],
        [
          2.2958117752357627,
          48.87499457617108
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:22094",
      "to_stop_id": "IDFM:463322",
      "duration": 60,
      "path": [
        [
          2.2958117752357627,
          48.87499457617108
        ],
        [
          2.2858287659129797,
          48.869926082410636
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:22086",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:22094",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:22170",
      "duration": 120,
      "path": [
        [
          2.294995735441831,
          48.87383861478572
        ],
        [
          2.293146137248626,
          48.87148941293503
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:463013",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:463042",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22095",
      "to_stop_id": "IDFM:463043",
      "duration": 179,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:22057",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:22085",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:22194",
      "duration": 60,
      "path": [
        [
          2.3220315426557194,
          48.86652125193945
        ],
        [
          2.3260272117729994,
          48.86956561611772
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:463150",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:463151",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:463164",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22096",
      "to_stop_id": "IDFM:463199",
      "duration": 60,
      "path": [
        [
          2.314996938416133,
          48.86059945384315
        ],
        [
          2.3220315426557194,
          48.86652125193945
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:22010",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:22089",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:463000",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:463018",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:463019",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:463146",
      "duration": 60,
      "path": [
        [
          2.368095085385991,
          48.85708736782028
        ],
        [
          2.3695064282002,
          48.853425461693256
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:22097",
      "to_stop_id": "IDFM:463195",
      "duration": 60,
      "path": [
        [
          2.3695064282002,
          48.853425461693256
        ],
        [
          2.375924913735691,
          48.85119274222268
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:22098",
      "to_stop_id": "IDFM:22076",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22098",
      "to_stop_id": "IDFM:22204",
      "duration": 60,
      "path": [
        [
          2.3872010704093922,
          48.84721292669797
        ],
        [
          2.384072471688875,
          48.850200705509266
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:22098",
      "to_stop_id": "IDFM:22205",
      "duration": 60,
      "path": [
        [
          2.39052195642492,
          48.8440641552825
        ],
        [
          2.3872010704093922,
          48.84721292669797
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:22098",
      "to_stop_id": "IDFM:463293",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22098",
      "to_stop_id": "IDFM:463294",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22099",
      "to_stop_id": "IDFM:22102",
      "duration": 60,
      "path": [
        [
          2.2584954293691255,
          48.885489388974705
        ],
        [
          2.2499040727323307,
          48.88833173800946
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:22099",
      "to_stop_id": "IDFM:463044",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:22099",
      "to_stop_id": "IDFM:463217",
      "duration": 60,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ],
        [
          2.2584954293691255,
          48.885489388974705
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:22100",
      "to_stop_id": "IDFM:463044",
      "duration": 60,
      "path": [
        [
          2.2585225729481575,
          48.8854985504452
        ],
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:22100",
      "to_stop_id": "IDFM:463217",
      "duration": 0,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:22100",
      "to_stop_id": "IDFM:463257",
      "duration": 60,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ],
        [
          2.2822896139411903,
          48.877805650437985
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:22101",
      "to_stop_id": "IDFM:463170",
      "duration": 60,
      "path": [
        [
          2.2379881830988277,
          48.89181786646099
        ],
        [
          2.249931086743073,
          48.88834989394862
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71517",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:22101",
      "to_stop_id": "IDFM:463193",
      "duration": 42,
      "path": null,
      "from_stop_unique_id": "IDFM:71517",
      "to_stop_unique_id": "IDFM:71517"
    },
    {
      "from_stop_id": "IDFM:22102",
      "to_stop_id": "IDFM:22099",
      "duration": 60,
      "path": [
        [
          2.2584954293691255,
          48.885489388974705
        ],
        [
          2.2499040727323307,
          48.88833173800946
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:22102",
      "to_stop_id": "IDFM:463170",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:22102",
      "to_stop_id": "IDFM:463193",
      "duration": 120,
      "path": [
        [
          2.2499040727323307,
          48.88833173800946
        ],
        [
          2.2383579200010373,
          48.89199558886802
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71517"
    },
    {
      "from_stop_id": "IDFM:22103",
      "to_stop_id": "IDFM:22077",
      "duration": 60,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ],
        [
          2.4189623545327077,
          48.846238208060065
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:22103",
      "to_stop_id": "IDFM:22105",
      "duration": 60,
      "path": [
        [
          2.4189623545327077,
          48.846238208060065
        ],
        [
          2.4292049740990573,
          48.84540614839331
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:22103",
      "to_stop_id": "IDFM:463040",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:22104",
      "to_stop_id": "IDFM:22105",
      "duration": 120,
      "path": [
        [
          2.4292049740990573,
          48.84540614839331
        ],
        [
          2.440540095406114,
          48.84431751431355
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71634",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:22104",
      "to_stop_id": "IDFM:463149",
      "duration": 0,
      "path": [
        [
          2.440540095406114,
          48.84431751431355
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71634",
      "to_stop_unique_id": "IDFM:71634"
    },
    {
      "from_stop_id": "IDFM:22105",
      "to_stop_id": "IDFM:22103",
      "duration": 60,
      "path": [
        [
          2.4189623545327077,
          48.846238208060065
        ],
        [
          2.4292049740990573,
          48.84540614839331
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:22105",
      "to_stop_id": "IDFM:22104",
      "duration": 120,
      "path": [
        [
          2.4292049740990573,
          48.84540614839331
        ],
        [
          2.440540095406114,
          48.84431751431355
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71634"
    },
    {
      "from_stop_id": "IDFM:22105",
      "to_stop_id": "IDFM:463130",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:22106",
      "to_stop_id": "IDFM:22027",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22106",
      "to_stop_id": "IDFM:22117",
      "duration": 60,
      "path": [
        [
          2.3157165772440385,
          48.88130644704797
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:22106",
      "to_stop_id": "IDFM:462987",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22106",
      "to_stop_id": "IDFM:462988",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22106",
      "to_stop_id": "IDFM:463296",
      "duration": 60,
      "path": [
        [
          2.3213591738229726,
          48.88234581879614
        ],
        [
          2.3157165772440385,
          48.88130644704797
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:22001",
      "duration": 127,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:22119",
      "duration": 60,
      "path": [
        [
          2.3604041692740987,
          48.884386410852784
        ],
        [
          2.365751439124989,
          48.884243984567746
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:22368",
      "duration": 249,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:462981",
      "duration": 60,
      "path": [
        [
          2.365751439124989,
          48.884243984567746
        ],
        [
          2.3701539703369967,
          48.881596221131936
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:463008",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:463009",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22107",
      "to_stop_id": "IDFM:463032",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22108",
      "to_stop_id": "IDFM:22121",
      "duration": 60,
      "path": [
        [
          2.2981136521983068,
          48.8782007528283
        ],
        [
          2.3032943624252122,
          48.87926525411311
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:22108",
      "to_stop_id": "IDFM:463043",
      "duration": 60,
      "path": [
        [
          2.295927507409278,
          48.87494579313413
        ],
        [
          2.2981136521983068,
          48.8782007528283
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22108",
      "to_stop_id": "IDFM:463266",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:22109",
      "to_stop_id": "IDFM:22111",
      "duration": 60,
      "path": [
        [
          2.2776327175452717,
          48.871396795248316
        ],
        [
          2.2859020006220643,
          48.8698545716049
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:474152"
    },
    {
      "from_stop_id": "IDFM:22109",
      "to_stop_id": "IDFM:463043",
      "duration": 60,
      "path": [
        [
          2.2859020006220643,
          48.8698545716049
        ],
        [
          2.295927507409278,
          48.87494579313413
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22109",
      "to_stop_id": "IDFM:463322",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:22110",
      "to_stop_id": "IDFM:22115",
      "duration": 60,
      "path": [
        [
          2.3214039779193896,
          48.882256139435015
        ],
        [
          2.3279583280102196,
          48.88366908827266
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22110",
      "to_stop_id": "IDFM:462988",
      "duration": 60,
      "path": [
        [
          2.3157614492393397,
          48.88121227337839
        ],
        [
          2.3214039779193896,
          48.882256139435015
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22110",
      "to_stop_id": "IDFM:463296",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:22111",
      "to_stop_id": "IDFM:22109",
      "duration": 60,
      "path": [
        [
          2.2776327175452717,
          48.871396795248316
        ],
        [
          2.2859020006220643,
          48.8698545716049
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:474152",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:22111",
      "to_stop_id": "IDFM:463256",
      "duration": 50,
      "path": null,
      "from_stop_unique_id": "IDFM:474152",
      "to_stop_unique_id": "IDFM:474152"
    },
    {
      "from_stop_id": "IDFM:22112",
      "to_stop_id": "IDFM:22020",
      "duration": 97,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22112",
      "to_stop_id": "IDFM:22113",
      "duration": 60,
      "path": [
        [
          2.3905477055525735,
          48.858175121260864
        ],
        [
          2.3876893260656895,
          48.86248637862667
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:22112",
      "to_stop_id": "IDFM:22116",
      "duration": 60,
      "path": [
        [
          2.3876893260656895,
          48.86248637862667
        ],
        [
          2.38353966877949,
          48.866440166781686
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:22112",
      "to_stop_id": "IDFM:463285",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22112",
      "to_stop_id": "IDFM:463286",
      "duration": 103,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22113",
      "to_stop_id": "IDFM:22112",
      "duration": 60,
      "path": [
        [
          2.3905477055525735,
          48.858175121260864
        ],
        [
          2.3876893260656895,
          48.86248637862667
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22113",
      "to_stop_id": "IDFM:463117",
      "duration": 60,
      "path": [
        [
          2.3947127337554788,
          48.85624467843737
        ],
        [
          2.3905477055525735,
          48.858175121260864
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:22113",
      "to_stop_id": "IDFM:463230",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:22040",
      "duration": 114,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:22059",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:22123",
      "duration": 60,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ],
        [
          2.338257976509587,
          48.882269542226226
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:463088",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:463089",
      "duration": 131,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:463113",
      "duration": 60,
      "path": [
        [
          2.338257976509587,
          48.882269542226226
        ],
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:22114",
      "to_stop_id": "IDFM:463116",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:22115",
      "to_stop_id": "IDFM:22110",
      "duration": 60,
      "path": [
        [
          2.3214039779193896,
          48.882256139435015
        ],
        [
          2.3279583280102196,
          48.88366908827266
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:22115",
      "to_stop_id": "IDFM:22125",
      "duration": 60,
      "path": [
        [
          2.3279583280102196,
          48.88366908827266
        ],
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:22115",
      "to_stop_id": "IDFM:22129",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22115",
      "to_stop_id": "IDFM:463252",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22115",
      "to_stop_id": "IDFM:463253",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22116",
      "to_stop_id": "IDFM:22112",
      "duration": 60,
      "path": [
        [
          2.3876893260656895,
          48.86248637862667
        ],
        [
          2.38353966877949,
          48.866440166781686
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:22116",
      "to_stop_id": "IDFM:22126",
      "duration": 60,
      "path": [
        [
          2.38353966877949,
          48.866440166781686
        ],
        [
          2.3805336747351005,
          48.86932051914457
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:22116",
      "to_stop_id": "IDFM:463243",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:22117",
      "to_stop_id": "IDFM:22031",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:22117",
      "to_stop_id": "IDFM:22106",
      "duration": 60,
      "path": [
        [
          2.3157165772440385,
          48.88130644704797
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:22117",
      "to_stop_id": "IDFM:463068",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:22117",
      "to_stop_id": "IDFM:463154",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:22117",
      "to_stop_id": "IDFM:463240",
      "duration": 0,
      "path": [
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:21952",
      "duration": 229,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:22120",
      "duration": 60,
      "path": [
        [
          2.3705761028196575,
          48.877695345805535
        ],
        [
          2.370269867509251,
          48.88159683079925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:24680",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:24685",
      "duration": 376,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:462981",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:463009",
      "duration": 60,
      "path": [
        [
          2.370269867509251,
          48.88159683079925
        ],
        [
          2.3657743705581695,
          48.88432054886986
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:463074",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:463095",
      "duration": 235,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22118",
      "to_stop_id": "IDFM:463132",
      "duration": 379,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:22119",
      "to_stop_id": "IDFM:22007",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22119",
      "to_stop_id": "IDFM:22107",
      "duration": 60,
      "path": [
        [
          2.3604041692740987,
          48.884386410852784
        ],
        [
          2.365751439124989,
          48.884243984567746
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22119",
      "to_stop_id": "IDFM:22127",
      "duration": 60,
      "path": [
        [
          2.3506070793550977,
          48.88377608955688
        ],
        [
          2.3604041692740987,
          48.884386410852784
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22119",
      "to_stop_id": "IDFM:462982",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22119",
      "to_stop_id": "IDFM:463183",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22120",
      "to_stop_id": "IDFM:22118",
      "duration": 60,
      "path": [
        [
          2.3705761028196575,
          48.877695345805535
        ],
        [
          2.370269867509251,
          48.88159683079925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22120",
      "to_stop_id": "IDFM:22128",
      "duration": 60,
      "path": [
        [
          2.3768306915222848,
          48.872314077361665
        ],
        [
          2.3705761028196575,
          48.877695345805535
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:22120",
      "to_stop_id": "IDFM:462984",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:22121",
      "to_stop_id": "IDFM:22108",
      "duration": 60,
      "path": [
        [
          2.2981136521983068,
          48.8782007528283
        ],
        [
          2.3032943624252122,
          48.87926525411311
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:22121",
      "to_stop_id": "IDFM:463154",
      "duration": 0,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:22121",
      "to_stop_id": "IDFM:463240",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:22122",
      "to_stop_id": "IDFM:463117",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:22122",
      "to_stop_id": "IDFM:463125",
      "duration": 60,
      "path": [
        [
          2.3945914186721278,
          48.856199097341126
        ],
        [
          2.3980666384995177,
          48.85161645805963
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:22122",
      "to_stop_id": "IDFM:463230",
      "duration": 60,
      "path": [
        [
          2.390497596745289,
          48.858089428135756
        ],
        [
          2.3945914186721278,
          48.856199097341126
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:22123",
      "to_stop_id": "IDFM:22114",
      "duration": 60,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ],
        [
          2.338257976509587,
          48.882269542226226
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22123",
      "to_stop_id": "IDFM:463094",
      "duration": 60,
      "path": [
        [
          2.350573400888514,
          48.883852349861876
        ],
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22123",
      "to_stop_id": "IDFM:463120",
      "duration": 0,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:22124",
      "to_stop_id": "IDFM:22072",
      "duration": 60,
      "path": [
        [
          2.3957988351125254,
          48.84845279707999
        ],
        [
          2.3981875566593973,
          48.85164854359716
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22124",
      "to_stop_id": "IDFM:25084",
      "duration": 278,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:22124",
      "to_stop_id": "IDFM:463039",
      "duration": 278,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:22124",
      "to_stop_id": "IDFM:463117",
      "duration": 60,
      "path": [
        [
          2.3981875566593973,
          48.85164854359716
        ],
        [
          2.3947127337554788,
          48.85624467843737
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:22124",
      "to_stop_id": "IDFM:463125",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:22125",
      "to_stop_id": "IDFM:22115",
      "duration": 60,
      "path": [
        [
          2.3279583280102196,
          48.88366908827266
        ],
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22125",
      "to_stop_id": "IDFM:463088",
      "duration": 60,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ],
        [
          2.3382179854559224,
          48.88219737464818
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:22125",
      "to_stop_id": "IDFM:463113",
      "duration": 0,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:22126",
      "to_stop_id": "IDFM:22116",
      "duration": 60,
      "path": [
        [
          2.38353966877949,
          48.866440166781686
        ],
        [
          2.3805336747351005,
          48.86932051914457
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:22126",
      "to_stop_id": "IDFM:22128",
      "duration": 60,
      "path": [
        [
          2.3805336747351005,
          48.86932051914457
        ],
        [
          2.3768306915222848,
          48.872314077361665
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:22126",
      "to_stop_id": "IDFM:462983",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:22127",
      "to_stop_id": "IDFM:22119",
      "duration": 60,
      "path": [
        [
          2.3506070793550977,
          48.88377608955688
        ],
        [
          2.3604041692740987,
          48.884386410852784
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22127",
      "to_stop_id": "IDFM:22130",
      "duration": 104,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22127",
      "to_stop_id": "IDFM:463093",
      "duration": 91,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22127",
      "to_stop_id": "IDFM:463094",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22127",
      "to_stop_id": "IDFM:463120",
      "duration": 60,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ],
        [
          2.3506070793550977,
          48.88377608955688
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:22128",
      "to_stop_id": "IDFM:21998",
      "duration": 139,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:22128",
      "to_stop_id": "IDFM:22120",
      "duration": 60,
      "path": [
        [
          2.3768306915222848,
          48.872314077361665
        ],
        [
          2.3705761028196575,
          48.877695345805535
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:22128",
      "to_stop_id": "IDFM:22126",
      "duration": 60,
      "path": [
        [
          2.3805336747351005,
          48.86932051914457
        ],
        [
          2.3768306915222848,
          48.872314077361665
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:22128",
      "to_stop_id": "IDFM:463110",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:22128",
      "to_stop_id": "IDFM:463111",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:22129",
      "to_stop_id": "IDFM:22115",
      "duration": 152,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22129",
      "to_stop_id": "IDFM:22225",
      "duration": 60,
      "path": [
        [
          2.3268526712397555,
          48.87953493737576
        ],
        [
          2.327398345647013,
          48.883737890227636
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:22129",
      "to_stop_id": "IDFM:22227",
      "duration": 60,
      "path": [
        [
          2.327398345647013,
          48.883737890227636
        ],
        [
          2.3256869607667356,
          48.887424510518066
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:22129",
      "to_stop_id": "IDFM:463252",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22129",
      "to_stop_id": "IDFM:463253",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22130",
      "to_stop_id": "IDFM:22127",
      "duration": 128,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22130",
      "to_stop_id": "IDFM:22149",
      "duration": 60,
      "path": [
        [
          2.349629927853002,
          48.883402060486226
        ],
        [
          2.356768884572096,
          48.879510553130615
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22130",
      "to_stop_id": "IDFM:463093",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22130",
      "to_stop_id": "IDFM:463094",
      "duration": 130,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22130",
      "to_stop_id": "IDFM:463148",
      "duration": 60,
      "path": [
        [
          2.3493658080048405,
          48.88707887746752
        ],
        [
          2.349629927853002,
          48.883402060486226
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:22131",
      "to_stop_id": "IDFM:22155",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22131",
      "to_stop_id": "IDFM:463016",
      "duration": 60,
      "path": [
        [
          2.3288628540691043,
          48.84205232329236
        ],
        [
          2.330466993719873,
          48.83915635952557
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:22131",
      "to_stop_id": "IDFM:463104",
      "duration": 60,
      "path": [
        [
          2.330466993719873,
          48.83915635952557
        ],
        [
          2.332018835216387,
          48.83394869369227
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22131",
      "to_stop_id": "IDFM:463291",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22131",
      "to_stop_id": "IDFM:463292",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22132",
      "to_stop_id": "IDFM:22052",
      "duration": 177,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22132",
      "to_stop_id": "IDFM:22152",
      "duration": 0,
      "path": [
        [
          2.3495819680256593,
          48.887241930648955
        ],
        [
          2.3496815417224775,
          48.891280439844486
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:22132",
      "to_stop_id": "IDFM:462973",
      "duration": 170,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22132",
      "to_stop_id": "IDFM:462974",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22132",
      "to_stop_id": "IDFM:463310",
      "duration": 0,
      "path": [
        [
          2.3496815417224775,
          48.891280439844486
        ],
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:22133",
      "to_stop_id": "IDFM:22054",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:22133",
      "to_stop_id": "IDFM:462974",
      "duration": 60,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ],
        [
          2.3495451670385634,
          48.89127969913649
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22133",
      "to_stop_id": "IDFM:463065",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:22133",
      "to_stop_id": "IDFM:463073",
      "duration": 0,
      "path": [
        [
          2.344624099102791,
          48.897525631278015
        ],
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:478883"
    },
    {
      "from_stop_id": "IDFM:22133",
      "to_stop_id": "IDFM:463310",
      "duration": 0,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:21920",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:22142",
      "duration": 60,
      "path": [
        [
          2.352613903045621,
          48.86604305354321
        ],
        [
          2.354491616563834,
          48.86962358263758
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:22153",
      "duration": 0,
      "path": [
        [
          2.354491616563834,
          48.86962358263758
        ],
        [
          2.3560517414015862,
          48.87244691759551
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:22159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:463264",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:463312",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22134",
      "to_stop_id": "IDFM:463313",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22135",
      "to_stop_id": "IDFM:462996",
      "duration": 60,
      "path": [
        [
          2.32902391746117,
          48.842242093452064
        ],
        [
          2.3243858698057784,
          48.84391578859517
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22135",
      "to_stop_id": "IDFM:463016",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:22135",
      "to_stop_id": "IDFM:463291",
      "duration": 60,
      "path": [
        [
          2.3307802165662403,
          48.83910415024143
        ],
        [
          2.32902391746117,
          48.842242093452064
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22136",
      "to_stop_id": "IDFM:21973",
      "duration": 152,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:22136",
      "to_stop_id": "IDFM:22145",
      "duration": 60,
      "path": [
        [
          2.333947810798194,
          48.85357456382241
        ],
        [
          2.339486020317896,
          48.85210334038093
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22136",
      "to_stop_id": "IDFM:462992",
      "duration": 60,
      "path": [
        [
          2.330953550650454,
          48.85113858957915
        ],
        [
          2.333947810798194,
          48.85357456382241
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:22136",
      "to_stop_id": "IDFM:462993",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:22136",
      "to_stop_id": "IDFM:462994",
      "duration": 137,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:21983",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:22150",
      "duration": 60,
      "path": [
        [
          2.3472322349319112,
          48.85493384910671
        ],
        [
          2.343964657062377,
          48.85358493521485
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:462995",
      "duration": 60,
      "path": [
        [
          2.343964657062377,
          48.85358493521485
        ],
        [
          2.3395157696170745,
          48.852175453081486
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:462998",
      "duration": 376,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:463161",
      "duration": 448,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22137",
      "to_stop_id": "IDFM:463306",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:22049",
      "duration": 202,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:22051",
      "duration": 320,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:22139",
      "duration": 60,
      "path": [
        [
          2.330611953081543,
          48.85120862756671
        ],
        [
          2.3268499626718526,
          48.847041473609366
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:22143",
      "duration": 60,
      "path": [
        [
          2.3268499626718526,
          48.847041473609366
        ],
        [
          2.3243294607791096,
          48.84385251452581
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:462965",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:462968",
      "duration": 195,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:22138",
      "to_stop_id": "IDFM:463308",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22139",
      "to_stop_id": "IDFM:21980",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:22139",
      "to_stop_id": "IDFM:22138",
      "duration": 60,
      "path": [
        [
          2.330611953081543,
          48.85120862756671
        ],
        [
          2.3268499626718526,
          48.847041473609366
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22139",
      "to_stop_id": "IDFM:462966",
      "duration": 351,
      "path": null,
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:22139",
      "to_stop_id": "IDFM:462992",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:22139",
      "to_stop_id": "IDFM:462993",
      "duration": 60,
      "path": [
        [
          2.3339874291342255,
          48.85367371343323
        ],
        [
          2.330611953081543,
          48.85120862756671
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:22140",
      "to_stop_id": "IDFM:22154",
      "duration": 60,
      "path": [
        [
          2.3270932349484528,
          48.82820106421924
        ],
        [
          2.3254932653821045,
          48.823416351140224
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:22140",
      "to_stop_id": "IDFM:26602",
      "duration": 60,
      "path": [
        [
          2.3254932653821045,
          48.823416351140224
        ],
        [
          2.319523835754741,
          48.818471900073675
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:22140",
      "to_stop_id": "IDFM:463092",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:22141",
      "to_stop_id": "IDFM:463073",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:478883",
      "to_stop_unique_id": "IDFM:478883"
    },
    {
      "from_stop_id": "IDFM:22141",
      "to_stop_id": "IDFM:463310",
      "duration": 60,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ],
        [
          2.3447472731828847,
          48.897557781418705
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:478883",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:21997",
      "duration": 377,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:22018",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:22134",
      "duration": 60,
      "path": [
        [
          2.352613903045621,
          48.86604305354321
        ],
        [
          2.354491616563834,
          48.86962358263758
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:22147",
      "duration": 60,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.352613903045621,
          48.86604305354321
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:463072",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:463091",
      "duration": 158,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22142",
      "to_stop_id": "IDFM:463108",
      "duration": 377,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:22038",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:22138",
      "duration": 60,
      "path": [
        [
          2.3268499626718526,
          48.847041473609366
        ],
        [
          2.3243294607791096,
          48.84385251452581
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:22156",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:22157",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:462954",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:462955",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:462980",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:462996",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22143",
      "to_stop_id": "IDFM:463016",
      "duration": 0,
      "path": [
        [
          2.3243294607791096,
          48.84385251452581
        ],
        [
          2.3288628540691043,
          48.84205232329236
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:22144",
      "to_stop_id": "IDFM:22154",
      "duration": 60,
      "path": [
        [
          2.32979034782274,
          48.831382009093616
        ],
        [
          2.3270932349484528,
          48.82820106421924
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:22144",
      "to_stop_id": "IDFM:463104",
      "duration": 0,
      "path": [
        [
          2.332018835216387,
          48.83394869369227
        ],
        [
          2.32979034782274,
          48.831382009093616
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22144",
      "to_stop_id": "IDFM:463242",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:22145",
      "to_stop_id": "IDFM:21978",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22145",
      "to_stop_id": "IDFM:22136",
      "duration": 60,
      "path": [
        [
          2.333947810798194,
          48.85357456382241
        ],
        [
          2.339486020317896,
          48.85210334038093
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:22145",
      "to_stop_id": "IDFM:462995",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22145",
      "to_stop_id": "IDFM:462998",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:22145",
      "to_stop_id": "IDFM:463306",
      "duration": 60,
      "path": [
        [
          2.339486020317896,
          48.85210334038093
        ],
        [
          2.343991799033288,
          48.85359407756809
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:22146",
      "to_stop_id": "IDFM:22092",
      "duration": 60,
      "path": [
        [
          2.34600573656331,
          48.86252665962869
        ],
        [
          2.3466414308440138,
          48.859661211559086
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22146",
      "to_stop_id": "IDFM:22147",
      "duration": 323,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.34600573656331,
          48.86252665962869
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:22146",
      "to_stop_id": "IDFM:463171",
      "duration": 60,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.34600573656331,
          48.86252665962869
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:22146",
      "to_stop_id": "IDFM:463208",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:22147",
      "to_stop_id": "IDFM:22142",
      "duration": 60,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.352613903045621,
          48.86604305354321
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:22147",
      "to_stop_id": "IDFM:22146",
      "duration": 323,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.34600573656331,
          48.86252665962869
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:22147",
      "to_stop_id": "IDFM:463171",
      "duration": 0,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:22147",
      "to_stop_id": "IDFM:463208",
      "duration": 0,
      "path": [
        [
          2.3461273251736805,
          48.8625048403394
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:22013",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:22149",
      "duration": 60,
      "path": [
        [
          2.356768884572096,
          48.879510553130615
        ],
        [
          2.3577495818204395,
          48.87627821831841
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:22387",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:462979",
      "duration": 0,
      "path": [
        [
          2.3577495818204395,
          48.87627821831841
        ],
        [
          2.3559014497805357,
          48.87247308911766
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:463001",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:463021",
      "duration": 92,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22148",
      "to_stop_id": "IDFM:463022",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:22007",
      "duration": 161,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:22119",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:22130",
      "duration": 60,
      "path": [
        [
          2.349629927853002,
          48.883402060486226
        ],
        [
          2.356768884572096,
          48.879510553130615
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:22148",
      "duration": 60,
      "path": [
        [
          2.356768884572096,
          48.879510553130615
        ],
        [
          2.3577495818204395,
          48.87627821831841
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:462982",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:463182",
      "duration": 184,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22149",
      "to_stop_id": "IDFM:463183",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:22092",
      "duration": 60,
      "path": [
        [
          2.3466414308440138,
          48.859661211559086
        ],
        [
          2.3472322349319112,
          48.85493384910671
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:22137",
      "duration": 60,
      "path": [
        [
          2.3472322349319112,
          48.85493384910671
        ],
        [
          2.343964657062377,
          48.85358493521485
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:22364",
      "duration": 298,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:463060",
      "duration": 285,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:463161",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22150",
      "to_stop_id": "IDFM:463306",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:22151",
      "to_stop_id": "IDFM:22158",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22151",
      "to_stop_id": "IDFM:463103",
      "duration": 80,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22151",
      "to_stop_id": "IDFM:463104",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22151",
      "to_stop_id": "IDFM:463242",
      "duration": 0,
      "path": [
        [
          2.329887640408421,
          48.83133758557153
        ],
        [
          2.3321281604376116,
          48.83392232305997
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:22151",
      "to_stop_id": "IDFM:463291",
      "duration": 60,
      "path": [
        [
          2.3321281604376116,
          48.83392232305997
        ],
        [
          2.3307802165662403,
          48.83910415024143
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22152",
      "to_stop_id": "IDFM:22132",
      "duration": 0,
      "path": [
        [
          2.3495819680256593,
          48.887241930648955
        ],
        [
          2.3496815417224775,
          48.891280439844486
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:22152",
      "to_stop_id": "IDFM:463093",
      "duration": 0,
      "path": [
        [
          2.3497455480753806,
          48.88342517173516
        ],
        [
          2.3495819680256593,
          48.887241930648955
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:22152",
      "to_stop_id": "IDFM:463148",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:22153",
      "to_stop_id": "IDFM:22134",
      "duration": 0,
      "path": [
        [
          2.354491616563834,
          48.86962358263758
        ],
        [
          2.3560517414015862,
          48.87244691759551
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22153",
      "to_stop_id": "IDFM:462979",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:22153",
      "to_stop_id": "IDFM:463022",
      "duration": 60,
      "path": [
        [
          2.3560517414015862,
          48.87244691759551
        ],
        [
          2.3578981321043053,
          48.876283511884786
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22154",
      "to_stop_id": "IDFM:22140",
      "duration": 60,
      "path": [
        [
          2.3270932349484528,
          48.82820106421924
        ],
        [
          2.3254932653821045,
          48.823416351140224
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:22154",
      "to_stop_id": "IDFM:22144",
      "duration": 60,
      "path": [
        [
          2.32979034782274,
          48.831382009093616
        ],
        [
          2.3270932349484528,
          48.82820106421924
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:22154",
      "to_stop_id": "IDFM:463118",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:22155",
      "to_stop_id": "IDFM:22131",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22155",
      "to_stop_id": "IDFM:22158",
      "duration": 0,
      "path": [
        [
          2.3328724454976864,
          48.83431320632965
        ],
        [
          2.330549660589195,
          48.83908487281128
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22155",
      "to_stop_id": "IDFM:22172",
      "duration": 60,
      "path": [
        [
          2.330549660589195,
          48.83908487281128
        ],
        [
          2.3253749636089105,
          48.84098044632817
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:22155",
      "to_stop_id": "IDFM:463291",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22155",
      "to_stop_id": "IDFM:463292",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:22038",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:22143",
      "duration": 468,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:22157",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:22166",
      "duration": 60,
      "path": [
        [
          2.312792651441268,
          48.842482718285865
        ],
        [
          2.321273396249205,
          48.84205447194666
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:462954",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:462955",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:462980",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:462996",
      "duration": 468,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22156",
      "to_stop_id": "IDFM:463100",
      "duration": 60,
      "path": [
        [
          2.321273396249205,
          48.84205447194666
        ],
        [
          2.3252865779432965,
          48.84090350169825
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:22038",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:22143",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:22156",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:22229",
      "duration": 60,
      "path": [
        [
          2.320983883036087,
          48.84231364506487
        ],
        [
          2.3165212194565217,
          48.847009893611975
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:22230",
      "duration": 60,
      "path": [
        [
          2.322463569405974,
          48.83854469308166
        ],
        [
          2.320983883036087,
          48.84231364506487
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:462954",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:462955",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:462980",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22157",
      "to_stop_id": "IDFM:462996",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22158",
      "to_stop_id": "IDFM:22151",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22158",
      "to_stop_id": "IDFM:22155",
      "duration": 0,
      "path": [
        [
          2.3328724454976864,
          48.83431320632965
        ],
        [
          2.330549660589195,
          48.83908487281128
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22158",
      "to_stop_id": "IDFM:22160",
      "duration": 60,
      "path": [
        [
          2.337154370925007,
          48.832916006219165
        ],
        [
          2.3328724454976864,
          48.83431320632965
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:22158",
      "to_stop_id": "IDFM:463103",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22158",
      "to_stop_id": "IDFM:463104",
      "duration": 105,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:21920",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:22134",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:462962",
      "duration": 60,
      "path": [
        [
          2.353744768129407,
          48.86938572399119
        ],
        [
          2.363149406900382,
          48.86742161002706
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:463115",
      "duration": 60,
      "path": [
        [
          2.348496122542364,
          48.87048144580479
        ],
        [
          2.353744768129407,
          48.86938572399119
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:463264",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:463312",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22159",
      "to_stop_id": "IDFM:463313",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22160",
      "to_stop_id": "IDFM:22158",
      "duration": 60,
      "path": [
        [
          2.337154370925007,
          48.832916006219165
        ],
        [
          2.3328724454976864,
          48.83431320632965
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:22160",
      "to_stop_id": "IDFM:22173",
      "duration": 60,
      "path": [
        [
          2.3434974927179884,
          48.831170235245835
        ],
        [
          2.337154370925007,
          48.832916006219165
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:22160",
      "to_stop_id": "IDFM:463305",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:22161",
      "to_stop_id": "IDFM:22178",
      "duration": 60,
      "path": [
        [
          2.372825013717543,
          48.83700262953923
        ],
        [
          2.379463070185257,
          48.840176028121896
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:22161",
      "to_stop_id": "IDFM:463147",
      "duration": 60,
      "path": [
        [
          2.3680951237726693,
          48.83494516788824
        ],
        [
          2.372825013717543,
          48.83700262953923
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:22161",
      "to_stop_id": "IDFM:463288",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:21969",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:22183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:463099",
      "duration": 60,
      "path": [
        [
          2.2936368390558783,
          48.85038448621434
        ],
        [
          2.298906224573169,
          48.848697697821954
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:463140",
      "duration": 0,
      "path": [
        [
          2.298906224573169,
          48.848697697821954
        ],
        [
          2.302898786915822,
          48.84749339701438
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:463194",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:463201",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22162",
      "to_stop_id": "IDFM:463202",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22163",
      "to_stop_id": "IDFM:21979",
      "duration": 311,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:22163",
      "to_stop_id": "IDFM:22166",
      "duration": 60,
      "path": [
        [
          2.3094677673863235,
          48.84558437021755
        ],
        [
          2.312792651441268,
          48.842482718285865
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22163",
      "to_stop_id": "IDFM:463140",
      "duration": 60,
      "path": [
        [
          2.302898786915822,
          48.84749339701438
        ],
        [
          2.3094677673863235,
          48.84558437021755
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:22163",
      "to_stop_id": "IDFM:463314",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:22163",
      "to_stop_id": "IDFM:463315",
      "duration": 312,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:22164",
      "to_stop_id": "IDFM:21917",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:22164",
      "to_stop_id": "IDFM:22165",
      "duration": 60,
      "path": [
        [
          2.285839418881462,
          48.85751508962033
        ],
        [
          2.287625377853519,
          48.86346147371448
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:22164",
      "to_stop_id": "IDFM:463114",
      "duration": 60,
      "path": [
        [
          2.287625377853519,
          48.86346147371448
        ],
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:22164",
      "to_stop_id": "IDFM:463268",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:22164",
      "to_stop_id": "IDFM:463318",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:22165",
      "to_stop_id": "IDFM:22164",
      "duration": 60,
      "path": [
        [
          2.285839418881462,
          48.85751508962033
        ],
        [
          2.287625377853519,
          48.86346147371448
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:22165",
      "to_stop_id": "IDFM:463005",
      "duration": 60,
      "path": [
        [
          2.2894007376345997,
          48.85392536837598
        ],
        [
          2.285839418881462,
          48.85751508962033
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:22165",
      "to_stop_id": "IDFM:463101",
      "duration": 24,
      "path": null,
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:22166",
      "to_stop_id": "IDFM:22039",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22166",
      "to_stop_id": "IDFM:22156",
      "duration": 60,
      "path": [
        [
          2.312792651441268,
          48.842482718285865
        ],
        [
          2.321273396249205,
          48.84205447194666
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22166",
      "to_stop_id": "IDFM:22163",
      "duration": 60,
      "path": [
        [
          2.3094677673863235,
          48.84558437021755
        ],
        [
          2.312792651441268,
          48.842482718285865
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:22166",
      "to_stop_id": "IDFM:462947",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22166",
      "to_stop_id": "IDFM:462948",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:22167",
      "to_stop_id": "IDFM:22073",
      "duration": 60,
      "path": [
        [
          2.3958598043279085,
          48.84789550545761
        ],
        [
          2.401248269453829,
          48.84501767720269
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22167",
      "to_stop_id": "IDFM:22177",
      "duration": 60,
      "path": [
        [
          2.401248269453829,
          48.84501767720269
        ],
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:22167",
      "to_stop_id": "IDFM:463098",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:22006",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:22169",
      "duration": 60,
      "path": [
        [
          2.356101612721528,
          48.830914982874944
        ],
        [
          2.362857958087621,
          48.83318160406702
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:22365",
      "duration": 162,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:463003",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:463025",
      "duration": 56,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:463026",
      "duration": 162,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22168",
      "to_stop_id": "IDFM:463105",
      "duration": 60,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ],
        [
          2.356101612721528,
          48.830914982874944
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:22169",
      "to_stop_id": "IDFM:22168",
      "duration": 60,
      "path": [
        [
          2.356101612721528,
          48.830914982874944
        ],
        [
          2.362857958087621,
          48.83318160406702
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22169",
      "to_stop_id": "IDFM:463004",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:22169",
      "to_stop_id": "IDFM:463147",
      "duration": 60,
      "path": [
        [
          2.362857958087621,
          48.83318160406702
        ],
        [
          2.3680951237726693,
          48.83494516788824
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:22170",
      "to_stop_id": "IDFM:22095",
      "duration": 120,
      "path": [
        [
          2.294995735441831,
          48.87383861478572
        ],
        [
          2.293146137248626,
          48.87148941293503
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22170",
      "to_stop_id": "IDFM:22180",
      "duration": 60,
      "path": [
        [
          2.293146137248626,
          48.87148941293503
        ],
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:22170",
      "to_stop_id": "IDFM:463042",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:22170",
      "to_stop_id": "IDFM:463102",
      "duration": 31,
      "path": null,
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:22171",
      "to_stop_id": "IDFM:463005",
      "duration": 60,
      "path": [
        [
          2.293663726731039,
          48.8504116258067
        ],
        [
          2.2894007376345997,
          48.85392536837598
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:22171",
      "to_stop_id": "IDFM:463099",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:22171",
      "to_stop_id": "IDFM:463194",
      "duration": 0,
      "path": [
        [
          2.298957096977008,
          48.848765448122315
        ],
        [
          2.293663726731039,
          48.8504116258067
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22172",
      "to_stop_id": "IDFM:22155",
      "duration": 60,
      "path": [
        [
          2.330549660589195,
          48.83908487281128
        ],
        [
          2.3253749636089105,
          48.84098044632817
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:22172",
      "to_stop_id": "IDFM:462955",
      "duration": 60,
      "path": [
        [
          2.3253749636089105,
          48.84098044632817
        ],
        [
          2.3212187199903807,
          48.84213060781436
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22172",
      "to_stop_id": "IDFM:463016",
      "duration": 358,
      "path": null,
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:22172",
      "to_stop_id": "IDFM:463100",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:22173",
      "to_stop_id": "IDFM:22160",
      "duration": 60,
      "path": [
        [
          2.3434974927179884,
          48.831170235245835
        ],
        [
          2.337154370925007,
          48.832916006219165
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:22173",
      "to_stop_id": "IDFM:22175",
      "duration": 60,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ],
        [
          2.3434974927179884,
          48.831170235245835
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:22173",
      "to_stop_id": "IDFM:463186",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:22174",
      "to_stop_id": "IDFM:463004",
      "duration": 60,
      "path": [
        [
          2.368081282972743,
          48.83496308210916
        ],
        [
          2.3628041715935075,
          48.83323527967617
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:22174",
      "to_stop_id": "IDFM:463147",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:22174",
      "to_stop_id": "IDFM:463288",
      "duration": 0,
      "path": [
        [
          2.372766248948172,
          48.8370742706096
        ],
        [
          2.368081282972743,
          48.83496308210916
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:22175",
      "to_stop_id": "IDFM:22173",
      "duration": 60,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ],
        [
          2.3434974927179884,
          48.831170235245835
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:22175",
      "to_stop_id": "IDFM:463003",
      "duration": 60,
      "path": [
        [
          2.3560502682264337,
          48.830991153013954
        ],
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22175",
      "to_stop_id": "IDFM:463105",
      "duration": 0,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:22176",
      "to_stop_id": "IDFM:463140",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:22176",
      "to_stop_id": "IDFM:463194",
      "duration": 60,
      "path": [
        [
          2.3029417283376117,
          48.84754311219352
        ],
        [
          2.298957096977008,
          48.848765448122315
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22176",
      "to_stop_id": "IDFM:463314",
      "duration": 60,
      "path": [
        [
          2.309529610430393,
          48.84564768265073
        ],
        [
          2.3029417283376117,
          48.84754311219352
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:22177",
      "to_stop_id": "IDFM:22167",
      "duration": 60,
      "path": [
        [
          2.401248269453829,
          48.84501767720269
        ],
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:22177",
      "to_stop_id": "IDFM:463127",
      "duration": 0,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:22177",
      "to_stop_id": "IDFM:463169",
      "duration": 60,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ],
        [
          2.3961460679154514,
          48.839537421644565
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22178",
      "to_stop_id": "IDFM:21957",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:22178",
      "to_stop_id": "IDFM:22161",
      "duration": 60,
      "path": [
        [
          2.372825013717543,
          48.83700262953923
        ],
        [
          2.379463070185257,
          48.840176028121896
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:22178",
      "to_stop_id": "IDFM:22182",
      "duration": 60,
      "path": [
        [
          2.379463070185257,
          48.840176028121896
        ],
        [
          2.3896007705991944,
          48.838951094045356
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:22178",
      "to_stop_id": "IDFM:463128",
      "duration": 1,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:22178",
      "to_stop_id": "IDFM:463129",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:22179",
      "to_stop_id": "IDFM:463005",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:22179",
      "to_stop_id": "IDFM:463099",
      "duration": 60,
      "path": [
        [
          2.2892963570634604,
          48.85388427726308
        ],
        [
          2.2936368390558783,
          48.85038448621434
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:22179",
      "to_stop_id": "IDFM:463101",
      "duration": 60,
      "path": [
        [
          2.2855802300954733,
          48.8575315296204
        ],
        [
          2.2892963570634604,
          48.85388427726308
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:22180",
      "to_stop_id": "IDFM:22170",
      "duration": 60,
      "path": [
        [
          2.293146137248626,
          48.87148941293503
        ],
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:22180",
      "to_stop_id": "IDFM:463114",
      "duration": 0,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:22180",
      "to_stop_id": "IDFM:463268",
      "duration": 0,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ],
        [
          2.2874927969664416,
          48.86348766501957
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:22181",
      "to_stop_id": "IDFM:22182",
      "duration": 60,
      "path": [
        [
          2.3896007705991944,
          48.838951094045356
        ],
        [
          2.396148628489371,
          48.839434008053175
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:22181",
      "to_stop_id": "IDFM:22184",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22181",
      "to_stop_id": "IDFM:463127",
      "duration": 60,
      "path": [
        [
          2.396148628489371,
          48.839434008053175
        ],
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:22181",
      "to_stop_id": "IDFM:463168",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22181",
      "to_stop_id": "IDFM:463169",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22182",
      "to_stop_id": "IDFM:22178",
      "duration": 60,
      "path": [
        [
          2.379463070185257,
          48.840176028121896
        ],
        [
          2.3896007705991944,
          48.838951094045356
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:22182",
      "to_stop_id": "IDFM:22181",
      "duration": 60,
      "path": [
        [
          2.3896007705991944,
          48.838951094045356
        ],
        [
          2.396148628489371,
          48.839434008053175
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22182",
      "to_stop_id": "IDFM:463075",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:21969",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:22162",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:463162",
      "duration": 60,
      "path": [
        [
          2.293851708440619,
          48.84458485524012
        ],
        [
          2.298745444388579,
          48.84950618174656
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:463177",
      "duration": 60,
      "path": [
        [
          2.298745444388579,
          48.84950618174656
        ],
        [
          2.3064410394306907,
          48.854861755122315
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:463194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:463201",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22183",
      "to_stop_id": "IDFM:463202",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22184",
      "to_stop_id": "IDFM:22181",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22184",
      "to_stop_id": "IDFM:22205",
      "duration": 60,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ],
        [
          2.39052195642492,
          48.8440641552825
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:22184",
      "to_stop_id": "IDFM:463168",
      "duration": 0,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22184",
      "to_stop_id": "IDFM:463169",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22184",
      "to_stop_id": "IDFM:463226",
      "duration": 60,
      "path": [
        [
          2.402366739988004,
          48.83707743103578
        ],
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:22185",
      "to_stop_id": "IDFM:22193",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22185",
      "to_stop_id": "IDFM:463011",
      "duration": 60,
      "path": [
        [
          2.3138736155952313,
          48.86749552078754
        ],
        [
          2.3146399951225347,
          48.86053445554123
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22185",
      "to_stop_id": "IDFM:463198",
      "duration": 45,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22185",
      "to_stop_id": "IDFM:463199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22185",
      "to_stop_id": "IDFM:463320",
      "duration": 60,
      "path": [
        [
          2.3146399951225347,
          48.86053445554123
        ],
        [
          2.3149776470363417,
          48.85662420264694
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:22186",
      "to_stop_id": "IDFM:22187",
      "duration": 60,
      "path": [
        [
          2.2785093588069993,
          48.836650792286115
        ],
        [
          2.2822419598550767,
          48.83866086365992
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71073",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:22186",
      "to_stop_id": "IDFM:463051",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71073",
      "to_stop_unique_id": "IDFM:71073"
    },
    {
      "from_stop_id": "IDFM:22187",
      "to_stop_id": "IDFM:22186",
      "duration": 60,
      "path": [
        [
          2.2785093588069993,
          48.836650792286115
        ],
        [
          2.2822419598550767,
          48.83866086365992
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:71073"
    },
    {
      "from_stop_id": "IDFM:22187",
      "to_stop_id": "IDFM:22188",
      "duration": 60,
      "path": [
        [
          2.2822419598550767,
          48.83866086365992
        ],
        [
          2.2879184311245595,
          48.841024160993214
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:22187",
      "to_stop_id": "IDFM:463211",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:22188",
      "to_stop_id": "IDFM:22187",
      "duration": 60,
      "path": [
        [
          2.2822419598550767,
          48.83866086365992
        ],
        [
          2.2879184311245595,
          48.841024160993214
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:22188",
      "to_stop_id": "IDFM:22189",
      "duration": 60,
      "path": [
        [
          2.2879184311245595,
          48.841024160993214
        ],
        [
          2.2918472203679703,
          48.84268433479664
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:22188",
      "to_stop_id": "IDFM:463135",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:22189",
      "to_stop_id": "IDFM:22188",
      "duration": 60,
      "path": [
        [
          2.2879184311245595,
          48.841024160993214
        ],
        [
          2.2918472203679703,
          48.84268433479664
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:22189",
      "to_stop_id": "IDFM:22190",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:22189",
      "to_stop_id": "IDFM:463162",
      "duration": 60,
      "path": [
        [
          2.2918472203679703,
          48.84268433479664
        ],
        [
          2.293851708440619,
          48.84458485524012
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:22189",
      "to_stop_id": "IDFM:463163",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:21987",
      "duration": 352,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:22189",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:463124",
      "duration": 361,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:463162",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:463163",
      "duration": 60,
      "path": [
        [
          2.293687475167137,
          48.84463784771031
        ],
        [
          2.291751809719901,
          48.84274672574357
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:22190",
      "to_stop_id": "IDFM:463201",
      "duration": 60,
      "path": [
        [
          2.298488389923641,
          48.84967105681514
        ],
        [
          2.293687475167137,
          48.84463784771031
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22191",
      "to_stop_id": "IDFM:463177",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:22191",
      "to_stop_id": "IDFM:463201",
      "duration": 60,
      "path": [
        [
          2.3063456838200764,
          48.85491966058707
        ],
        [
          2.298488389923641,
          48.84967105681514
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:22191",
      "to_stop_id": "IDFM:463204",
      "duration": 60,
      "path": [
        [
          2.3104735359369846,
          48.85772702353455
        ],
        [
          2.3063456838200764,
          48.85491966058707
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:22192",
      "to_stop_id": "IDFM:463177",
      "duration": 60,
      "path": [
        [
          2.3064410394306907,
          48.854861755122315
        ],
        [
          2.3105887898739876,
          48.85766922956403
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:22192",
      "to_stop_id": "IDFM:463199",
      "duration": 60,
      "path": [
        [
          2.3105887898739876,
          48.85766922956403
        ],
        [
          2.314996938416133,
          48.86059945384315
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22192",
      "to_stop_id": "IDFM:463204",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:22193",
      "to_stop_id": "IDFM:22185",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22193",
      "to_stop_id": "IDFM:463151",
      "duration": 60,
      "path": [
        [
          2.321915152849789,
          48.86656106315263
        ],
        [
          2.3146326604445164,
          48.86109201138112
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22193",
      "to_stop_id": "IDFM:463198",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22193",
      "to_stop_id": "IDFM:463199",
      "duration": 77,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22193",
      "to_stop_id": "IDFM:463204",
      "duration": 60,
      "path": [
        [
          2.3146326604445164,
          48.86109201138112
        ],
        [
          2.3104735359369846,
          48.85772702353455
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:21961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:21965",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:22096",
      "duration": 60,
      "path": [
        [
          2.3220315426557194,
          48.86652125193945
        ],
        [
          2.3260272117729994,
          48.86956561611772
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:22195",
      "duration": 60,
      "path": [
        [
          2.3260272117729994,
          48.86956561611772
        ],
        [
          2.332135072925294,
          48.87059812248669
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:463063",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:463064",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22194",
      "to_stop_id": "IDFM:463081",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:21932",
      "duration": 367,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:22030",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:22194",
      "duration": 60,
      "path": [
        [
          2.3260272117729994,
          48.86956561611772
        ],
        [
          2.332135072925294,
          48.87059812248669
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:22381",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:463037",
      "duration": 60,
      "path": [
        [
          2.332135072925294,
          48.87059812248669
        ],
        [
          2.3384603609547776,
          48.87164051013642
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:463144",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:463244",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:463245",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22195",
      "to_stop_id": "IDFM:463246",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:21921",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:22024",
      "duration": 363,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:462989",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:463036",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:463037",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:463058",
      "duration": 60,
      "path": [
        [
          2.3428924760133967,
          48.871601969451355
        ],
        [
          2.3384190255427955,
          48.87173920910264
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:22196",
      "to_stop_id": "IDFM:463244",
      "duration": 60,
      "path": [
        [
          2.3384190255427955,
          48.87173920910264
        ],
        [
          2.3320941299398554,
          48.87066534465599
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22197",
      "to_stop_id": "IDFM:21909",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:22197",
      "to_stop_id": "IDFM:463037",
      "duration": 60,
      "path": [
        [
          2.3384603609547776,
          48.87164051013642
        ],
        [
          2.342839648636545,
          48.87146677844407
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:22197",
      "to_stop_id": "IDFM:463049",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:22197",
      "to_stop_id": "IDFM:463058",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:22197",
      "to_stop_id": "IDFM:463115",
      "duration": 60,
      "path": [
        [
          2.342839648636545,
          48.87146677844407
        ],
        [
          2.348496122542364,
          48.87048144580479
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:22198",
      "to_stop_id": "IDFM:21933",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:22198",
      "to_stop_id": "IDFM:463058",
      "duration": 60,
      "path": [
        [
          2.3485760027859746,
          48.8706347683979
        ],
        [
          2.3428924760133967,
          48.871601969451355
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:22198",
      "to_stop_id": "IDFM:463115",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:22198",
      "to_stop_id": "IDFM:463133",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:22198",
      "to_stop_id": "IDFM:463312",
      "duration": 60,
      "path": [
        [
          2.353797473153033,
          48.86946694920397
        ],
        [
          2.3485760027859746,
          48.8706347683979
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:21902",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:21903",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:21918",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:22026",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:22200",
      "duration": 60,
      "path": [
        [
          2.366826859795412,
          48.86308830188695
        ],
        [
          2.3632563242479026,
          48.86748513277203
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:462946",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:462961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:462962",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:462963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:463312",
      "duration": 60,
      "path": [
        [
          2.3632563242479026,
          48.86748513277203
        ],
        [
          2.353797473153033,
          48.86946694920397
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:22199",
      "to_stop_id": "IDFM:463316",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:21905",
      "duration": 264,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:21925",
      "duration": 269,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:22199",
      "duration": 60,
      "path": [
        [
          2.366826859795412,
          48.86308830188695
        ],
        [
          2.3632563242479026,
          48.86748513277203
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:22201",
      "duration": 60,
      "path": [
        [
          2.367370357789606,
          48.86098670797991
        ],
        [
          2.366826859795412,
          48.86308830188695
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:463048",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:463054",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:463059",
      "duration": 275,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:22200",
      "to_stop_id": "IDFM:463309",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:22201",
      "to_stop_id": "IDFM:22200",
      "duration": 60,
      "path": [
        [
          2.367370357789606,
          48.86098670797991
        ],
        [
          2.366826859795412,
          48.86308830188695
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:22201",
      "to_stop_id": "IDFM:22202",
      "duration": 60,
      "path": [
        [
          2.3682216127650335,
          48.85710602289208
        ],
        [
          2.367370357789606,
          48.86098670797991
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:22201",
      "to_stop_id": "IDFM:463048",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:22201",
      "to_stop_id": "IDFM:463309",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:22202",
      "to_stop_id": "IDFM:22008",
      "duration": 220,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:22202",
      "to_stop_id": "IDFM:22201",
      "duration": 60,
      "path": [
        [
          2.3682216127650335,
          48.85710602289208
        ],
        [
          2.367370357789606,
          48.86098670797991
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:22202",
      "to_stop_id": "IDFM:463000",
      "duration": 60,
      "path": [
        [
          2.369652217650188,
          48.85348468769834
        ],
        [
          2.3682216127650335,
          48.85710602289208
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22202",
      "to_stop_id": "IDFM:463138",
      "duration": 233,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:22202",
      "to_stop_id": "IDFM:463146",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:22203",
      "to_stop_id": "IDFM:22204",
      "duration": 60,
      "path": [
        [
          2.384072471688875,
          48.850200705509266
        ],
        [
          2.3761412233185926,
          48.85133776679838
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:22203",
      "to_stop_id": "IDFM:463000",
      "duration": 60,
      "path": [
        [
          2.3761412233185926,
          48.85133776679838
        ],
        [
          2.369652217650188,
          48.85348468769834
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:22203",
      "to_stop_id": "IDFM:463195",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:22204",
      "to_stop_id": "IDFM:22098",
      "duration": 60,
      "path": [
        [
          2.3872010704093922,
          48.84721292669797
        ],
        [
          2.384072471688875,
          48.850200705509266
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22204",
      "to_stop_id": "IDFM:22203",
      "duration": 60,
      "path": [
        [
          2.384072471688875,
          48.850200705509266
        ],
        [
          2.3761412233185926,
          48.85133776679838
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:22204",
      "to_stop_id": "IDFM:463172",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:22205",
      "to_stop_id": "IDFM:22098",
      "duration": 60,
      "path": [
        [
          2.39052195642492,
          48.8440641552825
        ],
        [
          2.3872010704093922,
          48.84721292669797
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:22205",
      "to_stop_id": "IDFM:22184",
      "duration": 60,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ],
        [
          2.39052195642492,
          48.8440641552825
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22205",
      "to_stop_id": "IDFM:463241",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:22206",
      "to_stop_id": "IDFM:22207",
      "duration": 60,
      "path": [
        [
          2.40228042706469,
          48.837000553950986
        ],
        [
          2.405749381400099,
          48.8350662204656
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:22206",
      "to_stop_id": "IDFM:463168",
      "duration": 60,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ],
        [
          2.40228042706469,
          48.837000553950986
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:22206",
      "to_stop_id": "IDFM:463226",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:22207",
      "to_stop_id": "IDFM:22206",
      "duration": 60,
      "path": [
        [
          2.40228042706469,
          48.837000553950986
        ],
        [
          2.405749381400099,
          48.8350662204656
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:22207",
      "to_stop_id": "IDFM:22208",
      "duration": 378,
      "path": null,
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:22207",
      "to_stop_id": "IDFM:463050",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:22207",
      "to_stop_id": "IDFM:463277",
      "duration": 60,
      "path": [
        [
          2.405749381400099,
          48.8350662204656
        ],
        [
          2.402372975541869,
          48.8336104146937
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:22208",
      "to_stop_id": "IDFM:22207",
      "duration": 378,
      "path": null,
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:22208",
      "to_stop_id": "IDFM:22209",
      "duration": 60,
      "path": [
        [
          2.4064912314699263,
          48.826148180607525
        ],
        [
          2.402511872850073,
          48.83344922287947
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:22208",
      "to_stop_id": "IDFM:463050",
      "duration": 60,
      "path": [
        [
          2.402511872850073,
          48.83344922287947
        ],
        [
          2.4058739129875644,
          48.83501737409391
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:22208",
      "to_stop_id": "IDFM:463277",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:22209",
      "to_stop_id": "IDFM:22208",
      "duration": 60,
      "path": [
        [
          2.4064912314699263,
          48.826148180607525
        ],
        [
          2.402511872850073,
          48.83344922287947
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:22209",
      "to_stop_id": "IDFM:463142",
      "duration": 60,
      "path": [
        [
          2.413844709558796,
          48.82148073316996
        ],
        [
          2.4064912314699263,
          48.826148180607525
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:22209",
      "to_stop_id": "IDFM:463209",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:22210",
      "to_stop_id": "IDFM:22214",
      "duration": 60,
      "path": [
        [
          2.413847237068526,
          48.82125590204953
        ],
        [
          2.422322751702586,
          48.814758684006726
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:22210",
      "to_stop_id": "IDFM:463142",
      "duration": 31,
      "path": null,
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:22210",
      "to_stop_id": "IDFM:463209",
      "duration": 60,
      "path": [
        [
          2.406409459132366,
          48.82608032193611
        ],
        [
          2.413847237068526,
          48.82125590204953
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:22211",
      "to_stop_id": "IDFM:22212",
      "duration": 60,
      "path": [
        [
          2.4493695956133714,
          48.79661083011156
        ],
        [
          2.450529196348293,
          48.789915686006935
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:22211",
      "to_stop_id": "IDFM:22216",
      "duration": 60,
      "path": [
        [
          2.4457883063759605,
          48.80310585036659
        ],
        [
          2.4493695956133714,
          48.79661083011156
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:22211",
      "to_stop_id": "IDFM:463166",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:22212",
      "to_stop_id": "IDFM:22211",
      "duration": 60,
      "path": [
        [
          2.4493695956133714,
          48.79661083011156
        ],
        [
          2.450529196348293,
          48.789915686006935
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:22212",
      "to_stop_id": "IDFM:463056",
      "duration": 120,
      "path": [
        [
          2.450529196348293,
          48.789915686006935
        ],
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:22212",
      "to_stop_id": "IDFM:463167",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:22213",
      "to_stop_id": "IDFM:463056",
      "duration": 0,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:22213",
      "to_stop_id": "IDFM:463057",
      "duration": 120,
      "path": [
        [
          2.464317312722042,
          48.76876136434425
        ],
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:69884"
    },
    {
      "from_stop_id": "IDFM:22213",
      "to_stop_id": "IDFM:463167",
      "duration": 60,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ],
        [
          2.4505697455342568,
          48.78994285432577
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:22214",
      "to_stop_id": "IDFM:22210",
      "duration": 60,
      "path": [
        [
          2.413847237068526,
          48.82125590204953
        ],
        [
          2.422322751702586,
          48.814758684006726
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:22214",
      "to_stop_id": "IDFM:22215",
      "duration": 60,
      "path": [
        [
          2.422322751702586,
          48.814758684006726
        ],
        [
          2.434819533389365,
          48.80891847848263
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:22214",
      "to_stop_id": "IDFM:463047",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:22215",
      "to_stop_id": "IDFM:22214",
      "duration": 60,
      "path": [
        [
          2.422322751702586,
          48.814758684006726
        ],
        [
          2.434819533389365,
          48.80891847848263
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:22215",
      "to_stop_id": "IDFM:22216",
      "duration": 60,
      "path": [
        [
          2.434819533389365,
          48.80891847848263
        ],
        [
          2.4457883063759605,
          48.80310585036659
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:22215",
      "to_stop_id": "IDFM:463225",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:22216",
      "to_stop_id": "IDFM:22211",
      "duration": 60,
      "path": [
        [
          2.4457883063759605,
          48.80310585036659
        ],
        [
          2.4493695956133714,
          48.79661083011156
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:22216",
      "to_stop_id": "IDFM:22215",
      "duration": 60,
      "path": [
        [
          2.434819533389365,
          48.80891847848263
        ],
        [
          2.4457883063759605,
          48.80310585036659
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:22216",
      "to_stop_id": "IDFM:463015",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:22217",
      "to_stop_id": "IDFM:22226",
      "duration": 60,
      "path": [
        [
          2.314407403528732,
          48.8512787565696
        ],
        [
          2.315113930509657,
          48.85662498274656
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:22217",
      "to_stop_id": "IDFM:22229",
      "duration": 60,
      "path": [
        [
          2.3165212194565217,
          48.847009893611975
        ],
        [
          2.314407403528732,
          48.8512787565696
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22217",
      "to_stop_id": "IDFM:463303",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21906",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21926",
      "duration": 315,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21963",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:21964",
      "duration": 173,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:22224",
      "duration": 120,
      "path": [
        [
          2.3160106931210245,
          48.87344791579522
        ],
        [
          2.325488390672613,
          48.87538131600797
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:22225",
      "duration": 60,
      "path": [
        [
          2.325488390672613,
          48.87538131600797
        ],
        [
          2.3268526712397555,
          48.87953493737576
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:462969",
      "duration": 179,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:462970",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:462971",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:463188",
      "duration": 307,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:463189",
      "duration": 323,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:22218",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:22219",
      "to_stop_id": "IDFM:22231",
      "duration": 60,
      "path": [
        [
          2.3201902468999456,
          48.89062201554889
        ],
        [
          2.3136590957118313,
          48.89464071673921
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:22219",
      "to_stop_id": "IDFM:22238",
      "duration": 60,
      "path": [
        [
          2.3136590957118313,
          48.89464071673921
        ],
        [
          2.3058484063578857,
          48.90349897462669
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:22219",
      "to_stop_id": "IDFM:463278",
      "duration": 51,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:22219",
      "to_stop_id": "IDFM:480531",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:22219",
      "to_stop_id": "IDFM:480541",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:22220",
      "to_stop_id": "IDFM:22244",
      "duration": 60,
      "path": [
        [
          2.3290483407446505,
          48.89749783732745
        ],
        [
          2.331962553647662,
          48.906372391923206
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:22220",
      "to_stop_id": "IDFM:462951",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:22220",
      "to_stop_id": "IDFM:462952",
      "duration": 60,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ],
        [
          2.3290483407446505,
          48.89749783732745
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:22221",
      "to_stop_id": "IDFM:22223",
      "duration": 60,
      "path": [
        [
          2.313833364032832,
          48.83174121370108
        ],
        [
          2.305223578739507,
          48.82766229777493
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:22221",
      "to_stop_id": "IDFM:22235",
      "duration": 60,
      "path": [
        [
          2.305223578739507,
          48.82766229777493
        ],
        [
          2.298295381702497,
          48.82220756804045
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:22221",
      "to_stop_id": "IDFM:463260",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:22222",
      "to_stop_id": "IDFM:22230",
      "duration": 60,
      "path": [
        [
          2.318393706614701,
          48.834078679774976
        ],
        [
          2.322463569405974,
          48.83854469308166
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:22222",
      "to_stop_id": "IDFM:463085",
      "duration": 60,
      "path": [
        [
          2.313860489344306,
          48.83175036306747
        ],
        [
          2.318393706614701,
          48.834078679774976
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:22222",
      "to_stop_id": "IDFM:463229",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:22223",
      "to_stop_id": "IDFM:22221",
      "duration": 60,
      "path": [
        [
          2.313833364032832,
          48.83174121370108
        ],
        [
          2.305223578739507,
          48.82766229777493
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:22223",
      "to_stop_id": "IDFM:463085",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:22223",
      "to_stop_id": "IDFM:463229",
      "duration": 60,
      "path": [
        [
          2.318323659736018,
          48.83416372097089
        ],
        [
          2.313833364032832,
          48.83174121370108
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:22224",
      "to_stop_id": "IDFM:21924",
      "duration": 71,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:22224",
      "to_stop_id": "IDFM:22093",
      "duration": 60,
      "path": [
        [
          2.31401668387612,
          48.86750083759083
        ],
        [
          2.3160106931210245,
          48.87344791579522
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:22224",
      "to_stop_id": "IDFM:22218",
      "duration": 120,
      "path": [
        [
          2.3160106931210245,
          48.87344791579522
        ],
        [
          2.325488390672613,
          48.87538131600797
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22224",
      "to_stop_id": "IDFM:462957",
      "duration": 55,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:22224",
      "to_stop_id": "IDFM:474397",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:22225",
      "to_stop_id": "IDFM:22129",
      "duration": 60,
      "path": [
        [
          2.3268526712397555,
          48.87953493737576
        ],
        [
          2.327398345647013,
          48.883737890227636
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22225",
      "to_stop_id": "IDFM:22218",
      "duration": 60,
      "path": [
        [
          2.325488390672613,
          48.87538131600797
        ],
        [
          2.3268526712397555,
          48.87953493737576
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:22225",
      "to_stop_id": "IDFM:463066",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:22226",
      "to_stop_id": "IDFM:22217",
      "duration": 60,
      "path": [
        [
          2.314407403528732,
          48.8512787565696
        ],
        [
          2.315113930509657,
          48.85662498274656
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:22226",
      "to_stop_id": "IDFM:463198",
      "duration": 60,
      "path": [
        [
          2.315113930509657,
          48.85662498274656
        ],
        [
          2.3145404484362686,
          48.86084865840772
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:22226",
      "to_stop_id": "IDFM:463320",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:22227",
      "to_stop_id": "IDFM:22129",
      "duration": 60,
      "path": [
        [
          2.327398345647013,
          48.883737890227636
        ],
        [
          2.3256869607667356,
          48.887424510518066
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:22227",
      "to_stop_id": "IDFM:22231",
      "duration": 60,
      "path": [
        [
          2.3256869607667356,
          48.887424510518066
        ],
        [
          2.3201902468999456,
          48.89062201554889
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:22227",
      "to_stop_id": "IDFM:462952",
      "duration": 60,
      "path": [
        [
          2.3256869607667356,
          48.887424510518066
        ],
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:22227",
      "to_stop_id": "IDFM:462975",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:22227",
      "to_stop_id": "IDFM:478395",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:22228",
      "to_stop_id": "IDFM:462951",
      "duration": 60,
      "path": [
        [
          2.3290209467099534,
          48.89750667705023
        ],
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:22228",
      "to_stop_id": "IDFM:462952",
      "duration": 0,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:22228",
      "to_stop_id": "IDFM:478395",
      "duration": 60,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ],
        [
          2.325414931297021,
          48.88736901850862
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:21970",
      "duration": 161,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:22157",
      "duration": 60,
      "path": [
        [
          2.320983883036087,
          48.84231364506487
        ],
        [
          2.3165212194565217,
          48.847009893611975
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:22217",
      "duration": 60,
      "path": [
        [
          2.3165212194565217,
          48.847009893611975
        ],
        [
          2.314407403528732,
          48.8512787565696
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:463083",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:463084",
      "duration": 160,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:22229",
      "to_stop_id": "IDFM:463173",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:22230",
      "to_stop_id": "IDFM:22157",
      "duration": 60,
      "path": [
        [
          2.322463569405974,
          48.83854469308166
        ],
        [
          2.320983883036087,
          48.84231364506487
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:22230",
      "to_stop_id": "IDFM:22222",
      "duration": 60,
      "path": [
        [
          2.318393706614701,
          48.834078679774976
        ],
        [
          2.322463569405974,
          48.83854469308166
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:22230",
      "to_stop_id": "IDFM:463184",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:22231",
      "to_stop_id": "IDFM:22219",
      "duration": 60,
      "path": [
        [
          2.3201902468999456,
          48.89062201554889
        ],
        [
          2.3136590957118313,
          48.89464071673921
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:22231",
      "to_stop_id": "IDFM:22227",
      "duration": 60,
      "path": [
        [
          2.3256869607667356,
          48.887424510518066
        ],
        [
          2.3201902468999456,
          48.89062201554889
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:22231",
      "to_stop_id": "IDFM:462978",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:22232",
      "to_stop_id": "IDFM:22233",
      "duration": 60,
      "path": [
        [
          2.286263202479002,
          48.92308777796361
        ],
        [
          2.284372100658843,
          48.93060364140038
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72286"
    },
    {
      "from_stop_id": "IDFM:22232",
      "to_stop_id": "IDFM:22234",
      "duration": 60,
      "path": [
        [
          2.2946921563609406,
          48.91639290208823
        ],
        [
          2.286263202479002,
          48.92308777796361
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:22232",
      "to_stop_id": "IDFM:463207",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:22233",
      "to_stop_id": "IDFM:22232",
      "duration": 60,
      "path": [
        [
          2.286263202479002,
          48.92308777796361
        ],
        [
          2.284372100658843,
          48.93060364140038
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72286",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:22233",
      "to_stop_id": "IDFM:463109",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:72286",
      "to_stop_unique_id": "IDFM:72286"
    },
    {
      "from_stop_id": "IDFM:22234",
      "to_stop_id": "IDFM:22232",
      "duration": 60,
      "path": [
        [
          2.2946921563609406,
          48.91639290208823
        ],
        [
          2.286263202479002,
          48.92308777796361
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:22234",
      "to_stop_id": "IDFM:22238",
      "duration": 120,
      "path": [
        [
          2.3058484063578857,
          48.90349897462669
        ],
        [
          2.2946921563609406,
          48.91639290208823
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:22234",
      "to_stop_id": "IDFM:462953",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:22235",
      "to_stop_id": "IDFM:22221",
      "duration": 60,
      "path": [
        [
          2.305223578739507,
          48.82766229777493
        ],
        [
          2.298295381702497,
          48.82220756804045
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:22235",
      "to_stop_id": "IDFM:22236",
      "duration": 60,
      "path": [
        [
          2.298295381702497,
          48.82220756804045
        ],
        [
          2.296972463702674,
          48.815265523780766
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:22235",
      "to_stop_id": "IDFM:463213",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:22236",
      "to_stop_id": "IDFM:22235",
      "duration": 60,
      "path": [
        [
          2.298295381702497,
          48.82220756804045
        ],
        [
          2.296972463702674,
          48.815265523780766
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:22236",
      "to_stop_id": "IDFM:463087",
      "duration": 60,
      "path": [
        [
          2.296972463702674,
          48.815265523780766
        ],
        [
          2.301595459041787,
          48.81072820779425
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:461505"
    },
    {
      "from_stop_id": "IDFM:22236",
      "to_stop_id": "IDFM:463214",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:22237",
      "to_stop_id": "IDFM:463087",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:461505",
      "to_stop_unique_id": "IDFM:461505"
    },
    {
      "from_stop_id": "IDFM:22237",
      "to_stop_id": "IDFM:463214",
      "duration": 60,
      "path": [
        [
          2.301717705004608,
          48.81075140670139
        ],
        [
          2.2971240100835835,
          48.81533836595972
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:461505",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:22238",
      "to_stop_id": "IDFM:22219",
      "duration": 60,
      "path": [
        [
          2.3136590957118313,
          48.89464071673921
        ],
        [
          2.3058484063578857,
          48.90349897462669
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:22238",
      "to_stop_id": "IDFM:22234",
      "duration": 120,
      "path": [
        [
          2.3058484063578857,
          48.90349897462669
        ],
        [
          2.2946921563609406,
          48.91639290208823
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:22238",
      "to_stop_id": "IDFM:463086",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:22242",
      "duration": 480,
      "path": [
        [
          2.3644942905103172,
          48.945911458883856
        ],
        [
          2.3592958408126816,
          48.93678306532419
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.343443653352174,
          48.91980854982986
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72358"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:22243",
      "duration": 60,
      "path": [
        [
          2.343443653352174,
          48.91980854982986
        ],
        [
          2.3337865427286206,
          48.91202122382531
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:462976",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:463301",
      "duration": 120,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.343443653352174,
          48.91980854982986
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:490774",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:22239",
      "to_stop_id": "IDFM:490794",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:22240",
      "to_stop_id": "IDFM:22242",
      "duration": 60,
      "path": [
        [
          2.3644942905103172,
          48.945911458883856
        ],
        [
          2.3592958408126816,
          48.93678306532419
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72358"
    },
    {
      "from_stop_id": "IDFM:22240",
      "to_stop_id": "IDFM:463126",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:22240",
      "to_stop_id": "IDFM:463301",
      "duration": 60,
      "path": [
        [
          2.3592958408126816,
          48.93678306532419
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:22241",
      "to_stop_id": "IDFM:462976",
      "duration": 120,
      "path": [
        [
          2.3435346741834655,
          48.919732608049756
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:22241",
      "to_stop_id": "IDFM:463126",
      "duration": 60,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.359431626687363,
          48.9367747982655
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:22241",
      "to_stop_id": "IDFM:463301",
      "duration": 0,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:22242",
      "to_stop_id": "IDFM:22239",
      "duration": 480,
      "path": [
        [
          2.3644942905103172,
          48.945911458883856
        ],
        [
          2.3592958408126816,
          48.93678306532419
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.343443653352174,
          48.91980854982986
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72358",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:22242",
      "to_stop_id": "IDFM:22240",
      "duration": 60,
      "path": [
        [
          2.3644942905103172,
          48.945911458883856
        ],
        [
          2.3592958408126816,
          48.93678306532419
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72358",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:22242",
      "to_stop_id": "IDFM:463302",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72358",
      "to_stop_unique_id": "IDFM:72358"
    },
    {
      "from_stop_id": "IDFM:22243",
      "to_stop_id": "IDFM:22239",
      "duration": 60,
      "path": [
        [
          2.343443653352174,
          48.91980854982986
        ],
        [
          2.3337865427286206,
          48.91202122382531
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:22243",
      "to_stop_id": "IDFM:462977",
      "duration": 60,
      "path": [
        [
          2.3337865427286206,
          48.91202122382531
        ],
        [
          2.331907872638921,
          48.90638108007163
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:22243",
      "to_stop_id": "IDFM:463212",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:22243",
      "to_stop_id": "IDFM:480290",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:22243",
      "to_stop_id": "IDFM:480330",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:22244",
      "to_stop_id": "IDFM:22220",
      "duration": 60,
      "path": [
        [
          2.3290483407446505,
          48.89749783732745
        ],
        [
          2.331962553647662,
          48.906372391923206
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:22244",
      "to_stop_id": "IDFM:462977",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:22244",
      "to_stop_id": "IDFM:463212",
      "duration": 60,
      "path": [
        [
          2.331962553647662,
          48.906372391923206
        ],
        [
          2.333893080685357,
          48.911958864732505
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:21959",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:21966",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:21990",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:22087",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:22091",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:22092",
      "duration": 248,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:22150",
      "duration": 298,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463060",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463061",
      "duration": 317,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463079",
      "duration": 123,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463080",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463158",
      "duration": 262,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463159",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463160",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463161",
      "duration": 297,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463231",
      "duration": 60,
      "path": [
        [
          2.357430292176306,
          48.85353209666315
        ],
        [
          2.3481609912345776,
          48.856953460785334
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:22364",
      "to_stop_id": "IDFM:463232",
      "duration": 60,
      "path": [
        [
          2.3481609912345776,
          48.856953460785334
        ],
        [
          2.341776749148542,
          48.85854633926832
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:22006",
      "duration": 129,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:22168",
      "duration": 116,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:22383",
      "duration": 60,
      "path": [
        [
          2.3524168018982756,
          48.835841622344475
        ],
        [
          2.355303769754901,
          48.83154024475565
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:463003",
      "duration": 112,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:463025",
      "duration": 83,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:463026",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22365",
      "to_stop_id": "IDFM:463267",
      "duration": 60,
      "path": [
        [
          2.355303769754901,
          48.83154024475565
        ],
        [
          2.357018567738162,
          48.82615323734341
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:22366",
      "to_stop_id": "IDFM:22386",
      "duration": 60,
      "path": [
        [
          2.3521548685787708,
          48.842666385642886
        ],
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:22366",
      "to_stop_id": "IDFM:22391",
      "duration": 60,
      "path": [
        [
          2.351626572605499,
          48.84022624851393
        ],
        [
          2.3521548685787708,
          48.842666385642886
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:22366",
      "to_stop_id": "IDFM:463106",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:22366",
      "to_stop_id": "IDFM:463157",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:22367",
      "to_stop_id": "IDFM:463001",
      "duration": 60,
      "path": [
        [
          2.358120525273069,
          48.876271214541624
        ],
        [
          2.3620179355106075,
          48.87844145573239
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22367",
      "to_stop_id": "IDFM:463006",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:22367",
      "to_stop_id": "IDFM:463033",
      "duration": 60,
      "path": [
        [
          2.3620179355106075,
          48.87844145573239
        ],
        [
          2.3650968074522214,
          48.880895011629946
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:21952",
      "duration": 291,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:22001",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:22107",
      "duration": 245,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:22371",
      "duration": 60,
      "path": [
        [
          2.368996070723285,
          48.88431508830977
        ],
        [
          2.3737641527012556,
          48.88810829453359
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:24680",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463008",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463009",
      "duration": 234,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463032",
      "duration": 104,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463033",
      "duration": 60,
      "path": [
        [
          2.3650968074522214,
          48.880895011629946
        ],
        [
          2.368996070723285,
          48.88431508830977
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463074",
      "duration": 313,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22368",
      "to_stop_id": "IDFM:463095",
      "duration": 280,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:22369",
      "to_stop_id": "IDFM:22378",
      "duration": 60,
      "path": [
        [
          2.357349968447059,
          48.85341474936241
        ],
        [
          2.3617517381263218,
          48.85120791254347
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:22369",
      "to_stop_id": "IDFM:463078",
      "duration": 60,
      "path": [
        [
          2.3617517381263218,
          48.85120791254347
        ],
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:22369",
      "to_stop_id": "IDFM:463265",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:22370",
      "to_stop_id": "IDFM:22385",
      "duration": 60,
      "path": [
        [
          2.3585387043161337,
          48.82216816831483
        ],
        [
          2.357318439213192,
          48.82613686175372
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22370",
      "to_stop_id": "IDFM:463026",
      "duration": 60,
      "path": [
        [
          2.357318439213192,
          48.82613686175372
        ],
        [
          2.355330902236442,
          48.83154938471729
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22370",
      "to_stop_id": "IDFM:463267",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:22371",
      "to_stop_id": "IDFM:22368",
      "duration": 60,
      "path": [
        [
          2.368996070723285,
          48.88431508830977
        ],
        [
          2.3737641527012556,
          48.88810829453359
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22371",
      "to_stop_id": "IDFM:463155",
      "duration": 60,
      "path": [
        [
          2.3737641527012556,
          48.88810829453359
        ],
        [
          2.3770380600019925,
          48.890814345621656
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:22371",
      "to_stop_id": "IDFM:463295",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:22372",
      "to_stop_id": "IDFM:22373",
      "duration": 60,
      "path": [
        [
          2.3695112543194927,
          48.821489035562834
        ],
        [
          2.3646245490007405,
          48.820132089232395
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:22372",
      "to_stop_id": "IDFM:463029",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:22372",
      "to_stop_id": "IDFM:463251",
      "duration": 60,
      "path": [
        [
          2.377362970985953,
          48.81587757774557
        ],
        [
          2.3695112543194927,
          48.821489035562834
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:22373",
      "to_stop_id": "IDFM:22372",
      "duration": 60,
      "path": [
        [
          2.3695112543194927,
          48.821489035562834
        ],
        [
          2.3646245490007405,
          48.820132089232395
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:22373",
      "to_stop_id": "IDFM:463030",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:22373",
      "to_stop_id": "IDFM:463235",
      "duration": 60,
      "path": [
        [
          2.3646245490007405,
          48.820132089232395
        ],
        [
          2.3595297331071694,
          48.81910659655869
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:22374",
      "to_stop_id": "IDFM:22389",
      "duration": 60,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ],
        [
          2.385869033143345,
          48.89780269235572
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:22374",
      "to_stop_id": "IDFM:463007",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:22374",
      "to_stop_id": "IDFM:463031",
      "duration": 60,
      "path": [
        [
          2.385869033143345,
          48.89780269235572
        ],
        [
          2.3922205712292195,
          48.90375706036556
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:22375",
      "to_stop_id": "IDFM:21960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:22375",
      "to_stop_id": "IDFM:463245",
      "duration": 60,
      "path": [
        [
          2.3344175359665553,
          48.865880289914124
        ],
        [
          2.332572085877118,
          48.870326260852515
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22375",
      "to_stop_id": "IDFM:463249",
      "duration": 60,
      "path": [
        [
          2.3370872868854393,
          48.86276538184415
        ],
        [
          2.3344175359665553,
          48.865880289914124
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22375",
      "to_stop_id": "IDFM:463283",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:22375",
      "to_stop_id": "IDFM:463284",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:22376",
      "to_stop_id": "IDFM:22079",
      "duration": 73,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22376",
      "to_stop_id": "IDFM:22379",
      "duration": 60,
      "path": [
        [
          2.337007354765684,
          48.862683997769665
        ],
        [
          2.3417089232034765,
          48.858456031111984
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:22376",
      "to_stop_id": "IDFM:463227",
      "duration": 54,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22376",
      "to_stop_id": "IDFM:463249",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22376",
      "to_stop_id": "IDFM:463283",
      "duration": 60,
      "path": [
        [
          2.33429208315197,
          48.86583012818995
        ],
        [
          2.337007354765684,
          48.862683997769665
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:22377",
      "to_stop_id": "IDFM:22387",
      "duration": 60,
      "path": [
        [
          2.3581030566976637,
          48.876361054333444
        ],
        [
          2.3487397513900734,
          48.87716484925024
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22377",
      "to_stop_id": "IDFM:463139",
      "duration": 60,
      "path": [
        [
          2.3487397513900734,
          48.87716484925024
        ],
        [
          2.3443838837796562,
          48.87604838013132
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:22377",
      "to_stop_id": "IDFM:463255",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:22378",
      "to_stop_id": "IDFM:22369",
      "duration": 60,
      "path": [
        [
          2.357349968447059,
          48.85341474936241
        ],
        [
          2.3617517381263218,
          48.85120791254347
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:22378",
      "to_stop_id": "IDFM:463060",
      "duration": 60,
      "path": [
        [
          2.348102094822081,
          48.85686770157052
        ],
        [
          2.357349968447059,
          48.85341474936241
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22378",
      "to_stop_id": "IDFM:463231",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:22379",
      "to_stop_id": "IDFM:22081",
      "duration": 337,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:22379",
      "to_stop_id": "IDFM:22376",
      "duration": 60,
      "path": [
        [
          2.337007354765684,
          48.862683997769665
        ],
        [
          2.3417089232034765,
          48.858456031111984
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:22379",
      "to_stop_id": "IDFM:463060",
      "duration": 60,
      "path": [
        [
          2.3417089232034765,
          48.858456031111984
        ],
        [
          2.348102094822081,
          48.85686770157052
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:22379",
      "to_stop_id": "IDFM:463218",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:22379",
      "to_stop_id": "IDFM:463232",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:22380",
      "to_stop_id": "IDFM:463030",
      "duration": 60,
      "path": [
        [
          2.359462523882543,
          48.81903428608761
        ],
        [
          2.3646785940494577,
          48.82005592914816
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:22380",
      "to_stop_id": "IDFM:463224",
      "duration": 60,
      "path": [
        [
          2.358412946155939,
          48.82214950607496
        ],
        [
          2.359462523882543,
          48.81903428608761
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22380",
      "to_stop_id": "IDFM:463235",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:22380",
      "to_stop_id": "IDFM:490841",
      "duration": 312,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22380",
      "to_stop_id": "IDFM:490848",
      "duration": 308,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:22024",
      "duration": 371,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:22030",
      "duration": 88,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:22195",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:462989",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:463145",
      "duration": 60,
      "path": [
        [
          2.333669568112413,
          48.873201272012516
        ],
        [
          2.3324387808144875,
          48.87030303390994
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:463244",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:463245",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:463246",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22381",
      "to_stop_id": "IDFM:463283",
      "duration": 60,
      "path": [
        [
          2.3324387808144875,
          48.87030303390994
        ],
        [
          2.33429208315197,
          48.86583012818995
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:22382",
      "to_stop_id": "IDFM:22050",
      "duration": 261,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22382",
      "to_stop_id": "IDFM:463062",
      "duration": 259,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:22382",
      "to_stop_id": "IDFM:463139",
      "duration": 60,
      "path": [
        [
          2.3443838837796562,
          48.87604838013132
        ],
        [
          2.3401505597063736,
          48.874959406040965
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:22382",
      "to_stop_id": "IDFM:463145",
      "duration": 60,
      "path": [
        [
          2.3401505597063736,
          48.874959406040965
        ],
        [
          2.333669568112413,
          48.873201272012516
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22382",
      "to_stop_id": "IDFM:463206",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:22383",
      "to_stop_id": "IDFM:22365",
      "duration": 60,
      "path": [
        [
          2.3524168018982756,
          48.835841622344475
        ],
        [
          2.355303769754901,
          48.83154024475565
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:22383",
      "to_stop_id": "IDFM:463157",
      "duration": 60,
      "path": [
        [
          2.351495277596339,
          48.84026600820119
        ],
        [
          2.3524168018982756,
          48.835841622344475
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:22383",
      "to_stop_id": "IDFM:463216",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:22384",
      "to_stop_id": "IDFM:24681",
      "duration": 75,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:22384",
      "to_stop_id": "IDFM:463006",
      "duration": 60,
      "path": [
        [
          2.3643562491890786,
          48.88124182075271
        ],
        [
          2.3620450936034145,
          48.87845059378512
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:22384",
      "to_stop_id": "IDFM:463008",
      "duration": 60,
      "path": [
        [
          2.3688985490576733,
          48.88437303056126
        ],
        [
          2.3643562491890786,
          48.88124182075271
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:22384",
      "to_stop_id": "IDFM:463033",
      "duration": 84,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:22384",
      "to_stop_id": "IDFM:463034",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:22370",
      "duration": 60,
      "path": [
        [
          2.3585387043161337,
          48.82216816831483
        ],
        [
          2.357318439213192,
          48.82613686175372
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:463107",
      "duration": 120,
      "path": [
        [
          2.3622716933808072,
          48.81028030813587
        ],
        [
          2.3585387043161337,
          48.82216816831483
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:463224",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:463235",
      "duration": 60,
      "path": [
        [
          2.3595297331071694,
          48.81910659655869
        ],
        [
          2.3585387043161337,
          48.82216816831483
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:490841",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22385",
      "to_stop_id": "IDFM:490848",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:21968",
      "duration": 67,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:21984",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:22366",
      "duration": 60,
      "path": [
        [
          2.3521548685787708,
          48.842666385642886
        ],
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:463077",
      "duration": 78,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:463078",
      "duration": 0,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:463141",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:22386",
      "to_stop_id": "IDFM:463265",
      "duration": 60,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ],
        [
          2.3618531696972482,
          48.851271408393764
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:22013",
      "duration": 67,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:22148",
      "duration": 67,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:22377",
      "duration": 60,
      "path": [
        [
          2.3581030566976637,
          48.876361054333444
        ],
        [
          2.3487397513900734,
          48.87716484925024
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:463001",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:463006",
      "duration": 60,
      "path": [
        [
          2.3620450936034145,
          48.87845059378512
        ],
        [
          2.3581030566976637,
          48.876361054333444
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:463021",
      "duration": 70,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22387",
      "to_stop_id": "IDFM:463022",
      "duration": 59,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:21932",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:22030",
      "duration": 347,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463144",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463145",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463206",
      "duration": 60,
      "path": [
        [
          2.3337385944087012,
          48.87313420577469
        ],
        [
          2.340206459456337,
          48.874851793670715
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463244",
      "duration": 381,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463245",
      "duration": 60,
      "path": [
        [
          2.332572085877118,
          48.870326260852515
        ],
        [
          2.3337385944087012,
          48.87313420577469
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22388",
      "to_stop_id": "IDFM:463246",
      "duration": 334,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:22389",
      "to_stop_id": "IDFM:22374",
      "duration": 60,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ],
        [
          2.385869033143345,
          48.89780269235572
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:22389",
      "to_stop_id": "IDFM:463152",
      "duration": 0,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:22389",
      "to_stop_id": "IDFM:463155",
      "duration": 60,
      "path": [
        [
          2.3770380600019925,
          48.890814345621656
        ],
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:22390",
      "to_stop_id": "IDFM:463139",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:22390",
      "to_stop_id": "IDFM:463206",
      "duration": 60,
      "path": [
        [
          2.340206459456337,
          48.874851793670715
        ],
        [
          2.344446309702274,
          48.875963285075564
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:22390",
      "to_stop_id": "IDFM:463255",
      "duration": 60,
      "path": [
        [
          2.344446309702274,
          48.875963285075564
        ],
        [
          2.3487939912846394,
          48.877079707510546
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:22391",
      "to_stop_id": "IDFM:22366",
      "duration": 60,
      "path": [
        [
          2.351626572605499,
          48.84022624851393
        ],
        [
          2.3521548685787708,
          48.842666385642886
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:22391",
      "to_stop_id": "IDFM:463106",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:22391",
      "to_stop_id": "IDFM:463157",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:22391",
      "to_stop_id": "IDFM:463216",
      "duration": 60,
      "path": [
        [
          2.3525311653622043,
          48.83589170669027
        ],
        [
          2.351626572605499,
          48.84022624851393
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:22392",
      "to_stop_id": "IDFM:463152",
      "duration": 60,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ],
        [
          2.37693573698238,
          48.8908857589288
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:22392",
      "to_stop_id": "IDFM:463155",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:22392",
      "to_stop_id": "IDFM:463295",
      "duration": 60,
      "path": [
        [
          2.37693573698238,
          48.8908857589288
        ],
        [
          2.373669464743196,
          48.888157262065796
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:22393",
      "to_stop_id": "IDFM:22394",
      "duration": 120,
      "path": [
        [
          2.404075377271855,
          48.91475665506255
        ],
        [
          2.3921143824978492,
          48.90379699038963
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:22393",
      "to_stop_id": "IDFM:463179",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:22393",
      "to_stop_id": "IDFM:463200",
      "duration": 60,
      "path": [
        [
          2.410008476572451,
          48.92037064946693
        ],
        [
          2.404075377271855,
          48.91475665506255
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:73696"
    },
    {
      "from_stop_id": "IDFM:22394",
      "to_stop_id": "IDFM:22393",
      "duration": 120,
      "path": [
        [
          2.404075377271855,
          48.91475665506255
        ],
        [
          2.3921143824978492,
          48.90379699038963
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:22394",
      "to_stop_id": "IDFM:463007",
      "duration": 120,
      "path": [
        [
          2.3921143824978492,
          48.90379699038963
        ],
        [
          2.3857788171360426,
          48.897865181710756
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:22394",
      "to_stop_id": "IDFM:463031",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:22395",
      "to_stop_id": "IDFM:463179",
      "duration": 60,
      "path": [
        [
          2.404187821795194,
          48.91470775289347
        ],
        [
          2.4102214036405085,
          48.92024579662936
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73696",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:22395",
      "to_stop_id": "IDFM:463200",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73696",
      "to_stop_unique_id": "IDFM:73696"
    },
    {
      "from_stop_id": "IDFM:22396",
      "to_stop_id": "IDFM:463220",
      "duration": 0,
      "path": [
        [
          2.383498053635828,
          48.811210093393306
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70488",
      "to_stop_unique_id": "IDFM:70488"
    },
    {
      "from_stop_id": "IDFM:22396",
      "to_stop_id": "IDFM:463251",
      "duration": 60,
      "path": [
        [
          2.383498053635828,
          48.811210093393306
        ],
        [
          2.377362970985953,
          48.81587757774557
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70488",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:22397",
      "to_stop_id": "IDFM:463029",
      "duration": 60,
      "path": [
        [
          2.369294978223312,
          48.821352988336876
        ],
        [
          2.377246886036494,
          48.815836500997094
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:22397",
      "to_stop_id": "IDFM:463220",
      "duration": 120,
      "path": [
        [
          2.377246886036494,
          48.815836500997094
        ],
        [
          2.383498053635828,
          48.811210093393306
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70488"
    },
    {
      "from_stop_id": "IDFM:22397",
      "to_stop_id": "IDFM:463251",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:22398",
      "to_stop_id": "IDFM:22399",
      "duration": 60,
      "path": [
        [
          2.362130413802561,
          48.81025257278989
        ],
        [
          2.3639317476477353,
          48.80435318028833
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:22398",
      "to_stop_id": "IDFM:463107",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:22398",
      "to_stop_id": "IDFM:463224",
      "duration": 120,
      "path": [
        [
          2.358412946155939,
          48.82214950607496
        ],
        [
          2.362130413802561,
          48.81025257278989
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:22399",
      "to_stop_id": "IDFM:22398",
      "duration": 60,
      "path": [
        [
          2.362130413802561,
          48.81025257278989
        ],
        [
          2.3639317476477353,
          48.80435318028833
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:22399",
      "to_stop_id": "IDFM:463323",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:22399",
      "to_stop_id": "IDFM:463324",
      "duration": 60,
      "path": [
        [
          2.3639317476477353,
          48.80435318028833
        ],
        [
          2.368195583812103,
          48.79630822122641
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:22400",
      "to_stop_id": "IDFM:22401",
      "duration": 60,
      "path": [
        [
          2.36783901176962,
          48.78753717099888
        ],
        [
          2.3683316015776423,
          48.79631793416014
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70143",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:22400",
      "to_stop_id": "IDFM:463269",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70143",
      "to_stop_unique_id": "IDFM:70143"
    },
    {
      "from_stop_id": "IDFM:22401",
      "to_stop_id": "IDFM:22400",
      "duration": 60,
      "path": [
        [
          2.36783901176962,
          48.78753717099888
        ],
        [
          2.3683316015776423,
          48.79631793416014
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70143"
    },
    {
      "from_stop_id": "IDFM:22401",
      "to_stop_id": "IDFM:463323",
      "duration": 60,
      "path": [
        [
          2.3683316015776423,
          48.79631793416014
        ],
        [
          2.364068547658874,
          48.80436739901952
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:22401",
      "to_stop_id": "IDFM:463324",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:22442",
      "to_stop_id": "IDFM:21919",
      "duration": 60,
      "path": [
        [
          2.3891047050322425,
          48.85221407333194
        ],
        [
          2.3959437210498136,
          48.84888521841715
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:22442",
      "to_stop_id": "IDFM:463143",
      "duration": 60,
      "path": [
        [
          2.384782396448888,
          48.854939443448494
        ],
        [
          2.3891047050322425,
          48.85221407333194
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:22442",
      "to_stop_id": "IDFM:463298",
      "duration": 37,
      "path": null,
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:24679",
      "to_stop_id": "IDFM:463082",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:24679",
      "to_stop_id": "IDFM:463281",
      "duration": 120,
      "path": [
        [
          2.365777177193171,
          48.906763048164294
        ],
        [
          2.359103857300198,
          48.89741064164834
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:24679",
      "to_stop_id": "IDFM:483903",
      "duration": 120,
      "path": [
        [
          2.378035995881576,
          48.90838312983791
        ],
        [
          2.365777177193171,
          48.906763048164294
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:21952",
      "duration": 161,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:22118",
      "duration": 146,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:22368",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:24681",
      "duration": 60,
      "path": [
        [
          2.364424862493678,
          48.88120621182599
        ],
        [
          2.370451734004561,
          48.882344230208226
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:24685",
      "duration": 60,
      "path": [
        [
          2.370451734004561,
          48.882344230208226
        ],
        [
          2.374124871187542,
          48.88078966297506
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:462981",
      "duration": 146,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:463008",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:463074",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24680",
      "to_stop_id": "IDFM:463095",
      "duration": 167,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24681",
      "to_stop_id": "IDFM:22384",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:24681",
      "to_stop_id": "IDFM:24680",
      "duration": 60,
      "path": [
        [
          2.364424862493678,
          48.88120621182599
        ],
        [
          2.370451734004561,
          48.882344230208226
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24681",
      "to_stop_id": "IDFM:463033",
      "duration": 76,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:24681",
      "to_stop_id": "IDFM:463034",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:24682",
      "to_stop_id": "IDFM:21951",
      "duration": 75,
      "path": null,
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:24682",
      "to_stop_id": "IDFM:24684",
      "duration": 120,
      "path": [
        [
          2.392959616507311,
          48.87693859004261
        ],
        [
          2.398580770693528,
          48.8801595806643
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71911"
    },
    {
      "from_stop_id": "IDFM:24682",
      "to_stop_id": "IDFM:24686",
      "duration": 60,
      "path": [
        [
          2.388908111185046,
          48.87943610160117
        ],
        [
          2.392959616507311,
          48.87693859004261
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:24682",
      "to_stop_id": "IDFM:463254",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:24683",
      "to_stop_id": "IDFM:463132",
      "duration": 60,
      "path": [
        [
          2.381569842088008,
          48.87849908839857
        ],
        [
          2.374152140698752,
          48.880789805531926
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:24683",
      "to_stop_id": "IDFM:463134",
      "duration": 60,
      "path": [
        [
          2.388900951315739,
          48.879534991268365
        ],
        [
          2.381569842088008,
          48.87849908839857
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:24683",
      "to_stop_id": "IDFM:463156",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:24684",
      "to_stop_id": "IDFM:24682",
      "duration": 120,
      "path": [
        [
          2.392959616507311,
          48.87693859004261
        ],
        [
          2.398580770693528,
          48.8801595806643
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71911",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:24684",
      "to_stop_id": "IDFM:463282",
      "duration": 90,
      "path": [
        [
          2.398580770693528,
          48.8801595806643
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71911",
      "to_stop_unique_id": "IDFM:71911"
    },
    {
      "from_stop_id": "IDFM:24685",
      "to_stop_id": "IDFM:22118",
      "duration": 376,
      "path": null,
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24685",
      "to_stop_id": "IDFM:24680",
      "duration": 60,
      "path": [
        [
          2.370451734004561,
          48.882344230208226
        ],
        [
          2.374124871187542,
          48.88078966297506
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:24685",
      "to_stop_id": "IDFM:463132",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:24685",
      "to_stop_id": "IDFM:463156",
      "duration": 60,
      "path": [
        [
          2.374124871187542,
          48.88078966297506
        ],
        [
          2.381632921459486,
          48.878422971131464
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:24686",
      "to_stop_id": "IDFM:24682",
      "duration": 60,
      "path": [
        [
          2.388908111185046,
          48.87943610160117
        ],
        [
          2.392959616507311,
          48.87693859004261
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:24686",
      "to_stop_id": "IDFM:463134",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:24686",
      "to_stop_id": "IDFM:463156",
      "duration": 60,
      "path": [
        [
          2.381632921459486,
          48.878422971131464
        ],
        [
          2.388908111185046,
          48.87943610160117
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:24687",
      "to_stop_id": "IDFM:463134",
      "duration": 60,
      "path": [
        [
          2.393228471227492,
          48.88194921492064
        ],
        [
          2.388900951315739,
          48.879534991268365
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71930",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:24687",
      "to_stop_id": "IDFM:463282",
      "duration": 60,
      "path": [
        [
          2.398580770693528,
          48.8801595806643
        ],
        [
          2.393228471227492,
          48.88194921492064
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71930",
      "to_stop_unique_id": "IDFM:71911"
    },
    {
      "from_stop_id": "IDFM:25084",
      "to_stop_id": "IDFM:21936",
      "duration": 60,
      "path": [
        [
          2.406038544798052,
          48.85273102047999
        ],
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:25084",
      "to_stop_id": "IDFM:22124",
      "duration": 278,
      "path": null,
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:25084",
      "to_stop_id": "IDFM:462942",
      "duration": 60,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ],
        [
          2.395921369214201,
          48.848979537674516
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:25084",
      "to_stop_id": "IDFM:463039",
      "duration": 0,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:25084",
      "to_stop_id": "IDFM:463125",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:26602",
      "to_stop_id": "IDFM:22140",
      "duration": 60,
      "path": [
        [
          2.3254932653821045,
          48.823416351140224
        ],
        [
          2.319523835754741,
          48.818471900073675
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:26602",
      "to_stop_id": "IDFM:463222",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:26602",
      "to_stop_id": "IDFM:483313",
      "duration": 60,
      "path": [
        [
          2.319523835754741,
          48.818471900073675
        ],
        [
          2.3174174096489284,
          48.8097987811254
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:462914",
      "to_stop_id": "IDFM:21914",
      "duration": 120,
      "path": [
        [
          2.243583048618053,
          48.83359298218974
        ],
        [
          2.2570461929221506,
          48.83795840561949
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:462914",
      "to_stop_id": "IDFM:21939",
      "duration": 60,
      "path": [
        [
          2.237965973200589,
          48.83183051284947
        ],
        [
          2.243583048618053,
          48.83359298218974
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:462914",
      "to_stop_id": "IDFM:21940",
      "duration": 32,
      "path": null,
      "from_stop_unique_id": "IDFM:71054",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:462915",
      "to_stop_id": "IDFM:21912",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:462915",
      "to_stop_id": "IDFM:462957",
      "duration": 60,
      "path": [
        [
          2.3101372056878247,
          48.87215513107743
        ],
        [
          2.3146768238242688,
          48.87369210197914
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:462915",
      "to_stop_id": "IDFM:463180",
      "duration": 60,
      "path": [
        [
          2.3095735420193506,
          48.86866242617973
        ],
        [
          2.3101372056878247,
          48.87215513107743
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71334",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:21919",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:22072",
      "duration": 149,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:22073",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:22080",
      "duration": 163,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:25084",
      "duration": 60,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ],
        [
          2.395921369214201,
          48.848979537674516
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:462943",
      "duration": 153,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:463028",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:463067",
      "duration": 176,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462942",
      "to_stop_id": "IDFM:463298",
      "duration": 60,
      "path": [
        [
          2.395921369214201,
          48.848979537674516
        ],
        [
          2.389429968854563,
          48.85236862327955
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:21919",
      "duration": 136,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:22072",
      "duration": 83,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:22073",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:22080",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:462942",
      "duration": 154,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:463012",
      "duration": 60,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ],
        [
          2.395825965451431,
          48.84816963759798
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:463028",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:463067",
      "duration": 80,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:462943",
      "to_stop_id": "IDFM:463294",
      "duration": 60,
      "path": [
        [
          2.395825965451431,
          48.84816963759798
        ],
        [
          2.3858367760086865,
          48.84734982784612
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:462944",
      "to_stop_id": "IDFM:21975",
      "duration": 60,
      "path": [
        [
          2.3450218824744566,
          48.850793731720536
        ],
        [
          2.348219343927666,
          48.85010969451494
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:462944",
      "to_stop_id": "IDFM:21978",
      "duration": 0,
      "path": [
        [
          2.340619126473996,
          48.8519477060765
        ],
        [
          2.3450218824744566,
          48.850793731720536
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462944",
      "to_stop_id": "IDFM:21983",
      "duration": 35,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:462944",
      "to_stop_id": "IDFM:463237",
      "duration": 315,
      "path": null,
      "from_stop_unique_id": "IDFM:73619",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:462945",
      "to_stop_id": "IDFM:21990",
      "duration": 60,
      "path": [
        [
          2.35154698561547,
          48.85757442237991
        ],
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:462945",
      "to_stop_id": "IDFM:21992",
      "duration": 0,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:462945",
      "to_stop_id": "IDFM:21997",
      "duration": 60,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ],
        [
          2.3565081436435467,
          48.86529961264491
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71273",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:21902",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:21903",
      "duration": 76,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:21918",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:22019",
      "duration": 60,
      "path": [
        [
          2.3747205969196896,
          48.86524326053047
        ],
        [
          2.3643332975454276,
          48.86720306822776
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:22026",
      "duration": 60,
      "path": [
        [
          2.3643332975454276,
          48.86720306822776
        ],
        [
          2.361561234526356,
          48.86675662943444
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:462961",
      "duration": 128,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:462963",
      "duration": 109,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462946",
      "to_stop_id": "IDFM:463316",
      "duration": 266,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462947",
      "to_stop_id": "IDFM:22039",
      "duration": 21,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462947",
      "to_stop_id": "IDFM:22048",
      "duration": 60,
      "path": [
        [
          2.3079833805768515,
          48.84141172841498
        ],
        [
          2.3126518981045816,
          48.84282366881032
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:462947",
      "to_stop_id": "IDFM:22056",
      "duration": 60,
      "path": [
        [
          2.3126518981045816,
          48.84282366881032
        ],
        [
          2.317551235617027,
          48.844317683534335
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:462947",
      "to_stop_id": "IDFM:22166",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462947",
      "to_stop_id": "IDFM:462948",
      "duration": 103,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462948",
      "to_stop_id": "IDFM:22039",
      "duration": 121,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462948",
      "to_stop_id": "IDFM:22166",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462948",
      "to_stop_id": "IDFM:462947",
      "duration": 97,
      "path": null,
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462948",
      "to_stop_id": "IDFM:462955",
      "duration": 60,
      "path": [
        [
          2.3212187199903807,
          48.84213060781436
        ],
        [
          2.3129146804739396,
          48.842528387543226
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462948",
      "to_stop_id": "IDFM:463314",
      "duration": 60,
      "path": [
        [
          2.3129146804739396,
          48.842528387543226
        ],
        [
          2.309529610430393,
          48.84564768265073
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71125",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:462949",
      "to_stop_id": "IDFM:22058",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:462949",
      "to_stop_id": "IDFM:463261",
      "duration": 60,
      "path": [
        [
          2.2963913864809173,
          48.83713505380496
        ],
        [
          2.2877417091423347,
          48.83251953251351
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:462949",
      "to_stop_id": "IDFM:463321",
      "duration": 60,
      "path": [
        [
          2.300977822126098,
          48.83953628376544
        ],
        [
          2.2963913864809173,
          48.83713505380496
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71075",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:462950",
      "to_stop_id": "IDFM:21980",
      "duration": 60,
      "path": [
        [
          2.3256998984524113,
          48.85588467848543
        ],
        [
          2.326859663121737,
          48.85156530639137
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462950",
      "to_stop_id": "IDFM:22043",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:462950",
      "to_stop_id": "IDFM:463311",
      "duration": 60,
      "path": [
        [
          2.3230759642515415,
          48.85853194972854
        ],
        [
          2.3256998984524113,
          48.85588467848543
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71227",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:462951",
      "to_stop_id": "IDFM:22220",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:462951",
      "to_stop_id": "IDFM:22228",
      "duration": 60,
      "path": [
        [
          2.3290209467099534,
          48.89750667705023
        ],
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:462951",
      "to_stop_id": "IDFM:462977",
      "duration": 60,
      "path": [
        [
          2.331907872638921,
          48.90638108007163
        ],
        [
          2.3290209467099534,
          48.89750667705023
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72078",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:462952",
      "to_stop_id": "IDFM:22220",
      "duration": 60,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ],
        [
          2.3290483407446505,
          48.89749783732745
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:462952",
      "to_stop_id": "IDFM:22227",
      "duration": 60,
      "path": [
        [
          2.3256869607667356,
          48.887424510518066
        ],
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:462952",
      "to_stop_id": "IDFM:22228",
      "duration": 0,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71528",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:462953",
      "to_stop_id": "IDFM:22234",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:462953",
      "to_stop_id": "IDFM:463086",
      "duration": 120,
      "path": [
        [
          2.29460408001733,
          48.916347418146714
        ],
        [
          2.305726300569584,
          48.90344880444455
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:462953",
      "to_stop_id": "IDFM:463207",
      "duration": 60,
      "path": [
        [
          2.28615583314788,
          48.923055662740055
        ],
        [
          2.29460408001733,
          48.916347418146714
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72203",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:22038",
      "duration": 361,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:22143",
      "duration": 343,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:22156",
      "duration": 113,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:22157",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:462955",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:462980",
      "duration": 241,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:462996",
      "duration": 289,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:463083",
      "duration": 60,
      "path": [
        [
          2.316535081176305,
          48.84699198555429
        ],
        [
          2.3208534383022275,
          48.84228592360469
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:462954",
      "to_stop_id": "IDFM:463184",
      "duration": 60,
      "path": [
        [
          2.3208534383022275,
          48.84228592360469
        ],
        [
          2.3223539967788067,
          48.83852608502253
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:22038",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:22143",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:22156",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:22157",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:22172",
      "duration": 60,
      "path": [
        [
          2.3253749636089105,
          48.84098044632817
        ],
        [
          2.3212187199903807,
          48.84213060781436
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:462948",
      "duration": 60,
      "path": [
        [
          2.3212187199903807,
          48.84213060781436
        ],
        [
          2.3129146804739396,
          48.842528387543226
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:462954",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:462980",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462955",
      "to_stop_id": "IDFM:462996",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462956",
      "to_stop_id": "IDFM:21923",
      "duration": 0,
      "path": [
        [
          2.261511916905205,
          48.84491122756327
        ],
        [
          2.25967486021195,
          48.842624465259945
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:462956",
      "to_stop_id": "IDFM:21930",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:462956",
      "to_stop_id": "IDFM:463239",
      "duration": 367,
      "path": null,
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:462956",
      "to_stop_id": "IDFM:463259",
      "duration": 60,
      "path": [
        [
          2.25967486021195,
          48.842624465259945
        ],
        [
          2.2567894964207142,
          48.83799728287381
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71124",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:462957",
      "to_stop_id": "IDFM:21911",
      "duration": 60,
      "path": [
        [
          2.3146768238242688,
          48.87369210197914
        ],
        [
          2.3210135978085997,
          48.87454661293485
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462957",
      "to_stop_id": "IDFM:21924",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:462957",
      "to_stop_id": "IDFM:22224",
      "duration": 64,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:462957",
      "to_stop_id": "IDFM:462915",
      "duration": 60,
      "path": [
        [
          2.3101372056878247,
          48.87215513107743
        ],
        [
          2.3146768238242688,
          48.87369210197914
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:462957",
      "to_stop_id": "IDFM:474397",
      "duration": 58,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:462958",
      "to_stop_id": "IDFM:21972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:462958",
      "to_stop_id": "IDFM:21982",
      "duration": 60,
      "path": [
        [
          2.2730641263728333,
          48.84707750734599
        ],
        [
          2.2780435059854063,
          48.846086877931405
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71162"
    },
    {
      "from_stop_id": "IDFM:462958",
      "to_stop_id": "IDFM:21986",
      "duration": 60,
      "path": [
        [
          2.2780435059854063,
          48.846086877931405
        ],
        [
          2.2856243417995747,
          48.84636172348247
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71150",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:462959",
      "to_stop_id": "IDFM:21970",
      "duration": 60,
      "path": [
        [
          2.3213512423547358,
          48.84889457005888
        ],
        [
          2.3162247549746047,
          48.84675637808778
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:462959",
      "to_stop_id": "IDFM:21977",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:462959",
      "to_stop_id": "IDFM:462967",
      "duration": 60,
      "path": [
        [
          2.3261490660967246,
          48.85112511792463
        ],
        [
          2.3213512423547358,
          48.84889457005888
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71170",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:21902",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:21903",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:21918",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:21937",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:21997",
      "duration": 60,
      "path": [
        [
          2.3565081436435467,
          48.86529961264491
        ],
        [
          2.3648796725347783,
          48.86788497134881
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:22026",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:462946",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:462961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:462963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:463187",
      "duration": 60,
      "path": [
        [
          2.3648796725347783,
          48.86788497134881
        ],
        [
          2.3710788377398995,
          48.869936723164365
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:462960",
      "to_stop_id": "IDFM:463316",
      "duration": 350,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:21902",
      "duration": 158,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:21903",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:21918",
      "duration": 156,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:22011",
      "duration": 60,
      "path": [
        [
          2.3641925544688656,
          48.867732931981166
        ],
        [
          2.3611315892483544,
          48.870666468034706
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:22026",
      "duration": 281,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:462946",
      "duration": 139,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:462963",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:463059",
      "duration": 60,
      "path": [
        [
          2.3686045625807752,
          48.86464456516527
        ],
        [
          2.3641925544688656,
          48.867732931981166
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:462961",
      "to_stop_id": "IDFM:463316",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:21902",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:21903",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:21918",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:22026",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:22159",
      "duration": 60,
      "path": [
        [
          2.353744768129407,
          48.86938572399119
        ],
        [
          2.363149406900382,
          48.86742161002706
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:22199",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:462946",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:462961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:462963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:463048",
      "duration": 60,
      "path": [
        [
          2.363149406900382,
          48.86742161002706
        ],
        [
          2.3665956945709348,
          48.8630421114174
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:462962",
      "to_stop_id": "IDFM:463316",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:21902",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:21903",
      "duration": 105,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:21918",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:21937",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:22026",
      "duration": 194,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:22199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:462946",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:462960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:462961",
      "duration": 117,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:462962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:463054",
      "duration": 60,
      "path": [
        [
          2.3682236708807456,
          48.86487188759515
        ],
        [
          2.3633023407804536,
          48.86751235781114
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:463264",
      "duration": 60,
      "path": [
        [
          2.3633023407804536,
          48.86751235781114
        ],
        [
          2.3538223820093616,
          48.86950305730265
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:462963",
      "to_stop_id": "IDFM:463316",
      "duration": 198,
      "path": null,
      "from_stop_unique_id": "IDFM:71311",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:462964",
      "to_stop_id": "IDFM:22039",
      "duration": 60,
      "path": [
        [
          2.312554745666783,
          48.842958015471986
        ],
        [
          2.3079137063600563,
          48.84152824313428
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:462964",
      "to_stop_id": "IDFM:22048",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:462964",
      "to_stop_id": "IDFM:463321",
      "duration": 60,
      "path": [
        [
          2.3079137063600563,
          48.84152824313428
        ],
        [
          2.300977822126098,
          48.83953628376544
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71113",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:462965",
      "to_stop_id": "IDFM:22051",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:462965",
      "to_stop_id": "IDFM:22138",
      "duration": 327,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462965",
      "to_stop_id": "IDFM:462968",
      "duration": 60,
      "path": [
        [
          2.3286958054987075,
          48.845077745637816
        ],
        [
          2.32802068556793,
          48.84819024311401
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:462965",
      "to_stop_id": "IDFM:462980",
      "duration": 60,
      "path": [
        [
          2.32392211008296,
          48.84374228942691
        ],
        [
          2.3286958054987075,
          48.845077745637816
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462965",
      "to_stop_id": "IDFM:463308",
      "duration": 313,
      "path": null,
      "from_stop_unique_id": "IDFM:71138",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:21976",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:21980",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:22043",
      "duration": 60,
      "path": [
        [
          2.326886801039095,
          48.851574452640946
        ],
        [
          2.3256727584985164,
          48.85587553200213
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:22139",
      "duration": 351,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:462967",
      "duration": 99,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462966",
      "to_stop_id": "IDFM:462968",
      "duration": 60,
      "path": [
        [
          2.32802068556793,
          48.84819024311401
        ],
        [
          2.326886801039095,
          48.851574452640946
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:462967",
      "to_stop_id": "IDFM:21976",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462967",
      "to_stop_id": "IDFM:21980",
      "duration": 59,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462967",
      "to_stop_id": "IDFM:462959",
      "duration": 60,
      "path": [
        [
          2.3261490660967246,
          48.85112511792463
        ],
        [
          2.3213512423547358,
          48.84889457005888
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:462967",
      "to_stop_id": "IDFM:462966",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462967",
      "to_stop_id": "IDFM:462994",
      "duration": 60,
      "path": [
        [
          2.3350868179946915,
          48.85296034336302
        ],
        [
          2.3261490660967246,
          48.85112511792463
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71203",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:462968",
      "to_stop_id": "IDFM:22049",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:462968",
      "to_stop_id": "IDFM:22138",
      "duration": 195,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462968",
      "to_stop_id": "IDFM:462965",
      "duration": 60,
      "path": [
        [
          2.3286958054987075,
          48.845077745637816
        ],
        [
          2.32802068556793,
          48.84819024311401
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:462968",
      "to_stop_id": "IDFM:462966",
      "duration": 60,
      "path": [
        [
          2.32802068556793,
          48.84819024311401
        ],
        [
          2.326886801039095,
          48.851574452640946
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462968",
      "to_stop_id": "IDFM:463308",
      "duration": 190,
      "path": null,
      "from_stop_unique_id": "IDFM:73640",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21906",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21926",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21963",
      "duration": 207,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:21964",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:22046",
      "duration": 60,
      "path": [
        [
          2.327470237303278,
          48.875594817796994
        ],
        [
          2.333030634158166,
          48.87625096700832
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:22218",
      "duration": 141,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:462970",
      "duration": 149,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:462971",
      "duration": 263,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:463081",
      "duration": 60,
      "path": [
        [
          2.325169929034193,
          48.87003294090397
        ],
        [
          2.327470237303278,
          48.875594817796994
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:463188",
      "duration": 261,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:463189",
      "duration": 289,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462969",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21906",
      "duration": 338,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21926",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21963",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:21964",
      "duration": 165,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:22218",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:462969",
      "duration": 170,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:462971",
      "duration": 114,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:463066",
      "duration": 60,
      "path": [
        [
          2.3269450274387555,
          48.87971082693934
        ],
        [
          2.3254668925871607,
          48.875462135328796
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:463188",
      "duration": 317,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:463189",
      "duration": 333,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462970",
      "to_stop_id": "IDFM:474397",
      "duration": 120,
      "path": [
        [
          2.3254668925871607,
          48.875462135328796
        ],
        [
          2.3159507090192264,
          48.87344757302508
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21906",
      "duration": 60,
      "path": [
        [
          2.3244924701707954,
          48.87496650103443
        ],
        [
          2.3274627984235092,
          48.87346783937801
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21911",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21926",
      "duration": 347,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21962",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21963",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:21964",
      "duration": 297,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:22218",
      "duration": 147,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:462969",
      "duration": 302,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:462970",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:462972",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:463069",
      "duration": 60,
      "path": [
        [
          2.32218591952051,
          48.87875316404477
        ],
        [
          2.3244924701707954,
          48.87496650103443
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:463188",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:463189",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:462971",
      "to_stop_id": "IDFM:463300",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:21911",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:21961",
      "duration": 60,
      "path": [
        [
          2.3240258961246782,
          48.875660854671985
        ],
        [
          2.3258100487932776,
          48.87054467671624
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:21962",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:21963",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:21964",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:22218",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:462969",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:462970",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:462971",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:463300",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:462972",
      "to_stop_id": "IDFM:480286",
      "duration": 120,
      "path": [
        [
          2.3151908648850013,
          48.888102389888395
        ],
        [
          2.3240258961246782,
          48.875660854671985
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71370",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:462973",
      "to_stop_id": "IDFM:22052",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462973",
      "to_stop_id": "IDFM:22054",
      "duration": 60,
      "path": [
        [
          2.3500246218070155,
          48.89154760285429
        ],
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:462973",
      "to_stop_id": "IDFM:22132",
      "duration": 172,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462973",
      "to_stop_id": "IDFM:462974",
      "duration": 188,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462973",
      "to_stop_id": "IDFM:463236",
      "duration": 60,
      "path": [
        [
          2.359808478084024,
          48.89057957856722
        ],
        [
          2.3500246218070155,
          48.89154760285429
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:462974",
      "to_stop_id": "IDFM:22052",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462974",
      "to_stop_id": "IDFM:22132",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462974",
      "to_stop_id": "IDFM:22133",
      "duration": 60,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ],
        [
          2.3495451670385634,
          48.89127969913649
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:462974",
      "to_stop_id": "IDFM:462973",
      "duration": 187,
      "path": null,
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:462974",
      "to_stop_id": "IDFM:463148",
      "duration": 60,
      "path": [
        [
          2.3495451670385634,
          48.89127969913649
        ],
        [
          2.3493658080048405,
          48.88707887746752
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71511",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:462975",
      "to_stop_id": "IDFM:22227",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:462975",
      "to_stop_id": "IDFM:462978",
      "duration": 60,
      "path": [
        [
          2.3199048172093666,
          48.89065187119005
        ],
        [
          2.3257141171661355,
          48.88743365673691
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:462975",
      "to_stop_id": "IDFM:463253",
      "duration": 60,
      "path": [
        [
          2.3257141171661355,
          48.88743365673691
        ],
        [
          2.327268983025867,
          48.883723673452664
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:462975",
      "to_stop_id": "IDFM:478395",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:462976",
      "to_stop_id": "IDFM:22239",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:462976",
      "to_stop_id": "IDFM:22241",
      "duration": 120,
      "path": [
        [
          2.3435346741834655,
          48.919732608049756
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:462976",
      "to_stop_id": "IDFM:463212",
      "duration": 60,
      "path": [
        [
          2.333893080685357,
          48.911958864732505
        ],
        [
          2.3435346741834655,
          48.919732608049756
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:462976",
      "to_stop_id": "IDFM:490774",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:462976",
      "to_stop_id": "IDFM:490794",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:72217",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:462977",
      "to_stop_id": "IDFM:22243",
      "duration": 60,
      "path": [
        [
          2.3337865427286206,
          48.91202122382531
        ],
        [
          2.331907872638921,
          48.90638108007163
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:462977",
      "to_stop_id": "IDFM:22244",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:462977",
      "to_stop_id": "IDFM:462951",
      "duration": 60,
      "path": [
        [
          2.331907872638921,
          48.90638108007163
        ],
        [
          2.3290209467099534,
          48.89750667705023
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72128",
      "to_stop_unique_id": "IDFM:72078"
    },
    {
      "from_stop_id": "IDFM:462978",
      "to_stop_id": "IDFM:22231",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:462978",
      "to_stop_id": "IDFM:462975",
      "duration": 60,
      "path": [
        [
          2.3199048172093666,
          48.89065187119005
        ],
        [
          2.3257141171661355,
          48.88743365673691
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:462978",
      "to_stop_id": "IDFM:463278",
      "duration": 60,
      "path": [
        [
          2.31320835075881,
          48.89443128801746
        ],
        [
          2.3199048172093666,
          48.89065187119005
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73661",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:462979",
      "to_stop_id": "IDFM:22148",
      "duration": 0,
      "path": [
        [
          2.3577495818204395,
          48.87627821831841
        ],
        [
          2.3559014497805357,
          48.87247308911766
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:462979",
      "to_stop_id": "IDFM:22153",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:462979",
      "to_stop_id": "IDFM:463313",
      "duration": 60,
      "path": [
        [
          2.3559014497805357,
          48.87247308911766
        ],
        [
          2.3543411093711257,
          48.86966773787259
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71333",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:22038",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:22056",
      "duration": 60,
      "path": [
        [
          2.317551235617027,
          48.844317683534335
        ],
        [
          2.32392211008296,
          48.84374228942691
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:22143",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:22156",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:22157",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:462954",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:462955",
      "duration": 396,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:462965",
      "duration": 60,
      "path": [
        [
          2.32392211008296,
          48.84374228942691
        ],
        [
          2.3286958054987075,
          48.845077745637816
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:462980",
      "to_stop_id": "IDFM:462996",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:21952",
      "duration": 225,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:22107",
      "duration": 60,
      "path": [
        [
          2.365751439124989,
          48.884243984567746
        ],
        [
          2.3701539703369967,
          48.881596221131936
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:22118",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:24680",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:462984",
      "duration": 60,
      "path": [
        [
          2.3701539703369967,
          48.881596221131936
        ],
        [
          2.370467574793864,
          48.87764980852146
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:463074",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462981",
      "to_stop_id": "IDFM:463095",
      "duration": 231,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462982",
      "to_stop_id": "IDFM:22007",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:462982",
      "to_stop_id": "IDFM:22119",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:462982",
      "to_stop_id": "IDFM:463009",
      "duration": 60,
      "path": [
        [
          2.3657743705581695,
          48.88432054886986
        ],
        [
          2.360430336162137,
          48.88447648340964
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:462982",
      "to_stop_id": "IDFM:463094",
      "duration": 60,
      "path": [
        [
          2.360430336162137,
          48.88447648340964
        ],
        [
          2.350573400888514,
          48.883852349861876
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:462982",
      "to_stop_id": "IDFM:463183",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71434",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:462983",
      "to_stop_id": "IDFM:22126",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:462983",
      "to_stop_id": "IDFM:463111",
      "duration": 60,
      "path": [
        [
          2.376640589315146,
          48.87225013398613
        ],
        [
          2.3802889829271936,
          48.86919334513237
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:462983",
      "to_stop_id": "IDFM:463243",
      "duration": 60,
      "path": [
        [
          2.3802889829271936,
          48.86919334513237
        ],
        [
          2.3834303882303045,
          48.866390140145434
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71834",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:462984",
      "to_stop_id": "IDFM:22120",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:462984",
      "to_stop_id": "IDFM:462981",
      "duration": 60,
      "path": [
        [
          2.3701539703369967,
          48.881596221131936
        ],
        [
          2.370467574793864,
          48.87764980852146
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:462984",
      "to_stop_id": "IDFM:463111",
      "duration": 60,
      "path": [
        [
          2.370467574793864,
          48.87764980852146
        ],
        [
          2.376640589315146,
          48.87225013398613
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71893",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:462985",
      "to_stop_id": "IDFM:22035",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72057",
      "to_stop_unique_id": "IDFM:72057"
    },
    {
      "from_stop_id": "IDFM:462985",
      "to_stop_id": "IDFM:22036",
      "duration": 60,
      "path": [
        [
          2.2849816963403193,
          48.892206617614
        ],
        [
          2.28030375318625,
          48.89722831603318
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:72057",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:462986",
      "to_stop_id": "IDFM:22029",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:462986",
      "to_stop_id": "IDFM:463090",
      "duration": 60,
      "path": [
        [
          2.3406519668083314,
          48.868738736969725
        ],
        [
          2.3475137108219895,
          48.86728343166051
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:462986",
      "to_stop_id": "IDFM:463091",
      "duration": 60,
      "path": [
        [
          2.3475137108219895,
          48.86728343166051
        ],
        [
          2.3520239377964742,
          48.86628268363084
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73634",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:462987",
      "to_stop_id": "IDFM:22027",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462987",
      "to_stop_id": "IDFM:22032",
      "duration": 60,
      "path": [
        [
          2.3222946416498362,
          48.878780759649736
        ],
        [
          2.3157901685080926,
          48.881153980760416
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:462987",
      "to_stop_id": "IDFM:22106",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462987",
      "to_stop_id": "IDFM:462988",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462987",
      "to_stop_id": "IDFM:463068",
      "duration": 60,
      "path": [
        [
          2.3157901685080926,
          48.881153980760416
        ],
        [
          2.309487846960011,
          48.88284002257779
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:462988",
      "to_stop_id": "IDFM:22027",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462988",
      "to_stop_id": "IDFM:22106",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462988",
      "to_stop_id": "IDFM:22110",
      "duration": 60,
      "path": [
        [
          2.3157614492393397,
          48.88121227337839
        ],
        [
          2.3214039779193896,
          48.882256139435015
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:462988",
      "to_stop_id": "IDFM:462987",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:462988",
      "to_stop_id": "IDFM:463240",
      "duration": 60,
      "path": [
        [
          2.309412967374703,
          48.880568770126835
        ],
        [
          2.3157614492393397,
          48.88121227337839
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71403",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:22024",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:22033",
      "duration": 60,
      "path": [
        [
          2.3406785469758815,
          48.868792843933164
        ],
        [
          2.3363187355954875,
          48.86965909738424
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:22196",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:22381",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:463037",
      "duration": 344,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:463245",
      "duration": 361,
      "path": null,
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:462989",
      "to_stop_id": "IDFM:463246",
      "duration": 60,
      "path": [
        [
          2.3363187355954875,
          48.86965909738424
        ],
        [
          2.331128974301561,
          48.87150983278418
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71313",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:462990",
      "to_stop_id": "IDFM:21948",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:462990",
      "to_stop_id": "IDFM:462997",
      "duration": 60,
      "path": [
        [
          2.4014967922879253,
          48.86846488701483
        ],
        [
          2.398537305704572,
          48.865032564464
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462990",
      "to_stop_id": "IDFM:463071",
      "duration": 60,
      "path": [
        [
          2.404361344448241,
          48.871887658077014
        ],
        [
          2.4014967922879253,
          48.86846488701483
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71828",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:462991",
      "to_stop_id": "IDFM:21945",
      "duration": 61,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462991",
      "to_stop_id": "IDFM:21950",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462991",
      "to_stop_id": "IDFM:462997",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462991",
      "to_stop_id": "IDFM:463276",
      "duration": 60,
      "path": [
        [
          2.4087542211895427,
          48.86453937293447
        ],
        [
          2.3973588775666475,
          48.86497267606974
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:462991",
      "to_stop_id": "IDFM:463286",
      "duration": 60,
      "path": [
        [
          2.3973588775666475,
          48.86497267606974
        ],
        [
          2.3862311113287342,
          48.86304099836643
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:462992",
      "to_stop_id": "IDFM:22136",
      "duration": 60,
      "path": [
        [
          2.330953550650454,
          48.85113858957915
        ],
        [
          2.333947810798194,
          48.85357456382241
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:462992",
      "to_stop_id": "IDFM:22139",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:462992",
      "to_stop_id": "IDFM:463308",
      "duration": 60,
      "path": [
        [
          2.327054815066312,
          48.84700665158937
        ],
        [
          2.330953550650454,
          48.85113858957915
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71202",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462993",
      "to_stop_id": "IDFM:21973",
      "duration": 159,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:462993",
      "to_stop_id": "IDFM:22136",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:462993",
      "to_stop_id": "IDFM:22139",
      "duration": 60,
      "path": [
        [
          2.3339874291342255,
          48.85367371343323
        ],
        [
          2.330611953081543,
          48.85120862756671
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:462993",
      "to_stop_id": "IDFM:462994",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:462993",
      "to_stop_id": "IDFM:462995",
      "duration": 60,
      "path": [
        [
          2.3395157696170745,
          48.852175453081486
        ],
        [
          2.3339874291342255,
          48.85367371343323
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71216",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462994",
      "to_stop_id": "IDFM:21973",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:462994",
      "to_stop_id": "IDFM:22136",
      "duration": 137,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:462994",
      "to_stop_id": "IDFM:462967",
      "duration": 60,
      "path": [
        [
          2.3350868179946915,
          48.85296034336302
        ],
        [
          2.3261490660967246,
          48.85112511792463
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71203"
    },
    {
      "from_stop_id": "IDFM:462994",
      "to_stop_id": "IDFM:462993",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:462994",
      "to_stop_id": "IDFM:462998",
      "duration": 60,
      "path": [
        [
          2.340692291066523,
          48.85202455451491
        ],
        [
          2.3350868179946915,
          48.85296034336302
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73639",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462995",
      "to_stop_id": "IDFM:21978",
      "duration": 91,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462995",
      "to_stop_id": "IDFM:22137",
      "duration": 60,
      "path": [
        [
          2.343964657062377,
          48.85358493521485
        ],
        [
          2.3395157696170745,
          48.852175453081486
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:462995",
      "to_stop_id": "IDFM:22145",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462995",
      "to_stop_id": "IDFM:462993",
      "duration": 60,
      "path": [
        [
          2.3395157696170745,
          48.852175453081486
        ],
        [
          2.3339874291342255,
          48.85367371343323
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:71216"
    },
    {
      "from_stop_id": "IDFM:462995",
      "to_stop_id": "IDFM:462998",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:22038",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:22135",
      "duration": 60,
      "path": [
        [
          2.32902391746117,
          48.842242093452064
        ],
        [
          2.3243858698057784,
          48.84391578859517
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:22143",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:22156",
      "duration": 381,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:22157",
      "duration": 311,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:462954",
      "duration": 338,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:462955",
      "duration": 367,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:462980",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:462996",
      "to_stop_id": "IDFM:463308",
      "duration": 60,
      "path": [
        [
          2.3243858698057784,
          48.84391578859517
        ],
        [
          2.327054815066312,
          48.84700665158937
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71139",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:462997",
      "to_stop_id": "IDFM:21945",
      "duration": 0,
      "path": [
        [
          2.398537305704572,
          48.865032564464
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462997",
      "to_stop_id": "IDFM:21950",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462997",
      "to_stop_id": "IDFM:462990",
      "duration": 60,
      "path": [
        [
          2.4014967922879253,
          48.86846488701483
        ],
        [
          2.398537305704572,
          48.865032564464
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:462997",
      "to_stop_id": "IDFM:462991",
      "duration": 61,
      "path": null,
      "from_stop_unique_id": "IDFM:71808",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:21978",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:21983",
      "duration": 60,
      "path": [
        [
          2.3448963012120796,
          48.851026876874485
        ],
        [
          2.340692291066523,
          48.85202455451491
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:22137",
      "duration": 376,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:22145",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:462994",
      "duration": 60,
      "path": [
        [
          2.340692291066523,
          48.85202455451491
        ],
        [
          2.3350868179946915,
          48.85296034336302
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73639"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:462995",
      "duration": 105,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:462998",
      "to_stop_id": "IDFM:463306",
      "duration": 379,
      "path": null,
      "from_stop_unique_id": "IDFM:73618",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:462999",
      "to_stop_id": "IDFM:22005",
      "duration": 60,
      "path": [
        [
          2.3866765356019912,
          48.88714990283661
        ],
        [
          2.3793117790180993,
          48.88520088689033
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:462999",
      "to_stop_id": "IDFM:22012",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:462999",
      "to_stop_id": "IDFM:463095",
      "duration": 60,
      "path": [
        [
          2.3793117790180993,
          48.88520088689033
        ],
        [
          2.371383333112514,
          48.88311355416086
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71957",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:22010",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:22089",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:22097",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:22202",
      "duration": 60,
      "path": [
        [
          2.369652217650188,
          48.85348468769834
        ],
        [
          2.3682216127650335,
          48.85710602289208
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:22203",
      "duration": 60,
      "path": [
        [
          2.3761412233185926,
          48.85133776679838
        ],
        [
          2.369652217650188,
          48.85348468769834
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:463018",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463000",
      "to_stop_id": "IDFM:463019",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:22013",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:22148",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:22367",
      "duration": 60,
      "path": [
        [
          2.358120525273069,
          48.876271214541624
        ],
        [
          2.3620179355106075,
          48.87844145573239
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:22387",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:463021",
      "duration": 55,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:463022",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463001",
      "to_stop_id": "IDFM:463255",
      "duration": 60,
      "path": [
        [
          2.3487939912846394,
          48.877079707510546
        ],
        [
          2.358120525273069,
          48.876271214541624
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:463002",
      "to_stop_id": "IDFM:22014",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:463002",
      "to_stop_id": "IDFM:22015",
      "duration": 180,
      "path": [
        [
          2.4490271414582585,
          48.9063655341063
        ],
        [
          2.426277595760804,
          48.89563789793626
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72486"
    },
    {
      "from_stop_id": "IDFM:463002",
      "to_stop_id": "IDFM:463178",
      "duration": 60,
      "path": [
        [
          2.426277595760804,
          48.89563789793626
        ],
        [
          2.413074999597678,
          48.89324469416795
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72039",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:22006",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:22168",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:22175",
      "duration": 60,
      "path": [
        [
          2.3560502682264337,
          48.830991153013954
        ],
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:22365",
      "duration": 162,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:463004",
      "duration": 60,
      "path": [
        [
          2.3628041715935075,
          48.83323527967617
        ],
        [
          2.3560502682264337,
          48.830991153013954
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:463025",
      "duration": 51,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463003",
      "to_stop_id": "IDFM:463026",
      "duration": 162,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463004",
      "to_stop_id": "IDFM:22169",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:463004",
      "to_stop_id": "IDFM:22174",
      "duration": 60,
      "path": [
        [
          2.368081282972743,
          48.83496308210916
        ],
        [
          2.3628041715935075,
          48.83323527967617
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:463004",
      "to_stop_id": "IDFM:463003",
      "duration": 60,
      "path": [
        [
          2.3628041715935075,
          48.83323527967617
        ],
        [
          2.3560502682264337,
          48.830991153013954
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71043",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463005",
      "to_stop_id": "IDFM:22165",
      "duration": 60,
      "path": [
        [
          2.2894007376345997,
          48.85392536837598
        ],
        [
          2.285839418881462,
          48.85751508962033
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:463005",
      "to_stop_id": "IDFM:22171",
      "duration": 60,
      "path": [
        [
          2.293663726731039,
          48.8504116258067
        ],
        [
          2.2894007376345997,
          48.85392536837598
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:463005",
      "to_stop_id": "IDFM:22179",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:473829",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:463006",
      "to_stop_id": "IDFM:22367",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:463006",
      "to_stop_id": "IDFM:22384",
      "duration": 60,
      "path": [
        [
          2.3643562491890786,
          48.88124182075271
        ],
        [
          2.3620450936034145,
          48.87845059378512
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463006",
      "to_stop_id": "IDFM:22387",
      "duration": 60,
      "path": [
        [
          2.3620450936034145,
          48.87845059378512
        ],
        [
          2.3581030566976637,
          48.876361054333444
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:474149",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463007",
      "to_stop_id": "IDFM:22374",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:463007",
      "to_stop_id": "IDFM:22394",
      "duration": 120,
      "path": [
        [
          2.3921143824978492,
          48.90379699038963
        ],
        [
          2.3857788171360426,
          48.897865181710756
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:463007",
      "to_stop_id": "IDFM:463152",
      "duration": 60,
      "path": [
        [
          2.3857788171360426,
          48.897865181710756
        ],
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72430",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:21952",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:22001",
      "duration": 127,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:22107",
      "duration": 247,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:22368",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:22384",
      "duration": 60,
      "path": [
        [
          2.3688985490576733,
          48.88437303056126
        ],
        [
          2.3643562491890786,
          48.88124182075271
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:24680",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:463009",
      "duration": 236,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:463032",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:463074",
      "duration": 324,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:463095",
      "duration": 292,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463008",
      "to_stop_id": "IDFM:463295",
      "duration": 60,
      "path": [
        [
          2.373669464743196,
          48.888157262065796
        ],
        [
          2.3688985490576733,
          48.88437303056126
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:22001",
      "duration": 127,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:22107",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:22118",
      "duration": 60,
      "path": [
        [
          2.370269867509251,
          48.88159683079925
        ],
        [
          2.3657743705581695,
          48.88432054886986
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:22368",
      "duration": 249,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:462982",
      "duration": 60,
      "path": [
        [
          2.3657743705581695,
          48.88432054886986
        ],
        [
          2.360430336162137,
          48.88447648340964
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:463008",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463009",
      "to_stop_id": "IDFM:463032",
      "duration": 121,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463010",
      "to_stop_id": "IDFM:22090",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463010",
      "to_stop_id": "IDFM:22093",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463010",
      "to_stop_id": "IDFM:463011",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463010",
      "to_stop_id": "IDFM:463150",
      "duration": 60,
      "path": [
        [
          2.3229614457982586,
          48.866557992949716
        ],
        [
          2.314464501322781,
          48.86765629219383
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463010",
      "to_stop_id": "IDFM:463181",
      "duration": 60,
      "path": [
        [
          2.314464501322781,
          48.86765629219383
        ],
        [
          2.309434325751582,
          48.86925069418826
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463011",
      "to_stop_id": "IDFM:22090",
      "duration": 117,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463011",
      "to_stop_id": "IDFM:22093",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463011",
      "to_stop_id": "IDFM:22185",
      "duration": 60,
      "path": [
        [
          2.3138736155952313,
          48.86749552078754
        ],
        [
          2.3146399951225347,
          48.86053445554123
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463011",
      "to_stop_id": "IDFM:463010",
      "duration": 148,
      "path": null,
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463011",
      "to_stop_id": "IDFM:474397",
      "duration": 60,
      "path": [
        [
          2.3159507090192264,
          48.87344757302508
        ],
        [
          2.3138736155952313,
          48.86749552078754
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71305",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:463012",
      "to_stop_id": "IDFM:22077",
      "duration": 0,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:463012",
      "to_stop_id": "IDFM:462943",
      "duration": 60,
      "path": [
        [
          2.4108049967015006,
          48.84700773108863
        ],
        [
          2.395825965451431,
          48.84816963759798
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463012",
      "to_stop_id": "IDFM:463040",
      "duration": 60,
      "path": [
        [
          2.4189897063438073,
          48.84622934735893
        ],
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71663",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:22086",
      "duration": 0,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:22088",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:22094",
      "duration": 62,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:22095",
      "duration": 68,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:463042",
      "duration": 45,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:463043",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463013",
      "to_stop_id": "IDFM:463185",
      "duration": 60,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:463015",
      "to_stop_id": "IDFM:22216",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:463015",
      "to_stop_id": "IDFM:463166",
      "duration": 60,
      "path": [
        [
          2.449424047013373,
          48.79661108096057
        ],
        [
          2.4459564771573397,
          48.80321905319987
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:463015",
      "to_stop_id": "IDFM:463225",
      "duration": 60,
      "path": [
        [
          2.4459564771573397,
          48.80321905319987
        ],
        [
          2.434924424365985,
          48.80897743416717
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70358",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:463016",
      "to_stop_id": "IDFM:22131",
      "duration": 60,
      "path": [
        [
          2.3288628540691043,
          48.84205232329236
        ],
        [
          2.330466993719873,
          48.83915635952557
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463016",
      "to_stop_id": "IDFM:22135",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:463016",
      "to_stop_id": "IDFM:22143",
      "duration": 0,
      "path": [
        [
          2.3243294607791096,
          48.84385251452581
        ],
        [
          2.3288628540691043,
          48.84205232329236
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463016",
      "to_stop_id": "IDFM:22172",
      "duration": 358,
      "path": null,
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:463016",
      "to_stop_id": "IDFM:463100",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71117",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:463017",
      "to_stop_id": "IDFM:22003",
      "duration": 60,
      "path": [
        [
          2.403114154894396,
          48.891495749437304
        ],
        [
          2.3920607143419303,
          48.888535337600175
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:463017",
      "to_stop_id": "IDFM:22017",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:463017",
      "to_stop_id": "IDFM:463178",
      "duration": 60,
      "path": [
        [
          2.413074999597678,
          48.89324469416795
        ],
        [
          2.403114154894396,
          48.891495749437304
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72004",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:22008",
      "duration": 60,
      "path": [
        [
          2.3701946685897517,
          48.85624405468632
        ],
        [
          2.3687984466220384,
          48.85330031648217
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:22010",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:22089",
      "duration": 100,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:22097",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:463000",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:463019",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463018",
      "to_stop_id": "IDFM:463289",
      "duration": 60,
      "path": [
        [
          2.3687984466220384,
          48.85330031648217
        ],
        [
          2.365884650750405,
          48.846427325479766
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:22010",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:22074",
      "duration": 120,
      "path": [
        [
          2.3608852562751967,
          48.85518742160445
        ],
        [
          2.368692796099789,
          48.85196422011205
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:22083",
      "duration": 120,
      "path": [
        [
          2.368692796099789,
          48.85196422011205
        ],
        [
          2.3731565937892047,
          48.8456832067358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:22089",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:22097",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:463000",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463019",
      "to_stop_id": "IDFM:463018",
      "duration": 100,
      "path": null,
      "from_stop_unique_id": "IDFM:463564",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463020",
      "to_stop_id": "IDFM:22006",
      "duration": 60,
      "path": [
        [
          2.35559694336975,
          48.83096622708413
        ],
        [
          2.3587419566993457,
          48.83554293374935
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463020",
      "to_stop_id": "IDFM:22009",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:463020",
      "to_stop_id": "IDFM:463097",
      "duration": 60,
      "path": [
        [
          2.3587419566993457,
          48.83554293374935
        ],
        [
          2.3608176665940044,
          48.83847698406796
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71063",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:22007",
      "duration": 120,
      "path": [
        [
          2.3572164888604563,
          48.87970181695894
        ],
        [
          2.357982972943156,
          48.876081616617256
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:22013",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:22148",
      "duration": 83,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:22387",
      "duration": 71,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:463001",
      "duration": 64,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:463022",
      "duration": 72,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463021",
      "to_stop_id": "IDFM:463191",
      "duration": 60,
      "path": [
        [
          2.357982972943156,
          48.876081616617256
        ],
        [
          2.361023902048923,
          48.870620926265296
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:22013",
      "duration": 99,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:22148",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:22153",
      "duration": 60,
      "path": [
        [
          2.3560517414015862,
          48.87244691759551
        ],
        [
          2.3578981321043053,
          48.876283511884786
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:22387",
      "duration": 71,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:463001",
      "duration": 100,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:463021",
      "duration": 84,
      "path": null,
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463022",
      "to_stop_id": "IDFM:463183",
      "duration": 60,
      "path": [
        [
          2.3578981321043053,
          48.876283511884786
        ],
        [
          2.356808788205409,
          48.87959170741013
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71359",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463023",
      "to_stop_id": "IDFM:21967",
      "duration": 129,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463023",
      "to_stop_id": "IDFM:21981",
      "duration": 20,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463023",
      "to_stop_id": "IDFM:22000",
      "duration": 60,
      "path": [
        [
          2.3654431802574227,
          48.842395852858516
        ],
        [
          2.3606397867113924,
          48.83853898898134
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:463023",
      "to_stop_id": "IDFM:463024",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463023",
      "to_stop_id": "IDFM:463289",
      "duration": 60,
      "path": [
        [
          2.365884650750405,
          48.846427325479766
        ],
        [
          2.3654431802574227,
          48.842395852858516
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:463024",
      "to_stop_id": "IDFM:21967",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463024",
      "to_stop_id": "IDFM:21968",
      "duration": 60,
      "path": [
        [
          2.3549316718232256,
          48.846197891637004
        ],
        [
          2.364395394600644,
          48.84228236697996
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463024",
      "to_stop_id": "IDFM:21981",
      "duration": 169,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463024",
      "to_stop_id": "IDFM:463023",
      "duration": 145,
      "path": null,
      "from_stop_unique_id": "IDFM:71135",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:22006",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:22009",
      "duration": 120,
      "path": [
        [
          2.358468834639247,
          48.835595431233266
        ],
        [
          2.355501591481485,
          48.830965713292656
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:22168",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:22365",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:463003",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463025",
      "to_stop_id": "IDFM:463026",
      "duration": 162,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:22006",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:22168",
      "duration": 102,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:22365",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:22370",
      "duration": 60,
      "path": [
        [
          2.357318439213192,
          48.82613686175372
        ],
        [
          2.355330902236442,
          48.83154938471729
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:463003",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:463025",
      "duration": 84,
      "path": null,
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463026",
      "to_stop_id": "IDFM:463216",
      "duration": 60,
      "path": [
        [
          2.355330902236442,
          48.83154938471729
        ],
        [
          2.3525311653622043,
          48.83589170669027
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71033",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:463027",
      "to_stop_id": "IDFM:22005",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:463027",
      "to_stop_id": "IDFM:22012",
      "duration": 60,
      "path": [
        [
          2.3793909893523923,
          48.88513384808583
        ],
        [
          2.386652016759884,
          48.88691595319499
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:463027",
      "to_stop_id": "IDFM:463280",
      "duration": 60,
      "path": [
        [
          2.386652016759884,
          48.88691595319499
        ],
        [
          2.3921229723281483,
          48.88845921134684
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71972",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:21919",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:22072",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:22073",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:22080",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:462942",
      "duration": 200,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:462943",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:463067",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463028",
      "to_stop_id": "IDFM:463098",
      "duration": 120,
      "path": [
        [
          2.4012745388223746,
          48.84510324750185
        ],
        [
          2.3957883190257903,
          48.84789064769285
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:463029",
      "to_stop_id": "IDFM:22372",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:463029",
      "to_stop_id": "IDFM:22397",
      "duration": 60,
      "path": [
        [
          2.369294978223312,
          48.821352988336876
        ],
        [
          2.377246886036494,
          48.815836500997094
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:463029",
      "to_stop_id": "IDFM:463030",
      "duration": 60,
      "path": [
        [
          2.3646785940494577,
          48.82005592914816
        ],
        [
          2.369294978223312,
          48.821352988336876
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70636",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:463030",
      "to_stop_id": "IDFM:22373",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:463030",
      "to_stop_id": "IDFM:22380",
      "duration": 60,
      "path": [
        [
          2.359462523882543,
          48.81903428608761
        ],
        [
          2.3646785940494577,
          48.82005592914816
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:463030",
      "to_stop_id": "IDFM:463029",
      "duration": 60,
      "path": [
        [
          2.3646785940494577,
          48.82005592914816
        ],
        [
          2.369294978223312,
          48.821352988336876
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70604",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:463031",
      "to_stop_id": "IDFM:22374",
      "duration": 60,
      "path": [
        [
          2.385869033143345,
          48.89780269235572
        ],
        [
          2.3922205712292195,
          48.90375706036556
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:463031",
      "to_stop_id": "IDFM:22394",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:463031",
      "to_stop_id": "IDFM:463179",
      "duration": 120,
      "path": [
        [
          2.3922205712292195,
          48.90375706036556
        ],
        [
          2.404187821795194,
          48.91470775289347
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72460",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:22001",
      "duration": 0,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:22007",
      "duration": 120,
      "path": [
        [
          2.367348121277192,
          48.8841445128949
        ],
        [
          2.3572164888604563,
          48.87970181695894
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:22107",
      "duration": 122,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:22368",
      "duration": 115,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:463008",
      "duration": 167,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:463009",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463032",
      "to_stop_id": "IDFM:463095",
      "duration": 60,
      "path": [
        [
          2.371383333112514,
          48.88311355416086
        ],
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71961",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463033",
      "to_stop_id": "IDFM:22367",
      "duration": 60,
      "path": [
        [
          2.3620179355106075,
          48.87844145573239
        ],
        [
          2.3650968074522214,
          48.880895011629946
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:474149"
    },
    {
      "from_stop_id": "IDFM:463033",
      "to_stop_id": "IDFM:22368",
      "duration": 60,
      "path": [
        [
          2.3650968074522214,
          48.880895011629946
        ],
        [
          2.368996070723285,
          48.88431508830977
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463033",
      "to_stop_id": "IDFM:22384",
      "duration": 84,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463033",
      "to_stop_id": "IDFM:24681",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463033",
      "to_stop_id": "IDFM:463034",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463034",
      "to_stop_id": "IDFM:22384",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463034",
      "to_stop_id": "IDFM:24681",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463034",
      "to_stop_id": "IDFM:463033",
      "duration": 70,
      "path": null,
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463034",
      "to_stop_id": "IDFM:463074",
      "duration": 60,
      "path": [
        [
          2.370465586581431,
          48.882326316477965
        ],
        [
          2.364365143550256,
          48.88118341152446
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71407",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463035",
      "to_stop_id": "IDFM:21939",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:463035",
      "to_stop_id": "IDFM:21940",
      "duration": 60,
      "path": [
        [
          2.24346821405889,
          48.83380810724753
        ],
        [
          2.237891101038026,
          48.83191997405451
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:463035",
      "to_stop_id": "IDFM:463233",
      "duration": 120,
      "path": [
        [
          2.237891101038026,
          48.83191997405451
        ],
        [
          2.2305052170885546,
          48.82966919074605
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71034",
      "to_stop_unique_id": "IDFM:70698"
    },
    {
      "from_stop_id": "IDFM:463036",
      "to_stop_id": "IDFM:21921",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463036",
      "to_stop_id": "IDFM:21932",
      "duration": 60,
      "path": [
        [
          2.3386163553228103,
          48.87223943327036
        ],
        [
          2.334000835220775,
          48.87288385004922
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463036",
      "to_stop_id": "IDFM:22196",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463036",
      "to_stop_id": "IDFM:463037",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463036",
      "to_stop_id": "IDFM:463049",
      "duration": 60,
      "path": [
        [
          2.3432202183423976,
          48.871558801223806
        ],
        [
          2.3386163553228103,
          48.87223943327036
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:21921",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:22024",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:22195",
      "duration": 60,
      "path": [
        [
          2.332135072925294,
          48.87059812248669
        ],
        [
          2.3384603609547776,
          48.87164051013642
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:22196",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:22197",
      "duration": 60,
      "path": [
        [
          2.3384603609547776,
          48.87164051013642
        ],
        [
          2.342839648636545,
          48.87146677844407
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:462989",
      "duration": 344,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463037",
      "to_stop_id": "IDFM:463036",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71330",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463038",
      "to_stop_id": "IDFM:21928",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:463038",
      "to_stop_id": "IDFM:463238",
      "duration": 60,
      "path": [
        [
          2.267933291647067,
          48.85243341872557
        ],
        [
          2.2638093220226634,
          48.84770893216831
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:463038",
      "to_stop_id": "IDFM:463290",
      "duration": 60,
      "path": [
        [
          2.269948157934635,
          48.85550355839564
        ],
        [
          2.267933291647067,
          48.85243341872557
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73657",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:463039",
      "to_stop_id": "IDFM:21919",
      "duration": 60,
      "path": [
        [
          2.3959437210498136,
          48.84888521841715
        ],
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463039",
      "to_stop_id": "IDFM:22124",
      "duration": 278,
      "path": null,
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:463039",
      "to_stop_id": "IDFM:25084",
      "duration": 0,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:463039",
      "to_stop_id": "IDFM:463125",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:463039",
      "to_stop_id": "IDFM:463215",
      "duration": 60,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ],
        [
          2.4060490065790447,
          48.85265013095636
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:479928",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:463040",
      "to_stop_id": "IDFM:22103",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:463040",
      "to_stop_id": "IDFM:463012",
      "duration": 60,
      "path": [
        [
          2.4189897063438073,
          48.84622934735893
        ],
        [
          2.4108049967015006,
          48.84700773108863
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71663"
    },
    {
      "from_stop_id": "IDFM:463040",
      "to_stop_id": "IDFM:463130",
      "duration": 60,
      "path": [
        [
          2.4292278831660283,
          48.845491696267075
        ],
        [
          2.4189897063438073,
          48.84622934735893
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71647",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:463041",
      "to_stop_id": "IDFM:22075",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:463041",
      "to_stop_id": "IDFM:22079",
      "duration": 60,
      "path": [
        [
          2.3296780947116416,
          48.864477839314645
        ],
        [
          2.3364543677542517,
          48.86222226557415
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463041",
      "to_stop_id": "IDFM:22085",
      "duration": 60,
      "path": [
        [
          2.3228909937191604,
          48.866472156532026
        ],
        [
          2.3296780947116416,
          48.864477839314645
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71284",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:22086",
      "duration": 74,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:22094",
      "duration": 73,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:22095",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:22170",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:463013",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:463043",
      "duration": 59,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463042",
      "to_stop_id": "IDFM:463102",
      "duration": 120,
      "path": [
        [
          2.2933259179892325,
          48.871301612039105
        ],
        [
          2.2947520493673434,
          48.87371127132366
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:22086",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:22094",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:22095",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:22108",
      "duration": 60,
      "path": [
        [
          2.295927507409278,
          48.87494579313413
        ],
        [
          2.2981136521983068,
          48.8782007528283
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:22109",
      "duration": 60,
      "path": [
        [
          2.2859020006220643,
          48.8698545716049
        ],
        [
          2.295927507409278,
          48.87494579313413
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:463013",
      "duration": 60,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463043",
      "to_stop_id": "IDFM:463042",
      "duration": 206,
      "path": null,
      "from_stop_unique_id": "IDFM:71347",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463044",
      "to_stop_id": "IDFM:22099",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:463044",
      "to_stop_id": "IDFM:22100",
      "duration": 60,
      "path": [
        [
          2.2585225729481575,
          48.8854985504452
        ],
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:463044",
      "to_stop_id": "IDFM:463170",
      "duration": 60,
      "path": [
        [
          2.249931086743073,
          48.88834989394862
        ],
        [
          2.2585225729481575,
          48.8854985504452
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71442",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:463045",
      "to_stop_id": "IDFM:21954",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:463045",
      "to_stop_id": "IDFM:463112",
      "duration": 60,
      "path": [
        [
          2.367034270941924,
          48.826944155043044
        ],
        [
          2.376487371168301,
          48.829925765928905
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:463045",
      "to_stop_id": "IDFM:490848",
      "duration": 60,
      "path": [
        [
          2.358986576752596,
          48.82119024728473
        ],
        [
          2.367034270941924,
          48.826944155043044
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71557",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463046",
      "to_stop_id": "IDFM:21958",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463046",
      "to_stop_id": "IDFM:21959",
      "duration": 180,
      "path": [
        [
          2.346275248188987,
          48.85951531585984
        ],
        [
          2.373100581933962,
          48.84467563174822
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463046",
      "to_stop_id": "IDFM:22083",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463046",
      "to_stop_id": "IDFM:463129",
      "duration": 60,
      "path": [
        [
          2.373100581933962,
          48.84467563174822
        ],
        [
          2.3790620152277233,
          48.84010199803391
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463046",
      "to_stop_id": "IDFM:463197",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463047",
      "to_stop_id": "IDFM:22214",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:463047",
      "to_stop_id": "IDFM:463142",
      "duration": 60,
      "path": [
        [
          2.422399525054415,
          48.81483550210013
        ],
        [
          2.413844709558796,
          48.82148073316996
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:463047",
      "to_stop_id": "IDFM:463225",
      "duration": 60,
      "path": [
        [
          2.434924424365985,
          48.80897743416717
        ],
        [
          2.422399525054415,
          48.81483550210013
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70522",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:21905",
      "duration": 282,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:21925",
      "duration": 285,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:22200",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:22201",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:462962",
      "duration": 60,
      "path": [
        [
          2.363149406900382,
          48.86742161002706
        ],
        [
          2.3665956945709348,
          48.8630421114174
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:463054",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:463059",
      "duration": 294,
      "path": null,
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463048",
      "to_stop_id": "IDFM:463309",
      "duration": 60,
      "path": [
        [
          2.3665956945709348,
          48.8630421114174
        ],
        [
          2.3671254626151184,
          48.86094943927989
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71277",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:463049",
      "to_stop_id": "IDFM:21909",
      "duration": 21,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463049",
      "to_stop_id": "IDFM:21933",
      "duration": 60,
      "path": [
        [
          2.3484940979375373,
          48.8706433160169
        ],
        [
          2.3432202183423976,
          48.871558801223806
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463049",
      "to_stop_id": "IDFM:22197",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463049",
      "to_stop_id": "IDFM:463036",
      "duration": 60,
      "path": [
        [
          2.3432202183423976,
          48.871558801223806
        ],
        [
          2.3386163553228103,
          48.87223943327036
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463049",
      "to_stop_id": "IDFM:463058",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463050",
      "to_stop_id": "IDFM:22207",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:463050",
      "to_stop_id": "IDFM:22208",
      "duration": 60,
      "path": [
        [
          2.402511872850073,
          48.83344922287947
        ],
        [
          2.4058739129875644,
          48.83501737409391
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:463050",
      "to_stop_id": "IDFM:463226",
      "duration": 60,
      "path": [
        [
          2.4058739129875644,
          48.83501737409391
        ],
        [
          2.402366739988004,
          48.83707743103578
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71591",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:463051",
      "to_stop_id": "IDFM:22186",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71073",
      "to_stop_unique_id": "IDFM:71073"
    },
    {
      "from_stop_id": "IDFM:463051",
      "to_stop_id": "IDFM:463211",
      "duration": 120,
      "path": [
        [
          2.2820358715985742,
          48.838785539019476
        ],
        [
          2.2783626618092008,
          48.83666789483108
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71073",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:463052",
      "to_stop_id": "IDFM:21927",
      "duration": 60,
      "path": [
        [
          2.293906396859107,
          48.864685839288676
        ],
        [
          2.3011043523926706,
          48.86464714790582
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:463052",
      "to_stop_id": "IDFM:21935",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:463052",
      "to_stop_id": "IDFM:463180",
      "duration": 60,
      "path": [
        [
          2.3011043523926706,
          48.86464714790582
        ],
        [
          2.3095735420193506,
          48.86866242617973
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71290",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463053",
      "to_stop_id": "IDFM:21913",
      "duration": 60,
      "path": [
        [
          2.4229449336324724,
          48.855754613895044
        ],
        [
          2.4107036110235414,
          48.853563500943025
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:463053",
      "to_stop_id": "IDFM:21942",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:463053",
      "to_stop_id": "IDFM:463176",
      "duration": 60,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4229449336324724,
          48.855754613895044
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71725",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:21905",
      "duration": 103,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:21925",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:22200",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:462963",
      "duration": 60,
      "path": [
        [
          2.3682236708807456,
          48.86487188759515
        ],
        [
          2.3633023407804536,
          48.86751235781114
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:463048",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:463059",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463054",
      "to_stop_id": "IDFM:463299",
      "duration": 60,
      "path": [
        [
          2.3739964519221886,
          48.861480218467655
        ],
        [
          2.3682236708807456,
          48.86487188759515
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:463055",
      "to_stop_id": "IDFM:21908",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:463055",
      "to_stop_id": "IDFM:21929",
      "duration": 60,
      "path": [
        [
          2.277916063289398,
          48.86403732937509
        ],
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:463055",
      "to_stop_id": "IDFM:463318",
      "duration": 60,
      "path": [
        [
          2.2868262498013006,
          48.86308797989344
        ],
        [
          2.277916063289398,
          48.86403732937509
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71292",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463056",
      "to_stop_id": "IDFM:21784",
      "duration": 120,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ],
        [
          2.464290193819998,
          48.768752248609914
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:69884"
    },
    {
      "from_stop_id": "IDFM:463056",
      "to_stop_id": "IDFM:22212",
      "duration": 120,
      "path": [
        [
          2.450529196348293,
          48.789915686006935
        ],
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:463056",
      "to_stop_id": "IDFM:22213",
      "duration": 0,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70021",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:463057",
      "to_stop_id": "IDFM:21784",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:69884",
      "to_stop_unique_id": "IDFM:69884"
    },
    {
      "from_stop_id": "IDFM:463057",
      "to_stop_id": "IDFM:22213",
      "duration": 120,
      "path": [
        [
          2.464317312722042,
          48.76876136434425
        ],
        [
          2.4593051692912615,
          48.77975652677895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:69884",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:463058",
      "to_stop_id": "IDFM:21909",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463058",
      "to_stop_id": "IDFM:22196",
      "duration": 60,
      "path": [
        [
          2.3428924760133967,
          48.871601969451355
        ],
        [
          2.3384190255427955,
          48.87173920910264
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463058",
      "to_stop_id": "IDFM:22197",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463058",
      "to_stop_id": "IDFM:22198",
      "duration": 60,
      "path": [
        [
          2.3485760027859746,
          48.8706347683979
        ],
        [
          2.3428924760133967,
          48.871601969451355
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463058",
      "to_stop_id": "IDFM:463049",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71326",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:21905",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:21925",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:22200",
      "duration": 275,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:462961",
      "duration": 60,
      "path": [
        [
          2.3686045625807752,
          48.86464456516527
        ],
        [
          2.3641925544688656,
          48.867732931981166
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:463048",
      "duration": 294,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:463054",
      "duration": 110,
      "path": null,
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463059",
      "to_stop_id": "IDFM:463096",
      "duration": 60,
      "path": [
        [
          2.3719501400872653,
          48.85985966744055
        ],
        [
          2.3686045625807752,
          48.86464456516527
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71803",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:21959",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:21966",
      "duration": 250,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:21990",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22087",
      "duration": 309,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22091",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22092",
      "duration": 257,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22150",
      "duration": 285,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22364",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22378",
      "duration": 60,
      "path": [
        [
          2.348102094822081,
          48.85686770157052
        ],
        [
          2.357349968447059,
          48.85341474936241
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:22379",
      "duration": 60,
      "path": [
        [
          2.3417089232034765,
          48.858456031111984
        ],
        [
          2.348102094822081,
          48.85686770157052
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463061",
      "duration": 326,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463079",
      "duration": 126,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463080",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463158",
      "duration": 271,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463159",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463160",
      "duration": 293,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463060",
      "to_stop_id": "IDFM:463161",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:21966",
      "duration": 60,
      "path": [
        [
          2.3514239919428728,
          48.85760073633446
        ],
        [
          2.3478374055687867,
          48.85776111354502
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:21990",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:21992",
      "duration": 60,
      "path": [
        [
          2.3532739485947713,
          48.86119015695036
        ],
        [
          2.3514239919428728,
          48.85760073633446
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:22091",
      "duration": 175,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:22364",
      "duration": 317,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:463060",
      "duration": 326,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:463079",
      "duration": 341,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463061",
      "to_stop_id": "IDFM:463080",
      "duration": 192,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:22044",
      "duration": 60,
      "path": [
        [
          2.3379007718233322,
          48.87604417483004
        ],
        [
          2.3375706403796697,
          48.87841659490173
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:22046",
      "duration": 60,
      "path": [
        [
          2.333030634158166,
          48.87625096700832
        ],
        [
          2.3379007718233322,
          48.87604417483004
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:22050",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:22382",
      "duration": 259,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:463206",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:463062",
      "to_stop_id": "IDFM:463304",
      "duration": 330,
      "path": null,
      "from_stop_unique_id": "IDFM:71350",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:21961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:21965",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:22194",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:463064",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:463081",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:463151",
      "duration": 60,
      "path": [
        [
          2.3259702743206723,
          48.8696462363775
        ],
        [
          2.321915152849789,
          48.86656106315263
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463063",
      "to_stop_id": "IDFM:463244",
      "duration": 60,
      "path": [
        [
          2.3320941299398554,
          48.87066534465599
        ],
        [
          2.3259702743206723,
          48.8696462363775
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:21961",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:21962",
      "duration": 60,
      "path": [
        [
          2.3258757399808627,
          48.870630484228144
        ],
        [
          2.324122029700433,
          48.87560743718584
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:21965",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:22194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:463063",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:463081",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463064",
      "to_stop_id": "IDFM:463284",
      "duration": 60,
      "path": [
        [
          2.3347388358754153,
          48.86580563193237
        ],
        [
          2.3258757399808627,
          48.870630484228144
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463065",
      "to_stop_id": "IDFM:22052",
      "duration": 60,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ],
        [
          2.3499794329782024,
          48.89145292865467
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:463065",
      "to_stop_id": "IDFM:22054",
      "duration": 0,
      "path": [
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:463065",
      "to_stop_id": "IDFM:22133",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:463065",
      "to_stop_id": "IDFM:463205",
      "duration": 60,
      "path": [
        [
          2.3385838243831936,
          48.88968180996681
        ],
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:463065",
      "to_stop_id": "IDFM:463310",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71521",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:463066",
      "to_stop_id": "IDFM:22225",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:463066",
      "to_stop_id": "IDFM:462970",
      "duration": 60,
      "path": [
        [
          2.3269450274387555,
          48.87971082693934
        ],
        [
          2.3254668925871607,
          48.875462135328796
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463066",
      "to_stop_id": "IDFM:463253",
      "duration": 60,
      "path": [
        [
          2.327268983025867,
          48.883723673452664
        ],
        [
          2.3269450274387555,
          48.87971082693934
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71382",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:21919",
      "duration": 138,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:22072",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:22073",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:22080",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:462942",
      "duration": 122,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:462943",
      "duration": 74,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:463028",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463067",
      "to_stop_id": "IDFM:463125",
      "duration": 120,
      "path": [
        [
          2.3980666384995177,
          48.85161645805963
        ],
        [
          2.395925559927623,
          48.848453436979646
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71673",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:463068",
      "to_stop_id": "IDFM:22031",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:463068",
      "to_stop_id": "IDFM:22117",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:463068",
      "to_stop_id": "IDFM:462987",
      "duration": 60,
      "path": [
        [
          2.3157901685080926,
          48.881153980760416
        ],
        [
          2.309487846960011,
          48.88284002257779
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:463068",
      "to_stop_id": "IDFM:463070",
      "duration": 60,
      "path": [
        [
          2.309487846960011,
          48.88284002257779
        ],
        [
          2.304673087584434,
          48.8838463907272
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:463068",
      "to_stop_id": "IDFM:463240",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:71416",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:463069",
      "to_stop_id": "IDFM:22027",
      "duration": 60,
      "path": [
        [
          2.315815092646378,
          48.88107318307563
        ],
        [
          2.32218591952051,
          48.87875316404477
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:463069",
      "to_stop_id": "IDFM:22032",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:73617"
    },
    {
      "from_stop_id": "IDFM:463069",
      "to_stop_id": "IDFM:462971",
      "duration": 60,
      "path": [
        [
          2.32218591952051,
          48.87875316404477
        ],
        [
          2.3244924701707954,
          48.87496650103443
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73617",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463070",
      "to_stop_id": "IDFM:22028",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:463070",
      "to_stop_id": "IDFM:463068",
      "duration": 60,
      "path": [
        [
          2.309487846960011,
          48.88284002257779
        ],
        [
          2.304673087584434,
          48.8838463907272
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:463070",
      "to_stop_id": "IDFM:463228",
      "duration": 60,
      "path": [
        [
          2.304673087584434,
          48.8838463907272
        ],
        [
          2.297701123838181,
          48.884929864637186
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71423",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:463071",
      "to_stop_id": "IDFM:21946",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:463071",
      "to_stop_id": "IDFM:462990",
      "duration": 60,
      "path": [
        [
          2.404361344448241,
          48.871887658077014
        ],
        [
          2.4014967922879253,
          48.86846488701483
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71828"
    },
    {
      "from_stop_id": "IDFM:463071",
      "to_stop_id": "IDFM:463262",
      "duration": 60,
      "path": [
        [
          2.4069512666209,
          48.87663999703494
        ],
        [
          2.404361344448241,
          48.871887658077014
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71860",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463072",
      "to_stop_id": "IDFM:22018",
      "duration": 103,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463072",
      "to_stop_id": "IDFM:22142",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463072",
      "to_stop_id": "IDFM:463091",
      "duration": 144,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463072",
      "to_stop_id": "IDFM:463171",
      "duration": 60,
      "path": [
        [
          2.352381731140252,
          48.866077771215046
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:463072",
      "to_stop_id": "IDFM:463313",
      "duration": 60,
      "path": [
        [
          2.3543411093711257,
          48.86966773787259
        ],
        [
          2.352381731140252,
          48.866077771215046
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463073",
      "to_stop_id": "IDFM:22133",
      "duration": 0,
      "path": [
        [
          2.344624099102791,
          48.897525631278015
        ],
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:478883",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:463073",
      "to_stop_id": "IDFM:22141",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:478883",
      "to_stop_unique_id": "IDFM:478883"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:21952",
      "duration": 169,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:22118",
      "duration": 156,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:22368",
      "duration": 313,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:24680",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:462981",
      "duration": 156,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:463008",
      "duration": 324,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:463034",
      "duration": 60,
      "path": [
        [
          2.370465586581431,
          48.882326316477965
        ],
        [
          2.364365143550256,
          48.88118341152446
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71407"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:463095",
      "duration": 174,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463074",
      "to_stop_id": "IDFM:463132",
      "duration": 60,
      "path": [
        [
          2.374152140698752,
          48.880789805531926
        ],
        [
          2.370465586581431,
          48.882326316477965
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:463075",
      "to_stop_id": "IDFM:22182",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:463075",
      "to_stop_id": "IDFM:463128",
      "duration": 60,
      "path": [
        [
          2.3895997700798097,
          48.83903652832887
        ],
        [
          2.3794765872566757,
          48.84018509185968
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463075",
      "to_stop_id": "IDFM:463169",
      "duration": 60,
      "path": [
        [
          2.3961460679154514,
          48.839537421644565
        ],
        [
          2.3895997700798097,
          48.83903652832887
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71606",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463076",
      "to_stop_id": "IDFM:21972",
      "duration": 60,
      "path": [
        [
          2.2856210133667174,
          48.84660453239739
        ],
        [
          2.278009496527359,
          48.84618110622323
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71150"
    },
    {
      "from_stop_id": "IDFM:463076",
      "to_stop_id": "IDFM:21986",
      "duration": 34,
      "path": null,
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:463076",
      "to_stop_id": "IDFM:463124",
      "duration": 60,
      "path": [
        [
          2.2950189971435506,
          48.84703801370517
        ],
        [
          2.2856210133667174,
          48.84660453239739
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71156",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:21967",
      "duration": 120,
      "path": [
        [
          2.3645307640842486,
          48.842355035073275
        ],
        [
          2.354944184375758,
          48.8462878950311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:21968",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:21984",
      "duration": 342,
      "path": [
        [
          2.354944184375758,
          48.8462878950311
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:22386",
      "duration": 85,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:463078",
      "duration": 76,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463077",
      "to_stop_id": "IDFM:463141",
      "duration": 60,
      "path": [
        [
          2.354944184375758,
          48.8462878950311
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:21968",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:21984",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:22369",
      "duration": 60,
      "path": [
        [
          2.3617517381263218,
          48.85120791254347
        ],
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:22386",
      "duration": 0,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:463077",
      "duration": 76,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:463106",
      "duration": 60,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ],
        [
          2.3520055901754713,
          48.842683564383904
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:463078",
      "to_stop_id": "IDFM:463141",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71148",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:21959",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:21966",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:21990",
      "duration": 60,
      "path": [
        [
          2.347759259772123,
          48.8576887395772
        ],
        [
          2.35154698561547,
          48.85757442237991
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:22087",
      "duration": 240,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:22092",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:22364",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:463060",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:463061",
      "duration": 341,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:463158",
      "duration": 400,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:463159",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463079",
      "to_stop_id": "IDFM:463160",
      "duration": 240,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:21990",
      "duration": 172,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:22074",
      "duration": 60,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ],
        [
          2.3608852562751967,
          48.85518742160445
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:22087",
      "duration": 60,
      "path": [
        [
          2.3457609541847755,
          48.85922471342816
        ],
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:22091",
      "duration": 0,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:22364",
      "duration": 368,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:463060",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463080",
      "to_stop_id": "IDFM:463061",
      "duration": 194,
      "path": null,
      "from_stop_unique_id": "IDFM:415852",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:21961",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:21965",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:22057",
      "duration": 60,
      "path": [
        [
          2.323181449571859,
          48.86615003482594
        ],
        [
          2.325169929034193,
          48.87003294090397
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:22194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:462969",
      "duration": 60,
      "path": [
        [
          2.325169929034193,
          48.87003294090397
        ],
        [
          2.327470237303278,
          48.875594817796994
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:463063",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463081",
      "to_stop_id": "IDFM:463064",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71324",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463082",
      "to_stop_id": "IDFM:22041",
      "duration": 120,
      "path": [
        [
          2.359248542310361,
          48.89740242267042
        ],
        [
          2.3657920239894126,
          48.90666420278779
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:463082",
      "to_stop_id": "IDFM:24679",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:463082",
      "to_stop_id": "IDFM:483900",
      "duration": 60,
      "path": [
        [
          2.3657920239894126,
          48.90666420278779
        ],
        [
          2.37813084516706,
          48.90843758066954
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:74041",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:21970",
      "duration": 152,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:22229",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:462954",
      "duration": 60,
      "path": [
        [
          2.316535081176305,
          48.84699198555429
        ],
        [
          2.3208534383022275,
          48.84228592360469
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:463084",
      "duration": 151,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:463173",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:463083",
      "to_stop_id": "IDFM:463303",
      "duration": 60,
      "path": [
        [
          2.3142859461805556,
          48.85129155093847
        ],
        [
          2.316535081176305,
          48.84699198555429
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:21970",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:21977",
      "duration": 60,
      "path": [
        [
          2.3162843486517035,
          48.84668027285166
        ],
        [
          2.321227717195941,
          48.84870050683084
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71170"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:22056",
      "duration": 354,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:22229",
      "duration": 166,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:463083",
      "duration": 157,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:463173",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:463084",
      "to_stop_id": "IDFM:463315",
      "duration": 60,
      "path": [
        [
          2.307137849403385,
          48.847167269995026
        ],
        [
          2.3162843486517035,
          48.84668027285166
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71159",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:463085",
      "to_stop_id": "IDFM:22222",
      "duration": 60,
      "path": [
        [
          2.313860489344306,
          48.83175036306747
        ],
        [
          2.318393706614701,
          48.834078679774976
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:463085",
      "to_stop_id": "IDFM:22223",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:463085",
      "to_stop_id": "IDFM:463260",
      "duration": 60,
      "path": [
        [
          2.3053323866574735,
          48.827613464004294
        ],
        [
          2.313860489344306,
          48.83175036306747
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71039",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:463086",
      "to_stop_id": "IDFM:22238",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:463086",
      "to_stop_id": "IDFM:462953",
      "duration": 120,
      "path": [
        [
          2.29460408001733,
          48.916347418146714
        ],
        [
          2.305726300569584,
          48.90344880444455
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:463086",
      "to_stop_id": "IDFM:463278",
      "duration": 60,
      "path": [
        [
          2.305726300569584,
          48.90344880444455
        ],
        [
          2.31320835075881,
          48.89443128801746
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72118",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:463087",
      "to_stop_id": "IDFM:22236",
      "duration": 60,
      "path": [
        [
          2.296972463702674,
          48.815265523780766
        ],
        [
          2.301595459041787,
          48.81072820779425
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:461505",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:463087",
      "to_stop_id": "IDFM:22237",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:461505",
      "to_stop_unique_id": "IDFM:461505"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:22040",
      "duration": 108,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:22059",
      "duration": 309,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:22114",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:22125",
      "duration": 60,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ],
        [
          2.3382179854559224,
          48.88219737464818
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:463089",
      "duration": 124,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:463116",
      "duration": 311,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463088",
      "to_stop_id": "IDFM:463120",
      "duration": 60,
      "path": [
        [
          2.3382179854559224,
          48.88219737464818
        ],
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:22040",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:22059",
      "duration": 351,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:22114",
      "duration": 129,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:463088",
      "duration": 122,
      "path": null,
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:463116",
      "duration": 60,
      "path": [
        [
          2.338394635220906,
          48.884392717991425
        ],
        [
          2.337211164701128,
          48.88202093206733
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463089",
      "to_stop_id": "IDFM:463304",
      "duration": 60,
      "path": [
        [
          2.337211164701128,
          48.88202093206733
        ],
        [
          2.3374546190400913,
          48.87836199275949
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71409",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:463090",
      "to_stop_id": "IDFM:22024",
      "duration": 60,
      "path": [
        [
          2.3362786423248583,
          48.86959592111917
        ],
        [
          2.3406519668083314,
          48.868738736969725
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463090",
      "to_stop_id": "IDFM:22033",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:73635"
    },
    {
      "from_stop_id": "IDFM:463090",
      "to_stop_id": "IDFM:462986",
      "duration": 60,
      "path": [
        [
          2.3406519668083314,
          48.868738736969725
        ],
        [
          2.3475137108219895,
          48.86728343166051
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73635",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:463091",
      "to_stop_id": "IDFM:21994",
      "duration": 60,
      "path": [
        [
          2.3520239377964742,
          48.86628268363084
        ],
        [
          2.3567233472754983,
          48.86553459885931
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463091",
      "to_stop_id": "IDFM:22018",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463091",
      "to_stop_id": "IDFM:22142",
      "duration": 79,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463091",
      "to_stop_id": "IDFM:462986",
      "duration": 60,
      "path": [
        [
          2.3475137108219895,
          48.86728343166051
        ],
        [
          2.3520239377964742,
          48.86628268363084
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73634"
    },
    {
      "from_stop_id": "IDFM:463091",
      "to_stop_id": "IDFM:463072",
      "duration": 137,
      "path": null,
      "from_stop_unique_id": "IDFM:73636",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463092",
      "to_stop_id": "IDFM:22140",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:463092",
      "to_stop_id": "IDFM:463118",
      "duration": 60,
      "path": [
        [
          2.325623760353114,
          48.823394602620716
        ],
        [
          2.3271926250651647,
          48.828152157703514
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:463092",
      "to_stop_id": "IDFM:463222",
      "duration": 60,
      "path": [
        [
          2.3196193991237846,
          48.818454456190004
        ],
        [
          2.325623760353114,
          48.823394602620716
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70657",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:463093",
      "to_stop_id": "IDFM:22127",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463093",
      "to_stop_id": "IDFM:22130",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463093",
      "to_stop_id": "IDFM:22152",
      "duration": 0,
      "path": [
        [
          2.3497455480753806,
          48.88342517173516
        ],
        [
          2.3495819680256593,
          48.887241930648955
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:463093",
      "to_stop_id": "IDFM:463094",
      "duration": 111,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463093",
      "to_stop_id": "IDFM:463183",
      "duration": 60,
      "path": [
        [
          2.356808788205409,
          48.87959170741013
        ],
        [
          2.3497455480753806,
          48.88342517173516
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463094",
      "to_stop_id": "IDFM:22123",
      "duration": 60,
      "path": [
        [
          2.350573400888514,
          48.883852349861876
        ],
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:463094",
      "to_stop_id": "IDFM:22127",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463094",
      "to_stop_id": "IDFM:22130",
      "duration": 104,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463094",
      "to_stop_id": "IDFM:462982",
      "duration": 60,
      "path": [
        [
          2.360430336162137,
          48.88447648340964
        ],
        [
          2.350573400888514,
          48.883852349861876
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463094",
      "to_stop_id": "IDFM:463093",
      "duration": 91,
      "path": null,
      "from_stop_unique_id": "IDFM:71426",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:21952",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:22118",
      "duration": 247,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:22368",
      "duration": 280,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:24680",
      "duration": 166,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:462981",
      "duration": 247,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:462999",
      "duration": 60,
      "path": [
        [
          2.3793117790180993,
          48.88520088689033
        ],
        [
          2.371383333112514,
          48.88311355416086
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71957"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:463008",
      "duration": 292,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:463032",
      "duration": 60,
      "path": [
        [
          2.371383333112514,
          48.88311355416086
        ],
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463095",
      "to_stop_id": "IDFM:463074",
      "duration": 174,
      "path": null,
      "from_stop_unique_id": "IDFM:71940",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463096",
      "to_stop_id": "IDFM:21910",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:463096",
      "to_stop_id": "IDFM:22002",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:463096",
      "to_stop_id": "IDFM:463059",
      "duration": 60,
      "path": [
        [
          2.3719501400872653,
          48.85985966744055
        ],
        [
          2.3686045625807752,
          48.86464456516527
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463096",
      "to_stop_id": "IDFM:463138",
      "duration": 60,
      "path": [
        [
          2.3703346849773035,
          48.8562178110506
        ],
        [
          2.3719501400872653,
          48.85985966744055
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:463096",
      "to_stop_id": "IDFM:463299",
      "duration": 298,
      "path": null,
      "from_stop_unique_id": "IDFM:73648",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:463097",
      "to_stop_id": "IDFM:21981",
      "duration": 60,
      "path": [
        [
          2.3608176665940044,
          48.83847698406796
        ],
        [
          2.3656078807625422,
          48.84229779662816
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463097",
      "to_stop_id": "IDFM:22000",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71092"
    },
    {
      "from_stop_id": "IDFM:463097",
      "to_stop_id": "IDFM:463020",
      "duration": 60,
      "path": [
        [
          2.3587419566993457,
          48.83554293374935
        ],
        [
          2.3608176665940044,
          48.83847698406796
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71092",
      "to_stop_unique_id": "IDFM:71063"
    },
    {
      "from_stop_id": "IDFM:463098",
      "to_stop_id": "IDFM:22167",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:463098",
      "to_stop_id": "IDFM:463028",
      "duration": 120,
      "path": [
        [
          2.4012745388223746,
          48.84510324750185
        ],
        [
          2.3957883190257903,
          48.84789064769285
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463098",
      "to_stop_id": "IDFM:463127",
      "duration": 60,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ],
        [
          2.4012745388223746,
          48.84510324750185
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71639",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:463099",
      "to_stop_id": "IDFM:22162",
      "duration": 60,
      "path": [
        [
          2.2936368390558783,
          48.85038448621434
        ],
        [
          2.298906224573169,
          48.848697697821954
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463099",
      "to_stop_id": "IDFM:22171",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:463099",
      "to_stop_id": "IDFM:22179",
      "duration": 60,
      "path": [
        [
          2.2892963570634604,
          48.85388427726308
        ],
        [
          2.2936368390558783,
          48.85038448621434
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71197",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:463100",
      "to_stop_id": "IDFM:22156",
      "duration": 60,
      "path": [
        [
          2.321273396249205,
          48.84205447194666
        ],
        [
          2.3252865779432965,
          48.84090350169825
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463100",
      "to_stop_id": "IDFM:22172",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:463100",
      "to_stop_id": "IDFM:463016",
      "duration": 370,
      "path": null,
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:463100",
      "to_stop_id": "IDFM:463292",
      "duration": 60,
      "path": [
        [
          2.3252865779432965,
          48.84090350169825
        ],
        [
          2.3304144712354793,
          48.8390661288004
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71108",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463101",
      "to_stop_id": "IDFM:22165",
      "duration": 24,
      "path": null,
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:463101",
      "to_stop_id": "IDFM:22179",
      "duration": 60,
      "path": [
        [
          2.2855802300954733,
          48.8575315296204
        ],
        [
          2.2892963570634604,
          48.85388427726308
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:473829"
    },
    {
      "from_stop_id": "IDFM:463101",
      "to_stop_id": "IDFM:463268",
      "duration": 60,
      "path": [
        [
          2.2874927969664416,
          48.86348766501957
        ],
        [
          2.2855802300954733,
          48.8575315296204
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71237",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463102",
      "to_stop_id": "IDFM:22170",
      "duration": 31,
      "path": null,
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:463102",
      "to_stop_id": "IDFM:463042",
      "duration": 120,
      "path": [
        [
          2.2933259179892325,
          48.871301612039105
        ],
        [
          2.2947520493673434,
          48.87371127132366
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463102",
      "to_stop_id": "IDFM:463114",
      "duration": 60,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ],
        [
          2.2933259179892325,
          48.871301612039105
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73656",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:463103",
      "to_stop_id": "IDFM:22151",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463103",
      "to_stop_id": "IDFM:22158",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463103",
      "to_stop_id": "IDFM:463104",
      "duration": 85,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463103",
      "to_stop_id": "IDFM:463292",
      "duration": 60,
      "path": [
        [
          2.3304144712354793,
          48.8390661288004
        ],
        [
          2.332803776289825,
          48.83424986732888
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463103",
      "to_stop_id": "IDFM:463305",
      "duration": 60,
      "path": [
        [
          2.332803776289825,
          48.83424986732888
        ],
        [
          2.3370965389054956,
          48.832857226540206
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:463104",
      "to_stop_id": "IDFM:22131",
      "duration": 60,
      "path": [
        [
          2.330466993719873,
          48.83915635952557
        ],
        [
          2.332018835216387,
          48.83394869369227
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463104",
      "to_stop_id": "IDFM:22144",
      "duration": 0,
      "path": [
        [
          2.332018835216387,
          48.83394869369227
        ],
        [
          2.32979034782274,
          48.831382009093616
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:463104",
      "to_stop_id": "IDFM:22151",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463104",
      "to_stop_id": "IDFM:22158",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463104",
      "to_stop_id": "IDFM:463103",
      "duration": 84,
      "path": null,
      "from_stop_unique_id": "IDFM:71056",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463105",
      "to_stop_id": "IDFM:22168",
      "duration": 60,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ],
        [
          2.356101612721528,
          48.830914982874944
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463105",
      "to_stop_id": "IDFM:22175",
      "duration": 0,
      "path": [
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:463105",
      "to_stop_id": "IDFM:463186",
      "duration": 60,
      "path": [
        [
          2.343438236678704,
          48.831115947616446
        ],
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71013",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:463106",
      "to_stop_id": "IDFM:22366",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:463106",
      "to_stop_id": "IDFM:22391",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:463106",
      "to_stop_id": "IDFM:463078",
      "duration": 60,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ],
        [
          2.3520055901754713,
          48.842683564383904
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463106",
      "to_stop_id": "IDFM:463157",
      "duration": 60,
      "path": [
        [
          2.3520055901754713,
          48.842683564383904
        ],
        [
          2.351495277596339,
          48.84026600820119
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71133",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:463107",
      "to_stop_id": "IDFM:22385",
      "duration": 120,
      "path": [
        [
          2.3622716933808072,
          48.81028030813587
        ],
        [
          2.3585387043161337,
          48.82216816831483
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463107",
      "to_stop_id": "IDFM:22398",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:463107",
      "to_stop_id": "IDFM:463323",
      "duration": 60,
      "path": [
        [
          2.364068547658874,
          48.80436739901952
        ],
        [
          2.3622716933808072,
          48.81028030813587
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70452",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:21937",
      "duration": 60,
      "path": [
        [
          2.364804805140413,
          48.86794303120089
        ],
        [
          2.356535223129095,
          48.865380699169286
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:21992",
      "duration": 60,
      "path": [
        [
          2.356535223129095,
          48.865380699169286
        ],
        [
          2.3532739485947713,
          48.86119015695036
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71273"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:21994",
      "duration": 118,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:21997",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:22142",
      "duration": 377,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463108",
      "to_stop_id": "IDFM:463122",
      "duration": 99,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463109",
      "to_stop_id": "IDFM:22233",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:72286",
      "to_stop_unique_id": "IDFM:72286"
    },
    {
      "from_stop_id": "IDFM:463109",
      "to_stop_id": "IDFM:463207",
      "duration": 60,
      "path": [
        [
          2.284216775386713,
          48.930544259451466
        ],
        [
          2.28615583314788,
          48.923055662740055
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72286",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:463110",
      "to_stop_id": "IDFM:21996",
      "duration": 60,
      "path": [
        [
          2.376684607718542,
          48.87198955568015
        ],
        [
          2.3707644347484003,
          48.8700070178386
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:463110",
      "to_stop_id": "IDFM:21998",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463110",
      "to_stop_id": "IDFM:22128",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463110",
      "to_stop_id": "IDFM:463111",
      "duration": 101,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463110",
      "to_stop_id": "IDFM:463287",
      "duration": 60,
      "path": [
        [
          2.3852029155695313,
          48.87381879018058
        ],
        [
          2.376684607718542,
          48.87198955568015
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:463111",
      "to_stop_id": "IDFM:21998",
      "duration": 132,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463111",
      "to_stop_id": "IDFM:22128",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463111",
      "to_stop_id": "IDFM:462983",
      "duration": 60,
      "path": [
        [
          2.376640589315146,
          48.87225013398613
        ],
        [
          2.3802889829271936,
          48.86919334513237
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:463111",
      "to_stop_id": "IDFM:462984",
      "duration": 60,
      "path": [
        [
          2.370467574793864,
          48.87764980852146
        ],
        [
          2.376640589315146,
          48.87225013398613
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71893"
    },
    {
      "from_stop_id": "IDFM:463111",
      "to_stop_id": "IDFM:463110",
      "duration": 98,
      "path": null,
      "from_stop_unique_id": "IDFM:71853",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463112",
      "to_stop_id": "IDFM:21955",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:463112",
      "to_stop_id": "IDFM:463045",
      "duration": 60,
      "path": [
        [
          2.367034270941924,
          48.826944155043044
        ],
        [
          2.376487371168301,
          48.829925765928905
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:463112",
      "to_stop_id": "IDFM:463165",
      "duration": 60,
      "path": [
        [
          2.376487371168301,
          48.829925765928905
        ],
        [
          2.386617850214058,
          48.833319303843425
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71572",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:463113",
      "to_stop_id": "IDFM:22114",
      "duration": 60,
      "path": [
        [
          2.338257976509587,
          48.882269542226226
        ],
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463113",
      "to_stop_id": "IDFM:22125",
      "duration": 0,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:463113",
      "to_stop_id": "IDFM:463252",
      "duration": 60,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ],
        [
          2.3278987090661456,
          48.88374519677981
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71420",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463114",
      "to_stop_id": "IDFM:22164",
      "duration": 60,
      "path": [
        [
          2.287625377853519,
          48.86346147371448
        ],
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463114",
      "to_stop_id": "IDFM:22180",
      "duration": 0,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:463114",
      "to_stop_id": "IDFM:463102",
      "duration": 60,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ],
        [
          2.2933259179892325,
          48.871301612039105
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71301",
      "to_stop_unique_id": "IDFM:73656"
    },
    {
      "from_stop_id": "IDFM:463115",
      "to_stop_id": "IDFM:21933",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463115",
      "to_stop_id": "IDFM:22159",
      "duration": 60,
      "path": [
        [
          2.348496122542364,
          48.87048144580479
        ],
        [
          2.353744768129407,
          48.86938572399119
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463115",
      "to_stop_id": "IDFM:22197",
      "duration": 60,
      "path": [
        [
          2.342839648636545,
          48.87146677844407
        ],
        [
          2.348496122542364,
          48.87048144580479
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463115",
      "to_stop_id": "IDFM:22198",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463115",
      "to_stop_id": "IDFM:463133",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:22040",
      "duration": 355,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:22055",
      "duration": 60,
      "path": [
        [
          2.338463589982122,
          48.889699132276796
        ],
        [
          2.338394635220906,
          48.884392717991425
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:22059",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:22114",
      "duration": 301,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:463088",
      "duration": 311,
      "path": null,
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463116",
      "to_stop_id": "IDFM:463089",
      "duration": 60,
      "path": [
        [
          2.338394635220906,
          48.884392717991425
        ],
        [
          2.337211164701128,
          48.88202093206733
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71432",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463117",
      "to_stop_id": "IDFM:22113",
      "duration": 60,
      "path": [
        [
          2.3947127337554788,
          48.85624467843737
        ],
        [
          2.3905477055525735,
          48.858175121260864
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:463117",
      "to_stop_id": "IDFM:22122",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:463117",
      "to_stop_id": "IDFM:22124",
      "duration": 60,
      "path": [
        [
          2.3981875566593973,
          48.85164854359716
        ],
        [
          2.3947127337554788,
          48.85624467843737
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71728",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:463118",
      "to_stop_id": "IDFM:22154",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:463118",
      "to_stop_id": "IDFM:463092",
      "duration": 60,
      "path": [
        [
          2.325623760353114,
          48.823394602620716
        ],
        [
          2.3271926250651647,
          48.828152157703514
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:463118",
      "to_stop_id": "IDFM:463242",
      "duration": 60,
      "path": [
        [
          2.3271926250651647,
          48.828152157703514
        ],
        [
          2.329887640408421,
          48.83133758557153
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71030",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:463119",
      "to_stop_id": "IDFM:22035",
      "duration": 60,
      "path": [
        [
          2.2801801985017236,
          48.89718710383118
        ],
        [
          2.2848122193525104,
          48.89223258539651
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:72057"
    },
    {
      "from_stop_id": "IDFM:463119",
      "to_stop_id": "IDFM:22036",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:463119",
      "to_stop_id": "IDFM:463210",
      "duration": 60,
      "path": [
        [
          2.2848122193525104,
          48.89223258539651
        ],
        [
          2.2881680068067354,
          48.88849338167193
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71519",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:463120",
      "to_stop_id": "IDFM:22123",
      "duration": 0,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71419"
    },
    {
      "from_stop_id": "IDFM:463120",
      "to_stop_id": "IDFM:22127",
      "duration": 60,
      "path": [
        [
          2.3441548403302916,
          48.882868568843925
        ],
        [
          2.3506070793550977,
          48.88377608955688
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463120",
      "to_stop_id": "IDFM:463088",
      "duration": 60,
      "path": [
        [
          2.3382179854559224,
          48.88219737464818
        ],
        [
          2.3441548403302916,
          48.882868568843925
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71419",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463121",
      "to_stop_id": "IDFM:22086",
      "duration": 60,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ],
        [
          2.2951172138230334,
          48.87392926445533
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463121",
      "to_stop_id": "IDFM:22088",
      "duration": 0,
      "path": [
        [
          2.289435418542214,
          48.87566737659971
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:463121",
      "to_stop_id": "IDFM:463257",
      "duration": 60,
      "path": [
        [
          2.2822896139411903,
          48.877805650437985
        ],
        [
          2.289435418542214,
          48.87566737659971
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71348",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:463122",
      "to_stop_id": "IDFM:21994",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463122",
      "to_stop_id": "IDFM:21997",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463122",
      "to_stop_id": "IDFM:22018",
      "duration": 60,
      "path": [
        [
          2.3566822734187167,
          48.86561531899988
        ],
        [
          2.3520507871028355,
          48.86638175675157
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463122",
      "to_stop_id": "IDFM:22026",
      "duration": 60,
      "path": [
        [
          2.361561234526356,
          48.86675662943444
        ],
        [
          2.3566822734187167,
          48.86561531899988
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:463122",
      "to_stop_id": "IDFM:463108",
      "duration": 93,
      "path": null,
      "from_stop_unique_id": "IDFM:71293",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463123",
      "to_stop_id": "IDFM:22045",
      "duration": 60,
      "path": [
        [
          2.3231029882985132,
          48.858550089641746
        ],
        [
          2.3206896796944263,
          48.861122050080816
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:463123",
      "to_stop_id": "IDFM:22057",
      "duration": 60,
      "path": [
        [
          2.3206896796944263,
          48.861122050080816
        ],
        [
          2.323181449571859,
          48.86615003482594
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463123",
      "to_stop_id": "IDFM:22060",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73642",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:463124",
      "to_stop_id": "IDFM:21969",
      "duration": 60,
      "path": [
        [
          2.2985257262366363,
          48.84963080443241
        ],
        [
          2.2950189971435506,
          48.84703801370517
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463124",
      "to_stop_id": "IDFM:21987",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:463124",
      "to_stop_id": "IDFM:22190",
      "duration": 361,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463124",
      "to_stop_id": "IDFM:463076",
      "duration": 60,
      "path": [
        [
          2.2950189971435506,
          48.84703801370517
        ],
        [
          2.2856210133667174,
          48.84660453239739
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71156"
    },
    {
      "from_stop_id": "IDFM:463124",
      "to_stop_id": "IDFM:463162",
      "duration": 364,
      "path": null,
      "from_stop_unique_id": "IDFM:71158",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463125",
      "to_stop_id": "IDFM:22122",
      "duration": 60,
      "path": [
        [
          2.3945914186721278,
          48.856199097341126
        ],
        [
          2.3980666384995177,
          48.85161645805963
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:463125",
      "to_stop_id": "IDFM:22124",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71697"
    },
    {
      "from_stop_id": "IDFM:463125",
      "to_stop_id": "IDFM:25084",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:463125",
      "to_stop_id": "IDFM:463039",
      "duration": 290,
      "path": null,
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:463125",
      "to_stop_id": "IDFM:463067",
      "duration": 120,
      "path": [
        [
          2.3980666384995177,
          48.85161645805963
        ],
        [
          2.395925559927623,
          48.848453436979646
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71697",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463126",
      "to_stop_id": "IDFM:22240",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:463126",
      "to_stop_id": "IDFM:22241",
      "duration": 60,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.359431626687363,
          48.9367747982655
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:463126",
      "to_stop_id": "IDFM:463302",
      "duration": 120,
      "path": [
        [
          2.359431626687363,
          48.9367747982655
        ],
        [
          2.364530774042862,
          48.9458307173959
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72326",
      "to_stop_unique_id": "IDFM:72358"
    },
    {
      "from_stop_id": "IDFM:463127",
      "to_stop_id": "IDFM:22177",
      "duration": 0,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:463127",
      "to_stop_id": "IDFM:22181",
      "duration": 60,
      "path": [
        [
          2.396148628489371,
          48.839434008053175
        ],
        [
          2.400867131995259,
          48.84142733223358
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463127",
      "to_stop_id": "IDFM:463098",
      "duration": 60,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ],
        [
          2.4012745388223746,
          48.84510324750185
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73650",
      "to_stop_unique_id": "IDFM:71639"
    },
    {
      "from_stop_id": "IDFM:463128",
      "to_stop_id": "IDFM:21957",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463128",
      "to_stop_id": "IDFM:22178",
      "duration": 1,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463128",
      "to_stop_id": "IDFM:463075",
      "duration": 60,
      "path": [
        [
          2.3895997700798097,
          48.83903652832887
        ],
        [
          2.3794765872566757,
          48.84018509185968
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:463128",
      "to_stop_id": "IDFM:463129",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463128",
      "to_stop_id": "IDFM:463288",
      "duration": 60,
      "path": [
        [
          2.3794765872566757,
          48.84018509185968
        ],
        [
          2.372766248948172,
          48.8370742706096
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:463129",
      "to_stop_id": "IDFM:21956",
      "duration": 60,
      "path": [
        [
          2.3790620152277233,
          48.84010199803391
        ],
        [
          2.386464909364189,
          48.83334999725014
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:463129",
      "to_stop_id": "IDFM:21957",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463129",
      "to_stop_id": "IDFM:22178",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463129",
      "to_stop_id": "IDFM:463046",
      "duration": 60,
      "path": [
        [
          2.373100581933962,
          48.84467563174822
        ],
        [
          2.3790620152277233,
          48.84010199803391
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463129",
      "to_stop_id": "IDFM:463128",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71607",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463130",
      "to_stop_id": "IDFM:22105",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:463130",
      "to_stop_id": "IDFM:463040",
      "duration": 60,
      "path": [
        [
          2.4292278831660283,
          48.845491696267075
        ],
        [
          2.4189897063438073,
          48.84622934735893
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71647"
    },
    {
      "from_stop_id": "IDFM:463130",
      "to_stop_id": "IDFM:463149",
      "duration": 60,
      "path": [
        [
          2.440540095406114,
          48.84431751431355
        ],
        [
          2.4292278831660283,
          48.845491696267075
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71650",
      "to_stop_unique_id": "IDFM:71634"
    },
    {
      "from_stop_id": "IDFM:463131",
      "to_stop_id": "IDFM:22014",
      "duration": 240,
      "path": [
        [
          2.426308679232036,
          48.89560207433252
        ],
        [
          2.4493013366465726,
          48.906366795030564
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72486",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:463131",
      "to_stop_id": "IDFM:22015",
      "duration": 25,
      "path": null,
      "from_stop_unique_id": "IDFM:72486",
      "to_stop_unique_id": "IDFM:72486"
    },
    {
      "from_stop_id": "IDFM:463132",
      "to_stop_id": "IDFM:22118",
      "duration": 379,
      "path": null,
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463132",
      "to_stop_id": "IDFM:24683",
      "duration": 60,
      "path": [
        [
          2.381569842088008,
          48.87849908839857
        ],
        [
          2.374152140698752,
          48.880789805531926
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:463132",
      "to_stop_id": "IDFM:24685",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:463132",
      "to_stop_id": "IDFM:463074",
      "duration": 60,
      "path": [
        [
          2.374152140698752,
          48.880789805531926
        ],
        [
          2.370465586581431,
          48.882326316477965
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71920",
      "to_stop_unique_id": "IDFM:71940"
    },
    {
      "from_stop_id": "IDFM:463133",
      "to_stop_id": "IDFM:21909",
      "duration": 60,
      "path": [
        [
          2.343167503558387,
          48.87141461757831
        ],
        [
          2.348428667867017,
          48.87049007203075
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71326"
    },
    {
      "from_stop_id": "IDFM:463133",
      "to_stop_id": "IDFM:21920",
      "duration": 60,
      "path": [
        [
          2.348428667867017,
          48.87049007203075
        ],
        [
          2.3537315826731833,
          48.869349679171926
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463133",
      "to_stop_id": "IDFM:21933",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463133",
      "to_stop_id": "IDFM:22198",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463133",
      "to_stop_id": "IDFM:463115",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71320",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463134",
      "to_stop_id": "IDFM:24683",
      "duration": 60,
      "path": [
        [
          2.388900951315739,
          48.879534991268365
        ],
        [
          2.381569842088008,
          48.87849908839857
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:463134",
      "to_stop_id": "IDFM:24686",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:463134",
      "to_stop_id": "IDFM:24687",
      "duration": 60,
      "path": [
        [
          2.393228471227492,
          48.88194921492064
        ],
        [
          2.388900951315739,
          48.879534991268365
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71906",
      "to_stop_unique_id": "IDFM:71930"
    },
    {
      "from_stop_id": "IDFM:463135",
      "to_stop_id": "IDFM:22188",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:463135",
      "to_stop_id": "IDFM:463163",
      "duration": 60,
      "path": [
        [
          2.291751809719901,
          48.84274672574357
        ],
        [
          2.2877944613211754,
          48.841122353680156
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:463135",
      "to_stop_id": "IDFM:463211",
      "duration": 60,
      "path": [
        [
          2.2877944613211754,
          48.841122353680156
        ],
        [
          2.2820358715985742,
          48.838785539019476
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:412992",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:463136",
      "to_stop_id": "IDFM:21989",
      "duration": 0,
      "path": [
        [
          2.2283125898228056,
          48.84067248206251
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:70721",
      "to_stop_unique_id": "IDFM:70721"
    },
    {
      "from_stop_id": "IDFM:463136",
      "to_stop_id": "IDFM:463137",
      "duration": 120,
      "path": [
        [
          2.2283125898228056,
          48.84067248206251
        ],
        [
          2.239077780728926,
          48.841991575324506
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:70721",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:463137",
      "to_stop_id": "IDFM:21904",
      "duration": 120,
      "path": [
        [
          2.239077780728926,
          48.841991575324506
        ],
        [
          2.2618218573220084,
          48.84515597018703
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:463137",
      "to_stop_id": "IDFM:21988",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:71147"
    },
    {
      "from_stop_id": "IDFM:463137",
      "to_stop_id": "IDFM:463136",
      "duration": 120,
      "path": [
        [
          2.2283125898228056,
          48.84067248206251
        ],
        [
          2.239077780728926,
          48.841991575324506
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71147",
      "to_stop_unique_id": "IDFM:70721"
    },
    {
      "from_stop_id": "IDFM:463138",
      "to_stop_id": "IDFM:22008",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:463138",
      "to_stop_id": "IDFM:22010",
      "duration": 60,
      "path": [
        [
          2.368922075646989,
          48.85328747830434
        ],
        [
          2.3703346849773035,
          48.8562178110506
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463138",
      "to_stop_id": "IDFM:22202",
      "duration": 233,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:463138",
      "to_stop_id": "IDFM:463096",
      "duration": 60,
      "path": [
        [
          2.3703346849773035,
          48.8562178110506
        ],
        [
          2.3719501400872653,
          48.85985966744055
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:463138",
      "to_stop_id": "IDFM:463146",
      "duration": 242,
      "path": null,
      "from_stop_unique_id": "IDFM:73621",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:463139",
      "to_stop_id": "IDFM:22377",
      "duration": 60,
      "path": [
        [
          2.3487397513900734,
          48.87716484925024
        ],
        [
          2.3443838837796562,
          48.87604838013132
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:463139",
      "to_stop_id": "IDFM:22382",
      "duration": 60,
      "path": [
        [
          2.3443838837796562,
          48.87604838013132
        ],
        [
          2.3401505597063736,
          48.874959406040965
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:463139",
      "to_stop_id": "IDFM:22390",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71351",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:463140",
      "to_stop_id": "IDFM:22162",
      "duration": 0,
      "path": [
        [
          2.298906224573169,
          48.848697697821954
        ],
        [
          2.302898786915822,
          48.84749339701438
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463140",
      "to_stop_id": "IDFM:22163",
      "duration": 60,
      "path": [
        [
          2.302898786915822,
          48.84749339701438
        ],
        [
          2.3094677673863235,
          48.84558437021755
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:463140",
      "to_stop_id": "IDFM:22176",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:71167",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:21968",
      "duration": 343,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3549316718232256,
          48.846197891637004
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:21984",
      "duration": 0,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:22386",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:463077",
      "duration": 60,
      "path": [
        [
          2.354944184375758,
          48.8462878950311
        ],
        [
          2.3513280016731763,
          48.84670003526311
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:463078",
      "duration": 339,
      "path": null,
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463141",
      "to_stop_id": "IDFM:463237",
      "duration": 60,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3482804131694897,
          48.85019546606951
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71153",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:463142",
      "to_stop_id": "IDFM:22209",
      "duration": 60,
      "path": [
        [
          2.413844709558796,
          48.82148073316996
        ],
        [
          2.4064912314699263,
          48.826148180607525
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:463142",
      "to_stop_id": "IDFM:22210",
      "duration": 31,
      "path": null,
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:463142",
      "to_stop_id": "IDFM:463047",
      "duration": 60,
      "path": [
        [
          2.422399525054415,
          48.81483550210013
        ],
        [
          2.413844709558796,
          48.82148073316996
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70648",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:463143",
      "to_stop_id": "IDFM:21934",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:463143",
      "to_stop_id": "IDFM:22442",
      "duration": 60,
      "path": [
        [
          2.384782396448888,
          48.854939443448494
        ],
        [
          2.3891047050322425,
          48.85221407333194
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:463143",
      "to_stop_id": "IDFM:463325",
      "duration": 60,
      "path": [
        [
          2.3800316941488044,
          48.85766243438601
        ],
        [
          2.384782396448888,
          48.854939443448494
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73647",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:21921",
      "duration": 60,
      "path": [
        [
          2.333970457594147,
          48.87280723715393
        ],
        [
          2.3385912215385414,
          48.872135870317955
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:21926",
      "duration": 60,
      "path": [
        [
          2.327651103418782,
          48.87366675194512
        ],
        [
          2.333970457594147,
          48.87280723715393
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:21932",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:22030",
      "duration": 334,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:22195",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:22388",
      "duration": 154,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:463145",
      "duration": 154,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:463244",
      "duration": 350,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:463245",
      "duration": 374,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463144",
      "to_stop_id": "IDFM:463246",
      "duration": 322,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:21932",
      "duration": 99,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:22030",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:22381",
      "duration": 60,
      "path": [
        [
          2.333669568112413,
          48.873201272012516
        ],
        [
          2.3324387808144875,
          48.87030303390994
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:22382",
      "duration": 60,
      "path": [
        [
          2.3401505597063736,
          48.874959406040965
        ],
        [
          2.333669568112413,
          48.873201272012516
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:22388",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:463144",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463145",
      "to_stop_id": "IDFM:463246",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:73689",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463146",
      "to_stop_id": "IDFM:22008",
      "duration": 229,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:463146",
      "to_stop_id": "IDFM:22097",
      "duration": 60,
      "path": [
        [
          2.368095085385991,
          48.85708736782028
        ],
        [
          2.3695064282002,
          48.853425461693256
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463146",
      "to_stop_id": "IDFM:22202",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:463146",
      "to_stop_id": "IDFM:463138",
      "duration": 242,
      "path": null,
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:73621"
    },
    {
      "from_stop_id": "IDFM:463146",
      "to_stop_id": "IDFM:463309",
      "duration": 60,
      "path": [
        [
          2.3671254626151184,
          48.86094943927989
        ],
        [
          2.368095085385991,
          48.85708736782028
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73622",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:463147",
      "to_stop_id": "IDFM:22161",
      "duration": 60,
      "path": [
        [
          2.3680951237726693,
          48.83494516788824
        ],
        [
          2.372825013717543,
          48.83700262953923
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:463147",
      "to_stop_id": "IDFM:22169",
      "duration": 60,
      "path": [
        [
          2.362857958087621,
          48.83318160406702
        ],
        [
          2.3680951237726693,
          48.83494516788824
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:71043"
    },
    {
      "from_stop_id": "IDFM:463147",
      "to_stop_id": "IDFM:22174",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:73651",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:463148",
      "to_stop_id": "IDFM:22130",
      "duration": 60,
      "path": [
        [
          2.3493658080048405,
          48.88707887746752
        ],
        [
          2.349629927853002,
          48.883402060486226
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463148",
      "to_stop_id": "IDFM:22152",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71469"
    },
    {
      "from_stop_id": "IDFM:463148",
      "to_stop_id": "IDFM:462974",
      "duration": 60,
      "path": [
        [
          2.3495451670385634,
          48.89127969913649
        ],
        [
          2.3493658080048405,
          48.88707887746752
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71469",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:463149",
      "to_stop_id": "IDFM:22104",
      "duration": 0,
      "path": [
        [
          2.440540095406114,
          48.84431751431355
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71634",
      "to_stop_unique_id": "IDFM:71634"
    },
    {
      "from_stop_id": "IDFM:463149",
      "to_stop_id": "IDFM:463130",
      "duration": 60,
      "path": [
        [
          2.440540095406114,
          48.84431751431355
        ],
        [
          2.4292278831660283,
          48.845491696267075
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71634",
      "to_stop_unique_id": "IDFM:71650"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:22057",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:22075",
      "duration": 60,
      "path": [
        [
          2.3297453212172794,
          48.864550163577015
        ],
        [
          2.3229614457982586,
          48.866557992949716
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:22085",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:22096",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:463010",
      "duration": 60,
      "path": [
        [
          2.3229614457982586,
          48.866557992949716
        ],
        [
          2.314464501322781,
          48.86765629219383
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:463151",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463150",
      "to_stop_id": "IDFM:463164",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:22057",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:22085",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:22096",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:22193",
      "duration": 60,
      "path": [
        [
          2.321915152849789,
          48.86656106315263
        ],
        [
          2.3146326604445164,
          48.86109201138112
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:463063",
      "duration": 60,
      "path": [
        [
          2.3259702743206723,
          48.8696462363775
        ],
        [
          2.321915152849789,
          48.86656106315263
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:463150",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463151",
      "to_stop_id": "IDFM:463164",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463152",
      "to_stop_id": "IDFM:22389",
      "duration": 0,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:463152",
      "to_stop_id": "IDFM:22392",
      "duration": 60,
      "path": [
        [
          2.382291557164633,
          48.89467266005628
        ],
        [
          2.37693573698238,
          48.8908857589288
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:463152",
      "to_stop_id": "IDFM:463007",
      "duration": 60,
      "path": [
        [
          2.3857788171360426,
          48.897865181710756
        ],
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72031",
      "to_stop_unique_id": "IDFM:72430"
    },
    {
      "from_stop_id": "IDFM:463153",
      "to_stop_id": "IDFM:22061",
      "duration": 0,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:463153",
      "to_stop_id": "IDFM:463219",
      "duration": 120,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ],
        [
          2.273558168023932,
          48.82434428766646
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:70671"
    },
    {
      "from_stop_id": "IDFM:463153",
      "to_stop_id": "IDFM:463261",
      "duration": 60,
      "path": [
        [
          2.2877417091423347,
          48.83251953251351
        ],
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70990",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:463154",
      "to_stop_id": "IDFM:22117",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:463154",
      "to_stop_id": "IDFM:22121",
      "duration": 0,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:463154",
      "to_stop_id": "IDFM:463266",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.2981132886172433,
          48.878227730862385
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71376",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:463155",
      "to_stop_id": "IDFM:22371",
      "duration": 60,
      "path": [
        [
          2.3737641527012556,
          48.88810829453359
        ],
        [
          2.3770380600019925,
          48.890814345621656
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:463155",
      "to_stop_id": "IDFM:22389",
      "duration": 60,
      "path": [
        [
          2.3770380600019925,
          48.890814345621656
        ],
        [
          2.382291557164633,
          48.89467266005628
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:72031"
    },
    {
      "from_stop_id": "IDFM:463155",
      "to_stop_id": "IDFM:22392",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72013",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:463156",
      "to_stop_id": "IDFM:24683",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71900"
    },
    {
      "from_stop_id": "IDFM:463156",
      "to_stop_id": "IDFM:24685",
      "duration": 60,
      "path": [
        [
          2.374124871187542,
          48.88078966297506
        ],
        [
          2.381632921459486,
          48.878422971131464
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71920"
    },
    {
      "from_stop_id": "IDFM:463156",
      "to_stop_id": "IDFM:24686",
      "duration": 60,
      "path": [
        [
          2.381632921459486,
          48.878422971131464
        ],
        [
          2.388908111185046,
          48.87943610160117
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71900",
      "to_stop_unique_id": "IDFM:71906"
    },
    {
      "from_stop_id": "IDFM:463157",
      "to_stop_id": "IDFM:22366",
      "duration": 345,
      "path": null,
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:463157",
      "to_stop_id": "IDFM:22383",
      "duration": 60,
      "path": [
        [
          2.351495277596339,
          48.84026600820119
        ],
        [
          2.3524168018982756,
          48.835841622344475
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:463157",
      "to_stop_id": "IDFM:22391",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:463157",
      "to_stop_id": "IDFM:463106",
      "duration": 60,
      "path": [
        [
          2.3520055901754713,
          48.842683564383904
        ],
        [
          2.351495277596339,
          48.84026600820119
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71100",
      "to_stop_unique_id": "IDFM:71133"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:21959",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:21966",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:22087",
      "duration": 141,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:22092",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:22364",
      "duration": 262,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463060",
      "duration": 271,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463079",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463160",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463161",
      "duration": 60,
      "path": [
        [
          2.347361051762225,
          48.85492106147581
        ],
        [
          2.3467600097151675,
          48.85971581985686
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463158",
      "to_stop_id": "IDFM:463208",
      "duration": 60,
      "path": [
        [
          2.3467600097151675,
          48.85971581985686
        ],
        [
          2.3461273251736805,
          48.8625048403394
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:21958",
      "duration": 180,
      "path": [
        [
          2.372519782814122,
          48.8442498880687
        ],
        [
          2.346411849769497,
          48.859556532726764
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:21959",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:21966",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:22087",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:22092",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:22364",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:463060",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:463079",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:463158",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:463160",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463159",
      "to_stop_id": "IDFM:463284",
      "duration": 60,
      "path": [
        [
          2.346411849769497,
          48.859556532726764
        ],
        [
          2.3347388358754153,
          48.86580563193237
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:21959",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:21966",
      "duration": 240,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:22087",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:22091",
      "duration": 60,
      "path": [
        [
          2.3520676701391,
          48.85735240518587
        ],
        [
          2.3458090025909044,
          48.85930591756934
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:22092",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:22364",
      "duration": 255,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:463060",
      "duration": 264,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:463079",
      "duration": 241,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:463158",
      "duration": 104,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:463159",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463160",
      "to_stop_id": "IDFM:463218",
      "duration": 60,
      "path": [
        [
          2.3458090025909044,
          48.85930591756934
        ],
        [
          2.3409964272720227,
          48.86090733426327
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71264",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:22137",
      "duration": 448,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:22150",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:22364",
      "duration": 297,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:463060",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:463158",
      "duration": 60,
      "path": [
        [
          2.347361051762225,
          48.85492106147581
        ],
        [
          2.3467600097151675,
          48.85971581985686
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463161",
      "to_stop_id": "IDFM:463306",
      "duration": 60,
      "path": [
        [
          2.343991799033288,
          48.85359407756809
        ],
        [
          2.347361051762225,
          48.85492106147581
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71223",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:21987",
      "duration": 354,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:22183",
      "duration": 60,
      "path": [
        [
          2.293851708440619,
          48.84458485524012
        ],
        [
          2.298745444388579,
          48.84950618174656
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:22189",
      "duration": 60,
      "path": [
        [
          2.2918472203679703,
          48.84268433479664
        ],
        [
          2.293851708440619,
          48.84458485524012
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:22190",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:463124",
      "duration": 364,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:463162",
      "to_stop_id": "IDFM:463163",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:71132",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:463163",
      "to_stop_id": "IDFM:22189",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71137"
    },
    {
      "from_stop_id": "IDFM:463163",
      "to_stop_id": "IDFM:22190",
      "duration": 60,
      "path": [
        [
          2.293687475167137,
          48.84463784771031
        ],
        [
          2.291751809719901,
          48.84274672574357
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463163",
      "to_stop_id": "IDFM:463135",
      "duration": 60,
      "path": [
        [
          2.291751809719901,
          48.84274672574357
        ],
        [
          2.2877944613211754,
          48.841122353680156
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:463163",
      "to_stop_id": "IDFM:463162",
      "duration": 325,
      "path": null,
      "from_stop_unique_id": "IDFM:71137",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:21965",
      "duration": 60,
      "path": [
        [
          2.325039317039756,
          48.8700546880179
        ],
        [
          2.323058362902906,
          48.8661808158217
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:22057",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:22060",
      "duration": 60,
      "path": [
        [
          2.323058362902906,
          48.8661808158217
        ],
        [
          2.320575836647556,
          48.861071939526084
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:22085",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:22096",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:463150",
      "duration": 166,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463164",
      "to_stop_id": "IDFM:463151",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71298",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463165",
      "to_stop_id": "IDFM:21956",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71576"
    },
    {
      "from_stop_id": "IDFM:463165",
      "to_stop_id": "IDFM:21957",
      "duration": 60,
      "path": [
        [
          2.386617850214058,
          48.833319303843425
        ],
        [
          2.3791909087742873,
          48.84014763512746
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463165",
      "to_stop_id": "IDFM:463112",
      "duration": 60,
      "path": [
        [
          2.376487371168301,
          48.829925765928905
        ],
        [
          2.386617850214058,
          48.833319303843425
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71576",
      "to_stop_unique_id": "IDFM:71572"
    },
    {
      "from_stop_id": "IDFM:463166",
      "to_stop_id": "IDFM:22211",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:463166",
      "to_stop_id": "IDFM:463015",
      "duration": 60,
      "path": [
        [
          2.449424047013373,
          48.79661108096057
        ],
        [
          2.4459564771573397,
          48.80321905319987
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:463166",
      "to_stop_id": "IDFM:463167",
      "duration": 60,
      "path": [
        [
          2.4505697455342568,
          48.78994285432577
        ],
        [
          2.449424047013373,
          48.79661108096057
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70269",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:463167",
      "to_stop_id": "IDFM:22212",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70172"
    },
    {
      "from_stop_id": "IDFM:463167",
      "to_stop_id": "IDFM:22213",
      "duration": 60,
      "path": [
        [
          2.4593051692912615,
          48.77975652677895
        ],
        [
          2.4505697455342568,
          48.78994285432577
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70021"
    },
    {
      "from_stop_id": "IDFM:463167",
      "to_stop_id": "IDFM:463166",
      "duration": 60,
      "path": [
        [
          2.4505697455342568,
          48.78994285432577
        ],
        [
          2.449424047013373,
          48.79661108096057
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70172",
      "to_stop_unique_id": "IDFM:70269"
    },
    {
      "from_stop_id": "IDFM:463168",
      "to_stop_id": "IDFM:22181",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463168",
      "to_stop_id": "IDFM:22184",
      "duration": 0,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463168",
      "to_stop_id": "IDFM:22206",
      "duration": 60,
      "path": [
        [
          2.395573606140371,
          48.83967393140864
        ],
        [
          2.40228042706469,
          48.837000553950986
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:463168",
      "to_stop_id": "IDFM:463169",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463168",
      "to_stop_id": "IDFM:463241",
      "duration": 60,
      "path": [
        [
          2.3904000136353645,
          48.84400507570895
        ],
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:463169",
      "to_stop_id": "IDFM:22177",
      "duration": 60,
      "path": [
        [
          2.400867131995259,
          48.84142733223358
        ],
        [
          2.3961460679154514,
          48.839537421644565
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:73650"
    },
    {
      "from_stop_id": "IDFM:463169",
      "to_stop_id": "IDFM:22181",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463169",
      "to_stop_id": "IDFM:22184",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463169",
      "to_stop_id": "IDFM:463075",
      "duration": 60,
      "path": [
        [
          2.3961460679154514,
          48.839537421644565
        ],
        [
          2.3895997700798097,
          48.83903652832887
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71606"
    },
    {
      "from_stop_id": "IDFM:463169",
      "to_stop_id": "IDFM:463168",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71614",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463170",
      "to_stop_id": "IDFM:22101",
      "duration": 60,
      "path": [
        [
          2.2379881830988277,
          48.89181786646099
        ],
        [
          2.249931086743073,
          48.88834989394862
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71517"
    },
    {
      "from_stop_id": "IDFM:463170",
      "to_stop_id": "IDFM:22102",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:463170",
      "to_stop_id": "IDFM:463044",
      "duration": 60,
      "path": [
        [
          2.249931086743073,
          48.88834989394862
        ],
        [
          2.2585225729481575,
          48.8854985504452
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71485",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:463171",
      "to_stop_id": "IDFM:22146",
      "duration": 60,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ],
        [
          2.34600573656331,
          48.86252665962869
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:463171",
      "to_stop_id": "IDFM:22147",
      "duration": 0,
      "path": [
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:463171",
      "to_stop_id": "IDFM:463072",
      "duration": 60,
      "path": [
        [
          2.352381731140252,
          48.866077771215046
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463171",
      "to_stop_id": "IDFM:463208",
      "duration": 315,
      "path": [
        [
          2.3461273251736805,
          48.8625048403394
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:479068",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:463172",
      "to_stop_id": "IDFM:22204",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:463172",
      "to_stop_id": "IDFM:463195",
      "duration": 60,
      "path": [
        [
          2.375924913735691,
          48.85119274222268
        ],
        [
          2.384028566383108,
          48.85011054413369
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:463172",
      "to_stop_id": "IDFM:463293",
      "duration": 60,
      "path": [
        [
          2.384028566383108,
          48.85011054413369
        ],
        [
          2.3869585404965377,
          48.847094767969466
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:74001",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:21970",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:22038",
      "duration": 60,
      "path": [
        [
          2.323989185205003,
          48.84382361125447
        ],
        [
          2.3175087114640447,
          48.84444335195695
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:22039",
      "duration": 60,
      "path": [
        [
          2.3175087114640447,
          48.84444335195695
        ],
        [
          2.312554745666783,
          48.842958015471986
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:22056",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:73641"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:22229",
      "duration": 375,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:463083",
      "duration": 372,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463173",
      "to_stop_id": "IDFM:463084",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:73641",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463174",
      "to_stop_id": "IDFM:22037",
      "duration": 38,
      "path": null,
      "from_stop_unique_id": "IDFM:71817",
      "to_stop_unique_id": "IDFM:71817"
    },
    {
      "from_stop_id": "IDFM:463174",
      "to_stop_id": "IDFM:463276",
      "duration": 60,
      "path": [
        [
          2.415921484287642,
          48.86539746068654
        ],
        [
          2.4087542211895427,
          48.86453937293447
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71817",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:463176",
      "to_stop_id": "IDFM:21943",
      "duration": 0,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:463176",
      "to_stop_id": "IDFM:463053",
      "duration": 60,
      "path": [
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4229449336324724,
          48.855754613895044
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:463176",
      "to_stop_id": "IDFM:463221",
      "duration": 60,
      "path": [
        [
          2.4414386664522225,
          48.862313130940244
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71756",
      "to_stop_unique_id": "IDFM:71785"
    },
    {
      "from_stop_id": "IDFM:463177",
      "to_stop_id": "IDFM:22183",
      "duration": 60,
      "path": [
        [
          2.298745444388579,
          48.84950618174656
        ],
        [
          2.3064410394306907,
          48.854861755122315
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463177",
      "to_stop_id": "IDFM:22191",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:463177",
      "to_stop_id": "IDFM:22192",
      "duration": 60,
      "path": [
        [
          2.3064410394306907,
          48.854861755122315
        ],
        [
          2.3105887898739876,
          48.85766922956403
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71224",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:463178",
      "to_stop_id": "IDFM:22016",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72024"
    },
    {
      "from_stop_id": "IDFM:463178",
      "to_stop_id": "IDFM:463002",
      "duration": 60,
      "path": [
        [
          2.426277595760804,
          48.89563789793626
        ],
        [
          2.413074999597678,
          48.89324469416795
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72039"
    },
    {
      "from_stop_id": "IDFM:463178",
      "to_stop_id": "IDFM:463017",
      "duration": 60,
      "path": [
        [
          2.413074999597678,
          48.89324469416795
        ],
        [
          2.403114154894396,
          48.891495749437304
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:72024",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:463179",
      "to_stop_id": "IDFM:22393",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:463179",
      "to_stop_id": "IDFM:22395",
      "duration": 60,
      "path": [
        [
          2.404187821795194,
          48.91470775289347
        ],
        [
          2.4102214036405085,
          48.92024579662936
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:73696"
    },
    {
      "from_stop_id": "IDFM:463179",
      "to_stop_id": "IDFM:463031",
      "duration": 120,
      "path": [
        [
          2.3922205712292195,
          48.90375706036556
        ],
        [
          2.404187821795194,
          48.91470775289347
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73695",
      "to_stop_unique_id": "IDFM:72460"
    },
    {
      "from_stop_id": "IDFM:463180",
      "to_stop_id": "IDFM:21931",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463180",
      "to_stop_id": "IDFM:22082",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463180",
      "to_stop_id": "IDFM:462915",
      "duration": 60,
      "path": [
        [
          2.3095735420193506,
          48.86866242617973
        ],
        [
          2.3101372056878247,
          48.87215513107743
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71334"
    },
    {
      "from_stop_id": "IDFM:463180",
      "to_stop_id": "IDFM:463052",
      "duration": 60,
      "path": [
        [
          2.3011043523926706,
          48.86464714790582
        ],
        [
          2.3095735420193506,
          48.86866242617973
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:463180",
      "to_stop_id": "IDFM:463181",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463181",
      "to_stop_id": "IDFM:21931",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463181",
      "to_stop_id": "IDFM:22082",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463181",
      "to_stop_id": "IDFM:463010",
      "duration": 60,
      "path": [
        [
          2.314464501322781,
          48.86765629219383
        ],
        [
          2.309434325751582,
          48.86925069418826
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463181",
      "to_stop_id": "IDFM:463180",
      "duration": 300,
      "path": null,
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463181",
      "to_stop_id": "IDFM:463185",
      "duration": 60,
      "path": [
        [
          2.309434325751582,
          48.86925069418826
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71318",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:22001",
      "duration": 120,
      "path": [
        [
          2.357342884924845,
          48.87968001223662
        ],
        [
          2.367348121277192,
          48.8841445128949
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:22007",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:22013",
      "duration": 60,
      "path": [
        [
          2.35806459441865,
          48.87616299446643
        ],
        [
          2.357342884924845,
          48.87968001223662
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:22119",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:22149",
      "duration": 203,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:462982",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463182",
      "to_stop_id": "IDFM:463183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:22007",
      "duration": 124,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:22119",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:22149",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:462982",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71434"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:463022",
      "duration": 60,
      "path": [
        [
          2.3578981321043053,
          48.876283511884786
        ],
        [
          2.356808788205409,
          48.87959170741013
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:463093",
      "duration": 60,
      "path": [
        [
          2.356808788205409,
          48.87959170741013
        ],
        [
          2.3497455480753806,
          48.88342517173516
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71426"
    },
    {
      "from_stop_id": "IDFM:463183",
      "to_stop_id": "IDFM:463182",
      "duration": 145,
      "path": null,
      "from_stop_unique_id": "IDFM:71410",
      "to_stop_unique_id": "IDFM:71410"
    },
    {
      "from_stop_id": "IDFM:463184",
      "to_stop_id": "IDFM:22230",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:463184",
      "to_stop_id": "IDFM:462954",
      "duration": 60,
      "path": [
        [
          2.3208534383022275,
          48.84228592360469
        ],
        [
          2.3223539967788067,
          48.83852608502253
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463184",
      "to_stop_id": "IDFM:463229",
      "duration": 60,
      "path": [
        [
          2.3223539967788067,
          48.83852608502253
        ],
        [
          2.318323659736018,
          48.83416372097089
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73675",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:463185",
      "to_stop_id": "IDFM:22084",
      "duration": 0,
      "path": [
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71328"
    },
    {
      "from_stop_id": "IDFM:463185",
      "to_stop_id": "IDFM:463013",
      "duration": 60,
      "path": [
        [
          2.2951172138230334,
          48.87392926445533
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463185",
      "to_stop_id": "IDFM:463181",
      "duration": 60,
      "path": [
        [
          2.309434325751582,
          48.86925069418826
        ],
        [
          2.300759784978918,
          48.87203776458909
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71328",
      "to_stop_unique_id": "IDFM:71318"
    },
    {
      "from_stop_id": "IDFM:463186",
      "to_stop_id": "IDFM:22173",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:463186",
      "to_stop_id": "IDFM:463105",
      "duration": 60,
      "path": [
        [
          2.343438236678704,
          48.831115947616446
        ],
        [
          2.350611225926166,
          48.82986001331238
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71013"
    },
    {
      "from_stop_id": "IDFM:463186",
      "to_stop_id": "IDFM:463305",
      "duration": 60,
      "path": [
        [
          2.3370965389054956,
          48.832857226540206
        ],
        [
          2.343438236678704,
          48.831115947616446
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71026",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:463187",
      "to_stop_id": "IDFM:21996",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71840"
    },
    {
      "from_stop_id": "IDFM:463187",
      "to_stop_id": "IDFM:21998",
      "duration": 60,
      "path": [
        [
          2.3710788377398995,
          48.869936723164365
        ],
        [
          2.376753738418521,
          48.871908975367674
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463187",
      "to_stop_id": "IDFM:462960",
      "duration": 60,
      "path": [
        [
          2.3648796725347783,
          48.86788497134881
        ],
        [
          2.3710788377398995,
          48.869936723164365
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71840",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:21906",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:21926",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:21932",
      "duration": 60,
      "path": [
        [
          2.334000835220775,
          48.87288385004922
        ],
        [
          2.327677208279404,
          48.8737568325288
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:21963",
      "duration": 337,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:21964",
      "duration": 274,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:22218",
      "duration": 307,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:462969",
      "duration": 261,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:462970",
      "duration": 317,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:462971",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:463189",
      "duration": 78,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463188",
      "to_stop_id": "IDFM:463300",
      "duration": 60,
      "path": [
        [
          2.327677208279404,
          48.8737568325288
        ],
        [
          2.321031570403537,
          48.874632152132925
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:21906",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:21926",
      "duration": 59,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:21963",
      "duration": 60,
      "path": [
        [
          2.32755854621969,
          48.8735493178711
        ],
        [
          2.3246067760696794,
          48.875025603040974
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:21964",
      "duration": 303,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:22218",
      "duration": 323,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:462969",
      "duration": 289,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:462970",
      "duration": 333,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:462971",
      "duration": 349,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:463188",
      "duration": 67,
      "path": null,
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463189",
      "to_stop_id": "IDFM:463246",
      "duration": 60,
      "path": [
        [
          2.331128974301561,
          48.87150983278418
        ],
        [
          2.32755854621969,
          48.8735493178711
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:482368",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463190",
      "to_stop_id": "IDFM:21927",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:463190",
      "to_stop_id": "IDFM:21935",
      "duration": 60,
      "path": [
        [
          2.301130527470699,
          48.8647282422178
        ],
        [
          2.2938642256822708,
          48.864780022257456
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71290"
    },
    {
      "from_stop_id": "IDFM:463190",
      "to_stop_id": "IDFM:463318",
      "duration": 60,
      "path": [
        [
          2.2938642256822708,
          48.864780022257456
        ],
        [
          2.2868262498013006,
          48.86308797989344
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71294",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463191",
      "to_stop_id": "IDFM:21903",
      "duration": 60,
      "path": [
        [
          2.361023902048923,
          48.870620926265296
        ],
        [
          2.3640990472225725,
          48.867687468165165
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463191",
      "to_stop_id": "IDFM:22011",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71322"
    },
    {
      "from_stop_id": "IDFM:463191",
      "to_stop_id": "IDFM:463021",
      "duration": 60,
      "path": [
        [
          2.357982972943156,
          48.876081616617256
        ],
        [
          2.361023902048923,
          48.870620926265296
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71322",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463192",
      "to_stop_id": "IDFM:21991",
      "duration": 60,
      "path": [
        [
          2.384883957748741,
          48.87381265491752
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:463192",
      "to_stop_id": "IDFM:21995",
      "duration": 0,
      "path": [
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:463192",
      "to_stop_id": "IDFM:463254",
      "duration": 60,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71868",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:463193",
      "to_stop_id": "IDFM:22101",
      "duration": 42,
      "path": null,
      "from_stop_unique_id": "IDFM:71517",
      "to_stop_unique_id": "IDFM:71517"
    },
    {
      "from_stop_id": "IDFM:463193",
      "to_stop_id": "IDFM:22102",
      "duration": 120,
      "path": [
        [
          2.2499040727323307,
          48.88833173800946
        ],
        [
          2.2383579200010373,
          48.89199558886802
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71517",
      "to_stop_unique_id": "IDFM:71485"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:21969",
      "duration": 133,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:22162",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:22171",
      "duration": 0,
      "path": [
        [
          2.298957096977008,
          48.848765448122315
        ],
        [
          2.293663726731039,
          48.8504116258067
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71197"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:22176",
      "duration": 60,
      "path": [
        [
          2.3029417283376117,
          48.84754311219352
        ],
        [
          2.298957096977008,
          48.848765448122315
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:22183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:463201",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463194",
      "to_stop_id": "IDFM:463202",
      "duration": 143,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463195",
      "to_stop_id": "IDFM:22097",
      "duration": 60,
      "path": [
        [
          2.3695064282002,
          48.853425461693256
        ],
        [
          2.375924913735691,
          48.85119274222268
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463195",
      "to_stop_id": "IDFM:22203",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:71693"
    },
    {
      "from_stop_id": "IDFM:463195",
      "to_stop_id": "IDFM:463172",
      "duration": 60,
      "path": [
        [
          2.375924913735691,
          48.85119274222268
        ],
        [
          2.384028566383108,
          48.85011054413369
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71693",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:463197",
      "to_stop_id": "IDFM:21958",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463197",
      "to_stop_id": "IDFM:22083",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463197",
      "to_stop_id": "IDFM:22089",
      "duration": 120,
      "path": [
        [
          2.373125016776689,
          48.84575948671397
        ],
        [
          2.368718961034089,
          48.85205429349772
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463197",
      "to_stop_id": "IDFM:463046",
      "duration": 420,
      "path": null,
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463197",
      "to_stop_id": "IDFM:463294",
      "duration": 60,
      "path": [
        [
          2.3858367760086865,
          48.84734982784612
        ],
        [
          2.373125016776689,
          48.84575948671397
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73626",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463198",
      "to_stop_id": "IDFM:22093",
      "duration": 60,
      "path": [
        [
          2.3145404484362686,
          48.86084865840772
        ],
        [
          2.31401668387612,
          48.86750083759083
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:463198",
      "to_stop_id": "IDFM:22185",
      "duration": 45,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463198",
      "to_stop_id": "IDFM:22193",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463198",
      "to_stop_id": "IDFM:22226",
      "duration": 60,
      "path": [
        [
          2.315113930509657,
          48.85662498274656
        ],
        [
          2.3145404484362686,
          48.86084865840772
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:463198",
      "to_stop_id": "IDFM:463199",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463199",
      "to_stop_id": "IDFM:22096",
      "duration": 60,
      "path": [
        [
          2.314996938416133,
          48.86059945384315
        ],
        [
          2.3220315426557194,
          48.86652125193945
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71298"
    },
    {
      "from_stop_id": "IDFM:463199",
      "to_stop_id": "IDFM:22185",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463199",
      "to_stop_id": "IDFM:22192",
      "duration": 60,
      "path": [
        [
          2.3105887898739876,
          48.85766922956403
        ],
        [
          2.314996938416133,
          48.86059945384315
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:463199",
      "to_stop_id": "IDFM:22193",
      "duration": 77,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463199",
      "to_stop_id": "IDFM:463198",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71274",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463200",
      "to_stop_id": "IDFM:22393",
      "duration": 60,
      "path": [
        [
          2.410008476572451,
          48.92037064946693
        ],
        [
          2.404075377271855,
          48.91475665506255
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73696",
      "to_stop_unique_id": "IDFM:73695"
    },
    {
      "from_stop_id": "IDFM:463200",
      "to_stop_id": "IDFM:22395",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:73696",
      "to_stop_unique_id": "IDFM:73696"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:21969",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:22162",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:22183",
      "duration": 33,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:22190",
      "duration": 60,
      "path": [
        [
          2.298488389923641,
          48.84967105681514
        ],
        [
          2.293687475167137,
          48.84463784771031
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71132"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:22191",
      "duration": 60,
      "path": [
        [
          2.3063456838200764,
          48.85491966058707
        ],
        [
          2.298488389923641,
          48.84967105681514
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:463194",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463201",
      "to_stop_id": "IDFM:463202",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:21969",
      "duration": 20,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:21987",
      "duration": 60,
      "path": [
        [
          2.2950459810371475,
          48.84695722990175
        ],
        [
          2.298684579816939,
          48.849532805857095
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71158"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:22162",
      "duration": 167,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:22183",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:463194",
      "duration": 167,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:463201",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463202",
      "to_stop_id": "IDFM:463315",
      "duration": 60,
      "path": [
        [
          2.298684579816939,
          48.849532805857095
        ],
        [
          2.307137849403385,
          48.847167269995026
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71199",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:463203",
      "to_stop_id": "IDFM:21908",
      "duration": 60,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ],
        [
          2.2778858979590564,
          48.863951708879945
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:463203",
      "to_stop_id": "IDFM:21915",
      "duration": 60,
      "path": [
        [
          2.270032697561899,
          48.855306214887065
        ],
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:463203",
      "to_stop_id": "IDFM:21929",
      "duration": 0,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:474150",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:463204",
      "to_stop_id": "IDFM:22191",
      "duration": 60,
      "path": [
        [
          2.3104735359369846,
          48.85772702353455
        ],
        [
          2.3063456838200764,
          48.85491966058707
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71224"
    },
    {
      "from_stop_id": "IDFM:463204",
      "to_stop_id": "IDFM:22192",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71242"
    },
    {
      "from_stop_id": "IDFM:463204",
      "to_stop_id": "IDFM:22193",
      "duration": 60,
      "path": [
        [
          2.3146326604445164,
          48.86109201138112
        ],
        [
          2.3104735359369846,
          48.85772702353455
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71242",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463205",
      "to_stop_id": "IDFM:22055",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71492"
    },
    {
      "from_stop_id": "IDFM:463205",
      "to_stop_id": "IDFM:22059",
      "duration": 60,
      "path": [
        [
          2.338367478180517,
          48.884383574623314
        ],
        [
          2.3385838243831936,
          48.88968180996681
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71432"
    },
    {
      "from_stop_id": "IDFM:463205",
      "to_stop_id": "IDFM:463065",
      "duration": 60,
      "path": [
        [
          2.3385838243831936,
          48.88968180996681
        ],
        [
          2.3443200293804827,
          48.892492268201934
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71492",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:463206",
      "to_stop_id": "IDFM:22050",
      "duration": 274,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463206",
      "to_stop_id": "IDFM:22382",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:478885"
    },
    {
      "from_stop_id": "IDFM:463206",
      "to_stop_id": "IDFM:22388",
      "duration": 60,
      "path": [
        [
          2.3337385944087012,
          48.87313420577469
        ],
        [
          2.340206459456337,
          48.874851793670715
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463206",
      "to_stop_id": "IDFM:22390",
      "duration": 60,
      "path": [
        [
          2.340206459456337,
          48.874851793670715
        ],
        [
          2.344446309702274,
          48.875963285075564
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:463206",
      "to_stop_id": "IDFM:463062",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:478885",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463207",
      "to_stop_id": "IDFM:22232",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72240"
    },
    {
      "from_stop_id": "IDFM:463207",
      "to_stop_id": "IDFM:462953",
      "duration": 60,
      "path": [
        [
          2.28615583314788,
          48.923055662740055
        ],
        [
          2.29460408001733,
          48.916347418146714
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72203"
    },
    {
      "from_stop_id": "IDFM:463207",
      "to_stop_id": "IDFM:463109",
      "duration": 60,
      "path": [
        [
          2.284216775386713,
          48.930544259451466
        ],
        [
          2.28615583314788,
          48.923055662740055
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72240",
      "to_stop_unique_id": "IDFM:72286"
    },
    {
      "from_stop_id": "IDFM:463208",
      "to_stop_id": "IDFM:22146",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:73794"
    },
    {
      "from_stop_id": "IDFM:463208",
      "to_stop_id": "IDFM:22147",
      "duration": 0,
      "path": [
        [
          2.3461273251736805,
          48.8625048403394
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:463208",
      "to_stop_id": "IDFM:463158",
      "duration": 60,
      "path": [
        [
          2.3467600097151675,
          48.85971581985686
        ],
        [
          2.3461273251736805,
          48.8625048403394
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463208",
      "to_stop_id": "IDFM:463171",
      "duration": 315,
      "path": [
        [
          2.3461273251736805,
          48.8625048403394
        ],
        [
          2.3489761968791085,
          48.86370301202431
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73794",
      "to_stop_unique_id": "IDFM:479068"
    },
    {
      "from_stop_id": "IDFM:463209",
      "to_stop_id": "IDFM:22209",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:463209",
      "to_stop_id": "IDFM:22210",
      "duration": 60,
      "path": [
        [
          2.406409459132366,
          48.82608032193611
        ],
        [
          2.413847237068526,
          48.82125590204953
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:70648"
    },
    {
      "from_stop_id": "IDFM:463209",
      "to_stop_id": "IDFM:463277",
      "duration": 60,
      "path": [
        [
          2.402372975541869,
          48.8336104146937
        ],
        [
          2.406409459132366,
          48.82608032193611
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71558",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:463210",
      "to_stop_id": "IDFM:22034",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:463210",
      "to_stop_id": "IDFM:463119",
      "duration": 60,
      "path": [
        [
          2.2848122193525104,
          48.89223258539651
        ],
        [
          2.2881680068067354,
          48.88849338167193
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:71519"
    },
    {
      "from_stop_id": "IDFM:463210",
      "to_stop_id": "IDFM:463258",
      "duration": 60,
      "path": [
        [
          2.2881680068067354,
          48.88849338167193
        ],
        [
          2.2921124821588448,
          48.885652393133846
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:73667",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:463211",
      "to_stop_id": "IDFM:22187",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:71086"
    },
    {
      "from_stop_id": "IDFM:463211",
      "to_stop_id": "IDFM:463051",
      "duration": 120,
      "path": [
        [
          2.2820358715985742,
          48.838785539019476
        ],
        [
          2.2783626618092008,
          48.83666789483108
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:71073"
    },
    {
      "from_stop_id": "IDFM:463211",
      "to_stop_id": "IDFM:463135",
      "duration": 60,
      "path": [
        [
          2.2877944613211754,
          48.841122353680156
        ],
        [
          2.2820358715985742,
          48.838785539019476
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71086",
      "to_stop_unique_id": "IDFM:412992"
    },
    {
      "from_stop_id": "IDFM:463212",
      "to_stop_id": "IDFM:22243",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:463212",
      "to_stop_id": "IDFM:22244",
      "duration": 60,
      "path": [
        [
          2.331962553647662,
          48.906372391923206
        ],
        [
          2.333893080685357,
          48.911958864732505
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72128"
    },
    {
      "from_stop_id": "IDFM:463212",
      "to_stop_id": "IDFM:462976",
      "duration": 60,
      "path": [
        [
          2.333893080685357,
          48.911958864732505
        ],
        [
          2.3435346741834655,
          48.919732608049756
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:463212",
      "to_stop_id": "IDFM:480290",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:463212",
      "to_stop_id": "IDFM:480330",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:463213",
      "to_stop_id": "IDFM:22235",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:463213",
      "to_stop_id": "IDFM:463214",
      "duration": 60,
      "path": [
        [
          2.2971240100835835,
          48.81533836595972
        ],
        [
          2.2984111759006174,
          48.82214529059278
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:463213",
      "to_stop_id": "IDFM:463260",
      "duration": 60,
      "path": [
        [
          2.2984111759006174,
          48.82214529059278
        ],
        [
          2.3053323866574735,
          48.827613464004294
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:73766",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:463214",
      "to_stop_id": "IDFM:22236",
      "duration": 17,
      "path": null,
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:70562"
    },
    {
      "from_stop_id": "IDFM:463214",
      "to_stop_id": "IDFM:22237",
      "duration": 60,
      "path": [
        [
          2.301717705004608,
          48.81075140670139
        ],
        [
          2.2971240100835835,
          48.81533836595972
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:461505"
    },
    {
      "from_stop_id": "IDFM:463214",
      "to_stop_id": "IDFM:463213",
      "duration": 60,
      "path": [
        [
          2.2971240100835835,
          48.81533836595972
        ],
        [
          2.2984111759006174,
          48.82214529059278
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:70562",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:463215",
      "to_stop_id": "IDFM:21936",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:463215",
      "to_stop_id": "IDFM:463039",
      "duration": 60,
      "path": [
        [
          2.401170769368413,
          48.85176243983227
        ],
        [
          2.4060490065790447,
          48.85265013095636
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:479928"
    },
    {
      "from_stop_id": "IDFM:463215",
      "to_stop_id": "IDFM:463279",
      "duration": 60,
      "path": [
        [
          2.4060490065790447,
          48.85265013095636
        ],
        [
          2.410718154027495,
          48.85348263120656
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71703",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:463216",
      "to_stop_id": "IDFM:22383",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71076"
    },
    {
      "from_stop_id": "IDFM:463216",
      "to_stop_id": "IDFM:22391",
      "duration": 60,
      "path": [
        [
          2.3525311653622043,
          48.83589170669027
        ],
        [
          2.351626572605499,
          48.84022624851393
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71100"
    },
    {
      "from_stop_id": "IDFM:463216",
      "to_stop_id": "IDFM:463026",
      "duration": 60,
      "path": [
        [
          2.355330902236442,
          48.83154938471729
        ],
        [
          2.3525311653622043,
          48.83589170669027
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71076",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463217",
      "to_stop_id": "IDFM:22078",
      "duration": 60,
      "path": [
        [
          2.2825838473615496,
          48.87816265364455
        ],
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:463217",
      "to_stop_id": "IDFM:22099",
      "duration": 60,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ],
        [
          2.2584954293691255,
          48.885489388974705
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:71442"
    },
    {
      "from_stop_id": "IDFM:463217",
      "to_stop_id": "IDFM:22100",
      "duration": 0,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:425779",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:463218",
      "to_stop_id": "IDFM:22081",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:463218",
      "to_stop_id": "IDFM:22379",
      "duration": 353,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:463218",
      "to_stop_id": "IDFM:463160",
      "duration": 60,
      "path": [
        [
          2.3458090025909044,
          48.85930591756934
        ],
        [
          2.3409964272720227,
          48.86090733426327
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463218",
      "to_stop_id": "IDFM:463227",
      "duration": 60,
      "path": [
        [
          2.3409964272720227,
          48.86090733426327
        ],
        [
          2.336561574182243,
          48.862366755622624
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463218",
      "to_stop_id": "IDFM:463232",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:73630",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:463219",
      "to_stop_id": "IDFM:22062",
      "duration": 25,
      "path": null,
      "from_stop_unique_id": "IDFM:70671",
      "to_stop_unique_id": "IDFM:70671"
    },
    {
      "from_stop_id": "IDFM:463219",
      "to_stop_id": "IDFM:463153",
      "duration": 120,
      "path": [
        [
          2.2789586605256367,
          48.826823295159116
        ],
        [
          2.273558168023932,
          48.82434428766646
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:70671",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:463220",
      "to_stop_id": "IDFM:22396",
      "duration": 0,
      "path": [
        [
          2.383498053635828,
          48.811210093393306
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70488",
      "to_stop_unique_id": "IDFM:70488"
    },
    {
      "from_stop_id": "IDFM:463220",
      "to_stop_id": "IDFM:22397",
      "duration": 120,
      "path": [
        [
          2.377246886036494,
          48.815836500997094
        ],
        [
          2.383498053635828,
          48.811210093393306
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70488",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:463221",
      "to_stop_id": "IDFM:21913",
      "duration": 360,
      "path": [
        [
          2.4414386664522225,
          48.862313130940244
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ],
        [
          2.4229449336324724,
          48.855754613895044
        ],
        [
          2.4107036110235414,
          48.853563500943025
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71785",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:463221",
      "to_stop_id": "IDFM:21941",
      "duration": 35,
      "path": null,
      "from_stop_unique_id": "IDFM:71785",
      "to_stop_unique_id": "IDFM:71785"
    },
    {
      "from_stop_id": "IDFM:463221",
      "to_stop_id": "IDFM:463176",
      "duration": 60,
      "path": [
        [
          2.4414386664522225,
          48.862313130940244
        ],
        [
          2.4358546126745826,
          48.857992585158016
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71785",
      "to_stop_unique_id": "IDFM:71756"
    },
    {
      "from_stop_id": "IDFM:463222",
      "to_stop_id": "IDFM:26602",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:463222",
      "to_stop_id": "IDFM:463092",
      "duration": 60,
      "path": [
        [
          2.3196193991237846,
          48.818454456190004
        ],
        [
          2.325623760353114,
          48.823394602620716
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70657"
    },
    {
      "from_stop_id": "IDFM:463222",
      "to_stop_id": "IDFM:483277",
      "duration": 60,
      "path": [
        [
          2.3174992240284045,
          48.80979025430712
        ],
        [
          2.3196193991237846,
          48.818454456190004
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70586",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:463223",
      "to_stop_id": "IDFM:21949",
      "duration": 60,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ],
        [
          2.4163499077398614,
          48.879658467973904
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463223",
      "to_stop_id": "IDFM:21999",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:463223",
      "to_stop_id": "IDFM:490787",
      "duration": 60,
      "path": [
        [
          2.4163499077398614,
          48.879658467973904
        ],
        [
          2.427162663175348,
          48.881208070541476
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71909",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:22380",
      "duration": 60,
      "path": [
        [
          2.358412946155939,
          48.82214950607496
        ],
        [
          2.359462523882543,
          48.81903428608761
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:22385",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:22398",
      "duration": 120,
      "path": [
        [
          2.358412946155939,
          48.82214950607496
        ],
        [
          2.362130413802561,
          48.81025257278989
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:463267",
      "duration": 60,
      "path": [
        [
          2.357018567738162,
          48.82615323734341
        ],
        [
          2.358412946155939,
          48.82214950607496
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:490841",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463224",
      "to_stop_id": "IDFM:490848",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463225",
      "to_stop_id": "IDFM:22215",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70426"
    },
    {
      "from_stop_id": "IDFM:463225",
      "to_stop_id": "IDFM:463015",
      "duration": 60,
      "path": [
        [
          2.4459564771573397,
          48.80321905319987
        ],
        [
          2.434924424365985,
          48.80897743416717
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70358"
    },
    {
      "from_stop_id": "IDFM:463225",
      "to_stop_id": "IDFM:463047",
      "duration": 60,
      "path": [
        [
          2.434924424365985,
          48.80897743416717
        ],
        [
          2.422399525054415,
          48.81483550210013
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:70426",
      "to_stop_unique_id": "IDFM:70522"
    },
    {
      "from_stop_id": "IDFM:463226",
      "to_stop_id": "IDFM:22184",
      "duration": 60,
      "path": [
        [
          2.402366739988004,
          48.83707743103578
        ],
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463226",
      "to_stop_id": "IDFM:22206",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71595"
    },
    {
      "from_stop_id": "IDFM:463226",
      "to_stop_id": "IDFM:463050",
      "duration": 60,
      "path": [
        [
          2.4058739129875644,
          48.83501737409391
        ],
        [
          2.402366739988004,
          48.83707743103578
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71595",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:463227",
      "to_stop_id": "IDFM:22075",
      "duration": 60,
      "path": [
        [
          2.336561574182243,
          48.862366755622624
        ],
        [
          2.3297453212172794,
          48.864550163577015
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71284"
    },
    {
      "from_stop_id": "IDFM:463227",
      "to_stop_id": "IDFM:22079",
      "duration": 22,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463227",
      "to_stop_id": "IDFM:22376",
      "duration": 58,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463227",
      "to_stop_id": "IDFM:463218",
      "duration": 60,
      "path": [
        [
          2.3409964272720227,
          48.86090733426327
        ],
        [
          2.336561574182243,
          48.862366755622624
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:463227",
      "to_stop_id": "IDFM:463249",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463228",
      "to_stop_id": "IDFM:22021",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:463228",
      "to_stop_id": "IDFM:22023",
      "duration": 60,
      "path": [
        [
          2.297701123838181,
          48.884929864637186
        ],
        [
          2.291848147336647,
          48.885736266685264
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:463228",
      "to_stop_id": "IDFM:463070",
      "duration": 60,
      "path": [
        [
          2.304673087584434,
          48.8838463907272
        ],
        [
          2.297701123838181,
          48.884929864637186
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71453",
      "to_stop_unique_id": "IDFM:71423"
    },
    {
      "from_stop_id": "IDFM:463229",
      "to_stop_id": "IDFM:22222",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:412687"
    },
    {
      "from_stop_id": "IDFM:463229",
      "to_stop_id": "IDFM:22223",
      "duration": 60,
      "path": [
        [
          2.318323659736018,
          48.83416372097089
        ],
        [
          2.313833364032832,
          48.83174121370108
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:463229",
      "to_stop_id": "IDFM:463184",
      "duration": 60,
      "path": [
        [
          2.3223539967788067,
          48.83852608502253
        ],
        [
          2.318323659736018,
          48.83416372097089
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:412687",
      "to_stop_unique_id": "IDFM:73675"
    },
    {
      "from_stop_id": "IDFM:463230",
      "to_stop_id": "IDFM:22113",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:463230",
      "to_stop_id": "IDFM:22122",
      "duration": 60,
      "path": [
        [
          2.390497596745289,
          48.858089428135756
        ],
        [
          2.3945914186721278,
          48.856199097341126
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71728"
    },
    {
      "from_stop_id": "IDFM:463230",
      "to_stop_id": "IDFM:463285",
      "duration": 60,
      "path": [
        [
          2.3875798912544455,
          48.86244984493746
        ],
        [
          2.390497596745289,
          48.858089428135756
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71751",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463231",
      "to_stop_id": "IDFM:22364",
      "duration": 60,
      "path": [
        [
          2.357430292176306,
          48.85353209666315
        ],
        [
          2.3481609912345776,
          48.856953460785334
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463231",
      "to_stop_id": "IDFM:22378",
      "duration": 18,
      "path": null,
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:463231",
      "to_stop_id": "IDFM:463265",
      "duration": 60,
      "path": [
        [
          2.3618531696972482,
          48.851271408393764
        ],
        [
          2.357430292176306,
          48.85353209666315
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71217",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:463232",
      "to_stop_id": "IDFM:22081",
      "duration": 326,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:463232",
      "to_stop_id": "IDFM:22364",
      "duration": 60,
      "path": [
        [
          2.3481609912345776,
          48.856953460785334
        ],
        [
          2.341776749148542,
          48.85854633926832
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463232",
      "to_stop_id": "IDFM:22379",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:463232",
      "to_stop_id": "IDFM:463218",
      "duration": 342,
      "path": null,
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:73630"
    },
    {
      "from_stop_id": "IDFM:463232",
      "to_stop_id": "IDFM:463249",
      "duration": 60,
      "path": [
        [
          2.341776749148542,
          48.85854633926832
        ],
        [
          2.3370872868854393,
          48.86276538184415
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71253",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463233",
      "to_stop_id": "IDFM:21938",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:70698",
      "to_stop_unique_id": "IDFM:70698"
    },
    {
      "from_stop_id": "IDFM:463233",
      "to_stop_id": "IDFM:463035",
      "duration": 120,
      "path": [
        [
          2.237891101038026,
          48.83191997405451
        ],
        [
          2.2305052170885546,
          48.82966919074605
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:70698",
      "to_stop_unique_id": "IDFM:71034"
    },
    {
      "from_stop_id": "IDFM:463234",
      "to_stop_id": "IDFM:21904",
      "duration": 60,
      "path": [
        [
          2.258280744595537,
          48.847904123629235
        ],
        [
          2.2618218573220084,
          48.84515597018703
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71169",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:463234",
      "to_stop_id": "IDFM:21974",
      "duration": 31,
      "path": null,
      "from_stop_unique_id": "IDFM:71169",
      "to_stop_unique_id": "IDFM:71169"
    },
    {
      "from_stop_id": "IDFM:463235",
      "to_stop_id": "IDFM:22373",
      "duration": 60,
      "path": [
        [
          2.3646245490007405,
          48.820132089232395
        ],
        [
          2.3595297331071694,
          48.81910659655869
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70604"
    },
    {
      "from_stop_id": "IDFM:463235",
      "to_stop_id": "IDFM:22380",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:463235",
      "to_stop_id": "IDFM:22385",
      "duration": 60,
      "path": [
        [
          2.3595297331071694,
          48.81910659655869
        ],
        [
          2.3585387043161337,
          48.82216816831483
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463235",
      "to_stop_id": "IDFM:490841",
      "duration": 303,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463235",
      "to_stop_id": "IDFM:490848",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:70596",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463236",
      "to_stop_id": "IDFM:22053",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:463236",
      "to_stop_id": "IDFM:462973",
      "duration": 60,
      "path": [
        [
          2.359808478084024,
          48.89057957856722
        ],
        [
          2.3500246218070155,
          48.89154760285429
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:463236",
      "to_stop_id": "IDFM:463281",
      "duration": 60,
      "path": [
        [
          2.359103857300198,
          48.89741064164834
        ],
        [
          2.359808478084024,
          48.89057957856722
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71510",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:463237",
      "to_stop_id": "IDFM:21975",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:71190"
    },
    {
      "from_stop_id": "IDFM:463237",
      "to_stop_id": "IDFM:21983",
      "duration": 60,
      "path": [
        [
          2.3482804131694897,
          48.85019546606951
        ],
        [
          2.3448963012120796,
          48.851026876874485
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:463237",
      "to_stop_id": "IDFM:462944",
      "duration": 315,
      "path": null,
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:463237",
      "to_stop_id": "IDFM:463141",
      "duration": 60,
      "path": [
        [
          2.3513280016731763,
          48.84670003526311
        ],
        [
          2.3482804131694897,
          48.85019546606951
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71190",
      "to_stop_unique_id": "IDFM:71153"
    },
    {
      "from_stop_id": "IDFM:463238",
      "to_stop_id": "IDFM:21907",
      "duration": 87,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:463238",
      "to_stop_id": "IDFM:21922",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:463238",
      "to_stop_id": "IDFM:21923",
      "duration": 60,
      "path": [
        [
          2.2638093220226634,
          48.84770893216831
        ],
        [
          2.261511916905205,
          48.84491122756327
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:463238",
      "to_stop_id": "IDFM:463038",
      "duration": 60,
      "path": [
        [
          2.267933291647067,
          48.85243341872557
        ],
        [
          2.2638093220226634,
          48.84770893216831
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71206",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:463239",
      "to_stop_id": "IDFM:21904",
      "duration": 82,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:463239",
      "to_stop_id": "IDFM:21922",
      "duration": 60,
      "path": [
        [
          2.261631583355701,
          48.844880488259605
        ],
        [
          2.2639392129884905,
          48.84767825351756
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71206"
    },
    {
      "from_stop_id": "IDFM:463239",
      "to_stop_id": "IDFM:21923",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:73658"
    },
    {
      "from_stop_id": "IDFM:463239",
      "to_stop_id": "IDFM:21930",
      "duration": 0,
      "path": [
        [
          2.259800845144346,
          48.84258027627653
        ],
        [
          2.261631583355701,
          48.844880488259605
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:463239",
      "to_stop_id": "IDFM:462956",
      "duration": 367,
      "path": null,
      "from_stop_unique_id": "IDFM:73658",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:463240",
      "to_stop_id": "IDFM:22031",
      "duration": 310,
      "path": null,
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:463240",
      "to_stop_id": "IDFM:22117",
      "duration": 0,
      "path": [
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:73671"
    },
    {
      "from_stop_id": "IDFM:463240",
      "to_stop_id": "IDFM:22121",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.309412967374703,
          48.880568770126835
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:463240",
      "to_stop_id": "IDFM:462988",
      "duration": 60,
      "path": [
        [
          2.309412967374703,
          48.880568770126835
        ],
        [
          2.3157614492393397,
          48.88121227337839
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:463240",
      "to_stop_id": "IDFM:463068",
      "duration": 321,
      "path": null,
      "from_stop_unique_id": "IDFM:73671",
      "to_stop_unique_id": "IDFM:71416"
    },
    {
      "from_stop_id": "IDFM:463241",
      "to_stop_id": "IDFM:22205",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:463241",
      "to_stop_id": "IDFM:463168",
      "duration": 60,
      "path": [
        [
          2.3904000136353645,
          48.84400507570895
        ],
        [
          2.395573606140371,
          48.83967393140864
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71614"
    },
    {
      "from_stop_id": "IDFM:463241",
      "to_stop_id": "IDFM:463293",
      "duration": 60,
      "path": [
        [
          2.3869585404965377,
          48.847094767969466
        ],
        [
          2.3904000136353645,
          48.84400507570895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71631",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463242",
      "to_stop_id": "IDFM:22144",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:73653"
    },
    {
      "from_stop_id": "IDFM:463242",
      "to_stop_id": "IDFM:22151",
      "duration": 0,
      "path": [
        [
          2.329887640408421,
          48.83133758557153
        ],
        [
          2.3321281604376116,
          48.83392232305997
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463242",
      "to_stop_id": "IDFM:463118",
      "duration": 60,
      "path": [
        [
          2.3271926250651647,
          48.828152157703514
        ],
        [
          2.329887640408421,
          48.83133758557153
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73653",
      "to_stop_unique_id": "IDFM:71030"
    },
    {
      "from_stop_id": "IDFM:463243",
      "to_stop_id": "IDFM:22116",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:463243",
      "to_stop_id": "IDFM:462983",
      "duration": 60,
      "path": [
        [
          2.3802889829271936,
          48.86919334513237
        ],
        [
          2.3834303882303045,
          48.866390140145434
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71834"
    },
    {
      "from_stop_id": "IDFM:463243",
      "to_stop_id": "IDFM:463285",
      "duration": 60,
      "path": [
        [
          2.3834303882303045,
          48.866390140145434
        ],
        [
          2.3875798912544455,
          48.86244984493746
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71815",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:21932",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:22030",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:22195",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:22196",
      "duration": 60,
      "path": [
        [
          2.3384190255427955,
          48.87173920910264
        ],
        [
          2.3320941299398554,
          48.87066534465599
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71330"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:22381",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:22388",
      "duration": 381,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:463063",
      "duration": 60,
      "path": [
        [
          2.3320941299398554,
          48.87066534465599
        ],
        [
          2.3259702743206723,
          48.8696462363775
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:463144",
      "duration": 350,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:463245",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463244",
      "to_stop_id": "IDFM:463246",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22024",
      "duration": 360,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22030",
      "duration": 119,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22195",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22375",
      "duration": 60,
      "path": [
        [
          2.3344175359665553,
          48.865880289914124
        ],
        [
          2.332572085877118,
          48.870326260852515
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22381",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:22388",
      "duration": 60,
      "path": [
        [
          2.332572085877118,
          48.870326260852515
        ],
        [
          2.3337385944087012,
          48.87313420577469
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:462989",
      "duration": 361,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:463144",
      "duration": 374,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:463244",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463245",
      "to_stop_id": "IDFM:463246",
      "duration": 125,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:21932",
      "duration": 330,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:22030",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:22195",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:22381",
      "duration": 150,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:22388",
      "duration": 334,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:462989",
      "duration": 60,
      "path": [
        [
          2.3363187355954875,
          48.86965909738424
        ],
        [
          2.331128974301561,
          48.87150983278418
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71313"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:463144",
      "duration": 322,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:463145",
      "duration": 336,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:73689"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:463189",
      "duration": 60,
      "path": [
        [
          2.331128974301561,
          48.87150983278418
        ],
        [
          2.32755854621969,
          48.8735493178711
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:463244",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463246",
      "to_stop_id": "IDFM:463245",
      "duration": 160,
      "path": null,
      "from_stop_unique_id": "IDFM:71337",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463249",
      "to_stop_id": "IDFM:22079",
      "duration": 109,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463249",
      "to_stop_id": "IDFM:22375",
      "duration": 60,
      "path": [
        [
          2.3370872868854393,
          48.86276538184415
        ],
        [
          2.3344175359665553,
          48.865880289914124
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463249",
      "to_stop_id": "IDFM:22376",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463249",
      "to_stop_id": "IDFM:463227",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463249",
      "to_stop_id": "IDFM:463232",
      "duration": 60,
      "path": [
        [
          2.341776749148542,
          48.85854633926832
        ],
        [
          2.3370872868854393,
          48.86276538184415
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71297",
      "to_stop_unique_id": "IDFM:71253"
    },
    {
      "from_stop_id": "IDFM:463250",
      "to_stop_id": "IDFM:21902",
      "duration": 60,
      "path": [
        [
          2.364283168306328,
          48.86712186124499
        ],
        [
          2.3746806763746613,
          48.865162111168765
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463250",
      "to_stop_id": "IDFM:22019",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:463250",
      "to_stop_id": "IDFM:463297",
      "duration": 60,
      "path": [
        [
          2.3746806763746613,
          48.865162111168765
        ],
        [
          2.3804600339610773,
          48.86405000324374
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71801",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:463251",
      "to_stop_id": "IDFM:22372",
      "duration": 60,
      "path": [
        [
          2.377362970985953,
          48.81587757774557
        ],
        [
          2.3695112543194927,
          48.821489035562834
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70636"
    },
    {
      "from_stop_id": "IDFM:463251",
      "to_stop_id": "IDFM:22396",
      "duration": 60,
      "path": [
        [
          2.383498053635828,
          48.811210093393306
        ],
        [
          2.377362970985953,
          48.81587757774557
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70488"
    },
    {
      "from_stop_id": "IDFM:463251",
      "to_stop_id": "IDFM:22397",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70537",
      "to_stop_unique_id": "IDFM:70537"
    },
    {
      "from_stop_id": "IDFM:463252",
      "to_stop_id": "IDFM:22115",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463252",
      "to_stop_id": "IDFM:22129",
      "duration": 75,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463252",
      "to_stop_id": "IDFM:463113",
      "duration": 60,
      "path": [
        [
          2.3324843754343627,
          48.88376635447715
        ],
        [
          2.3278987090661456,
          48.88374519677981
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71420"
    },
    {
      "from_stop_id": "IDFM:463252",
      "to_stop_id": "IDFM:463253",
      "duration": 86,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463252",
      "to_stop_id": "IDFM:463296",
      "duration": 60,
      "path": [
        [
          2.3278987090661456,
          48.88374519677981
        ],
        [
          2.3213591738229726,
          48.88234581879614
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:22115",
      "duration": 149,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:22129",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:462975",
      "duration": 60,
      "path": [
        [
          2.3257141171661355,
          48.88743365673691
        ],
        [
          2.327268983025867,
          48.883723673452664
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:463066",
      "duration": 60,
      "path": [
        [
          2.327268983025867,
          48.883723673452664
        ],
        [
          2.3269450274387555,
          48.87971082693934
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71382"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:463252",
      "duration": 120,
      "path": null,
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463253",
      "to_stop_id": "IDFM:478395",
      "duration": 60,
      "path": [
        [
          2.325414931297021,
          48.88736901850862
        ],
        [
          2.327268983025867,
          48.883723673452664
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71435",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:463254",
      "to_stop_id": "IDFM:21951",
      "duration": 0,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:463254",
      "to_stop_id": "IDFM:24682",
      "duration": 80,
      "path": null,
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:463254",
      "to_stop_id": "IDFM:463192",
      "duration": 60,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.389325378910096,
          48.875247348641416
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:463254",
      "to_stop_id": "IDFM:463319",
      "duration": 60,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.3986752529487725,
          48.875519513947125
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71885",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:463255",
      "to_stop_id": "IDFM:22377",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71363"
    },
    {
      "from_stop_id": "IDFM:463255",
      "to_stop_id": "IDFM:22390",
      "duration": 60,
      "path": [
        [
          2.344446309702274,
          48.875963285075564
        ],
        [
          2.3487939912846394,
          48.877079707510546
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71351"
    },
    {
      "from_stop_id": "IDFM:463255",
      "to_stop_id": "IDFM:463001",
      "duration": 60,
      "path": [
        [
          2.3487939912846394,
          48.877079707510546
        ],
        [
          2.358120525273069,
          48.876271214541624
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71363",
      "to_stop_unique_id": "IDFM:71359"
    },
    {
      "from_stop_id": "IDFM:463256",
      "to_stop_id": "IDFM:22111",
      "duration": 50,
      "path": null,
      "from_stop_unique_id": "IDFM:474152",
      "to_stop_unique_id": "IDFM:474152"
    },
    {
      "from_stop_id": "IDFM:463256",
      "to_stop_id": "IDFM:463322",
      "duration": 120,
      "path": [
        [
          2.2858287659129797,
          48.869926082410636
        ],
        [
          2.2778093495879346,
          48.8717351159749
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:474152",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:463257",
      "to_stop_id": "IDFM:22078",
      "duration": 57,
      "path": null,
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:71379"
    },
    {
      "from_stop_id": "IDFM:463257",
      "to_stop_id": "IDFM:22100",
      "duration": 60,
      "path": [
        [
          2.271904762259129,
          48.88129083793358
        ],
        [
          2.2822896139411903,
          48.877805650437985
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:425779"
    },
    {
      "from_stop_id": "IDFM:463257",
      "to_stop_id": "IDFM:463121",
      "duration": 60,
      "path": [
        [
          2.2822896139411903,
          48.877805650437985
        ],
        [
          2.289435418542214,
          48.87566737659971
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71379",
      "to_stop_unique_id": "IDFM:71348"
    },
    {
      "from_stop_id": "IDFM:463258",
      "to_stop_id": "IDFM:22021",
      "duration": 60,
      "path": [
        [
          2.2921124821588448,
          48.885652393133846
        ],
        [
          2.2976831860125837,
          48.88484432273985
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:71453"
    },
    {
      "from_stop_id": "IDFM:463258",
      "to_stop_id": "IDFM:22023",
      "duration": 27,
      "path": null,
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:71450"
    },
    {
      "from_stop_id": "IDFM:463258",
      "to_stop_id": "IDFM:463210",
      "duration": 60,
      "path": [
        [
          2.2881680068067354,
          48.88849338167193
        ],
        [
          2.2921124821588448,
          48.885652393133846
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71450",
      "to_stop_unique_id": "IDFM:73667"
    },
    {
      "from_stop_id": "IDFM:463259",
      "to_stop_id": "IDFM:21914",
      "duration": 24,
      "path": null,
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71084"
    },
    {
      "from_stop_id": "IDFM:463259",
      "to_stop_id": "IDFM:21940",
      "duration": 120,
      "path": [
        [
          2.2567894964207142,
          48.83799728287381
        ],
        [
          2.24346821405889,
          48.83380810724753
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71054"
    },
    {
      "from_stop_id": "IDFM:463259",
      "to_stop_id": "IDFM:462956",
      "duration": 60,
      "path": [
        [
          2.25967486021195,
          48.842624465259945
        ],
        [
          2.2567894964207142,
          48.83799728287381
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71084",
      "to_stop_unique_id": "IDFM:71124"
    },
    {
      "from_stop_id": "IDFM:463260",
      "to_stop_id": "IDFM:22221",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:71006"
    },
    {
      "from_stop_id": "IDFM:463260",
      "to_stop_id": "IDFM:463085",
      "duration": 60,
      "path": [
        [
          2.3053323866574735,
          48.827613464004294
        ],
        [
          2.313860489344306,
          48.83175036306747
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:71039"
    },
    {
      "from_stop_id": "IDFM:463260",
      "to_stop_id": "IDFM:463213",
      "duration": 60,
      "path": [
        [
          2.2984111759006174,
          48.82214529059278
        ],
        [
          2.3053323866574735,
          48.827613464004294
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71006",
      "to_stop_unique_id": "IDFM:73766"
    },
    {
      "from_stop_id": "IDFM:463261",
      "to_stop_id": "IDFM:22042",
      "duration": 47,
      "path": null,
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:71045"
    },
    {
      "from_stop_id": "IDFM:463261",
      "to_stop_id": "IDFM:462949",
      "duration": 60,
      "path": [
        [
          2.2963913864809173,
          48.83713505380496
        ],
        [
          2.2877417091423347,
          48.83251953251351
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:463261",
      "to_stop_id": "IDFM:463153",
      "duration": 60,
      "path": [
        [
          2.2877417091423347,
          48.83251953251351
        ],
        [
          2.2789586605256367,
          48.826823295159116
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71045",
      "to_stop_unique_id": "IDFM:70990"
    },
    {
      "from_stop_id": "IDFM:463262",
      "to_stop_id": "IDFM:21947",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463262",
      "to_stop_id": "IDFM:21949",
      "duration": 92,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463262",
      "to_stop_id": "IDFM:463071",
      "duration": 60,
      "path": [
        [
          2.4069512666209,
          48.87663999703494
        ],
        [
          2.404361344448241,
          48.871887658077014
        ]
      ],
      "route_id": "IDFM:C01386",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71860"
    },
    {
      "from_stop_id": "IDFM:463262",
      "to_stop_id": "IDFM:463263",
      "duration": 92,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463263",
      "to_stop_id": "IDFM:21947",
      "duration": 106,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463263",
      "to_stop_id": "IDFM:21949",
      "duration": 0,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463263",
      "to_stop_id": "IDFM:21993",
      "duration": 60,
      "path": [
        [
          2.4065226763982275,
          48.87723143067265
        ],
        [
          2.3986480899605933,
          48.87551038418075
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:463263",
      "to_stop_id": "IDFM:21999",
      "duration": 60,
      "path": [
        [
          2.4162978208011863,
          48.87974365006289
        ],
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:463263",
      "to_stop_id": "IDFM:463262",
      "duration": 156,
      "path": null,
      "from_stop_unique_id": "IDFM:71889",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:21920",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:21933",
      "duration": 60,
      "path": [
        [
          2.3538223820093616,
          48.86950305730265
        ],
        [
          2.3484940979375373,
          48.8706433160169
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:22134",
      "duration": 107,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:22159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:462963",
      "duration": 60,
      "path": [
        [
          2.3633023407804536,
          48.86751235781114
        ],
        [
          2.3538223820093616,
          48.86950305730265
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:463312",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463264",
      "to_stop_id": "IDFM:463313",
      "duration": 116,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463265",
      "to_stop_id": "IDFM:22369",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71201"
    },
    {
      "from_stop_id": "IDFM:463265",
      "to_stop_id": "IDFM:22386",
      "duration": 60,
      "path": [
        [
          2.354798309820857,
          48.84596333903808
        ],
        [
          2.3618531696972482,
          48.851271408393764
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71148"
    },
    {
      "from_stop_id": "IDFM:463265",
      "to_stop_id": "IDFM:463231",
      "duration": 60,
      "path": [
        [
          2.3618531696972482,
          48.851271408393764
        ],
        [
          2.357430292176306,
          48.85353209666315
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71201",
      "to_stop_unique_id": "IDFM:71217"
    },
    {
      "from_stop_id": "IDFM:463266",
      "to_stop_id": "IDFM:22094",
      "duration": 60,
      "path": [
        [
          2.2981132886172433,
          48.878227730862385
        ],
        [
          2.2958117752357627,
          48.87499457617108
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463266",
      "to_stop_id": "IDFM:22108",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71367"
    },
    {
      "from_stop_id": "IDFM:463266",
      "to_stop_id": "IDFM:463154",
      "duration": 60,
      "path": [
        [
          2.3032943624252122,
          48.87926525411311
        ],
        [
          2.2981132886172433,
          48.878227730862385
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71367",
      "to_stop_unique_id": "IDFM:71376"
    },
    {
      "from_stop_id": "IDFM:463267",
      "to_stop_id": "IDFM:22365",
      "duration": 60,
      "path": [
        [
          2.355303769754901,
          48.83154024475565
        ],
        [
          2.357018567738162,
          48.82615323734341
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:71033"
    },
    {
      "from_stop_id": "IDFM:463267",
      "to_stop_id": "IDFM:22370",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:71001"
    },
    {
      "from_stop_id": "IDFM:463267",
      "to_stop_id": "IDFM:463224",
      "duration": 60,
      "path": [
        [
          2.357018567738162,
          48.82615323734341
        ],
        [
          2.358412946155939,
          48.82214950607496
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71001",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:463268",
      "to_stop_id": "IDFM:21917",
      "duration": 96,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463268",
      "to_stop_id": "IDFM:22164",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463268",
      "to_stop_id": "IDFM:22180",
      "duration": 0,
      "path": [
        [
          2.2900328376074373,
          48.8668483434173
        ],
        [
          2.2874927969664416,
          48.86348766501957
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71301"
    },
    {
      "from_stop_id": "IDFM:463268",
      "to_stop_id": "IDFM:463101",
      "duration": 60,
      "path": [
        [
          2.2874927969664416,
          48.86348766501957
        ],
        [
          2.2855802300954733,
          48.8575315296204
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71237"
    },
    {
      "from_stop_id": "IDFM:463268",
      "to_stop_id": "IDFM:463318",
      "duration": 81,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463269",
      "to_stop_id": "IDFM:22400",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70143",
      "to_stop_unique_id": "IDFM:70143"
    },
    {
      "from_stop_id": "IDFM:463269",
      "to_stop_id": "IDFM:463324",
      "duration": 60,
      "path": [
        [
          2.368195583812103,
          48.79630822122641
        ],
        [
          2.3677168990538426,
          48.787572501132765
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70143",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:463276",
      "to_stop_id": "IDFM:22022",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71799"
    },
    {
      "from_stop_id": "IDFM:463276",
      "to_stop_id": "IDFM:462991",
      "duration": 60,
      "path": [
        [
          2.4087542211895427,
          48.86453937293447
        ],
        [
          2.3973588775666475,
          48.86497267606974
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:463276",
      "to_stop_id": "IDFM:463174",
      "duration": 60,
      "path": [
        [
          2.415921484287642,
          48.86539746068654
        ],
        [
          2.4087542211895427,
          48.86453937293447
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71799",
      "to_stop_unique_id": "IDFM:71817"
    },
    {
      "from_stop_id": "IDFM:463277",
      "to_stop_id": "IDFM:22207",
      "duration": 60,
      "path": [
        [
          2.405749381400099,
          48.8350662204656
        ],
        [
          2.402372975541869,
          48.8336104146937
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71591"
    },
    {
      "from_stop_id": "IDFM:463277",
      "to_stop_id": "IDFM:22208",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71579"
    },
    {
      "from_stop_id": "IDFM:463277",
      "to_stop_id": "IDFM:463209",
      "duration": 60,
      "path": [
        [
          2.402372975541869,
          48.8336104146937
        ],
        [
          2.406409459132366,
          48.82608032193611
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71579",
      "to_stop_unique_id": "IDFM:71558"
    },
    {
      "from_stop_id": "IDFM:463278",
      "to_stop_id": "IDFM:22219",
      "duration": 51,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:463278",
      "to_stop_id": "IDFM:462978",
      "duration": 60,
      "path": [
        [
          2.31320835075881,
          48.89443128801746
        ],
        [
          2.3199048172093666,
          48.89065187119005
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:73661"
    },
    {
      "from_stop_id": "IDFM:463278",
      "to_stop_id": "IDFM:463086",
      "duration": 60,
      "path": [
        [
          2.305726300569584,
          48.90344880444455
        ],
        [
          2.31320835075881,
          48.89443128801746
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:72118"
    },
    {
      "from_stop_id": "IDFM:463278",
      "to_stop_id": "IDFM:480531",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:463278",
      "to_stop_id": "IDFM:480541",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:463279",
      "to_stop_id": "IDFM:21913",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71710"
    },
    {
      "from_stop_id": "IDFM:463279",
      "to_stop_id": "IDFM:21942",
      "duration": 60,
      "path": [
        [
          2.410718154027495,
          48.85348263120656
        ],
        [
          2.4229730864963517,
          48.855673808550314
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71725"
    },
    {
      "from_stop_id": "IDFM:463279",
      "to_stop_id": "IDFM:463215",
      "duration": 60,
      "path": [
        [
          2.4060490065790447,
          48.85265013095636
        ],
        [
          2.410718154027495,
          48.85348263120656
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71710",
      "to_stop_unique_id": "IDFM:71703"
    },
    {
      "from_stop_id": "IDFM:463280",
      "to_stop_id": "IDFM:22003",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:71989"
    },
    {
      "from_stop_id": "IDFM:463280",
      "to_stop_id": "IDFM:22017",
      "duration": 60,
      "path": [
        [
          2.3921229723281483,
          48.88845921134684
        ],
        [
          2.4032119887626737,
          48.89128939386479
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:72004"
    },
    {
      "from_stop_id": "IDFM:463280",
      "to_stop_id": "IDFM:463027",
      "duration": 60,
      "path": [
        [
          2.386652016759884,
          48.88691595319499
        ],
        [
          2.3921229723281483,
          48.88845921134684
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71989",
      "to_stop_unique_id": "IDFM:71972"
    },
    {
      "from_stop_id": "IDFM:463281",
      "to_stop_id": "IDFM:22041",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:72064"
    },
    {
      "from_stop_id": "IDFM:463281",
      "to_stop_id": "IDFM:24679",
      "duration": 120,
      "path": [
        [
          2.365777177193171,
          48.906763048164294
        ],
        [
          2.359103857300198,
          48.89741064164834
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:463281",
      "to_stop_id": "IDFM:463236",
      "duration": 60,
      "path": [
        [
          2.359103857300198,
          48.89741064164834
        ],
        [
          2.359808478084024,
          48.89057957856722
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72064",
      "to_stop_unique_id": "IDFM:71510"
    },
    {
      "from_stop_id": "IDFM:463282",
      "to_stop_id": "IDFM:24684",
      "duration": 0,
      "path": [
        [
          2.398580770693528,
          48.8801595806643
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71911",
      "to_stop_unique_id": "IDFM:71911"
    },
    {
      "from_stop_id": "IDFM:463282",
      "to_stop_id": "IDFM:24687",
      "duration": 60,
      "path": [
        [
          2.398580770693528,
          48.8801595806643
        ],
        [
          2.393228471227492,
          48.88194921492064
        ]
      ],
      "route_id": "IDFM:C01387",
      "from_stop_unique_id": "IDFM:71911",
      "to_stop_unique_id": "IDFM:71930"
    },
    {
      "from_stop_id": "IDFM:463283",
      "to_stop_id": "IDFM:21960",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463283",
      "to_stop_id": "IDFM:22375",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463283",
      "to_stop_id": "IDFM:22376",
      "duration": 60,
      "path": [
        [
          2.33429208315197,
          48.86583012818995
        ],
        [
          2.337007354765684,
          48.862683997769665
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71297"
    },
    {
      "from_stop_id": "IDFM:463283",
      "to_stop_id": "IDFM:22381",
      "duration": 60,
      "path": [
        [
          2.3324387808144875,
          48.87030303390994
        ],
        [
          2.33429208315197,
          48.86583012818995
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71337"
    },
    {
      "from_stop_id": "IDFM:463283",
      "to_stop_id": "IDFM:463284",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463284",
      "to_stop_id": "IDFM:21960",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463284",
      "to_stop_id": "IDFM:22375",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463284",
      "to_stop_id": "IDFM:463064",
      "duration": 60,
      "path": [
        [
          2.3347388358754153,
          48.86580563193237
        ],
        [
          2.3258757399808627,
          48.870630484228144
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71324"
    },
    {
      "from_stop_id": "IDFM:463284",
      "to_stop_id": "IDFM:463159",
      "duration": 60,
      "path": [
        [
          2.346411849769497,
          48.859556532726764
        ],
        [
          2.3347388358754153,
          48.86580563193237
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:71264"
    },
    {
      "from_stop_id": "IDFM:463284",
      "to_stop_id": "IDFM:463283",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73788",
      "to_stop_unique_id": "IDFM:73788"
    },
    {
      "from_stop_id": "IDFM:463285",
      "to_stop_id": "IDFM:22020",
      "duration": 89,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463285",
      "to_stop_id": "IDFM:22112",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463285",
      "to_stop_id": "IDFM:463230",
      "duration": 60,
      "path": [
        [
          2.3875798912544455,
          48.86244984493746
        ],
        [
          2.390497596745289,
          48.858089428135756
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71751"
    },
    {
      "from_stop_id": "IDFM:463285",
      "to_stop_id": "IDFM:463243",
      "duration": 60,
      "path": [
        [
          2.3834303882303045,
          48.866390140145434
        ],
        [
          2.3875798912544455,
          48.86244984493746
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71815"
    },
    {
      "from_stop_id": "IDFM:463285",
      "to_stop_id": "IDFM:463286",
      "duration": 95,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463286",
      "to_stop_id": "IDFM:22020",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463286",
      "to_stop_id": "IDFM:22025",
      "duration": 60,
      "path": [
        [
          2.3862311113287342,
          48.86304099836643
        ],
        [
          2.380486332251813,
          48.86413108020325
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:463286",
      "to_stop_id": "IDFM:22112",
      "duration": 100,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463286",
      "to_stop_id": "IDFM:462991",
      "duration": 60,
      "path": [
        [
          2.3973588775666475,
          48.86497267606974
        ],
        [
          2.3862311113287342,
          48.86304099836643
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71808"
    },
    {
      "from_stop_id": "IDFM:463286",
      "to_stop_id": "IDFM:463285",
      "duration": 94,
      "path": null,
      "from_stop_unique_id": "IDFM:71787",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463287",
      "to_stop_id": "IDFM:21991",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71864"
    },
    {
      "from_stop_id": "IDFM:463287",
      "to_stop_id": "IDFM:21995",
      "duration": 0,
      "path": [
        [
          2.389325378910096,
          48.875247348641416
        ],
        [
          2.3852029155695313,
          48.87381879018058
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71868"
    },
    {
      "from_stop_id": "IDFM:463287",
      "to_stop_id": "IDFM:463110",
      "duration": 60,
      "path": [
        [
          2.3852029155695313,
          48.87381879018058
        ],
        [
          2.376684607718542,
          48.87198955568015
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71864",
      "to_stop_unique_id": "IDFM:71853"
    },
    {
      "from_stop_id": "IDFM:463288",
      "to_stop_id": "IDFM:22161",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:71597"
    },
    {
      "from_stop_id": "IDFM:463288",
      "to_stop_id": "IDFM:22174",
      "duration": 0,
      "path": [
        [
          2.372766248948172,
          48.8370742706096
        ],
        [
          2.368081282972743,
          48.83496308210916
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:73651"
    },
    {
      "from_stop_id": "IDFM:463288",
      "to_stop_id": "IDFM:463128",
      "duration": 60,
      "path": [
        [
          2.3794765872566757,
          48.84018509185968
        ],
        [
          2.372766248948172,
          48.8370742706096
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71597",
      "to_stop_unique_id": "IDFM:71607"
    },
    {
      "from_stop_id": "IDFM:463289",
      "to_stop_id": "IDFM:22004",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:71637"
    },
    {
      "from_stop_id": "IDFM:463289",
      "to_stop_id": "IDFM:463018",
      "duration": 60,
      "path": [
        [
          2.3687984466220384,
          48.85330031648217
        ],
        [
          2.365884650750405,
          48.846427325479766
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463289",
      "to_stop_id": "IDFM:463023",
      "duration": 60,
      "path": [
        [
          2.365884650750405,
          48.846427325479766
        ],
        [
          2.3654431802574227,
          48.842395852858516
        ]
      ],
      "route_id": "IDFM:C01375",
      "from_stop_unique_id": "IDFM:71637",
      "to_stop_unique_id": "IDFM:71135"
    },
    {
      "from_stop_id": "IDFM:463290",
      "to_stop_id": "IDFM:21915",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:71243"
    },
    {
      "from_stop_id": "IDFM:463290",
      "to_stop_id": "IDFM:21929",
      "duration": 60,
      "path": [
        [
          2.2740962446697224,
          48.85809196689865
        ],
        [
          2.269948157934635,
          48.85550355839564
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:474150"
    },
    {
      "from_stop_id": "IDFM:463290",
      "to_stop_id": "IDFM:463038",
      "duration": 60,
      "path": [
        [
          2.269948157934635,
          48.85550355839564
        ],
        [
          2.267933291647067,
          48.85243341872557
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71243",
      "to_stop_unique_id": "IDFM:73657"
    },
    {
      "from_stop_id": "IDFM:463291",
      "to_stop_id": "IDFM:22131",
      "duration": 30,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463291",
      "to_stop_id": "IDFM:22135",
      "duration": 60,
      "path": [
        [
          2.3307802165662403,
          48.83910415024143
        ],
        [
          2.32902391746117,
          48.842242093452064
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71117"
    },
    {
      "from_stop_id": "IDFM:463291",
      "to_stop_id": "IDFM:22151",
      "duration": 60,
      "path": [
        [
          2.3321281604376116,
          48.83392232305997
        ],
        [
          2.3307802165662403,
          48.83910415024143
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463291",
      "to_stop_id": "IDFM:22155",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463291",
      "to_stop_id": "IDFM:463292",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463292",
      "to_stop_id": "IDFM:22131",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463292",
      "to_stop_id": "IDFM:22155",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463292",
      "to_stop_id": "IDFM:463100",
      "duration": 60,
      "path": [
        [
          2.3252865779432965,
          48.84090350169825
        ],
        [
          2.3304144712354793,
          48.8390661288004
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71108"
    },
    {
      "from_stop_id": "IDFM:463292",
      "to_stop_id": "IDFM:463103",
      "duration": 60,
      "path": [
        [
          2.3304144712354793,
          48.8390661288004
        ],
        [
          2.332803776289825,
          48.83424986732888
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463292",
      "to_stop_id": "IDFM:463291",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71088",
      "to_stop_unique_id": "IDFM:71088"
    },
    {
      "from_stop_id": "IDFM:463293",
      "to_stop_id": "IDFM:22076",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463293",
      "to_stop_id": "IDFM:22098",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463293",
      "to_stop_id": "IDFM:463172",
      "duration": 60,
      "path": [
        [
          2.384028566383108,
          48.85011054413369
        ],
        [
          2.3869585404965377,
          48.847094767969466
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:74001"
    },
    {
      "from_stop_id": "IDFM:463293",
      "to_stop_id": "IDFM:463241",
      "duration": 60,
      "path": [
        [
          2.3869585404965377,
          48.847094767969466
        ],
        [
          2.3904000136353645,
          48.84400507570895
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71631"
    },
    {
      "from_stop_id": "IDFM:463293",
      "to_stop_id": "IDFM:463294",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463294",
      "to_stop_id": "IDFM:22076",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463294",
      "to_stop_id": "IDFM:22098",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463294",
      "to_stop_id": "IDFM:462943",
      "duration": 60,
      "path": [
        [
          2.395825965451431,
          48.84816963759798
        ],
        [
          2.3858367760086865,
          48.84734982784612
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463294",
      "to_stop_id": "IDFM:463197",
      "duration": 60,
      "path": [
        [
          2.3858367760086865,
          48.84734982784612
        ],
        [
          2.373125016776689,
          48.84575948671397
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:73626"
    },
    {
      "from_stop_id": "IDFM:463294",
      "to_stop_id": "IDFM:463293",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71654",
      "to_stop_unique_id": "IDFM:71654"
    },
    {
      "from_stop_id": "IDFM:463295",
      "to_stop_id": "IDFM:22371",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:71977"
    },
    {
      "from_stop_id": "IDFM:463295",
      "to_stop_id": "IDFM:22392",
      "duration": 60,
      "path": [
        [
          2.37693573698238,
          48.8908857589288
        ],
        [
          2.373669464743196,
          48.888157262065796
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:72013"
    },
    {
      "from_stop_id": "IDFM:463295",
      "to_stop_id": "IDFM:463008",
      "duration": 60,
      "path": [
        [
          2.373669464743196,
          48.888157262065796
        ],
        [
          2.3688985490576733,
          48.88437303056126
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:71977",
      "to_stop_unique_id": "IDFM:71961"
    },
    {
      "from_stop_id": "IDFM:463296",
      "to_stop_id": "IDFM:22106",
      "duration": 60,
      "path": [
        [
          2.3213591738229726,
          48.88234581879614
        ],
        [
          2.3157165772440385,
          48.88130644704797
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71403"
    },
    {
      "from_stop_id": "IDFM:463296",
      "to_stop_id": "IDFM:22110",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71408"
    },
    {
      "from_stop_id": "IDFM:463296",
      "to_stop_id": "IDFM:463252",
      "duration": 60,
      "path": [
        [
          2.3278987090661456,
          48.88374519677981
        ],
        [
          2.3213591738229726,
          48.88234581879614
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71408",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:463297",
      "to_stop_id": "IDFM:22020",
      "duration": 60,
      "path": [
        [
          2.3804600339610773,
          48.86405000324374
        ],
        [
          2.386157046298338,
          48.86296417435419
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71787"
    },
    {
      "from_stop_id": "IDFM:463297",
      "to_stop_id": "IDFM:22025",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71795"
    },
    {
      "from_stop_id": "IDFM:463297",
      "to_stop_id": "IDFM:463250",
      "duration": 60,
      "path": [
        [
          2.3746806763746613,
          48.865162111168765
        ],
        [
          2.3804600339610773,
          48.86405000324374
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71795",
      "to_stop_unique_id": "IDFM:71801"
    },
    {
      "from_stop_id": "IDFM:463298",
      "to_stop_id": "IDFM:21934",
      "duration": 60,
      "path": [
        [
          2.389429968854563,
          48.85236862327955
        ],
        [
          2.3848878986690423,
          48.85500743705073
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:463298",
      "to_stop_id": "IDFM:22442",
      "duration": 37,
      "path": null,
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:71694"
    },
    {
      "from_stop_id": "IDFM:463298",
      "to_stop_id": "IDFM:462942",
      "duration": 60,
      "path": [
        [
          2.395921369214201,
          48.848979537674516
        ],
        [
          2.389429968854563,
          48.85236862327955
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71694",
      "to_stop_unique_id": "IDFM:71673"
    },
    {
      "from_stop_id": "IDFM:463299",
      "to_stop_id": "IDFM:21910",
      "duration": 14,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:463299",
      "to_stop_id": "IDFM:21916",
      "duration": 60,
      "path": [
        [
          2.3802080115879227,
          48.857735295553454
        ],
        [
          2.3739964519221886,
          48.861480218467655
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:463299",
      "to_stop_id": "IDFM:22002",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:463299",
      "to_stop_id": "IDFM:463054",
      "duration": 60,
      "path": [
        [
          2.3739964519221886,
          48.861480218467655
        ],
        [
          2.3682236708807456,
          48.86487188759515
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:71803"
    },
    {
      "from_stop_id": "IDFM:463299",
      "to_stop_id": "IDFM:463096",
      "duration": 298,
      "path": null,
      "from_stop_unique_id": "IDFM:71781",
      "to_stop_unique_id": "IDFM:73648"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:21911",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:73690"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:21924",
      "duration": 60,
      "path": [
        [
          2.321031570403537,
          48.874632152132925
        ],
        [
          2.3146430401104467,
          48.8737728492328
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:21962",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:21963",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:21964",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:22218",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:462969",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:462970",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:462971",
      "duration": 480,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:462972",
      "duration": 140,
      "path": null,
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463300",
      "to_stop_id": "IDFM:463188",
      "duration": 60,
      "path": [
        [
          2.327677208279404,
          48.8737568325288
        ],
        [
          2.321031570403537,
          48.874632152132925
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:73690",
      "to_stop_unique_id": "IDFM:482368"
    },
    {
      "from_stop_id": "IDFM:463301",
      "to_stop_id": "IDFM:22239",
      "duration": 120,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ],
        [
          2.343443653352174,
          48.91980854982986
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:463301",
      "to_stop_id": "IDFM:22240",
      "duration": 60,
      "path": [
        [
          2.3592958408126816,
          48.93678306532419
        ],
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:463301",
      "to_stop_id": "IDFM:22241",
      "duration": 0,
      "path": [
        [
          2.3561729251015997,
          48.929922750445755
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72285",
      "to_stop_unique_id": "IDFM:72285"
    },
    {
      "from_stop_id": "IDFM:463302",
      "to_stop_id": "IDFM:22242",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72358",
      "to_stop_unique_id": "IDFM:72358"
    },
    {
      "from_stop_id": "IDFM:463302",
      "to_stop_id": "IDFM:463126",
      "duration": 120,
      "path": [
        [
          2.359431626687363,
          48.9367747982655
        ],
        [
          2.364530774042862,
          48.9458307173959
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:72358",
      "to_stop_unique_id": "IDFM:72326"
    },
    {
      "from_stop_id": "IDFM:463303",
      "to_stop_id": "IDFM:22217",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:463303",
      "to_stop_id": "IDFM:463083",
      "duration": 60,
      "path": [
        [
          2.3142859461805556,
          48.85129155093847
        ],
        [
          2.316535081176305,
          48.84699198555429
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463303",
      "to_stop_id": "IDFM:463320",
      "duration": 60,
      "path": [
        [
          2.3149776470363417,
          48.85662420264694
        ],
        [
          2.3142859461805556,
          48.85129155093847
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71204",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:463304",
      "to_stop_id": "IDFM:22044",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71366"
    },
    {
      "from_stop_id": "IDFM:463304",
      "to_stop_id": "IDFM:22050",
      "duration": 60,
      "path": [
        [
          2.3374546190400913,
          48.87836199275949
        ],
        [
          2.3378736192226386,
          48.8760350312806
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463304",
      "to_stop_id": "IDFM:463062",
      "duration": 330,
      "path": null,
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463304",
      "to_stop_id": "IDFM:463089",
      "duration": 60,
      "path": [
        [
          2.337211164701128,
          48.88202093206733
        ],
        [
          2.3374546190400913,
          48.87836199275949
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71366",
      "to_stop_unique_id": "IDFM:71409"
    },
    {
      "from_stop_id": "IDFM:463305",
      "to_stop_id": "IDFM:22160",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71041"
    },
    {
      "from_stop_id": "IDFM:463305",
      "to_stop_id": "IDFM:463103",
      "duration": 60,
      "path": [
        [
          2.332803776289825,
          48.83424986732888
        ],
        [
          2.3370965389054956,
          48.832857226540206
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71056"
    },
    {
      "from_stop_id": "IDFM:463305",
      "to_stop_id": "IDFM:463186",
      "duration": 60,
      "path": [
        [
          2.3370965389054956,
          48.832857226540206
        ],
        [
          2.343438236678704,
          48.831115947616446
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71041",
      "to_stop_unique_id": "IDFM:71026"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:21983",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73619"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:22137",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73620"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:22145",
      "duration": 60,
      "path": [
        [
          2.339486020317896,
          48.85210334038093
        ],
        [
          2.343991799033288,
          48.85359407756809
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:22150",
      "duration": 356,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:462998",
      "duration": 379,
      "path": null,
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:73618"
    },
    {
      "from_stop_id": "IDFM:463306",
      "to_stop_id": "IDFM:463161",
      "duration": 60,
      "path": [
        [
          2.343991799033288,
          48.85359407756809
        ],
        [
          2.347361051762225,
          48.85492106147581
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73620",
      "to_stop_unique_id": "IDFM:71223"
    },
    {
      "from_stop_id": "IDFM:463307",
      "to_stop_id": "IDFM:22074",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:71222"
    },
    {
      "from_stop_id": "IDFM:463307",
      "to_stop_id": "IDFM:22089",
      "duration": 60,
      "path": [
        [
          2.368718961034089,
          48.85205429349772
        ],
        [
          2.3609713728017505,
          48.85527781668134
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:463564"
    },
    {
      "from_stop_id": "IDFM:463307",
      "to_stop_id": "IDFM:22091",
      "duration": 60,
      "path": [
        [
          2.3609713728017505,
          48.85527781668134
        ],
        [
          2.3520676701391,
          48.85735240518587
        ]
      ],
      "route_id": "IDFM:C01371",
      "from_stop_unique_id": "IDFM:71222",
      "to_stop_unique_id": "IDFM:415852"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:22049",
      "duration": 199,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:22051",
      "duration": 306,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:22138",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71184"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:462965",
      "duration": 313,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71138"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:462968",
      "duration": 190,
      "path": null,
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:73640"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:462992",
      "duration": 60,
      "path": [
        [
          2.327054815066312,
          48.84700665158937
        ],
        [
          2.330953550650454,
          48.85113858957915
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71202"
    },
    {
      "from_stop_id": "IDFM:463308",
      "to_stop_id": "IDFM:462996",
      "duration": 60,
      "path": [
        [
          2.3243858698057784,
          48.84391578859517
        ],
        [
          2.327054815066312,
          48.84700665158937
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71184",
      "to_stop_unique_id": "IDFM:71139"
    },
    {
      "from_stop_id": "IDFM:463309",
      "to_stop_id": "IDFM:22200",
      "duration": 304,
      "path": null,
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463309",
      "to_stop_id": "IDFM:22201",
      "duration": 23,
      "path": null,
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71777"
    },
    {
      "from_stop_id": "IDFM:463309",
      "to_stop_id": "IDFM:463048",
      "duration": 60,
      "path": [
        [
          2.3665956945709348,
          48.8630421114174
        ],
        [
          2.3671254626151184,
          48.86094943927989
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:71277"
    },
    {
      "from_stop_id": "IDFM:463309",
      "to_stop_id": "IDFM:463146",
      "duration": 60,
      "path": [
        [
          2.3671254626151184,
          48.86094943927989
        ],
        [
          2.368095085385991,
          48.85708736782028
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:71777",
      "to_stop_unique_id": "IDFM:73622"
    },
    {
      "from_stop_id": "IDFM:463310",
      "to_stop_id": "IDFM:22054",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:463310",
      "to_stop_id": "IDFM:22132",
      "duration": 0,
      "path": [
        [
          2.3496815417224775,
          48.891280439844486
        ],
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71511"
    },
    {
      "from_stop_id": "IDFM:463310",
      "to_stop_id": "IDFM:22133",
      "duration": 0,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71556"
    },
    {
      "from_stop_id": "IDFM:463310",
      "to_stop_id": "IDFM:22141",
      "duration": 60,
      "path": [
        [
          2.347794968187123,
          48.8938152613519
        ],
        [
          2.3447472731828847,
          48.897557781418705
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:478883"
    },
    {
      "from_stop_id": "IDFM:463310",
      "to_stop_id": "IDFM:463065",
      "duration": 373,
      "path": null,
      "from_stop_unique_id": "IDFM:71556",
      "to_stop_unique_id": "IDFM:71521"
    },
    {
      "from_stop_id": "IDFM:463311",
      "to_stop_id": "IDFM:22045",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:71270"
    },
    {
      "from_stop_id": "IDFM:463311",
      "to_stop_id": "IDFM:22060",
      "duration": 60,
      "path": [
        [
          2.320575836647556,
          48.861071939526084
        ],
        [
          2.3230759642515415,
          48.85853194972854
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:73642"
    },
    {
      "from_stop_id": "IDFM:463311",
      "to_stop_id": "IDFM:462950",
      "duration": 60,
      "path": [
        [
          2.3230759642515415,
          48.85853194972854
        ],
        [
          2.3256998984524113,
          48.85588467848543
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71270",
      "to_stop_unique_id": "IDFM:71227"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:21920",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:22134",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:22159",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:22198",
      "duration": 60,
      "path": [
        [
          2.353797473153033,
          48.86946694920397
        ],
        [
          2.3485760027859746,
          48.8706347683979
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71320"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:22199",
      "duration": 60,
      "path": [
        [
          2.3632563242479026,
          48.86748513277203
        ],
        [
          2.353797473153033,
          48.86946694920397
        ]
      ],
      "route_id": "IDFM:C01378",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:463264",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463312",
      "to_stop_id": "IDFM:463313",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:21920",
      "duration": 105,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:22134",
      "duration": 15,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:22159",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:462979",
      "duration": 60,
      "path": [
        [
          2.3559014497805357,
          48.87247308911766
        ],
        [
          2.3543411093711257,
          48.86966773787259
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:71333"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:463072",
      "duration": 60,
      "path": [
        [
          2.3543411093711257,
          48.86966773787259
        ],
        [
          2.352381731140252,
          48.866077771215046
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73636"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:463264",
      "duration": 63,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463313",
      "to_stop_id": "IDFM:463312",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:73633",
      "to_stop_unique_id": "IDFM:73633"
    },
    {
      "from_stop_id": "IDFM:463314",
      "to_stop_id": "IDFM:21979",
      "duration": 308,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:463314",
      "to_stop_id": "IDFM:22163",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:463314",
      "to_stop_id": "IDFM:22176",
      "duration": 60,
      "path": [
        [
          2.309529610430393,
          48.84564768265073
        ],
        [
          2.3029417283376117,
          48.84754311219352
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71167"
    },
    {
      "from_stop_id": "IDFM:463314",
      "to_stop_id": "IDFM:462948",
      "duration": 60,
      "path": [
        [
          2.3129146804739396,
          48.842528387543226
        ],
        [
          2.309529610430393,
          48.84564768265073
        ]
      ],
      "route_id": "IDFM:C01376",
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71125"
    },
    {
      "from_stop_id": "IDFM:463314",
      "to_stop_id": "IDFM:463315",
      "duration": 309,
      "path": null,
      "from_stop_unique_id": "IDFM:71144",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:463315",
      "to_stop_id": "IDFM:21979",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71157"
    },
    {
      "from_stop_id": "IDFM:463315",
      "to_stop_id": "IDFM:22163",
      "duration": 312,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:463315",
      "to_stop_id": "IDFM:463084",
      "duration": 60,
      "path": [
        [
          2.307137849403385,
          48.847167269995026
        ],
        [
          2.3162843486517035,
          48.84668027285166
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71159"
    },
    {
      "from_stop_id": "IDFM:463315",
      "to_stop_id": "IDFM:463202",
      "duration": 60,
      "path": [
        [
          2.298684579816939,
          48.849532805857095
        ],
        [
          2.307137849403385,
          48.847167269995026
        ]
      ],
      "route_id": "IDFM:C01380",
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71199"
    },
    {
      "from_stop_id": "IDFM:463315",
      "to_stop_id": "IDFM:463314",
      "duration": 309,
      "path": null,
      "from_stop_unique_id": "IDFM:71157",
      "to_stop_unique_id": "IDFM:71144"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:21902",
      "duration": 60,
      "path": [
        [
          2.3615893783321895,
          48.86668483219092
        ],
        [
          2.364283168306328,
          48.86712186124499
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:21903",
      "duration": 273,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:21918",
      "duration": 174,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:21937",
      "duration": 348,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:21994",
      "duration": 60,
      "path": [
        [
          2.3567233472754983,
          48.86553459885931
        ],
        [
          2.3615893783321895,
          48.86668483219092
        ]
      ],
      "route_id": "IDFM:C01373",
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71293"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:22026",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71296"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:22199",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:462946",
      "duration": 266,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:462960",
      "duration": 350,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:462961",
      "duration": 284,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:462962",
      "duration": 600,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463316",
      "to_stop_id": "IDFM:462963",
      "duration": 198,
      "path": null,
      "from_stop_unique_id": "IDFM:71296",
      "to_stop_unique_id": "IDFM:71311"
    },
    {
      "from_stop_id": "IDFM:463317",
      "to_stop_id": "IDFM:21964",
      "duration": 60,
      "path": [
        [
          2.333049172418236,
          48.87633650730735
        ],
        [
          2.3274477500193202,
          48.875689121953926
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:463317",
      "to_stop_id": "IDFM:22046",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71355"
    },
    {
      "from_stop_id": "IDFM:463317",
      "to_stop_id": "IDFM:22050",
      "duration": 60,
      "path": [
        [
          2.3378736192226386,
          48.8760350312806
        ],
        [
          2.333049172418236,
          48.87633650730735
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71355",
      "to_stop_unique_id": "IDFM:71350"
    },
    {
      "from_stop_id": "IDFM:463318",
      "to_stop_id": "IDFM:21917",
      "duration": 1,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463318",
      "to_stop_id": "IDFM:22164",
      "duration": 105,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463318",
      "to_stop_id": "IDFM:463055",
      "duration": 60,
      "path": [
        [
          2.2868262498013006,
          48.86308797989344
        ],
        [
          2.277916063289398,
          48.86403732937509
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71292"
    },
    {
      "from_stop_id": "IDFM:463318",
      "to_stop_id": "IDFM:463190",
      "duration": 60,
      "path": [
        [
          2.2938642256822708,
          48.864780022257456
        ],
        [
          2.2868262498013006,
          48.86308797989344
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71294"
    },
    {
      "from_stop_id": "IDFM:463318",
      "to_stop_id": "IDFM:463268",
      "duration": 90,
      "path": null,
      "from_stop_unique_id": "IDFM:71285",
      "to_stop_unique_id": "IDFM:71285"
    },
    {
      "from_stop_id": "IDFM:463319",
      "to_stop_id": "IDFM:21949",
      "duration": 60,
      "path": [
        [
          2.3986752529487725,
          48.875519513947125
        ],
        [
          2.4065226763982275,
          48.87723143067265
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71889"
    },
    {
      "from_stop_id": "IDFM:463319",
      "to_stop_id": "IDFM:21993",
      "duration": 2,
      "path": null,
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71870"
    },
    {
      "from_stop_id": "IDFM:463319",
      "to_stop_id": "IDFM:463254",
      "duration": 60,
      "path": [
        [
          2.393139370360497,
          48.87672366197325
        ],
        [
          2.3986752529487725,
          48.875519513947125
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71870",
      "to_stop_unique_id": "IDFM:71885"
    },
    {
      "from_stop_id": "IDFM:463320",
      "to_stop_id": "IDFM:22185",
      "duration": 60,
      "path": [
        [
          2.3146399951225347,
          48.86053445554123
        ],
        [
          2.3149776470363417,
          48.85662420264694
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71274"
    },
    {
      "from_stop_id": "IDFM:463320",
      "to_stop_id": "IDFM:22226",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71232"
    },
    {
      "from_stop_id": "IDFM:463320",
      "to_stop_id": "IDFM:463303",
      "duration": 60,
      "path": [
        [
          2.3149776470363417,
          48.85662420264694
        ],
        [
          2.3142859461805556,
          48.85129155093847
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71232",
      "to_stop_unique_id": "IDFM:71204"
    },
    {
      "from_stop_id": "IDFM:463321",
      "to_stop_id": "IDFM:22047",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71091"
    },
    {
      "from_stop_id": "IDFM:463321",
      "to_stop_id": "IDFM:462949",
      "duration": 60,
      "path": [
        [
          2.300977822126098,
          48.83953628376544
        ],
        [
          2.2963913864809173,
          48.83713505380496
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71075"
    },
    {
      "from_stop_id": "IDFM:463321",
      "to_stop_id": "IDFM:462964",
      "duration": 60,
      "path": [
        [
          2.3079137063600563,
          48.84152824313428
        ],
        [
          2.300977822126098,
          48.83953628376544
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:71091",
      "to_stop_unique_id": "IDFM:71113"
    },
    {
      "from_stop_id": "IDFM:463322",
      "to_stop_id": "IDFM:22094",
      "duration": 60,
      "path": [
        [
          2.2958117752357627,
          48.87499457617108
        ],
        [
          2.2858287659129797,
          48.869926082410636
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:71347"
    },
    {
      "from_stop_id": "IDFM:463322",
      "to_stop_id": "IDFM:22109",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:71315"
    },
    {
      "from_stop_id": "IDFM:463322",
      "to_stop_id": "IDFM:463256",
      "duration": 120,
      "path": [
        [
          2.2858287659129797,
          48.869926082410636
        ],
        [
          2.2778093495879346,
          48.8717351159749
        ]
      ],
      "route_id": "IDFM:C01372",
      "from_stop_unique_id": "IDFM:71315",
      "to_stop_unique_id": "IDFM:474152"
    },
    {
      "from_stop_id": "IDFM:463323",
      "to_stop_id": "IDFM:22399",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:463323",
      "to_stop_id": "IDFM:22401",
      "duration": 60,
      "path": [
        [
          2.3683316015776423,
          48.79631793416014
        ],
        [
          2.364068547658874,
          48.80436739901952
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:463323",
      "to_stop_id": "IDFM:463107",
      "duration": 60,
      "path": [
        [
          2.364068547658874,
          48.80436739901952
        ],
        [
          2.3622716933808072,
          48.81028030813587
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70375",
      "to_stop_unique_id": "IDFM:70452"
    },
    {
      "from_stop_id": "IDFM:463324",
      "to_stop_id": "IDFM:22399",
      "duration": 60,
      "path": [
        [
          2.3639317476477353,
          48.80435318028833
        ],
        [
          2.368195583812103,
          48.79630822122641
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70375"
    },
    {
      "from_stop_id": "IDFM:463324",
      "to_stop_id": "IDFM:22401",
      "duration": 12,
      "path": null,
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70248"
    },
    {
      "from_stop_id": "IDFM:463324",
      "to_stop_id": "IDFM:463269",
      "duration": 60,
      "path": [
        [
          2.368195583812103,
          48.79630822122641
        ],
        [
          2.3677168990538426,
          48.787572501132765
        ]
      ],
      "route_id": "IDFM:C01377",
      "from_stop_unique_id": "IDFM:70248",
      "to_stop_unique_id": "IDFM:70143"
    },
    {
      "from_stop_id": "IDFM:463325",
      "to_stop_id": "IDFM:21910",
      "duration": 60,
      "path": [
        [
          2.3738805382115475,
          48.86141665790915
        ],
        [
          2.3800316941488044,
          48.85766243438601
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:71781"
    },
    {
      "from_stop_id": "IDFM:463325",
      "to_stop_id": "IDFM:21916",
      "duration": 19,
      "path": null,
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:71750"
    },
    {
      "from_stop_id": "IDFM:463325",
      "to_stop_id": "IDFM:463143",
      "duration": 60,
      "path": [
        [
          2.3800316941488044,
          48.85766243438601
        ],
        [
          2.384782396448888,
          48.854939443448494
        ]
      ],
      "route_id": "IDFM:C01379",
      "from_stop_unique_id": "IDFM:71750",
      "to_stop_unique_id": "IDFM:73647"
    },
    {
      "from_stop_id": "IDFM:474397",
      "to_stop_id": "IDFM:21924",
      "duration": 68,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:474397",
      "to_stop_id": "IDFM:22224",
      "duration": 5,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:474397",
      "to_stop_id": "IDFM:462957",
      "duration": 51,
      "path": null,
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71346"
    },
    {
      "from_stop_id": "IDFM:474397",
      "to_stop_id": "IDFM:462970",
      "duration": 120,
      "path": [
        [
          2.3254668925871607,
          48.875462135328796
        ],
        [
          2.3159507090192264,
          48.87344757302508
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:474397",
      "to_stop_id": "IDFM:463011",
      "duration": 60,
      "path": [
        [
          2.3159507090192264,
          48.87344757302508
        ],
        [
          2.3138736155952313,
          48.86749552078754
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71346",
      "to_stop_unique_id": "IDFM:71305"
    },
    {
      "from_stop_id": "IDFM:478395",
      "to_stop_id": "IDFM:22227",
      "duration": 26,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:478395",
      "to_stop_id": "IDFM:22228",
      "duration": 60,
      "path": [
        [
          2.327483258626126,
          48.8930014419616
        ],
        [
          2.325414931297021,
          48.88736901850862
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71528"
    },
    {
      "from_stop_id": "IDFM:478395",
      "to_stop_id": "IDFM:462975",
      "duration": 29,
      "path": null,
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71474"
    },
    {
      "from_stop_id": "IDFM:478395",
      "to_stop_id": "IDFM:463253",
      "duration": 60,
      "path": [
        [
          2.325414931297021,
          48.88736901850862
        ],
        [
          2.327268983025867,
          48.883723673452664
        ]
      ],
      "route_id": "IDFM:C01383",
      "from_stop_unique_id": "IDFM:71474",
      "to_stop_unique_id": "IDFM:71435"
    },
    {
      "from_stop_id": "IDFM:480286",
      "to_stop_id": "IDFM:462972",
      "duration": 120,
      "path": [
        [
          2.3151908648850013,
          48.888102389888395
        ],
        [
          2.3240258961246782,
          48.875660854671985
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:480286",
      "to_stop_id": "IDFM:480340",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:480286",
      "to_stop_id": "IDFM:480541",
      "duration": 60,
      "path": [
        [
          2.313431869544054,
          48.89428867785142
        ],
        [
          2.3151908648850013,
          48.888102389888395
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480288",
      "to_stop_id": "IDFM:480290",
      "duration": 60,
      "path": [
        [
          2.333435858789529,
          48.91170451534923
        ],
        [
          2.3226598821131716,
          48.90521399238465
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480288",
      "to_stop_id": "IDFM:480341",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480288",
      "to_stop_id": "IDFM:480541",
      "duration": 120,
      "path": [
        [
          2.3226598821131716,
          48.90521399238465
        ],
        [
          2.313431869544054,
          48.89428867785142
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480290",
      "to_stop_id": "IDFM:22243",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480290",
      "to_stop_id": "IDFM:463212",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480290",
      "to_stop_id": "IDFM:480288",
      "duration": 60,
      "path": [
        [
          2.333435858789529,
          48.91170451534923
        ],
        [
          2.3226598821131716,
          48.90521399238465
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480290",
      "to_stop_id": "IDFM:480330",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480290",
      "to_stop_id": "IDFM:490774",
      "duration": 60,
      "path": [
        [
          2.3457474221109633,
          48.91891285848584
        ],
        [
          2.333435858789529,
          48.91170451534923
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:480330",
      "to_stop_id": "IDFM:22243",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480330",
      "to_stop_id": "IDFM:463212",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480330",
      "to_stop_id": "IDFM:480290",
      "duration": 13,
      "path": null,
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480330",
      "to_stop_id": "IDFM:480341",
      "duration": 60,
      "path": [
        [
          2.3227559546697414,
          48.90516956991565
        ],
        [
          2.3335458072132695,
          48.91164217552501
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480330",
      "to_stop_id": "IDFM:490794",
      "duration": 120,
      "path": [
        [
          2.3335458072132695,
          48.91164217552501
        ],
        [
          2.345886129823062,
          48.91873375567987
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72168",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:480340",
      "to_stop_id": "IDFM:21962",
      "duration": 120,
      "path": [
        [
          2.324122029700433,
          48.87560743718584
        ],
        [
          2.3153681405051527,
          48.88810340364297
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71370"
    },
    {
      "from_stop_id": "IDFM:480340",
      "to_stop_id": "IDFM:480286",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:480340",
      "to_stop_id": "IDFM:480531",
      "duration": 60,
      "path": [
        [
          2.3153681405051527,
          48.88810340364297
        ],
        [
          2.313404000186253,
          48.894333484274206
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71475",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480341",
      "to_stop_id": "IDFM:480288",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480341",
      "to_stop_id": "IDFM:480330",
      "duration": 60,
      "path": [
        [
          2.3227559546697414,
          48.90516956991565
        ],
        [
          2.3335458072132695,
          48.91164217552501
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:480341",
      "to_stop_id": "IDFM:480531",
      "duration": 120,
      "path": [
        [
          2.313404000186253,
          48.894333484274206
        ],
        [
          2.3227559546697414,
          48.90516956991565
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:72126",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480531",
      "to_stop_id": "IDFM:22219",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480531",
      "to_stop_id": "IDFM:463278",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480531",
      "to_stop_id": "IDFM:480340",
      "duration": 60,
      "path": [
        [
          2.3153681405051527,
          48.88810340364297
        ],
        [
          2.313404000186253,
          48.894333484274206
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:480531",
      "to_stop_id": "IDFM:480341",
      "duration": 120,
      "path": [
        [
          2.313404000186253,
          48.894333484274206
        ],
        [
          2.3227559546697414,
          48.90516956991565
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480531",
      "to_stop_id": "IDFM:480541",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480541",
      "to_stop_id": "IDFM:22219",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480541",
      "to_stop_id": "IDFM:463278",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:480541",
      "to_stop_id": "IDFM:480286",
      "duration": 60,
      "path": [
        [
          2.313431869544054,
          48.89428867785142
        ],
        [
          2.3151908648850013,
          48.888102389888395
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71475"
    },
    {
      "from_stop_id": "IDFM:480541",
      "to_stop_id": "IDFM:480288",
      "duration": 120,
      "path": [
        [
          2.3226598821131716,
          48.90521399238465
        ],
        [
          2.313431869544054,
          48.89428867785142
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:72126"
    },
    {
      "from_stop_id": "IDFM:480541",
      "to_stop_id": "IDFM:480531",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:71545",
      "to_stop_unique_id": "IDFM:71545"
    },
    {
      "from_stop_id": "IDFM:483277",
      "to_stop_id": "IDFM:463222",
      "duration": 60,
      "path": [
        [
          2.3174992240284045,
          48.80979025430712
        ],
        [
          2.3196193991237846,
          48.818454456190004
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:483277",
      "to_stop_id": "IDFM:483313",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:483277",
      "to_stop_id": "IDFM:483423",
      "duration": 60,
      "path": [
        [
          2.317499440854107,
          48.80353046920307
        ],
        [
          2.3174992240284045,
          48.80979025430712
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:483315"
    },
    {
      "from_stop_id": "IDFM:483313",
      "to_stop_id": "IDFM:26602",
      "duration": 60,
      "path": [
        [
          2.319523835754741,
          48.818471900073675
        ],
        [
          2.3174174096489284,
          48.8097987811254
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:70586"
    },
    {
      "from_stop_id": "IDFM:483313",
      "to_stop_id": "IDFM:483277",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:483313",
      "to_stop_id": "IDFM:483314",
      "duration": 120,
      "path": [
        [
          2.3174174096489284,
          48.8097987811254
        ],
        [
          2.317458362049886,
          48.80354822259104
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:70441",
      "to_stop_unique_id": "IDFM:483315"
    },
    {
      "from_stop_id": "IDFM:483314",
      "to_stop_id": "IDFM:483313",
      "duration": 120,
      "path": [
        [
          2.3174174096489284,
          48.8097987811254
        ],
        [
          2.317458362049886,
          48.80354822259104
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:483315",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:483314",
      "to_stop_id": "IDFM:483423",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:483315",
      "to_stop_unique_id": "IDFM:483315"
    },
    {
      "from_stop_id": "IDFM:483423",
      "to_stop_id": "IDFM:483277",
      "duration": 60,
      "path": [
        [
          2.317499440854107,
          48.80353046920307
        ],
        [
          2.3174992240284045,
          48.80979025430712
        ]
      ],
      "route_id": "IDFM:C01374",
      "from_stop_unique_id": "IDFM:483315",
      "to_stop_unique_id": "IDFM:70441"
    },
    {
      "from_stop_id": "IDFM:483423",
      "to_stop_id": "IDFM:483314",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:483315",
      "to_stop_unique_id": "IDFM:483315"
    },
    {
      "from_stop_id": "IDFM:483898",
      "to_stop_id": "IDFM:483900",
      "duration": 120,
      "path": [
        [
          2.37813084516706,
          48.90843758066954
        ],
        [
          2.3808510232809863,
          48.91373058592168
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72524",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:483898",
      "to_stop_id": "IDFM:483904",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:72524",
      "to_stop_unique_id": "IDFM:72524"
    },
    {
      "from_stop_id": "IDFM:483900",
      "to_stop_id": "IDFM:463082",
      "duration": 60,
      "path": [
        [
          2.3657920239894126,
          48.90666420278779
        ],
        [
          2.37813084516706,
          48.90843758066954
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:483900",
      "to_stop_id": "IDFM:483898",
      "duration": 120,
      "path": [
        [
          2.37813084516706,
          48.90843758066954
        ],
        [
          2.3808510232809863,
          48.91373058592168
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:72524"
    },
    {
      "from_stop_id": "IDFM:483900",
      "to_stop_id": "IDFM:483903",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:483903",
      "to_stop_id": "IDFM:24679",
      "duration": 120,
      "path": [
        [
          2.378035995881576,
          48.90838312983791
        ],
        [
          2.365777177193171,
          48.906763048164294
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:74041"
    },
    {
      "from_stop_id": "IDFM:483903",
      "to_stop_id": "IDFM:483900",
      "duration": 11,
      "path": null,
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:483903",
      "to_stop_id": "IDFM:483904",
      "duration": 60,
      "path": [
        [
          2.3807826993163266,
          48.913739225808136
        ],
        [
          2.378035995881576,
          48.90838312983791
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72491",
      "to_stop_unique_id": "IDFM:72524"
    },
    {
      "from_stop_id": "IDFM:483904",
      "to_stop_id": "IDFM:483898",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:72524",
      "to_stop_unique_id": "IDFM:72524"
    },
    {
      "from_stop_id": "IDFM:483904",
      "to_stop_id": "IDFM:483903",
      "duration": 60,
      "path": [
        [
          2.3807826993163266,
          48.913739225808136
        ],
        [
          2.378035995881576,
          48.90838312983791
        ]
      ],
      "route_id": "IDFM:C01382",
      "from_stop_unique_id": "IDFM:72524",
      "to_stop_unique_id": "IDFM:72491"
    },
    {
      "from_stop_id": "IDFM:490762",
      "to_stop_id": "IDFM:21999",
      "duration": 60,
      "path": [
        [
          2.4271621685895237,
          48.88125303440166
        ],
        [
          2.4162978208011863,
          48.87974365006289
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:490762",
      "to_stop_id": "IDFM:490764",
      "duration": 60,
      "path": [
        [
          2.440689824547642,
          48.88369120755144
        ],
        [
          2.4271621685895237,
          48.88125303440166
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490762",
      "to_stop_id": "IDFM:490787",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:490764",
      "to_stop_id": "IDFM:490762",
      "duration": 60,
      "path": [
        [
          2.440689824547642,
          48.88369120755144
        ],
        [
          2.4271621685895237,
          48.88125303440166
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:490764",
      "to_stop_id": "IDFM:490766",
      "duration": 60,
      "path": [
        [
          2.454682210916966,
          48.87833284108111
        ],
        [
          2.440689824547642,
          48.88369120755144
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490764",
      "to_stop_id": "IDFM:490788",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490766",
      "to_stop_id": "IDFM:490764",
      "duration": 60,
      "path": [
        [
          2.454682210916966,
          48.87833284108111
        ],
        [
          2.440689824547642,
          48.88369120755144
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490766",
      "to_stop_id": "IDFM:490768",
      "duration": 60,
      "path": [
        [
          2.4651563455784005,
          48.87808331706366
        ],
        [
          2.454682210916966,
          48.87833284108111
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490766",
      "to_stop_id": "IDFM:490789",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490768",
      "to_stop_id": "IDFM:490766",
      "duration": 60,
      "path": [
        [
          2.4651563455784005,
          48.87808331706366
        ],
        [
          2.454682210916966,
          48.87833284108111
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490768",
      "to_stop_id": "IDFM:490770",
      "duration": 60,
      "path": [
        [
          2.4673647804495924,
          48.88212212689498
        ],
        [
          2.4651563455784005,
          48.87808331706366
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490768",
      "to_stop_id": "IDFM:490791",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490770",
      "to_stop_id": "IDFM:490768",
      "duration": 60,
      "path": [
        [
          2.4673647804495924,
          48.88212212689498
        ],
        [
          2.4651563455784005,
          48.87808331706366
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490770",
      "to_stop_id": "IDFM:490772",
      "duration": 60,
      "path": [
        [
          2.4806093523524932,
          48.88307064342213
        ],
        [
          2.4673647804495924,
          48.88212212689498
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:426280"
    },
    {
      "from_stop_id": "IDFM:490770",
      "to_stop_id": "IDFM:490792",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490772",
      "to_stop_id": "IDFM:490770",
      "duration": 60,
      "path": [
        [
          2.4806093523524932,
          48.88307064342213
        ],
        [
          2.4673647804495924,
          48.88212212689498
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:426280",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490772",
      "to_stop_id": "IDFM:490793",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:426280",
      "to_stop_unique_id": "IDFM:426280"
    },
    {
      "from_stop_id": "IDFM:490774",
      "to_stop_id": "IDFM:22239",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:490774",
      "to_stop_id": "IDFM:462976",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:490774",
      "to_stop_id": "IDFM:480290",
      "duration": 60,
      "path": [
        [
          2.3457474221109633,
          48.91891285848584
        ],
        [
          2.333435858789529,
          48.91170451534923
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:490774",
      "to_stop_id": "IDFM:490794",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:490787",
      "to_stop_id": "IDFM:463223",
      "duration": 60,
      "path": [
        [
          2.4163499077398614,
          48.879658467973904
        ],
        [
          2.427162663175348,
          48.881208070541476
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:71909"
    },
    {
      "from_stop_id": "IDFM:490787",
      "to_stop_id": "IDFM:490762",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:490787",
      "to_stop_id": "IDFM:490788",
      "duration": 60,
      "path": [
        [
          2.427162663175348,
          48.881208070541476
        ],
        [
          2.4406774445901713,
          48.883574237927014
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490779",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490788",
      "to_stop_id": "IDFM:490764",
      "duration": 16,
      "path": null,
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490788",
      "to_stop_id": "IDFM:490787",
      "duration": 60,
      "path": [
        [
          2.427162663175348,
          48.881208070541476
        ],
        [
          2.4406774445901713,
          48.883574237927014
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:490779"
    },
    {
      "from_stop_id": "IDFM:490788",
      "to_stop_id": "IDFM:490789",
      "duration": 60,
      "path": [
        [
          2.4406774445901713,
          48.883574237927014
        ],
        [
          2.4546692358364623,
          48.878269829247664
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71947",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490789",
      "to_stop_id": "IDFM:490766",
      "duration": 9,
      "path": null,
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490789",
      "to_stop_id": "IDFM:490788",
      "duration": 60,
      "path": [
        [
          2.4406774445901713,
          48.883574237927014
        ],
        [
          2.4546692358364623,
          48.878269829247664
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:71947"
    },
    {
      "from_stop_id": "IDFM:490789",
      "to_stop_id": "IDFM:490791",
      "duration": 60,
      "path": [
        [
          2.4546692358364623,
          48.878269829247664
        ],
        [
          2.465116181953647,
          48.8780111916913
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:71897",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490791",
      "to_stop_id": "IDFM:490768",
      "duration": 10,
      "path": null,
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490791",
      "to_stop_id": "IDFM:490789",
      "duration": 60,
      "path": [
        [
          2.4546692358364623,
          48.878269829247664
        ],
        [
          2.465116181953647,
          48.8780111916913
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:71897"
    },
    {
      "from_stop_id": "IDFM:490791",
      "to_stop_id": "IDFM:490792",
      "duration": 60,
      "path": [
        [
          2.465116181953647,
          48.8780111916913
        ],
        [
          2.4674333244222826,
          48.88208645899326
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490782",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490792",
      "to_stop_id": "IDFM:490770",
      "duration": 8,
      "path": null,
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490792",
      "to_stop_id": "IDFM:490791",
      "duration": 60,
      "path": [
        [
          2.465116181953647,
          48.8780111916913
        ],
        [
          2.4674333244222826,
          48.88208645899326
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:490782"
    },
    {
      "from_stop_id": "IDFM:490792",
      "to_stop_id": "IDFM:490793",
      "duration": 120,
      "path": [
        [
          2.4674333244222826,
          48.88208645899326
        ],
        [
          2.480609800808591,
          48.88302567942574
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:490783",
      "to_stop_unique_id": "IDFM:426280"
    },
    {
      "from_stop_id": "IDFM:490793",
      "to_stop_id": "IDFM:490772",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:426280",
      "to_stop_unique_id": "IDFM:426280"
    },
    {
      "from_stop_id": "IDFM:490793",
      "to_stop_id": "IDFM:490792",
      "duration": 120,
      "path": [
        [
          2.4674333244222826,
          48.88208645899326
        ],
        [
          2.480609800808591,
          48.88302567942574
        ]
      ],
      "route_id": "IDFM:C01381",
      "from_stop_unique_id": "IDFM:426280",
      "to_stop_unique_id": "IDFM:490783"
    },
    {
      "from_stop_id": "IDFM:490794",
      "to_stop_id": "IDFM:22239",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:490794",
      "to_stop_id": "IDFM:462976",
      "duration": 840,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72217"
    },
    {
      "from_stop_id": "IDFM:490794",
      "to_stop_id": "IDFM:480330",
      "duration": 120,
      "path": [
        [
          2.3335458072132695,
          48.91164217552501
        ],
        [
          2.345886129823062,
          48.91873375567987
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:72168"
    },
    {
      "from_stop_id": "IDFM:490794",
      "to_stop_id": "IDFM:490774",
      "duration": 28,
      "path": null,
      "from_stop_unique_id": "IDFM:490784",
      "to_stop_unique_id": "IDFM:490784"
    },
    {
      "from_stop_id": "IDFM:490833",
      "to_stop_id": "IDFM:490851",
      "duration": 60,
      "path": [
        [
          2.367374604185919,
          48.75848356146962
        ],
        [
          2.3723847511214924,
          48.746835369386844
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490833",
      "to_stop_id": "IDFM:490852",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490833",
      "to_stop_id": "IDFM:490908",
      "duration": 180,
      "path": [
        [
          2.3723847511214924,
          48.746835369386844
        ],
        [
          2.362102529020536,
          48.727919520945235
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:63284"
    },
    {
      "from_stop_id": "IDFM:490835",
      "to_stop_id": "IDFM:490850",
      "duration": 120,
      "path": [
        [
          2.3674425092258047,
          48.758492915434516
        ],
        [
          2.355032032091648,
          48.775326684341785
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490835",
      "to_stop_id": "IDFM:490851",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490835",
      "to_stop_id": "IDFM:490852",
      "duration": 60,
      "path": [
        [
          2.372466241689093,
          48.74684479207573
        ],
        [
          2.3674425092258047,
          48.758492915434516
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490837",
      "to_stop_id": "IDFM:490839",
      "duration": 240,
      "path": [
        [
          2.349946533239157,
          48.809971090725966
        ],
        [
          2.354991210566311,
          48.77532646395088
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490837",
      "to_stop_id": "IDFM:490850",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490837",
      "to_stop_id": "IDFM:490851",
      "duration": 120,
      "path": [
        [
          2.354991210566311,
          48.77532646395088
        ],
        [
          2.367374604185919,
          48.75848356146962
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490839",
      "to_stop_id": "IDFM:490837",
      "duration": 240,
      "path": [
        [
          2.349946533239157,
          48.809971090725966
        ],
        [
          2.354991210566311,
          48.77532646395088
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490839",
      "to_stop_id": "IDFM:490841",
      "duration": 120,
      "path": [
        [
          2.3589182598590392,
          48.82120786862075
        ],
        [
          2.349946533239157,
          48.809971090725966
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490839",
      "to_stop_id": "IDFM:490849",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:21954",
      "duration": 60,
      "path": [
        [
          2.3669231215308075,
          48.827123441429386
        ],
        [
          2.3589182598590392,
          48.82120786862075
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:22380",
      "duration": 312,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:22385",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:463224",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:463235",
      "duration": 303,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:490839",
      "duration": 120,
      "path": [
        [
          2.3589182598590392,
          48.82120786862075
        ],
        [
          2.349946533239157,
          48.809971090725966
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490841",
      "to_stop_id": "IDFM:490848",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:22380",
      "duration": 308,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:22385",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:463045",
      "duration": 60,
      "path": [
        [
          2.358986576752596,
          48.82119024728473
        ],
        [
          2.367034270941924,
          48.826944155043044
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:71557"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:463224",
      "duration": 180,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:463235",
      "duration": 299,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70596"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:490841",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490848",
      "to_stop_id": "IDFM:490849",
      "duration": 120,
      "path": [
        [
          2.350014950529505,
          48.80994448106093
        ],
        [
          2.358986576752596,
          48.82119024728473
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70645",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490849",
      "to_stop_id": "IDFM:490839",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490849",
      "to_stop_id": "IDFM:490848",
      "duration": 120,
      "path": [
        [
          2.350014950529505,
          48.80994448106093
        ],
        [
          2.358986576752596,
          48.82119024728473
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:70645"
    },
    {
      "from_stop_id": "IDFM:490849",
      "to_stop_id": "IDFM:490850",
      "duration": 240,
      "path": [
        [
          2.355032032091648,
          48.775326684341785
        ],
        [
          2.350014950529505,
          48.80994448106093
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:70445",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490850",
      "to_stop_id": "IDFM:490835",
      "duration": 120,
      "path": [
        [
          2.3674425092258047,
          48.758492915434516
        ],
        [
          2.355032032091648,
          48.775326684341785
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490850",
      "to_stop_id": "IDFM:490837",
      "duration": 3,
      "path": null,
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490850",
      "to_stop_id": "IDFM:490849",
      "duration": 240,
      "path": [
        [
          2.355032032091648,
          48.775326684341785
        ],
        [
          2.350014950529505,
          48.80994448106093
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69974",
      "to_stop_unique_id": "IDFM:70445"
    },
    {
      "from_stop_id": "IDFM:490851",
      "to_stop_id": "IDFM:490833",
      "duration": 60,
      "path": [
        [
          2.367374604185919,
          48.75848356146962
        ],
        [
          2.3723847511214924,
          48.746835369386844
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490851",
      "to_stop_id": "IDFM:490835",
      "duration": 6,
      "path": null,
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490851",
      "to_stop_id": "IDFM:490837",
      "duration": 120,
      "path": [
        [
          2.354991210566311,
          48.77532646395088
        ],
        [
          2.367374604185919,
          48.75848356146962
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:73753",
      "to_stop_unique_id": "IDFM:69974"
    },
    {
      "from_stop_id": "IDFM:490852",
      "to_stop_id": "IDFM:490833",
      "duration": 7,
      "path": null,
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490852",
      "to_stop_id": "IDFM:490835",
      "duration": 60,
      "path": [
        [
          2.372466241689093,
          48.74684479207573
        ],
        [
          2.3674425092258047,
          48.758492915434516
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:73753"
    },
    {
      "from_stop_id": "IDFM:490852",
      "to_stop_id": "IDFM:490917",
      "duration": 120,
      "path": [
        [
          2.3621434233506027,
          48.72791074498099
        ],
        [
          2.372466241689093,
          48.74684479207573
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:69677",
      "to_stop_unique_id": "IDFM:63284"
    },
    {
      "from_stop_id": "IDFM:490908",
      "to_stop_id": "IDFM:490833",
      "duration": 180,
      "path": [
        [
          2.3723847511214924,
          48.746835369386844
        ],
        [
          2.362102529020536,
          48.727919520945235
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:63284",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490908",
      "to_stop_id": "IDFM:490917",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:63284",
      "to_stop_unique_id": "IDFM:63284"
    },
    {
      "from_stop_id": "IDFM:490917",
      "to_stop_id": "IDFM:490852",
      "duration": 120,
      "path": [
        [
          2.3621434233506027,
          48.72791074498099
        ],
        [
          2.372466241689093,
          48.74684479207573
        ]
      ],
      "route_id": "IDFM:C01384",
      "from_stop_unique_id": "IDFM:63284",
      "to_stop_unique_id": "IDFM:69677"
    },
    {
      "from_stop_id": "IDFM:490917",
      "to_stop_id": "IDFM:490908",
      "duration": 4,
      "path": null,
      "from_stop_unique_id": "IDFM:63284",
      "to_stop_unique_id": "IDFM:63284"
    }
  ],
  "uniqueStops": [
    {
      "stop_unique_id": "IDFM:69677",
      "stop_name": "Thiais - Orly​ (Pont de Rungis​)",
      "stop_lat": 48.747229547076934,
      "stop_lon": 2.3712307511079094
    },
    {
      "stop_unique_id": "IDFM:474149",
      "stop_name": "Château Landon",
      "stop_lat": 48.8796540658892,
      "stop_lon": 2.3625825823820072
    },
    {
      "stop_unique_id": "IDFM:474150",
      "stop_name": "La Muette",
      "stop_lat": 48.858243480448024,
      "stop_lon": 2.273869241020442
    },
    {
      "stop_unique_id": "IDFM:474152",
      "stop_name": "Porte Dauphine",
      "stop_lat": 48.87157696874958,
      "stop_lon": 2.2754510773211183
    },
    {
      "stop_unique_id": "IDFM:412687",
      "stop_name": "Pernety",
      "stop_lat": 48.833258748851236,
      "stop_lon": 2.3192422581536065
    },
    {
      "stop_unique_id": "IDFM:74041",
      "stop_name": "Front Populaire",
      "stop_lat": 48.906542009304765,
      "stop_lon": 2.365643446765684
    },
    {
      "stop_unique_id": "IDFM:74001",
      "stop_name": "Faidherbe - Chaligny",
      "stop_lat": 48.84985955714189,
      "stop_lon": 2.385064498025339
    },
    {
      "stop_unique_id": "IDFM:69974",
      "stop_name": "L'Haÿ-les-Roses",
      "stop_lat": 48.77553712609636,
      "stop_lon": 2.3540292331615507
    },
    {
      "stop_unique_id": "IDFM:412992",
      "stop_name": "Boucicaut",
      "stop_lat": 48.840976078146035,
      "stop_lon": 2.2881507185297303
    },
    {
      "stop_unique_id": "IDFM:69884",
      "stop_name": "Pointe du Lac",
      "stop_lat": 48.76902683106896,
      "stop_lon": 2.464348603981415
    },
    {
      "stop_unique_id": "IDFM:425779",
      "stop_name": "Les Sablons",
      "stop_lat": 48.8800630705949,
      "stop_lon": 2.271137881829007
    },
    {
      "stop_unique_id": "IDFM:461505",
      "stop_name": "Châtillon-Montrouge",
      "stop_lat": 48.810622111384355,
      "stop_lon": 2.3026794607873935
    },
    {
      "stop_unique_id": "IDFM:479068",
      "stop_name": "Etienne Marcel",
      "stop_lat": 48.86379344532947,
      "stop_lon": 2.349895172639756
    },
    {
      "stop_unique_id": "IDFM:71897",
      "stop_name": "Montreuil - Hôpital",
      "stop_lat": 48.877610005601404,
      "stop_lon": 2.4549283861447053
    },
    {
      "stop_unique_id": "IDFM:71893",
      "stop_name": "Colonel Fabien",
      "stop_lat": 48.877959843327126,
      "stop_lon": 2.3704229226090883
    },
    {
      "stop_unique_id": "IDFM:71889",
      "stop_name": "Porte des Lilas",
      "stop_lat": 48.87707350075588,
      "stop_lon": 2.408228800387185
    },
    {
      "stop_unique_id": "IDFM:71885",
      "stop_name": "Place des Fêtes",
      "stop_lat": 48.877147428192245,
      "stop_lon": 2.393352586759853
    },
    {
      "stop_unique_id": "IDFM:71900",
      "stop_name": "Buttes Chaumont",
      "stop_lat": 48.878369925764666,
      "stop_lon": 2.38180998777039
    },
    {
      "stop_unique_id": "IDFM:71911",
      "stop_name": "Pré-Saint-Gervais",
      "stop_lat": 48.879646342655,
      "stop_lon": 2.3984571624615114
    },
    {
      "stop_unique_id": "IDFM:71909",
      "stop_name": "Mairie des Lilas",
      "stop_lat": 48.87976093751731,
      "stop_lon": 2.4161544565292163
    },
    {
      "stop_unique_id": "IDFM:71906",
      "stop_name": "Botzaris",
      "stop_lat": 48.879492388736644,
      "stop_lon": 2.389364231673402
    },
    {
      "stop_unique_id": "IDFM:71920",
      "stop_name": "Bolivar",
      "stop_lat": 48.88078863015127,
      "stop_lon": 2.373927167224922
    },
    {
      "stop_unique_id": "IDFM:71930",
      "stop_name": "Danube",
      "stop_lat": 48.881900481272346,
      "stop_lon": 2.393372215754005
    },
    {
      "stop_unique_id": "IDFM:71940",
      "stop_name": "Jaurès",
      "stop_lat": 48.88245843109808,
      "stop_lon": 2.370791254786869
    },
    {
      "stop_unique_id": "IDFM:71947",
      "stop_name": "Romainville - Carnot",
      "stop_lat": 48.88339945225543,
      "stop_lon": 2.4408020477717267
    },
    {
      "stop_unique_id": "IDFM:71957",
      "stop_name": "Laumière",
      "stop_lat": 48.88483086655546,
      "stop_lon": 2.3796895517120946
    },
    {
      "stop_unique_id": "IDFM:71961",
      "stop_name": "Stalingrad",
      "stop_lat": 48.88406138033474,
      "stop_lon": 2.3669332186542533
    },
    {
      "stop_unique_id": "IDFM:71972",
      "stop_name": "Ourcq",
      "stop_lat": 48.88715231174162,
      "stop_lon": 2.3871469927328595
    },
    {
      "stop_unique_id": "IDFM:71977",
      "stop_name": "Riquet",
      "stop_lat": 48.88832972020398,
      "stop_lon": 2.373972872271015
    },
    {
      "stop_unique_id": "IDFM:71989",
      "stop_name": "Porte de Pantin",
      "stop_lat": 48.88885882641063,
      "stop_lon": 2.39410259923119
    },
    {
      "stop_unique_id": "IDFM:71795",
      "stop_name": "Rue Saint-Maur",
      "stop_lat": 48.86409054172495,
      "stop_lon": 2.3804731830962136
    },
    {
      "stop_unique_id": "IDFM:71799",
      "stop_name": "Porte de Bagnolet",
      "stop_lat": 48.863990934290335,
      "stop_lon": 2.4087863447432394
    },
    {
      "stop_unique_id": "IDFM:71785",
      "stop_name": "Mairie de Montreuil",
      "stop_lat": 48.86235360998902,
      "stop_lon": 2.4424046490336777
    },
    {
      "stop_unique_id": "IDFM:71787",
      "stop_name": "Père Lachaise",
      "stop_lat": 48.8627269785042,
      "stop_lon": 2.387004961714079
    },
    {
      "stop_unique_id": "IDFM:71781",
      "stop_name": "Saint-Ambroise",
      "stop_lat": 48.86172582541422,
      "stop_lon": 2.3753898284718367
    },
    {
      "stop_unique_id": "IDFM:71777",
      "stop_name": "Saint-Sébastien - Froissart",
      "stop_lat": 48.8609680736927,
      "stop_lon": 2.367247910158567
    },
    {
      "stop_unique_id": "IDFM:71817",
      "stop_name": "Gallieni",
      "stop_lat": 48.86498789253605,
      "stop_lon": 2.4166789260142094
    },
    {
      "stop_unique_id": "IDFM:71815",
      "stop_name": "Ménilmontant",
      "stop_lat": 48.86686759401077,
      "stop_lon": 2.3833028742273137
    },
    {
      "stop_unique_id": "IDFM:71828",
      "stop_name": "Pelleport",
      "stop_lat": 48.86835220456264,
      "stop_lon": 2.4014435576736393
    },
    {
      "stop_unique_id": "IDFM:71834",
      "stop_name": "Couronnes",
      "stop_lat": 48.86895659169776,
      "stop_lon": 2.3805925326197053
    },
    {
      "stop_unique_id": "IDFM:71801",
      "stop_name": "Parmentier",
      "stop_lat": 48.86563334660995,
      "stop_lon": 2.374504790575915
    },
    {
      "stop_unique_id": "IDFM:71803",
      "stop_name": "Oberkampf",
      "stop_lat": 48.8644859108331,
      "stop_lon": 2.3683638473985766
    },
    {
      "stop_unique_id": "IDFM:71808",
      "stop_name": "Gambetta",
      "stop_lat": 48.865055765835464,
      "stop_lon": 2.3986801681736405
    },
    {
      "stop_unique_id": "IDFM:71864",
      "stop_name": "Pyrénées",
      "stop_lat": 48.87396177244152,
      "stop_lon": 2.3850253517112368
    },
    {
      "stop_unique_id": "IDFM:71860",
      "stop_name": "Saint-Fargeau",
      "stop_lat": 48.87184742776555,
      "stop_lon": 2.404409520802737
    },
    {
      "stop_unique_id": "IDFM:71868",
      "stop_name": "Jourdain",
      "stop_lat": 48.87438411014814,
      "stop_lon": 2.3893491339468858
    },
    {
      "stop_unique_id": "IDFM:71870",
      "stop_name": "Télégraphe",
      "stop_lat": 48.87521719360119,
      "stop_lon": 2.398467414449192
    },
    {
      "stop_unique_id": "IDFM:71840",
      "stop_name": "Goncourt",
      "stop_lat": 48.87006161963351,
      "stop_lon": 2.370886470439591
    },
    {
      "stop_unique_id": "IDFM:71853",
      "stop_name": "Belleville",
      "stop_lat": 48.87214473966535,
      "stop_lon": 2.377125834393433
    },
    {
      "stop_unique_id": "IDFM:71697",
      "stop_name": "Avron",
      "stop_lat": 48.85131728048222,
      "stop_lon": 2.3984850411867953
    },
    {
      "stop_unique_id": "IDFM:71694",
      "stop_name": "Rue des Boulets",
      "stop_lat": 48.85213992819131,
      "stop_lon": 2.3895553014039312
    },
    {
      "stop_unique_id": "IDFM:71693",
      "stop_name": "Ledru-Rollin",
      "stop_lat": 48.85135454462724,
      "stop_lon": 2.37590934802813
    },
    {
      "stop_unique_id": "IDFM:71650",
      "stop_name": "Bérault",
      "stop_lat": 48.84550542958482,
      "stop_lon": 2.4283369782226205
    },
    {
      "stop_unique_id": "IDFM:71654",
      "stop_name": "Reuilly - Diderot",
      "stop_lat": 48.84727280540136,
      "stop_lon": 2.3866007859902467
    },
    {
      "stop_unique_id": "IDFM:71637",
      "stop_name": "Quai de la Rapée",
      "stop_lat": 48.845892913210584,
      "stop_lon": 2.3677171032066013
    },
    {
      "stop_unique_id": "IDFM:71639",
      "stop_name": "Picpus",
      "stop_lat": 48.84508948625797,
      "stop_lon": 2.402119530282281
    },
    {
      "stop_unique_id": "IDFM:71647",
      "stop_name": "Saint-Mandé",
      "stop_lat": 48.84604419655022,
      "stop_lon": 2.4188282529855627
    },
    {
      "stop_unique_id": "IDFM:71673",
      "stop_name": "Nation",
      "stop_lat": 48.848927647042316,
      "stop_lon": 2.3963316057344417
    },
    {
      "stop_unique_id": "IDFM:70021",
      "stop_name": "Créteil - Préfecture",
      "stop_lat": 48.779670282936785,
      "stop_lon": 2.4601226077032217
    },
    {
      "stop_unique_id": "IDFM:71663",
      "stop_name": "Porte de Vincennes",
      "stop_lat": 48.84715749064032,
      "stop_lon": 2.410169658994552
    },
    {
      "stop_unique_id": "IDFM:71725",
      "stop_name": "Robespierre",
      "stop_lat": 48.85564321369885,
      "stop_lon": 2.4222238389027457
    },
    {
      "stop_unique_id": "IDFM:71728",
      "stop_name": "Alexandre Dumas",
      "stop_lat": 48.85631417640588,
      "stop_lon": 2.3942286097970094
    },
    {
      "stop_unique_id": "IDFM:71756",
      "stop_name": "Croix de Chavaux",
      "stop_lat": 48.85856110096925,
      "stop_lon": 2.436264163195498
    },
    {
      "stop_unique_id": "IDFM:71751",
      "stop_name": "Philippe Auguste",
      "stop_lat": 48.858011582264716,
      "stop_lon": 2.3902225142300364
    },
    {
      "stop_unique_id": "IDFM:71750",
      "stop_name": "Voltaire",
      "stop_lat": 48.858286665575115,
      "stop_lon": 2.379874339445567
    },
    {
      "stop_unique_id": "IDFM:71710",
      "stop_name": "Porte de Montreuil",
      "stop_lat": 48.85359628484786,
      "stop_lon": 2.410968994981159
    },
    {
      "stop_unique_id": "IDFM:71703",
      "stop_name": "Maraîchers",
      "stop_lat": 48.85266834105208,
      "stop_lon": 2.406093772106721
    },
    {
      "stop_unique_id": "IDFM:71591",
      "stop_name": "Porte Dorée",
      "stop_lat": 48.83550150595087,
      "stop_lon": 2.406773016852677
    },
    {
      "stop_unique_id": "IDFM:71595",
      "stop_name": "Michel Bizot",
      "stop_lat": 48.836892747743654,
      "stop_lon": 2.4026767687506805
    },
    {
      "stop_unique_id": "IDFM:71597",
      "stop_name": "Quai de la Gare",
      "stop_lat": 48.83700930400022,
      "stop_lon": 2.3732404703125054
    },
    {
      "stop_unique_id": "IDFM:71572",
      "stop_name": "Bibliothèque François Mitterrand",
      "stop_lat": 48.82978760760208,
      "stop_lon": 2.3767260510661323
    },
    {
      "stop_unique_id": "IDFM:71579",
      "stop_name": "Porte de Charenton",
      "stop_lat": 48.832438455458615,
      "stop_lon": 2.400193944089189
    },
    {
      "stop_unique_id": "IDFM:71576",
      "stop_name": "Cour Saint-Emilion",
      "stop_lat": 48.83333545837056,
      "stop_lon": 2.3865414125370745
    },
    {
      "stop_unique_id": "IDFM:71558",
      "stop_name": "Liberté",
      "stop_lat": 48.82610635619645,
      "stop_lon": 2.407125096496336
    },
    {
      "stop_unique_id": "IDFM:70143",
      "stop_name": "Villejuif - Louis Aragon",
      "stop_lat": 48.787638101187206,
      "stop_lon": 2.3665174950300973
    },
    {
      "stop_unique_id": "IDFM:71556",
      "stop_name": "Simplon",
      "stop_lat": 48.895001279132465,
      "stop_lon": 2.346770797229445
    },
    {
      "stop_unique_id": "IDFM:71557",
      "stop_name": "Olympiades",
      "stop_lat": 48.82707096117994,
      "stop_lon": 2.367203001031431
    },
    {
      "stop_unique_id": "IDFM:71545",
      "stop_name": "Porte de Clichy",
      "stop_lat": 48.89440777079476,
      "stop_lon": 2.3138121984915094
    },
    {
      "stop_unique_id": "IDFM:71528",
      "stop_name": "Guy Môquet",
      "stop_lat": 48.89284860718066,
      "stop_lon": 2.32749205217676
    },
    {
      "stop_unique_id": "IDFM:71517",
      "stop_name": "La Défense",
      "stop_lat": 48.89161331499386,
      "stop_lon": 2.2397642015372172
    },
    {
      "stop_unique_id": "IDFM:71519",
      "stop_name": "Anatole France",
      "stop_lat": 48.8916977002622,
      "stop_lon": 2.284865937810641
    },
    {
      "stop_unique_id": "IDFM:71521",
      "stop_name": "Jules Joffrin",
      "stop_lat": 48.89259762416229,
      "stop_lon": 2.3446733099971144
    },
    {
      "stop_unique_id": "IDFM:70172",
      "stop_name": "Créteil - Université",
      "stop_lat": 48.78995516135582,
      "stop_lon": 2.4522710875340827
    },
    {
      "stop_unique_id": "IDFM:463564",
      "stop_name": "Bastille",
      "stop_lat": 48.85296715029945,
      "stop_lon": 2.3687215300968556
    },
    {
      "stop_unique_id": "IDFM:71634",
      "stop_name": "Château de Vincennes",
      "stop_lat": 48.84428708966407,
      "stop_lon": 2.4407652506058692
    },
    {
      "stop_unique_id": "IDFM:71631",
      "stop_name": "Montgallet",
      "stop_lat": 48.84429613431412,
      "stop_lon": 2.390158153415885
    },
    {
      "stop_unique_id": "IDFM:71614",
      "stop_name": "Daumesnil",
      "stop_lat": 48.83947072642419,
      "stop_lon": 2.3954056529297154
    },
    {
      "stop_unique_id": "IDFM:71606",
      "stop_name": "Dugommier",
      "stop_lat": 48.83874809752206,
      "stop_lon": 2.3885949347629385
    },
    {
      "stop_unique_id": "IDFM:71607",
      "stop_name": "Bercy",
      "stop_lat": 48.839016279762596,
      "stop_lon": 2.3812821470487995
    },
    {
      "stop_unique_id": "IDFM:478885",
      "stop_name": "Le Peletier",
      "stop_lat": 48.87466881894976,
      "stop_lon": 2.339642963933406
    },
    {
      "stop_unique_id": "IDFM:478883",
      "stop_name": "Porte de Clignancourt",
      "stop_lat": 48.89818244527761,
      "stop_lon": 2.3451076964680584
    },
    {
      "stop_unique_id": "IDFM:71084",
      "stop_name": "Porte de Saint-Cloud",
      "stop_lat": 48.83800858609376,
      "stop_lon": 2.257161286712118
    },
    {
      "stop_unique_id": "IDFM:71088",
      "stop_name": "Raspail",
      "stop_lat": 48.83878398756392,
      "stop_lon": 2.3306216485570057
    },
    {
      "stop_unique_id": "IDFM:71086",
      "stop_name": "Lourmel",
      "stop_lat": 48.83845909372984,
      "stop_lon": 2.282340111077987
    },
    {
      "stop_unique_id": "IDFM:71092",
      "stop_name": "Saint-Marcel",
      "stop_lat": 48.83907575035707,
      "stop_lon": 2.3609465709574886
    },
    {
      "stop_unique_id": "IDFM:71091",
      "stop_name": "Vaugirard",
      "stop_lat": 48.83933895532283,
      "stop_lon": 2.301069030267783
    },
    {
      "stop_unique_id": "IDFM:70721",
      "stop_name": "Boulogne Pont de Saint-Cloud",
      "stop_lat": 48.8406954476992,
      "stop_lon": 2.2283871891914253
    },
    {
      "stop_unique_id": "IDFM:72168",
      "stop_name": "Mairie de Saint-Ouen",
      "stop_lat": 48.91252311011162,
      "stop_lon": 2.334284945557004
    },
    {
      "stop_unique_id": "IDFM:72126",
      "stop_name": "Saint-Ouen",
      "stop_lat": 48.904115088005014,
      "stop_lon": 2.3207643298973695
    },
    {
      "stop_unique_id": "IDFM:72128",
      "stop_name": "Garibaldi",
      "stop_lat": 48.906299577055876,
      "stop_lon": 2.3318066010926306
    },
    {
      "stop_unique_id": "IDFM:72118",
      "stop_name": "Mairie de Clichy",
      "stop_lat": 48.90305864731478,
      "stop_lon": 2.3051312762967213
    },
    {
      "stop_unique_id": "IDFM:70698",
      "stop_name": "Pont de Sèvres",
      "stop_lat": 48.82980553157944,
      "stop_lon": 2.230727975764684
    },
    {
      "stop_unique_id": "IDFM:70671",
      "stop_name": "Mairie d'Issy",
      "stop_lat": 48.824574107789964,
      "stop_lon": 2.2736366921941396
    },
    {
      "stop_unique_id": "IDFM:71030",
      "stop_name": "Alésia",
      "stop_lat": 48.82818169822822,
      "stop_lon": 2.326848290367094
    },
    {
      "stop_unique_id": "IDFM:71033",
      "stop_name": "Place d'Italie",
      "stop_lat": 48.831079852712236,
      "stop_lon": 2.3558203122764656
    },
    {
      "stop_unique_id": "IDFM:71034",
      "stop_name": "Billancourt",
      "stop_lat": 48.832038826816394,
      "stop_lon": 2.238194520131341
    },
    {
      "stop_unique_id": "IDFM:71039",
      "stop_name": "Plaisance",
      "stop_lat": 48.83173491587499,
      "stop_lon": 2.314303435838755
    },
    {
      "stop_unique_id": "IDFM:71026",
      "stop_name": "Glacière",
      "stop_lat": 48.8308934672617,
      "stop_lon": 2.3438687884909264
    },
    {
      "stop_unique_id": "IDFM:71013",
      "stop_name": "Corvisart",
      "stop_lat": 48.82984291213528,
      "stop_lon": 2.3491197948810463
    },
    {
      "stop_unique_id": "IDFM:71001",
      "stop_name": "Tolbiac",
      "stop_lat": 48.825851710253055,
      "stop_lon": 2.3569746119941324
    },
    {
      "stop_unique_id": "IDFM:71006",
      "stop_name": "Porte de Vanves",
      "stop_lat": 48.8269980349395,
      "stop_lon": 2.305443566618193
    },
    {
      "stop_unique_id": "IDFM:71073",
      "stop_name": "Balard",
      "stop_lat": 48.83648195600811,
      "stop_lon": 2.2781029709215312
    },
    {
      "stop_unique_id": "IDFM:71076",
      "stop_name": "Les Gobelins",
      "stop_lat": 48.83657009255266,
      "stop_lon": 2.3515834949754653
    },
    {
      "stop_unique_id": "IDFM:71075",
      "stop_name": "Convention",
      "stop_lat": 48.83732023959481,
      "stop_lon": 2.297294906137799
    },
    {
      "stop_unique_id": "IDFM:71063",
      "stop_name": "Campo-Formio",
      "stop_lat": 48.835867013311166,
      "stop_lon": 2.3587992772529036
    },
    {
      "stop_unique_id": "IDFM:71056",
      "stop_name": "Denfert-Rochereau",
      "stop_lat": 48.833894420701434,
      "stop_lon": 2.332768817925535
    },
    {
      "stop_unique_id": "IDFM:71054",
      "stop_name": "Marcel Sembat",
      "stop_lat": 48.83374083221833,
      "stop_lon": 2.243496437443725
    },
    {
      "stop_unique_id": "IDFM:71043",
      "stop_name": "Nationale",
      "stop_lat": 48.8330625196255,
      "stop_lon": 2.362580145980611
    },
    {
      "stop_unique_id": "IDFM:71045",
      "stop_name": "Porte de Versailles",
      "stop_lat": 48.83252459684341,
      "stop_lon": 2.2878370017396894
    },
    {
      "stop_unique_id": "IDFM:71041",
      "stop_name": "Saint-Jacques",
      "stop_lat": 48.832886815191124,
      "stop_lon": 2.337125463269374
    },
    {
      "stop_unique_id": "IDFM:415852",
      "stop_name": "Hôtel de Ville",
      "stop_lat": 48.85662036233308,
      "stop_lon": 2.351408936870742
    },
    {
      "stop_unique_id": "IDFM:72057",
      "stop_name": "Pont de Levallois - Bécon",
      "stop_lat": 48.89725479345341,
      "stop_lon": 2.280220019093498
    },
    {
      "stop_unique_id": "IDFM:72064",
      "stop_name": "Porte de la Chapelle",
      "stop_lat": 48.897650630642055,
      "stop_lon": 2.3594159880185788
    },
    {
      "stop_unique_id": "IDFM:72078",
      "stop_name": "Porte de Saint-Ouen",
      "stop_lat": 48.897678834343054,
      "stop_lon": 2.329250609553739
    },
    {
      "stop_unique_id": "IDFM:72024",
      "stop_name": "Église de Pantin",
      "stop_lat": 48.89346699175861,
      "stop_lon": 2.4134761035853325
    },
    {
      "stop_unique_id": "IDFM:72013",
      "stop_name": "Crimée",
      "stop_lat": 48.89084132782932,
      "stop_lon": 2.37703855546864
    },
    {
      "stop_unique_id": "IDFM:72039",
      "stop_name": "Bobigny - Pantin - Raymond Queneau",
      "stop_lat": 48.895340829348676,
      "stop_lon": 2.426263820188867
    },
    {
      "stop_unique_id": "IDFM:72031",
      "stop_name": "Corentin Cariou",
      "stop_lat": 48.8949055544676,
      "stop_lon": 2.3821114831047407
    },
    {
      "stop_unique_id": "IDFM:72004",
      "stop_name": "Hoche",
      "stop_lat": 48.89132806364068,
      "stop_lon": 2.401949999647263
    },
    {
      "stop_unique_id": "IDFM:70990",
      "stop_name": "Corentin Celton",
      "stop_lat": 48.827031039093505,
      "stop_lon": 2.2791056259291196
    },
    {
      "stop_unique_id": "IDFM:72358",
      "stop_name": "Saint-Denis - Université",
      "stop_lat": 48.94619643253519,
      "stop_lon": 2.364654641686732
    },
    {
      "stop_unique_id": "IDFM:72326",
      "stop_name": "Basilique de Saint-Denis",
      "stop_lat": 48.9374306193042,
      "stop_lon": 2.3600659491456057
    },
    {
      "stop_unique_id": "IDFM:73788",
      "stop_name": "Pyramides",
      "stop_lat": 48.86636062761225,
      "stop_lon": 2.3342715478619316
    },
    {
      "stop_unique_id": "IDFM:73794",
      "stop_name": "Les Halles",
      "stop_lat": 48.862516045037715,
      "stop_lon": 2.34606637587523
    },
    {
      "stop_unique_id": "IDFM:73753",
      "stop_name": "Chevilly-Larue​ (Marché International​)",
      "stop_lat": 48.75904993918238,
      "stop_lon": 2.3664766903800145
    },
    {
      "stop_unique_id": "IDFM:73766",
      "stop_name": "Malakoff - Plateau de Vanves",
      "stop_lat": 48.822548165690456,
      "stop_lon": 2.2988628583970145
    },
    {
      "stop_unique_id": "IDFM:72217",
      "stop_name": "Carrefour Pleyel",
      "stop_lat": 48.91971944841158,
      "stop_lon": 2.343594881960972
    },
    {
      "stop_unique_id": "IDFM:72240",
      "stop_name": "Les Agnettes",
      "stop_lat": 48.92324255672704,
      "stop_lon": 2.2865817807272943
    },
    {
      "stop_unique_id": "IDFM:72286",
      "stop_name": "Asnières - Gennevilliers Les Courtilles",
      "stop_lat": 48.93054197241363,
      "stop_lon": 2.283833821983145
    },
    {
      "stop_unique_id": "IDFM:72285",
      "stop_name": "Saint-Denis - Porte de Paris",
      "stop_lat": 48.92963833680226,
      "stop_lon": 2.357636827571862
    },
    {
      "stop_unique_id": "IDFM:72203",
      "stop_name": "Gabriel Péri",
      "stop_lat": 48.916587078563516,
      "stop_lon": 2.294068672273638
    },
    {
      "stop_unique_id": "IDFM:73653",
      "stop_name": "Mouton-Duvernet",
      "stop_lat": 48.831080054439134,
      "stop_lon": 2.329671625847177
    },
    {
      "stop_unique_id": "IDFM:73656",
      "stop_name": "Kléber",
      "stop_lat": 48.87139551252481,
      "stop_lon": 2.293236027780974
    },
    {
      "stop_unique_id": "IDFM:71510",
      "stop_name": "Marx Dormoy",
      "stop_lat": 48.89023245780783,
      "stop_lon": 2.3596436335163613
    },
    {
      "stop_unique_id": "IDFM:73657",
      "stop_name": "Jasmin",
      "stop_lat": 48.852272422659006,
      "stop_lon": 2.2680786507284356
    },
    {
      "stop_unique_id": "IDFM:73658",
      "stop_name": "Michel-Ange - Molitor",
      "stop_lat": 48.84519775845013,
      "stop_lon": 2.261848525164008
    },
    {
      "stop_unique_id": "IDFM:71511",
      "stop_name": "Marcadet - Poissonniers",
      "stop_lat": 48.89132412691521,
      "stop_lon": 2.3502742666727396
    },
    {
      "stop_unique_id": "IDFM:73650",
      "stop_name": "Bel-Air",
      "stop_lat": 48.84160784185524,
      "stop_lon": 2.4009944975546307
    },
    {
      "stop_unique_id": "IDFM:73651",
      "stop_name": "Chevaleret",
      "stop_lat": 48.83485757369037,
      "stop_lon": 2.367687477476106
    },
    {
      "stop_unique_id": "IDFM:73639",
      "stop_name": "Mabillon",
      "stop_lat": 48.85283176740344,
      "stop_lon": 2.3362264259429257
    },
    {
      "stop_unique_id": "IDFM:73642",
      "stop_name": "Assemblée Nationale",
      "stop_lat": 48.861505143054565,
      "stop_lon": 2.3208427999668495
    },
    {
      "stop_unique_id": "IDFM:73648",
      "stop_name": "Richard-Lenoir",
      "stop_lat": 48.8589316814298,
      "stop_lon": 2.37163420897916
    },
    {
      "stop_unique_id": "IDFM:73647",
      "stop_name": "Charonne",
      "stop_lat": 48.85466000287222,
      "stop_lon": 2.3855325518561736
    },
    {
      "stop_unique_id": "IDFM:73640",
      "stop_name": "Rennes",
      "stop_lat": 48.848650605150944,
      "stop_lon": 2.3283213627340222
    },
    {
      "stop_unique_id": "IDFM:73641",
      "stop_name": "Falguière",
      "stop_lat": 48.84506895487195,
      "stop_lon": 2.3183930444072796
    },
    {
      "stop_unique_id": "IDFM:73635",
      "stop_name": "Bourse",
      "stop_lat": 48.868743758980536,
      "stop_lon": 2.3407473307833064
    },
    {
      "stop_unique_id": "IDFM:73636",
      "stop_name": "Réaumur - Sébastopol",
      "stop_lat": 48.866092916070166,
      "stop_lon": 2.351856718715776
    },
    {
      "stop_unique_id": "IDFM:73633",
      "stop_name": "Strasbourg - Saint-Denis",
      "stop_lat": 48.86924250613901,
      "stop_lon": 2.3530376487992517
    },
    {
      "stop_unique_id": "IDFM:73634",
      "stop_name": "Sentier",
      "stop_lat": 48.86733950799845,
      "stop_lon": 2.347076946613402
    },
    {
      "stop_unique_id": "IDFM:73630",
      "stop_name": "Louvre - Rivoli",
      "stop_lat": 48.86089635195878,
      "stop_lon": 2.340635366012601
    },
    {
      "stop_unique_id": "IDFM:73619",
      "stop_name": "Cluny - La Sorbonne",
      "stop_lat": 48.85063021717734,
      "stop_lon": 2.344724126976086
    },
    {
      "stop_unique_id": "IDFM:73618",
      "stop_name": "Odéon",
      "stop_lat": 48.85214019389643,
      "stop_lon": 2.339645318859136
    },
    {
      "stop_unique_id": "IDFM:73617",
      "stop_name": "Europe",
      "stop_lat": 48.87879768179647,
      "stop_lon": 2.3229011862490134
    },
    {
      "stop_unique_id": "IDFM:73626",
      "stop_name": "Gare de Lyon",
      "stop_lat": 48.844398547339175,
      "stop_lon": 2.375147828585711
    },
    {
      "stop_unique_id": "IDFM:73622",
      "stop_name": "Chemin Vert",
      "stop_lat": 48.857383615830855,
      "stop_lon": 2.3679933601043524
    },
    {
      "stop_unique_id": "IDFM:73620",
      "stop_name": "Saint-Michel Notre-Dame",
      "stop_lat": 48.85279583580717,
      "stop_lon": 2.3443902559046017
    },
    {
      "stop_unique_id": "IDFM:73621",
      "stop_name": "Bréguet-Sabin",
      "stop_lat": 48.856230444283455,
      "stop_lon": 2.3702647985546683
    },
    {
      "stop_unique_id": "IDFM:73690",
      "stop_name": "Saint-Augustin",
      "stop_lat": 48.87459282954179,
      "stop_lon": 2.3196495886458997
    },
    {
      "stop_unique_id": "IDFM:73696",
      "stop_name": "La Courneuve - 8 Mai 1945",
      "stop_lat": 48.920891105589405,
      "stop_lon": 2.410691702229731
    },
    {
      "stop_unique_id": "IDFM:73695",
      "stop_name": "Fort d'Aubervilliers",
      "stop_lat": 48.91468485097396,
      "stop_lon": 2.4041034883820616
    },
    {
      "stop_unique_id": "IDFM:73689",
      "stop_name": "Chaussée d'Antin - La Fayette",
      "stop_lat": 48.87364274624465,
      "stop_lon": 2.333275363884853
    },
    {
      "stop_unique_id": "IDFM:73671",
      "stop_name": "Monceau",
      "stop_lat": 48.880458144643214,
      "stop_lon": 2.3089440054773767
    },
    {
      "stop_unique_id": "IDFM:73675",
      "stop_name": "Gaîté",
      "stop_lat": 48.838026783772115,
      "stop_lon": 2.322327244398721
    },
    {
      "stop_unique_id": "IDFM:73661",
      "stop_name": "Brochant",
      "stop_lat": 48.8911661870755,
      "stop_lon": 2.319412571967334
    },
    {
      "stop_unique_id": "IDFM:73667",
      "stop_name": "Louise Michel",
      "stop_lat": 48.88851672346736,
      "stop_lon": 2.2882228633621353
    },
    {
      "stop_unique_id": "IDFM:71485",
      "stop_name": "Esplanade de la Défense",
      "stop_lat": 48.887559798844656,
      "stop_lon": 2.2494242401931523
    },
    {
      "stop_unique_id": "IDFM:71492",
      "stop_name": "Lamarck - Caulaincourt",
      "stop_lat": 48.88956644668994,
      "stop_lon": 2.3396790610076796
    },
    {
      "stop_unique_id": "IDFM:72524",
      "stop_name": "Mairie d'Aubervilliers",
      "stop_lat": 48.91402558979753,
      "stop_lon": 2.381379641787527
    },
    {
      "stop_unique_id": "IDFM:71423",
      "stop_name": "Wagram",
      "stop_lat": 48.88348713286755,
      "stop_lon": 2.303981063505202
    },
    {
      "stop_unique_id": "IDFM:71420",
      "stop_name": "Blanche",
      "stop_lat": 48.883524236583995,
      "stop_lon": 2.3326102064601364
    },
    {
      "stop_unique_id": "IDFM:71426",
      "stop_name": "Barbès - Rochechouart",
      "stop_lat": 48.884108689737936,
      "stop_lon": 2.349750656339381
    },
    {
      "stop_unique_id": "IDFM:71419",
      "stop_name": "Anvers",
      "stop_lat": 48.88286845680266,
      "stop_lon": 2.344134387443545
    },
    {
      "stop_unique_id": "IDFM:70269",
      "stop_name": "Créteil - L'Échat",
      "stop_lat": 48.79646137144668,
      "stop_lon": 2.4491397441394835
    },
    {
      "stop_unique_id": "IDFM:71432",
      "stop_name": "Abbesses",
      "stop_lat": 48.884607637679835,
      "stop_lon": 2.338228264699696
    },
    {
      "stop_unique_id": "IDFM:71434",
      "stop_name": "La Chapelle",
      "stop_lat": 48.88444058549895,
      "stop_lon": 2.3604444136425804
    },
    {
      "stop_unique_id": "IDFM:71435",
      "stop_name": "Place de Clichy",
      "stop_lat": 48.88402724396771,
      "stop_lon": 2.3284787120992734
    },
    {
      "stop_unique_id": "IDFM:71403",
      "stop_name": "Villiers",
      "stop_lat": 48.88152153069055,
      "stop_lon": 2.3155842101583417
    },
    {
      "stop_unique_id": "IDFM:70248",
      "stop_name": "Villejuif Paul Vaillant-Couturier",
      "stop_lat": 48.79626749486545,
      "stop_lon": 2.3672975910376417
    },
    {
      "stop_unique_id": "IDFM:71416",
      "stop_name": "Malesherbes",
      "stop_lat": 48.88243819516111,
      "stop_lon": 2.310226806002677
    },
    {
      "stop_unique_id": "IDFM:71410",
      "stop_name": "Gare du Nord",
      "stop_lat": 48.8809591551807,
      "stop_lon": 2.356144204632079
    },
    {
      "stop_unique_id": "IDFM:71407",
      "stop_name": "Louis Blanc",
      "stop_lat": 48.88160630393444,
      "stop_lon": 2.3652517657562235
    },
    {
      "stop_unique_id": "IDFM:71408",
      "stop_name": "Rome",
      "stop_lat": 48.8820947855925,
      "stop_lon": 2.320703831767569
    },
    {
      "stop_unique_id": "IDFM:71409",
      "stop_name": "Pigalle",
      "stop_lat": 48.88227106045421,
      "stop_lon": 2.3377193332036326
    },
    {
      "stop_unique_id": "IDFM:71469",
      "stop_name": "Château Rouge",
      "stop_lat": 48.88747990351188,
      "stop_lon": 2.3495176272228218
    },
    {
      "stop_unique_id": "IDFM:71475",
      "stop_name": "Pont Cardinet",
      "stop_lat": 48.887831871343174,
      "stop_lon": 2.315064865986367
    },
    {
      "stop_unique_id": "IDFM:71474",
      "stop_name": "La Fourche",
      "stop_lat": 48.88773314314732,
      "stop_lon": 2.3253965765023037
    },
    {
      "stop_unique_id": "IDFM:71442",
      "stop_name": "Pont de Neuilly",
      "stop_lat": 48.8842333985359,
      "stop_lon": 2.259720160628328
    },
    {
      "stop_unique_id": "IDFM:71453",
      "stop_name": "Péreire Levallois",
      "stop_lat": 48.88512607033229,
      "stop_lon": 2.2974202857451322
    },
    {
      "stop_unique_id": "IDFM:71450",
      "stop_name": "Porte de Champerret",
      "stop_lat": 48.885670289624045,
      "stop_lon": 2.2928581832760835
    },
    {
      "stop_unique_id": "IDFM:70426",
      "stop_name": "Maisons-Alfort - Stade",
      "stop_lat": 48.80886813053382,
      "stop_lon": 2.4351128454807247
    },
    {
      "stop_unique_id": "IDFM:71366",
      "stop_name": "Saint-Georges",
      "stop_lat": 48.87869967767963,
      "stop_lon": 2.3375329519457986
    },
    {
      "stop_unique_id": "IDFM:71367",
      "stop_name": "Ternes",
      "stop_lat": 48.878250372427516,
      "stop_lon": 2.29814025359363
    },
    {
      "stop_unique_id": "IDFM:71363",
      "stop_name": "Poissonnière",
      "stop_lat": 48.87753309886302,
      "stop_lon": 2.348932857907123
    },
    {
      "stop_unique_id": "IDFM:71379",
      "stop_name": "Neuilly Porte Maillot",
      "stop_lat": 48.87806050578374,
      "stop_lon": 2.28204667067102
    },
    {
      "stop_unique_id": "IDFM:71376",
      "stop_name": "Courcelles",
      "stop_lat": 48.8791612828857,
      "stop_lon": 2.303200306302588
    },
    {
      "stop_unique_id": "IDFM:71370",
      "stop_name": "Gare Saint-Lazare",
      "stop_lat": 48.875942170484834,
      "stop_lon": 2.3252697938313074
    },
    {
      "stop_unique_id": "IDFM:71382",
      "stop_name": "Liège",
      "stop_lat": 48.8797802520473,
      "stop_lon": 2.328097140824128
    },
    {
      "stop_unique_id": "IDFM:72430",
      "stop_name": "Porte de la Villette",
      "stop_lat": 48.89782692280753,
      "stop_lon": 2.386209748766301
    },
    {
      "stop_unique_id": "IDFM:70358",
      "stop_name": "Maisons-Alfort - Les Juilliottes",
      "stop_lat": 48.803654409376314,
      "stop_lon": 2.444804956488428
    },
    {
      "stop_unique_id": "IDFM:72491",
      "stop_name": "Aimé Césaire",
      "stop_lat": 48.90826024316105,
      "stop_lon": 2.3777509654431186
    },
    {
      "stop_unique_id": "IDFM:71301",
      "stop_name": "Boissière",
      "stop_lat": 48.867105405198515,
      "stop_lon": 2.290158839553604
    },
    {
      "stop_unique_id": "IDFM:71305",
      "stop_name": "Champs-Élysées - Clemenceau",
      "stop_lat": 48.86784725672992,
      "stop_lon": 2.3137258434204573
    },
    {
      "stop_unique_id": "IDFM:70375",
      "stop_name": "Villejuif Léo Lagrange",
      "stop_lat": 48.805058867183895,
      "stop_lon": 2.363868678371603
    },
    {
      "stop_unique_id": "IDFM:71311",
      "stop_name": "République",
      "stop_lat": 48.86769225425708,
      "stop_lon": 2.3641535175879786
    },
    {
      "stop_unique_id": "IDFM:71313",
      "stop_name": "Quatre Septembre",
      "stop_lat": 48.869639556747096,
      "stop_lon": 2.337661810027122
    },
    {
      "stop_unique_id": "IDFM:71315",
      "stop_name": "Victor Hugo",
      "stop_lat": 48.8697473717344,
      "stop_lon": 2.2852695426422143
    },
    {
      "stop_unique_id": "IDFM:71320",
      "stop_name": "Bonne Nouvelle",
      "stop_lat": 48.87056613523542,
      "stop_lon": 2.3483587335653286
    },
    {
      "stop_unique_id": "IDFM:71328",
      "stop_name": "George V",
      "stop_lat": 48.871815493831825,
      "stop_lon": 2.301199036633483
    },
    {
      "stop_unique_id": "IDFM:71326",
      "stop_name": "Grands Boulevards",
      "stop_lat": 48.87113744940455,
      "stop_lon": 2.3434641109196996
    },
    {
      "stop_unique_id": "IDFM:71324",
      "stop_name": "Madeleine",
      "stop_lat": 48.870027512554685,
      "stop_lon": 2.3247193036606753
    },
    {
      "stop_unique_id": "IDFM:71322",
      "stop_name": "Jacques Bonsergent",
      "stop_lat": 48.870871159647955,
      "stop_lon": 2.360795076178521
    },
    {
      "stop_unique_id": "IDFM:71318",
      "stop_name": "Franklin D. Roosevelt",
      "stop_lat": 48.86919080712688,
      "stop_lon": 2.309965975803638
    },
    {
      "stop_unique_id": "IDFM:71330",
      "stop_name": "Richelieu - Drouot",
      "stop_lat": 48.872034751145655,
      "stop_lon": 2.3390083233469814
    },
    {
      "stop_unique_id": "IDFM:72460",
      "stop_name": "Aubervilliers-Pantin Quatre Chemins",
      "stop_lat": 48.90369890290079,
      "stop_lon": 2.3922792270208864
    },
    {
      "stop_unique_id": "IDFM:71337",
      "stop_name": "Opéra",
      "stop_lat": 48.87123527878308,
      "stop_lon": 2.331888322918628
    },
    {
      "stop_unique_id": "IDFM:71333",
      "stop_name": "Château d'Eau",
      "stop_lat": 48.87220240272352,
      "stop_lon": 2.356572833097793
    },
    {
      "stop_unique_id": "IDFM:71334",
      "stop_name": "Saint-Philippe du Roule",
      "stop_lat": 48.87278848019531,
      "stop_lon": 2.310886307371271
    },
    {
      "stop_unique_id": "IDFM:71346",
      "stop_name": "Miromesnil",
      "stop_lat": 48.87356120780947,
      "stop_lon": 2.3145912897307794
    },
    {
      "stop_unique_id": "IDFM:71347",
      "stop_name": "Charles de Gaulle - Etoile",
      "stop_lat": 48.87362503818625,
      "stop_lon": 2.2946168784613463
    },
    {
      "stop_unique_id": "IDFM:71348",
      "stop_name": "Argentine",
      "stop_lat": 48.87576848552414,
      "stop_lon": 2.2890454581045376
    },
    {
      "stop_unique_id": "IDFM:72486",
      "stop_name": "Bobigny Pablo Picasso",
      "stop_lat": 48.90698806520723,
      "stop_lon": 2.449463929109329
    },
    {
      "stop_unique_id": "IDFM:71350",
      "stop_name": "Notre-Dame-de-Lorette",
      "stop_lat": 48.87586889279014,
      "stop_lon": 2.337916634442789
    },
    {
      "stop_unique_id": "IDFM:71351",
      "stop_name": "Cadet",
      "stop_lat": 48.87607458774838,
      "stop_lon": 2.3434223340761537
    },
    {
      "stop_unique_id": "IDFM:71355",
      "stop_name": "Trinité - d'Estienne d'Orves",
      "stop_lat": 48.87650096546529,
      "stop_lon": 2.3318985984226077
    },
    {
      "stop_unique_id": "IDFM:71359",
      "stop_name": "Gare de l'Est",
      "stop_lat": 48.877033973613294,
      "stop_lon": 2.3586469665130907
    },
    {
      "stop_unique_id": "IDFM:70537",
      "stop_name": "Pierre et Marie Curie",
      "stop_lat": 48.815567848424024,
      "stop_lon": 2.376606930958414
    },
    {
      "stop_unique_id": "IDFM:70522",
      "stop_name": "École Vétérinaire de Maisons-Alfort",
      "stop_lat": 48.814004055920265,
      "stop_lon": 2.421568498345177
    },
    {
      "stop_unique_id": "IDFM:71284",
      "stop_name": "Tuileries",
      "stop_lat": 48.864283698691594,
      "stop_lon": 2.3311459633017058
    },
    {
      "stop_unique_id": "IDFM:71285",
      "stop_name": "Trocadéro",
      "stop_lat": 48.8634455913546,
      "stop_lon": 2.287223474647482
    },
    {
      "stop_unique_id": "IDFM:71297",
      "stop_name": "Palais Royal - Musée du Louvre",
      "stop_lat": 48.86253006271001,
      "stop_lon": 2.3360074436243554
    },
    {
      "stop_unique_id": "IDFM:71298",
      "stop_name": "Concorde",
      "stop_lat": 48.86556403658532,
      "stop_lon": 2.321341974369632
    },
    {
      "stop_unique_id": "IDFM:71296",
      "stop_name": "Temple",
      "stop_lat": 48.86645395133275,
      "stop_lon": 2.3612991224545006
    },
    {
      "stop_unique_id": "IDFM:71293",
      "stop_name": "Arts et Métiers",
      "stop_lat": 48.86553230056115,
      "stop_lon": 2.3571323317470423
    },
    {
      "stop_unique_id": "IDFM:71294",
      "stop_name": "Iéna",
      "stop_lat": 48.86466464469911,
      "stop_lon": 2.2941247882632076
    },
    {
      "stop_unique_id": "IDFM:71292",
      "stop_name": "Rue de la Pompe",
      "stop_lat": 48.86471860202877,
      "stop_lon": 2.2782910298035497
    },
    {
      "stop_unique_id": "IDFM:71290",
      "stop_name": "Alma - Marceau",
      "stop_lat": 48.86456963401637,
      "stop_lon": 2.3016915456037386
    },
    {
      "stop_unique_id": "IDFM:71264",
      "stop_name": "Châtelet",
      "stop_lat": 48.85826227294274,
      "stop_lon": 2.3482877360417778
    },
    {
      "stop_unique_id": "IDFM:71270",
      "stop_name": "Solférino",
      "stop_lat": 48.858227656869374,
      "stop_lon": 2.323338883561332
    },
    {
      "stop_unique_id": "IDFM:71274",
      "stop_name": "Invalides",
      "stop_lat": 48.86170763515209,
      "stop_lon": 2.313772649516597
    },
    {
      "stop_unique_id": "IDFM:71273",
      "stop_name": "Rambuteau",
      "stop_lat": 48.860520114262876,
      "stop_lon": 2.353268635720191
    },
    {
      "stop_unique_id": "IDFM:71277",
      "stop_name": "Filles du Calvaire",
      "stop_lat": 48.86246821352985,
      "stop_lon": 2.366064254383943
    },
    {
      "stop_unique_id": "IDFM:71243",
      "stop_name": "Ranelagh",
      "stop_lat": 48.855609844498225,
      "stop_lon": 2.270416875317818
    },
    {
      "stop_unique_id": "IDFM:71242",
      "stop_name": "La Tour-Maubourg",
      "stop_lat": 48.85744943480267,
      "stop_lon": 2.3099034103919553
    },
    {
      "stop_unique_id": "IDFM:71253",
      "stop_name": "Pont Neuf",
      "stop_lat": 48.858998297777845,
      "stop_lon": 2.3430105472597145
    },
    {
      "stop_unique_id": "IDFM:71224",
      "stop_name": "École Militaire",
      "stop_lat": 48.854452533600195,
      "stop_lon": 2.3056630951536086
    },
    {
      "stop_unique_id": "IDFM:71222",
      "stop_name": "Saint-Paul (Le Marais)",
      "stop_lat": 48.85503393346524,
      "stop_lon": 2.3615140746969536
    },
    {
      "stop_unique_id": "IDFM:71223",
      "stop_name": "Cité",
      "stop_lat": 48.855377102018025,
      "stop_lon": 2.346879142365621
    },
    {
      "stop_unique_id": "IDFM:71227",
      "stop_name": "Rue du Bac",
      "stop_lat": 48.8561370177307,
      "stop_lon": 2.325792037417532
    },
    {
      "stop_unique_id": "IDFM:70452",
      "stop_name": "Le Kremlin-Bicêtre",
      "stop_lat": 48.810369776117064,
      "stop_lon": 2.36218344915433
    },
    {
      "stop_unique_id": "IDFM:71237",
      "stop_name": "Passy",
      "stop_lat": 48.857867542087384,
      "stop_lon": 2.285371349647822
    },
    {
      "stop_unique_id": "IDFM:63284",
      "stop_name": "Aéroport d’Orly (Terminaux 1-2-3)",
      "stop_lat": 48.728600064521956,
      "stop_lon": 2.360693840681084
    },
    {
      "stop_unique_id": "IDFM:71232",
      "stop_name": "Varenne",
      "stop_lat": 48.85662459275516,
      "stop_lon": 2.3150457955866766
    },
    {
      "stop_unique_id": "IDFM:71206",
      "stop_name": "Michel-Ange - Auteuil",
      "stop_lat": 48.84823136625337,
      "stop_lon": 2.264670688681439
    },
    {
      "stop_unique_id": "IDFM:71204",
      "stop_name": "Saint-François-Xavier",
      "stop_lat": 48.85121999118795,
      "stop_lon": 2.314353664858666
    },
    {
      "stop_unique_id": "IDFM:71202",
      "stop_name": "Saint-Sulpice",
      "stop_lat": 48.851486107532125,
      "stop_lon": 2.3319848170653725
    },
    {
      "stop_unique_id": "IDFM:71203",
      "stop_name": "Sèvres - Babylone",
      "stop_lat": 48.85156138487256,
      "stop_lon": 2.326961924012207
    },
    {
      "stop_unique_id": "IDFM:71201",
      "stop_name": "Sully - Morland",
      "stop_lat": 48.85098552281841,
      "stop_lon": 2.3622109943200655
    },
    {
      "stop_unique_id": "IDFM:71216",
      "stop_name": "Saint-Germain-des-Prés",
      "stop_lat": 48.85347721656853,
      "stop_lon": 2.3334243570371873
    },
    {
      "stop_unique_id": "IDFM:71217",
      "stop_name": "Pont Marie (Cité des Arts)",
      "stop_lat": 48.853733698528195,
      "stop_lon": 2.356453537385296
    },
    {
      "stop_unique_id": "IDFM:70445",
      "stop_name": "Hôpital Bicêtre",
      "stop_lat": 48.81041276068554,
      "stop_lon": 2.350124860784009
    },
    {
      "stop_unique_id": "IDFM:70441",
      "stop_name": "Barbara",
      "stop_lat": 48.809750601703634,
      "stop_lon": 2.3176427227007874
    },
    {
      "stop_unique_id": "IDFM:70488",
      "stop_name": "Mairie d'Ivry",
      "stop_lat": 48.811051302921314,
      "stop_lon": 2.3840990948217073
    },
    {
      "stop_unique_id": "IDFM:473829",
      "stop_name": "Bir-Hakeim",
      "stop_lat": 48.85414786198107,
      "stop_lon": 2.289762954220385
    },
    {
      "stop_unique_id": "IDFM:70604",
      "stop_name": "Porte de Choisy",
      "stop_lat": 48.81979687028014,
      "stop_lon": 2.3650099846998174
    },
    {
      "stop_unique_id": "IDFM:70645",
      "stop_name": "Maison Blanche",
      "stop_lat": 48.821849385790394,
      "stop_lon": 2.3586311576338144
    },
    {
      "stop_unique_id": "IDFM:70636",
      "stop_name": "Porte d'Ivry",
      "stop_lat": 48.82137654958477,
      "stop_lon": 2.369498994435339
    },
    {
      "stop_unique_id": "IDFM:70657",
      "stop_name": "Porte d'Orléans",
      "stop_lat": 48.82241845693561,
      "stop_lon": 2.325567466692253
    },
    {
      "stop_unique_id": "IDFM:70648",
      "stop_name": "Charenton - Écoles",
      "stop_lat": 48.82157243420381,
      "stop_lon": 2.4142046105987363
    },
    {
      "stop_unique_id": "IDFM:71166",
      "stop_name": "Église d'Auteuil",
      "stop_lat": 48.84771844593106,
      "stop_lon": 2.2697642383686474
    },
    {
      "stop_unique_id": "IDFM:71162",
      "stop_name": "Mirabeau",
      "stop_lat": 48.847341570641845,
      "stop_lon": 2.273598709754977
    },
    {
      "stop_unique_id": "IDFM:71167",
      "stop_name": "Cambronne",
      "stop_lat": 48.84781466221392,
      "stop_lon": 2.301698037878076
    },
    {
      "stop_unique_id": "IDFM:71169",
      "stop_name": "Porte d'Auteuil",
      "stop_lat": 48.84756173959799,
      "stop_lon": 2.2589056503826903
    },
    {
      "stop_unique_id": "IDFM:71170",
      "stop_name": "Vaneau",
      "stop_lat": 48.84853523091082,
      "stop_lon": 2.320630271036771
    },
    {
      "stop_unique_id": "IDFM:71184",
      "stop_name": "Saint-Placide",
      "stop_lat": 48.846707430514385,
      "stop_lon": 2.3266226225384568
    },
    {
      "stop_unique_id": "IDFM:71199",
      "stop_name": "La Motte-Picquet - Grenelle",
      "stop_lat": 48.84918377697637,
      "stop_lon": 2.2984077121284154
    },
    {
      "stop_unique_id": "IDFM:71197",
      "stop_name": "Dupleix",
      "stop_lat": 48.85117435971625,
      "stop_lon": 2.292610847350156
    },
    {
      "stop_unique_id": "IDFM:71190",
      "stop_name": "Maubert - Mutualité",
      "stop_lat": 48.84979562680443,
      "stop_lon": 2.3486320983198694
    },
    {
      "stop_unique_id": "IDFM:71124",
      "stop_name": "Exelmans",
      "stop_lat": 48.84300966471384,
      "stop_lon": 2.2601543750612154
    },
    {
      "stop_unique_id": "IDFM:71125",
      "stop_name": "Pasteur",
      "stop_lat": 48.84244072335257,
      "stop_lon": 2.3125275046565847
    },
    {
      "stop_unique_id": "IDFM:71139",
      "stop_name": "Gare Montparnasse",
      "stop_lat": 48.84067243107856,
      "stop_lon": 2.319342991047202
    },
    {
      "stop_unique_id": "IDFM:71138",
      "stop_name": "Notre-Dame des Champs",
      "stop_lat": 48.844916170203646,
      "stop_lon": 2.3287510292962232
    },
    {
      "stop_unique_id": "IDFM:71137",
      "stop_name": "Félix Faure",
      "stop_lat": 48.84299440812691,
      "stop_lon": 2.2925719898179917
    },
    {
      "stop_unique_id": "IDFM:71135",
      "stop_name": "Gare d'Austerlitz",
      "stop_lat": 48.84235421610177,
      "stop_lon": 2.3668334950792076
    },
    {
      "stop_unique_id": "IDFM:71133",
      "stop_name": "Place Monge",
      "stop_lat": 48.843147478331055,
      "stop_lon": 2.35214266138425
    },
    {
      "stop_unique_id": "IDFM:71132",
      "stop_name": "Commerce",
      "stop_lat": 48.844611352403035,
      "stop_lon": 2.2937695918334393
    },
    {
      "stop_unique_id": "IDFM:71148",
      "stop_name": "Jussieu",
      "stop_lat": 48.84616944298555,
      "stop_lon": 2.354659492342663
    },
    {
      "stop_unique_id": "IDFM:71147",
      "stop_name": "Boulogne Jean Jaurès",
      "stop_lat": 48.843048489709275,
      "stop_lon": 2.2383946783359385
    },
    {
      "stop_unique_id": "IDFM:71141",
      "stop_name": "Chardon Lagache",
      "stop_lat": 48.8449247565326,
      "stop_lon": 2.2666352486987615
    },
    {
      "stop_unique_id": "IDFM:71144",
      "stop_name": "Sèvres-Lecourbe",
      "stop_lat": 48.84514955274541,
      "stop_lon": 2.3103115511106758
    },
    {
      "stop_unique_id": "IDFM:71159",
      "stop_name": "Duroc",
      "stop_lat": 48.84716012619314,
      "stop_lon": 2.3160559346586007
    },
    {
      "stop_unique_id": "IDFM:71158",
      "stop_name": "Avenue Émile Zola",
      "stop_lat": 48.84697871602308,
      "stop_lon": 2.293975980197606
    },
    {
      "stop_unique_id": "IDFM:71157",
      "stop_name": "Ségur",
      "stop_lat": 48.84719625542126,
      "stop_lon": 2.307194975080617
    },
    {
      "stop_unique_id": "IDFM:71156",
      "stop_name": "Charles Michels",
      "stop_lat": 48.846294619242606,
      "stop_lon": 2.2864360512438364
    },
    {
      "stop_unique_id": "IDFM:71150",
      "stop_name": "Javel",
      "stop_lat": 48.84638139063437,
      "stop_lon": 2.2776660529864734
    },
    {
      "stop_unique_id": "IDFM:71153",
      "stop_name": "Cardinal Lemoine",
      "stop_lat": 48.846475950203825,
      "stop_lon": 2.3514670575552037
    },
    {
      "stop_unique_id": "IDFM:70562",
      "stop_name": "Malakoff - Rue Etienne Dolet",
      "stop_lat": 48.81476509220426,
      "stop_lon": 2.2966523673061356
    },
    {
      "stop_unique_id": "IDFM:71100",
      "stop_name": "Censier - Daubenton",
      "stop_lat": 48.84045621319383,
      "stop_lon": 2.351742220181727
    },
    {
      "stop_unique_id": "IDFM:71108",
      "stop_name": "Edgar Quinet",
      "stop_lat": 48.840942420836484,
      "stop_lon": 2.3253305810186986
    },
    {
      "stop_unique_id": "IDFM:70586",
      "stop_name": "Mairie de Montrouge",
      "stop_lat": 48.81861849633613,
      "stop_lon": 2.320788529742438
    },
    {
      "stop_unique_id": "IDFM:71113",
      "stop_name": "Volontaires",
      "stop_lat": 48.84152114146061,
      "stop_lon": 2.3082408110738704
    },
    {
      "stop_unique_id": "IDFM:71117",
      "stop_name": "Vavin",
      "stop_lat": 48.84188816967195,
      "stop_lon": 2.3292601073444943
    },
    {
      "stop_unique_id": "IDFM:70596",
      "stop_name": "Porte d'Italie",
      "stop_lat": 48.81876689621561,
      "stop_lon": 2.3599152491877704
    },
    {
      "stop_unique_id": "IDFM:426280",
      "stop_name": "Rosny Bois Perrier",
      "stop_lat": 48.882489480470596,
      "stop_lon": 2.4813923962573416
    },
    {
      "stop_unique_id": "IDFM:479928",
      "stop_name": "Buzenval",
      "stop_lat": 48.85210574161963,
      "stop_lon": 2.4014802697487627
    },
    {
      "stop_unique_id": "IDFM:482368",
      "stop_name": "Havre - Caumartin",
      "stop_lat": 48.87357402758874,
      "stop_lon": 2.3271544966132858
    },
    {
      "stop_unique_id": "IDFM:483315",
      "stop_name": "Bagneux - Lucie Aubrac",
      "stop_lat": 48.803548144851376,
      "stop_lon": 2.3174447475670448
    },
    {
      "stop_unique_id": "IDFM:490784",
      "stop_name": "Saint-Denis - Pleyel",
      "stop_lat": 48.917825211955325,
      "stop_lon": 2.3450174243970885
    },
    {
      "stop_unique_id": "IDFM:490783",
      "stop_name": "Coteaux Beauclair",
      "stop_lat": 48.881978139577015,
      "stop_lon": 2.468354848962464
    },
    {
      "stop_unique_id": "IDFM:490782",
      "stop_name": "La Dhuys",
      "stop_lat": 48.87804725437976,
      "stop_lon": 2.4651362637521186
    },
    {
      "stop_unique_id": "IDFM:490779",
      "stop_name": "Serge Gainsbourg",
      "stop_lat": 48.88115160103083,
      "stop_lon": 2.4266383122632105
    }
  ]
}