---
slug: '2024-04-12'
title: '2024-04-12'
---

# 2024-04-12

Entre mardi et aujourd'hui, j'ai pu contacter IDFM pour savoir comment faire le lien, et d'après leurs réponses ce n'est
pas si simple. Ma solution actuelle est peut-être la moins mauvaise, mais nos échanges ne sont pas terminés.

J'ai discuté ce matin autour d'un café avec Pierre Top, de ce projet et de ce que je faisais. Il a remis en question mon
usage de node.js et de stream pour insérer les données en base
vs [COPY FROM](https://www.postgresql.org/docs/current/sql-copy.html) de postgresql. Il est vrai que je n'ai pas
documenté ce point. Je vais donc m'y atteler.

Le projet étant en JS, je trouve que c'est un bon choix de tout faire dans ce langage.
J'apprécie l'article
["Scripts should be written using the project main language" de Freitas](https://joaomagfreitas.link/scripts-should-be-written-using-the-project-main-language/),
qui traite de ce sujet.
De ma petite expérience, j'apprécie knex pour sa gestion du schéma via les migrations, cela m'a donc semblé naturel de
l'utiliser pour créer le schéma.

Ensuite pour l'usage de COPY FROM, j'en garde de mauvais souvenirs où il faut après la copie mettre à jour les
séquences, cependant je n'ai pas d'autres données à ajouter ensuite c'est donc un choix judicieux. J'ai peut-être exclu
trop vite cette possibilité.
Néanmoins, il aurait fallu que je respecte le schéma du csv, ce qui n'est pas le cas actuellement.
