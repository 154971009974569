const _temp0 = require("../../docs/diary/2024-02-25.md");
const _temp1 = require("../../docs/diary/2024-02-27.md");
const _temp2 = require("../../docs/diary/2024-03-01.md");
const _temp3 = require("../../docs/diary/2024-03-02.md");
const _temp4 = require("../../docs/diary/2024-03-03.md");
const _temp5 = require("../../docs/diary/2024-03-04.md");
const _temp6 = require("../../docs/diary/2024-03-05.md");
const _temp7 = require("../../docs/diary/2024-03-06.md");
const _temp8 = require("../../docs/diary/2024-03-09.md");
const _temp9 = require("../../docs/diary/2024-03-10.md");
const _temp10 = require("../../docs/diary/2024-03-11.md");
const _temp11 = require("../../docs/diary/2024-03-13.md");
const _temp12 = require("../../docs/diary/2024-03-14.md");
const _temp13 = require("../../docs/diary/2024-03-25.md");
const _temp14 = require("../../docs/diary/2024-03-26.md");
const _temp15 = require("../../docs/diary/2024-03-27.md");
const _temp16 = require("../../docs/diary/2024-03-28.md");
const _temp17 = require("../../docs/diary/2024-03-29.md");
const _temp18 = require("../../docs/diary/2024-04-08.md");
const _temp19 = require("../../docs/diary/2024-04-09.md");
const _temp20 = require("../../docs/diary/2024-04-12.md");
const _temp21 = require("../../docs/diary/2024-04-14.md");
const _temp22 = require("../../docs/diary/2024-04-15.md");
const _temp23 = require("../../docs/diary/2024-04-26.md");
const _temp24 = require("../../docs/diary/2024-05-08.md");
module.exports = {
  "2024-02-25": _temp0,
  "2024-02-27": _temp1,
  "2024-03-01": _temp2,
  "2024-03-02": _temp3,
  "2024-03-03": _temp4,
  "2024-03-04": _temp5,
  "2024-03-05": _temp6,
  "2024-03-06": _temp7,
  "2024-03-09": _temp8,
  "2024-03-10": _temp9,
  "2024-03-11": _temp10,
  "2024-03-13": _temp11,
  "2024-03-14": _temp12,
  "2024-03-25": _temp13,
  "2024-03-26": _temp14,
  "2024-03-27": _temp15,
  "2024-03-28": _temp16,
  "2024-03-29": _temp17,
  "2024-04-08": _temp18,
  "2024-04-09": _temp19,
  "2024-04-12": _temp20,
  "2024-04-14": _temp21,
  "2024-04-15": _temp22,
  "2024-04-26": _temp23,
  "2024-05-08": _temp24
}