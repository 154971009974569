---
slug: '2024-03-04'
title: '2024-03-04'
---

# 2024-03-04

Aujourd'hui, il faudrait que je penche sur un script pour choisir deux stations qui ne sont pas trop proches l'une de
l'autre pour pouvoir faire le jeu.

Et surtout, il faudra que je fasse deux outils : - le premier, pour checker si les deux stations ont bien été reliées
entre elles par le joueur - le second, pour calculer le chemin le plus court (Dijkstra?) : est-ce que ça sera en nombre
de stations ou en temps de trajet ?

Je vais commencer par le second outil, qui me permettra de voir ensuite pour le premier outil,
d'avoir la longueur du chemin et ainsi ne pas prendre les chemins trop court

Je vais donc commencer par regarder comment je peux utiliser Dijkstra pour trouver le chemin le plus court entre deux
stations.

Juste avant, j'en ai un peu marre de ne pas avoir de linter, formatter, c'est une bonne raison pour installer Biome.
Finalement, le plugin Biome de Webstorm, ne gère pas le lint on save donc hop je passe au script.

J'ai demandé à ChatGPT, qui m'a fait une version de Dijkstra en JavaScript. J'ai eu à créer le graph à la main, mais
voilà le code a l'air de fonctionner.

Une idée me vient d'ajouter un cout de changement de ligne ?
D'ailleurs ma todo :

- [ ] Ajouter un cout de changement de ligne sur le script de Dijkstra
- [x] Exporter une méthode pour le Dijsktra
- [x] Créer le script qui pioche 2 stations
- [ ] Enregistrer les stations déjà piochées ? Au moins celui de la vieille pour ne pas tomber 2 jours de suite sur le
      même
- [ ] Optimiser le build : supprimer les propriétés inutiles dans les stations et lignes
- [ ] Trouver un nom
- [ ] MEP
- [ ] Ajouter un mode practice ?
- [ ] Ajouter un mode par temps entre stations

J'ai pu réaliser le script qui pioche deux stations, avec un random avec seed,
pour que tous les joueurs le même jour aient les mêmes stations.

J'en ai fini pour aujourd'hui !
