---
slug: '2024-03-06'
title: '2024-03-06'
---

# 2024-03-06

Sans trop faire attention hier soir, je n'ai pas vu que GitHub avait échoué la vérification DNS.
Je retire donc le CNAME et je le remets et ça fonctionne, super le site est en prod !

Je vois un petit souci avec le zoom, les chemins entre les stations ne sont pas zoomés, je fixe ça rapidement.
Je n'utilisais pas `g` qui est l'élément SVG zoomable, mais je l'ajoutais dans le svg, affaire résolue.

Il me reste quoi à faire ?
De tête :

- [ ] Améliorer le responsive, si une application de métro ne peut pas se jouer dans le métro …
- [ ] Ajouter les boutons pour le zoom : zoomer, dézoomer, reset
- [ ] Ajouter un mode practice ?
- [ ] Ajouter l'écran de fin de partie avec le chemin le plus court et le chemin du joueur
- [ ] Ajouter les stations du joueur en dessous de la carte, et pouvoir les activer/désactiver
- [ ] Ajouter un mode par temps entre stations

Je stoppe la todo ici, je vais commencer par le responsive mais pas là, je suis fatigué.
J'ai quand même regardé un peu ça n'a pas l'air si évident que ça de faire un SVG responsive.
J'ai proposé une première version, mais je n’en suis pas entièrement satisfait.
