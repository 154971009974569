<script setup>

</script>

<template>
  <div class="container">
    <h1>Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <router-link to="/">
      Go to Home
    </router-link>
  </div>
</template>

<style scoped>

</style>
