---
slug: '2024-03-11'
title: '2024-03-11'
---

# 2024-03-11

La gestion de la hauteur de carte et le centrage de la carte ne sont pas optimaux, ça serait bien d'améliorer ce point.

Je commence à avoir pas mal d'idées en vrac, mais pas de TODO, ça ne serait pas le moment de commencer à en faire une ?
Un projet GitHub ?

En soit le projet GitHub, c'est pas mal, mais j'ai la flemme de faire des pull requests ça marche sans ?
Un fichier texte dans docs, c'est bien aussi …

La tâche "- [ ] Script - Merge-duplicate-station - Ne pas prendre compte les accents dans les noms", me plait bien, je
vais commencer par ça.
Actuellement, il faut se souvenir de l'ordre pour lancer les scripts, et ils ne sont pas testés. Je vais améliorer ça.

Alors merci les pages de journal, je ne savais plus l'ordre des scripts, mais je viens de le retrouver :

```
node src/merge-duplicate-stations.js
node src/extract-lines.js
node src/add-adjacent-stations.js
```

Première étape, exporter des fonctions dans chaque script.
Ensuite, pour améliorer la testabilité, je vais passer en argument les données et que ça me les retourne modifiées.
Je peux enfin tester la feature que j'ajoute, puis je fais un script qui lance les 3 scripts dans l'ordre.
Ah les données sont un peu plus cohérentes, ça fait plaisir.

J'ai enchainé sur plusieurs tâches grâce à la todo, j'ai un peu laissé le journal, voilà ce que j'ai fait de la todo :

- [x] UX - Modale de fin - Ajouter le chemin le plus court et le chemin du joueur
- [x] UX - Search Input - Ajouter un feedback si la station n'existe pas
- [x] UX - Search Input - Retirer les stations déjà ajoutées
- [x] Script - Merge-duplicate-station - Ne pas prendre compte les accents dans les noms

Mais aussi :

- Corriger un bug avec l'animation d'ajout des stations qui prenait de plus en plus de temps à chaque ajout
- Corriger le workflow CD pour bien commit et pusher les screenshots du rendu de l'app
