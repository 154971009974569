---
slug: '2024-04-15'
title: '2024-04-15'
---

# 2024-04-15

J'ai commencé à migrer la class Map, cela avance tranquillement. Je n'arrive pas à afficher le chemin entre 2 stations,
pour au final me rendre compte que ce n'est pas un problème de code, mais de données. Le moyen de lié les données entre
les `stops` et les `unique_stops` a été un peu fatal. En effet, j'ai fait en sorte que dès que le nom est contenu dans
le nom d'une station unique un match se fait. Mais, le problème : `Villiers` est contenu dans `Fort d'Aubervilliers"`.

Je suis bon pour faire en sorte de récupérer tous les cas comme ça pour pouvoir voir ce que je peux faire ensuite.
J'ai plus de cas que je ne le pensais (23 cas) :

```json
[
  {
    "stop_name": "Nation",
    "foundUniqueStops": [
      {
        "stop_unique_id": "339",
        "stop_name": "Nation",
        "stop_lon": 48.848237109475654,
        "stop_lat": 2.3958235888261252,
        "id_red_zdc": "71673"
      },
      {
        "stop_unique_id": "106047",
        "stop_name": "Nationale",
        "stop_lon": 48.83321671877524,
        "stop_lat": 2.362856399519012,
        "id_red_zdc": "71043"
      },
      {
        "stop_unique_id": "112059",
        "stop_name": "Assemblée Nationale",
        "stop_lon": 48.86078696169296,
        "stop_lat": 2.320998191965805,
        "id_red_zdc": "73642"
      }
    ]
  },
  {
    "stop_name": "Miromesnil",
    "foundUniqueStops": [
      {
        "stop_unique_id": "114059",
        "stop_name": "Miromesnil",
        "stop_lon": 48.87368520970193,
        "stop_lat": 2.3144679895687355,
        "id_red_zdc": "71346"
      },
      {
        "stop_unique_id": "102067",
        "stop_name": "Rome",
        "stop_lon": 48.88233342277264,
        "stop_lat": 2.321801731063168,
        "id_red_zdc": "71408"
      }
    ]
  },
  {
    "stop_name": "Jaurès",
    "foundUniqueStops": [
      {
        "stop_unique_id": "117039",
        "stop_name": "Jaurès",
        "stop_lon": 48.88273813184986,
        "stop_lat": 2.3701223122811044,
        "id_red_zdc": "71940"
      },
      {
        "stop_unique_id": "110025",
        "stop_name": "Boulogne - Jean Jaurès",
        "stop_lon": 48.84200534636367,
        "stop_lat": 2.238887328515536,
        "id_red_zdc": "71147"
      }
    ]
  },
  {
    "stop_name": "Boulogne Jean Jaurès",
    "foundUniqueStops": [
      {
        "stop_unique_id": "117039",
        "stop_name": "Jaurès",
        "stop_lon": 48.88273813184986,
        "stop_lat": 2.3701223122811044,
        "id_red_zdc": "71940"
      },
      {
        "stop_unique_id": "110025",
        "stop_name": "Boulogne - Jean Jaurès",
        "stop_lon": 48.84200534636367,
        "stop_lat": 2.238887328515536,
        "id_red_zdc": "71147"
      }
    ]
  },
  {
    "stop_name": "Villiers",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107027",
        "stop_name": "Fort d'Aubervilliers",
        "stop_lon": 48.91494975634199,
        "stop_lat": 2.4044090887818634,
        "id_red_zdc": "73695"
      },
      {
        "stop_unique_id": "107029",
        "stop_name": "Aubervilliers Pantin - Quatre Chemins",
        "stop_lon": 48.90378388011075,
        "stop_lat": 2.392221221171115,
        "id_red_zdc": "72460"
      },
      {
        "stop_unique_id": "117093",
        "stop_name": "Mairie d'Aubervilliers",
        "stop_lon": 48.913710544615356,
        "stop_lat": 2.3807356063950937,
        "id_red_zdc": "72524"
      },
      {
        "stop_unique_id": "103067",
        "stop_name": "Villiers",
        "stop_lon": 48.881094426231876,
        "stop_lat": 2.3147210047461946,
        "id_red_zdc": "71403"
      }
    ]
  },
  {
    "stop_name": "Porte de la Chapelle",
    "foundUniqueStops": [
      {
        "stop_unique_id": "112085",
        "stop_name": "Porte de la Chapelle",
        "stop_lon": 48.898392952351074,
        "stop_lat": 2.3593939011158835,
        "id_red_zdc": "72064"
      },
      {
        "stop_unique_id": "102055",
        "stop_name": "La Chapelle",
        "stop_lon": 48.88439967613092,
        "stop_lat": 2.360399500808731,
        "id_red_zdc": "71434"
      }
    ]
  },
  {
    "stop_name": "Marcadet - Poissonniers",
    "foundUniqueStops": [
      {
        "stop_unique_id": "112081",
        "stop_name": "Marcadet-Poissonniers",
        "stop_lon": 48.89150083893872,
        "stop_lat": 2.3496204845329847,
        "id_red_zdc": "71511"
      },
      {
        "stop_unique_id": "107049",
        "stop_name": "Cadet",
        "stop_lon": 48.87600859287756,
        "stop_lat": 2.344572482089328,
        "id_red_zdc": "71351"
      }
    ]
  },
  {
    "stop_name": "Assemblée Nationale",
    "foundUniqueStops": [
      {
        "stop_unique_id": "339",
        "stop_name": "Nation",
        "stop_lon": 48.848237109475654,
        "stop_lat": 2.3958235888261252,
        "id_red_zdc": "71673"
      },
      {
        "stop_unique_id": "106047",
        "stop_name": "Nationale",
        "stop_lon": 48.83321671877524,
        "stop_lat": 2.362856399519012,
        "id_red_zdc": "71043"
      },
      {
        "stop_unique_id": "112059",
        "stop_name": "Assemblée Nationale",
        "stop_lon": 48.86078696169296,
        "stop_lat": 2.320998191965805,
        "id_red_zdc": "73642"
      }
    ]
  },
  {
    "stop_name": "Esplanade de la Défense",
    "foundUniqueStops": [
      {
        "stop_unique_id": "390",
        "stop_name": "La Défense",
        "stop_lon": 48.89218707551516,
        "stop_lat": 2.2370180563950135,
        "id_red_zdc": "71517"
      },
      {
        "stop_unique_id": "101079",
        "stop_name": "Esplanade de la Défense",
        "stop_lon": 48.88813847667783,
        "stop_lat": 2.249792769009765,
        "id_red_zdc": "71485"
      }
    ]
  },
  {
    "stop_name": "Rome",
    "foundUniqueStops": [
      {
        "stop_unique_id": "114059",
        "stop_name": "Miromesnil",
        "stop_lon": 48.87368520970193,
        "stop_lat": 2.3144679895687355,
        "id_red_zdc": "71346"
      },
      {
        "stop_unique_id": "102067",
        "stop_name": "Rome",
        "stop_lon": 48.88233342277264,
        "stop_lat": 2.321801731063168,
        "id_red_zdc": "71408"
      }
    ]
  },
  {
    "stop_name": "La Chapelle",
    "foundUniqueStops": [
      {
        "stop_unique_id": "112085",
        "stop_name": "Porte de la Chapelle",
        "stop_lon": 48.898392952351074,
        "stop_lat": 2.3593939011158835,
        "id_red_zdc": "72064"
      },
      {
        "stop_unique_id": "102055",
        "stop_name": "La Chapelle",
        "stop_lon": 48.88439967613092,
        "stop_lat": 2.360399500808731,
        "id_red_zdc": "71434"
      }
    ]
  },
  {
    "stop_name": "Blanche",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107081",
        "stop_name": "Maison Blanche",
        "stop_lon": 48.82218265762191,
        "stop_lat": 2.3585513482022034,
        "id_red_zdc": "70645"
      },
      {
        "stop_unique_id": "102063",
        "stop_name": "Blanche",
        "stop_lon": 48.88355628923431,
        "stop_lat": 2.3333796724221587,
        "id_red_zdc": "71420"
      }
    ]
  },
  {
    "stop_name": "Nationale",
    "foundUniqueStops": [
      {
        "stop_unique_id": "339",
        "stop_name": "Nation",
        "stop_lon": 48.848237109475654,
        "stop_lat": 2.3958235888261252,
        "id_red_zdc": "71673"
      },
      {
        "stop_unique_id": "106047",
        "stop_name": "Nationale",
        "stop_lon": 48.83321671877524,
        "stop_lat": 2.362856399519012,
        "id_red_zdc": "71043"
      },
      {
        "stop_unique_id": "112059",
        "stop_name": "Assemblée Nationale",
        "stop_lon": 48.86078696169296,
        "stop_lat": 2.320998191965805,
        "id_red_zdc": "73642"
      }
    ]
  },
  {
    "stop_name": "Porte de Saint-Ouen",
    "foundUniqueStops": [
      {
        "stop_unique_id": "430",
        "stop_name": "Saint-Ouen",
        "stop_lon": 48.90456638896682,
        "stop_lat": 2.3226263683342263,
        "id_red_zdc": "72126"
      },
      {
        "stop_unique_id": "114071",
        "stop_name": "Porte de Saint-Ouen",
        "stop_lon": 48.89737479459521,
        "stop_lat": 2.3289428127798923,
        "id_red_zdc": "72078"
      }
    ]
  },
  {
    "stop_name": "Asnières - Gennevilliers Les Courtilles",
    "foundUniqueStops": [
      {
        "stop_unique_id": "113079",
        "stop_name": "Les Courtilles",
        "stop_lon": 48.930757002402125,
        "stop_lat": 2.2841744482832955,
        "id_red_zdc": "72286"
      },
      {
        "stop_unique_id": "103067",
        "stop_name": "Villiers",
        "stop_lon": 48.881094426231876,
        "stop_lat": 2.3147210047461946,
        "id_red_zdc": "71403"
      }
    ]
  },
  {
    "stop_name": "Mairie de Saint-Ouen",
    "foundUniqueStops": [
      {
        "stop_unique_id": "430",
        "stop_name": "Saint-Ouen",
        "stop_lon": 48.90456638896682,
        "stop_lat": 2.3226263683342263,
        "id_red_zdc": "72126"
      },
      {
        "stop_unique_id": "114075",
        "stop_name": "Mairie de Saint-Ouen",
        "stop_lon": 48.91258349677915,
        "stop_lat": 2.3342940159066474,
        "id_red_zdc": "72168"
      }
    ]
  },
  {
    "stop_name": "Pont Marie (Cité des Arts)",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107065",
        "stop_name": "Pont Marie",
        "stop_lon": 48.85357547245371,
        "stop_lat": 2.357162568881775,
        "id_red_zdc": "71217"
      },
      {
        "stop_unique_id": "104055",
        "stop_name": "Cité",
        "stop_lon": 48.854936379230175,
        "stop_lat": 2.347321318407715,
        "id_red_zdc": "71223"
      }
    ]
  },
  {
    "stop_name": "Maison Blanche",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107081",
        "stop_name": "Maison Blanche",
        "stop_lon": 48.82218265762191,
        "stop_lat": 2.3585513482022034,
        "id_red_zdc": "70645"
      },
      {
        "stop_unique_id": "102063",
        "stop_name": "Blanche",
        "stop_lon": 48.88355628923431,
        "stop_lat": 2.3333796724221587,
        "id_red_zdc": "71420"
      }
    ]
  },
  {
    "stop_name": "Cadet",
    "foundUniqueStops": [
      {
        "stop_unique_id": "112081",
        "stop_name": "Marcadet-Poissonniers",
        "stop_lon": 48.89150083893872,
        "stop_lat": 2.3496204845329847,
        "id_red_zdc": "71511"
      },
      {
        "stop_unique_id": "107049",
        "stop_name": "Cadet",
        "stop_lon": 48.87600859287756,
        "stop_lat": 2.344572482089328,
        "id_red_zdc": "71351"
      }
    ]
  },
  {
    "stop_name": "Fort d'Aubervilliers",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107027",
        "stop_name": "Fort d'Aubervilliers",
        "stop_lon": 48.91494975634199,
        "stop_lat": 2.4044090887818634,
        "id_red_zdc": "73695"
      },
      {
        "stop_unique_id": "103067",
        "stop_name": "Villiers",
        "stop_lon": 48.881094426231876,
        "stop_lat": 2.3147210047461946,
        "id_red_zdc": "71403"
      }
    ]
  },
  {
    "stop_name": "Aubervilliers-Pantin Quatre Chemins",
    "foundUniqueStops": [
      {
        "stop_unique_id": "107029",
        "stop_name": "Aubervilliers Pantin - Quatre Chemins",
        "stop_lon": 48.90378388011075,
        "stop_lat": 2.392221221171115,
        "id_red_zdc": "72460"
      },
      {
        "stop_unique_id": "103067",
        "stop_name": "Villiers",
        "stop_lon": 48.881094426231876,
        "stop_lat": 2.3147210047461946,
        "id_red_zdc": "71403"
      }
    ]
  },
  {
    "stop_name": "Saint-Ouen",
    "foundUniqueStops": [
      {
        "stop_unique_id": "430",
        "stop_name": "Saint-Ouen",
        "stop_lon": 48.90456638896682,
        "stop_lat": 2.3226263683342263,
        "id_red_zdc": "72126"
      },
      {
        "stop_unique_id": "114075",
        "stop_name": "Mairie de Saint-Ouen",
        "stop_lon": 48.91258349677915,
        "stop_lat": 2.3342940159066474,
        "id_red_zdc": "72168"
      },
      {
        "stop_unique_id": "114071",
        "stop_name": "Porte de Saint-Ouen",
        "stop_lon": 48.89737479459521,
        "stop_lat": 2.3289428127798923,
        "id_red_zdc": "72078"
      }
    ]
  },
  {
    "stop_name": "Mairie d'Aubervilliers",
    "foundUniqueStops": [
      {
        "stop_unique_id": "117093",
        "stop_name": "Mairie d'Aubervilliers",
        "stop_lon": 48.913710544615356,
        "stop_lat": 2.3807356063950937,
        "id_red_zdc": "72524"
      },
      {
        "stop_unique_id": "103067",
        "stop_name": "Villiers",
        "stop_lon": 48.881094426231876,
        "stop_lat": 2.3147210047461946,
        "id_red_zdc": "71403"
      }
    ]
  }
]
```
