---
slug: '2024-03-10'
title: '2024-03-10'
---

# 2024-03-10

C'est le grand jour, hier, j'ai enfin ajouté le code pour vérifier si le joueur a fini, je peux donc faire une modale de
fin de partie.

Avec Bootstrap, c'est plutôt rapide.

Maintenant, j'ai bien envie d'améliorer le champ de recherche, je prépare le code pour pouvoir faire du TDD
tranquillement
sur le filtre de la liste déroulante.
J'ajoute plusieurs règles :

- Filtrer d'abord par ce qui commence par la recherche, puis ordre alphabétique le reste
- Ne pas prendre en compte les accents
- Ne pas tenir compte des tirets vs espaces

Je suis plutôt content du résultat. Il faudrait ajouter le fait de retirer les stations déjà ajoutées.

Je me rends compte que le visuel a quand même bien évolué.
J'aimerais bien pouvoir garder une trace de tout ça. Je me lance sur un script.
Est-ce que je vais encore utiliser puppeteer ? En cherchant rapidement, je
trouve [pageres](https://github.com/sindresorhus/pageres),
mais je vois que derrière ça utilise puppeteer. Bon ça doit être encore la norme.

J'ai rapidement un résultat convaincant, maintenant la partie moins fun c'est d'aller sur chaque version et de lancer le
script.
Puis, ça serait bien que ça se fasse automatiquement à chaque release minor ou major.

Pour finir la journée, j'installe un linter ça manque.
