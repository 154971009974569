---
slug: '2024-04-09'
title: '2024-04-09'
---

# 2024-04-09

Finalement, ce midi, j'ai regardé le format des données et je me suis rendu compte d'une heure que j'ai faite hier. Je
ne pensais pas qu'il y avait de lien entre les `stops` et les emplacements généralisés des gares et stations, mais en
fait si. Dans les `stops`, nous avons le `parent_station`, et dans les emplacements, nous avons les `zdA`, et `zdC`, qui
correspondent respectivement à : zone de Correspondance et zone d'Arrêts.

Prenons un exemple, la station `Place de Clichy` dans les stops a pour `parent_station` :

- `Place de Clichy - Caulaincourt` (bus) : `IDFM:71435` (zdC)
- `Place de Clichy` (metro et bus) : `IDFM:71435` (zdC)

Un exemple où nous avons eu un problème hier : `Javel`, nous avons bien son `parent_station` qui correspond au ref zdC.
Intéressant, je peux donc modifier le script et éviter toute la logique superflue que j'avais mise en place.

Finalement, si nous prenons par exemple la station `Have-Caumartin`, qui a comme `parent_station` `IDFM:482368`, qui
correspond à un autre stop `IDFM:482368`, qui lui n'a pas de `parent_station`, dont nous ne trouvons pas de trace
dans les emplacements généralisés. La méthode que j'ai développée à base de Regex a le mérite de fonctionner, je la
laisse ainsi.
