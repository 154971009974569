---
slug: '2024-04-12'
title: '2024-04-12'
---

# 2024-04-14

J'aimerais merger la PR aujourd'hui sur l'import et l'export des données provenant de GTFS.
J'ai des tests liés à mon environnement (charge des fichiers localement) que je dois modifier ou supprimer, car je ne
veux pas publier les données d'IDFM.

J'ai aussi envie d'harmoniser les noms, il y a des mélanges entre `stops` et `stations`. Je ne suis pas tout à fait au
clair sur le nommage.

Bon, j'ai mergé sans harmoniser finalement, je le ferai plus tard. J'ai aussi skipé les tests qui ne sont pas jouables
sans les données.
J'ai d'ailleurs eu des erreurs de flaky tests, que j'ai pu corriger en ajoutant l'option `--no-file-parallelism`

Maintenant, il est temps d'utiliser les nouvelles données, je migre la class Graph, puis pick-station. Il reste plus que
tout ce qui est affichage à migrer.
