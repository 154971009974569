---
slug: '2024-03-09'
title: '2024-03-09'
---

# 2024-03-09

Première envie de la journée, changer le css, je me renseigne sur uno.css,
mais je ne suis pas convaincu sur le fait d'être productif.
Je vais partir sur Bootstrap.

Migration faîte et MEP faite. Je me dis que ça serait bien d'avoir l'historique du design de toutes les versions.
Je garde ça dans le coin de ma tête.
Pourquoi pas rajouter l'info dans le changelog ?
Est-ce que je fais en sorte de générer ça automatiquement ?

Maintenant que je suis sur bootstrap, je vais m'attaquer au champ de recherche.
Actuellement, je suis sur un select, mais ce n'est pas le top niveau recherche.
Je passe sur un champ input avec un dropdown. Je suis plutôt convaincu du résultat.

Je me suis rendu compte il y a quelques jours que j'ai un souci avec la station "Champs-Élysées - Clemenceau" qui est en
double.
Une version avec un accent à Clémenceau et une sans. Il va falloir améliorer le script "merge-duplicate-stations".

Avec ce nouveau champ de recherche, le fichier "script", qui est plutôt mon fichier index ou front.js, a bien grossi.
Je me lance dans un refactoring pour séparer tout ce qui concerne la carte dans une class dans un fichier distinct.

J'ai passé un peu de temps à chercher comment ajouter au Dijkstra le cout de changement de ligne.

Finalement, je me suis rendu compte que pour savoir si le joueur a relié les deux stations est plutôt simple, mais ça
attendra demain.
